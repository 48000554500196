<!--
 * @Author: your name
 * @Date: 2021-07-23 09:01:17
 * @LastEditTime: 2021-07-23 15:43:14
 * @LastEditors: Please set LastEditors
 * @Description: 企业财报页面
 * @FilePath: /information-vue/code/src/views/workInformation/financialReport/indexBalance.vue
-->

<template>
  <PageContainer>
<!--    <VnotLogin-->
<!--      v-if="!show"-->
<!--      title="企业情报"-->
<!--      text="企业情报栏目围绕企业情报本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。"-->
<!--      tableTitle="最新企业情报"-->
<!--      :showTips="showTips"-->
<!--    />-->
    <template >
      <div style="display: flex;">
        <div style="width: 200px" >
          <div style="background: #ffffff; padding: 10px">
            <div>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="整车企业" name="整车企业"></el-tab-pane>
                <el-tab-pane label="零部件企业" name="零部件企业"></el-tab-pane>
              </el-tabs>
            </div>
            <div>
              <el-input
                placeholder="输入企业名称"
                size="small"
                v-model="enterpriseName"
                @input="searchInpt"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search"
                  style="cursor: pointer;"
                  @click="archiveReport"
                ></i>
              </el-input>
            </div>
          </div>
          <div style="margin-top: 10px; background: #ffffff; padding: 10px">
            <template v-for="item in filterData">
              <div :key="item.firstChar">
                <template v-for="(citem, index) in item.org">
                  <div
                    flex="sb"
                    style="
                      padding: 10px 0;
                      border-bottom: 1px solid #efefef;
                      color: #d3d3d3;
                      cursor: pointer;
                    "
                    :style="citem.check ? 'display:none' : 'display:flex'"
                    :key="citem.id"
                    @click="filterFun(citem.enterpriseName)"
                  >
                    <span style="color: #000000">{{
                      citem.enterpriseName
                    }}</span>
                    <span v-if="index == 0">{{ item.firstChar }}</span>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>

        <div style="flex: 1; margin-left: 10px">
          <div style="padding: 0 10px;background: #fff; ">
            <div class="dropdown-box">
              <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ yearDate
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="全部年份"
                    >全部年份</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="item"
                    v-for="item in year"
                    :key="item"
                    >{{ item }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div
              style="padding: 10px"
              v-for="item in filterTableData"
              :key="item.id"
            >
              <div
                style="
                  margin: 10px;
                  color: #0D57BC;
                  font-size: 16px;
                  font-weight: 600;
                "
              >
                {{ item.year }}
              </div>
              <a
                  style="
                  border-bottom: 1px solid #efefef;
                  padding: 10px;
                  overflow:hidden;
                "
                  flex='sb'
                  v-for="citem in item.fileInfo"
                  :key="citem.fileUrl"
                  :href="citem.fileUrl"
                  target="_blank"
              >
               <span  class="text-overflow">{{ citem.fileName }}</span>
               <span style="color:#333"> {{citem.createTime}}</span>
              </a>
            </div>
            <!-- <div style="padding: 10px">
              <div
                style="
                  margin: 10px;
                  color: #0D57BC;
                  font-size: 16px;
                  font-weight: 600;
                "
              >
                2021年
              </div>
              <div style="border-bottom: 1px solid #efefef; padding: 10px">
                东风汽车2021年Q1财报
              </div>
            </div>
            <div style="padding: 10px">
              <div
                style="
                  margin: 10px;
                  color: #0D57BC;
                  font-size: 16px;
                  font-weight: 600;
                "
              >
                2021年
              </div>
              <div style="border-bottom: 1px solid #efefef; padding: 10px">
                东风汽车2021年Q1财报
              </div>
            </div>
            <div style="padding: 10px">
              <div
                style="
                  margin: 10px;
                  color: #0D57BC;
                  font-size: 16px;
                  font-weight: 600;
                "
              >
                2021年
              </div>
              <div style="border-bottom: 1px solid #efefef; padding: 10px">
                东风汽车2021年Q1财报
              </div>
            </div>
            <div style="padding: 10px">
              <div
                style="
                  margin: 10px;
                  color: #0D57BC;
                  font-size: 16px;
                  font-weight: 600;
                "
              >
                2021年
              </div>
              <div style="border-bottom: 1px solid #efefef; padding: 10px">
                东风汽车2021年Q1财报
              </div>
            </div>
            <div style="padding: 10px">
              <div
                style="
                  margin: 10px;
                  color: #0D57BC;
                  font-size: 16px;
                  font-weight: 600;
                "
              >
                2021年
              </div>
              <div style="border-bottom: 1px solid #efefef; padding: 10px">
                东风汽车2021年Q1财报
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 企业情报动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, ydlTk} from "@/utils/auth.js";
import {
  archiveReport,
  queryGroupYears,
  queryFinancialReport
} from "@/api/enterpriseAnalysis.js";
import {buryPoint} from "@/api/common";
export default {
  // components: {
  //   VnotLogin,
  // },
  data() {
    return {
      activeName: "整车企业",
      showTips: [
        {
          title: "企业情报动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "企业情报库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "企业情报梳理",
          text: "专家梳理整合，真正读懂企业情报脉络",
        },
        {
          title: "企业情报大数据",
          text: "用数据深度理解企业情报结构和趋势",
        },
      ],
      tableData: [],
      filterTableData: [],
      show: false,
      list: [],
      enterpriseName: "",
      yearDate: "全部年份",
      year: [],
      filterData: [],
      timer: null,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "企业情报",
        userId: ""
      },
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    this.queryGroupYears();

    this.queryFinancialReport()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    handleClick(tab) {
      this.filterData = [];
      this.activeName = tab.name;
      this.enterpriseName = "";
      this.yearDate = "全部年份";
      this.queryFinancialReport();
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },

    //跳转
    toHref(newUrl) {

       window.open(newUrl.href, "_blank");

    },
    //年份切换
    handleCommand(command) {
      this.yearDate = command;
      if (command == "全部年份") {
        this.filterTableData = this.tableData;
      } else {
        this.filterTableData = this.tableData.filter((item) => {
          return item.year == command;
        });
      }
    },
    //根据年份获取数据
    archiveReport() {
      let data = {
        enterpriseName: this.enterpriseName,
        enterpriseType: this.activeName,
      };
      archiveReport(data).then((res) => {
        if (res.ok) {
          this.tableData = res.data;
          this.filterTableData = res.data;
          console.log(res.data,'dgdg')
        }
      });
    },
    //获取所有年份
    queryGroupYears() {
      queryGroupYears().then((res) => {
        if (res.ok) {
          this.year = res.data;
        }
      });
    },
    //输入筛选
    searchInpt(e) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        for (let i = 0; i < this.filterData.length; i++) {
          this.filterData[i].org.filter((item) => {
            if (
              item.enterpriseName.toLowerCase().indexOf(e.toLowerCase()) == -1
            ) {
              this.$set(item, "check", true);
            } else {
              this.$set(item, "check", false);
            }
          });
        }
      }, 500);
    },
    //侧边栏筛选
    filterFun(name) {
      this.enterpriseName = name;
      this.archiveReport();
    },
    //企业财报初始化
    queryFinancialReport(){
       let data = {
        // enterpriseName: this.enterpriseName,
        enterpriseType: this.activeName,
      };
      queryFinancialReport(data).then(res=>{
          this.list = res.data.map((item) => {
          item.org = JSON.parse(item.org);
          return item;
        });
        this.filterData = Object.assign([], this.list);
      })
       this.archiveReport();
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__nav {
  float: none;
  margin: 0 10px;
}
::v-deep .el-tabs__active-bar {
  display: none;
}
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-input__inner {
  border-radius: 50px;
}
::v-deep .el-checkbox__input {
  display: none;
}
.box {
  //   padding: 0 10%;

  .box_box {
    padding: 10px;
    background: #ffffff;
  }
  .box_box_title {
    margin-top: 10px;
    background: #ffffff;
    p {
      font-weight: 600;
      color: #000;
      font-size: 16px;
    }
    .flex_col {
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.dropdown-box {
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 58px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #0D57BC;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
