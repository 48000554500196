<template>
  <router-view v-if="$route.name == '标准征求意见稿内容'" />
  <PageContainer v-else breadcrumb>
    <VlistTitle big-ttitle="标准标准征求意见/报批稿列表" small-tip="会员专享精华内容推荐" :bgurl="bgurl" />
    <Vlist :list="solicitCommentsData" :page="solicitCommentsPage" :page-data="solicitCommentsPage" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref" />
  </PageContainer>
</template>
<script>
import VlistTitle from '@/components/listTitle.vue'
import { solicitComments } from '@/api/standards.js'
import Vlist from '@/components/list'
export default {
  components: {
    VlistTitle,
    Vlist
  },
  data() {
    return {
      bgurl: require('@/assets/img/bg-1.jpg'),
      solicitCommentsData: [],
      solicitCommentsPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      }
    }
  },
  created() {
    this.solicitComments(this.solicitCommentsPage.pages)
  },
  methods: {
    solicitComments(params) {
      solicitComments(params).then((res) => {
        if (res.ok) {
          this.solicitCommentsData = res.data.list
          this.solicitCommentsPage.total = res.data.count
        }
      })
    },
    handleSizeChange(val) {
      this.solicitCommentsPage.pages.pageSize = val
      this.solicitComments(this.solicitCommentsPage.pages)
    },
    handleCurrentChange(val) {
      this.solicitCommentsPage.pages.page = val
      this.solicitComments(this.solicitCommentsPage.pages)
    },
    toHref(item) {
      this.$router.push('/standards/dynamic/contentComments/' + item.id)
    }
  }
}
</script>
