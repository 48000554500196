<template>
  <div class="login">
    <div class="login-img">
      <img src="../../assets/img/fawerlogo.png" alt="" class="login-icon">
    </div>
    <div class="login-inner">
      <img src="../../assets/img/fawertm2.png" alt="" width="94" height="101">
      <h1>富奥竞争情报系统</h1>
      <el-form ref="form" :model="form" :rules="rules" class="login-form">
        <template v-if="swicthName == '手机号登录'">
          <el-form-item prop="username">
            <el-input v-model="form.username" prefix-icon="el-icon-user" placeholder="请输入帐号" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="form.password" prefix-icon="el-icon-lock" placeholder="请输入密码" type="password" />
          </el-form-item>
          <el-form-item prop="remember">
            <el-checkbox v-model="form.remember" label="记住密码" />
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item prop="phone">
            <el-input v-model="form.phone" prefix-icon="el-icon-user" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item prop="yzm">
            <el-input v-model="form.yzm" prefix-icon="el-icon-lock" placeholder="请输入验证码" />
            <el-button type="text" class="yzm-btn">获取验证码</el-button>
          </el-form-item>
        </template>
        <el-form-item class="text-center">
          <el-button type="primary" style="width: 100%" :loading="loading" @click="onSubmit('form')">登录</el-button>
          <el-button type="text" @click="loginSwitch()">{{
            swicthName
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { fuAoLogin } from '@/api/login.js'
import {
  setUserInfo,
  getUserInfo,
  restUserInfo,
  setUserState,
  restUserState,
  getUserState,
  getSecrecy,
  setSecrecy,
  restSecrecy,
  removeUserState
} from '@/utils/auth.js'
export default {
  data() {
    var validateUsername = (rule, value, callback) => {
      if (this.swicthName == '手机号登录') {
        if (value === '') {
          callback(new Error('请输入帐号'))
        } else {
          callback()
        }
      }
    }

    var validatePassword = (rule, value, callback) => {
      if (this.swicthName == '手机号登录') {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else {
          callback()
        }
      }
    }
    var validateUserPhone = (rule, value, callback) => {
      if (this.swicthName == '账号密码登录') {
        if (value === '') {
          callback(new Error('请输入手机号码'))
        } else if (!/^1[345789][0-9]{9}$/.test(value)) {
          callback(new Error('手机号码格式不正确'))
        } else {
          callback()
        }
      }
    }
    var validateYzm = (rule, value, callback) => {
      if (this.swicthName == '账号密码登录') {
        if (value === '') {
          callback(new Error('请输入验证码'))
        } else {
          callback()
        }
      }
    }
    // username: "adminceshi",
    //   password: "AI11209",
    return {
      form: {
        username: '',
        password: '',
        phone: '',
        yzm: '',
        remember: false
      },
      rules: {
        username: [{ validator: validateUsername, trigger: 'blur' }],
        password: [{ validator: validatePassword, trigger: 'blur' }],
        phone: [{ validator: validateUserPhone, trigger: 'blur' }],
        yzm: [{ validator: validateYzm, trigger: 'blur' }]
      },
      flag: false,
      loading: false,
      swicthName: '手机号登录'
    }
  },
  created() {
    const userInfo = getUserInfo()
    // this.form.remember = userInfo ? userInfo.remember : false;
    this.form.remember = getUserState()
    if (getUserState()) {
      this.form.username = getSecrecy().username
      this.form.password = getSecrecy().password
    }
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const form = {}
          if (this.swicthName == '手机号登录') {
            form.username = this.form.username
            form.password = this.form.password
            form.rememberMe = this.form.remember
          } else {
            form.phone = this.form.phone
            form.yzm = this.form.yzm
          }
          fuAoLogin(form).then((res) => {
            if (res.ok) {
              this.$message({
                showClose: true,
                message: '登录成功',
                type: 'success'
              })
              form.remember = this.form.remember
              const data = Object.assign(form, res.data)
              if (form.remember) {
                setUserInfo(data)
                setUserState(true)
                setSecrecy(form)
              } else {
                restUserInfo(data)
                removeUserState()
                restSecrecy(form)
              }
              this.flag = false
              this.$router.push('/')
            }
            this.loading = false
          })
        }
      })
    },
    close(formName) {
      this.flag = false
      this.$refs[formName].resetFields()
      this.swicthName = '手机号登录'
      this.$emit('close')
    },
    show() {
      this.flag = true
    },
    loginSwitch() {
      this.swicthName =
                this.swicthName == '手机号登录' ? '账号密码登录' : '手机号登录'
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
.login {
    display: flex;
    height: 100vh;
}
.login-img {
    position: relative;
    flex: 1;
    height: 100%;
    background: url(../../assets/img/b711fda0c369ba605.png) no-repeat center
        right;
    background-size: cover;
    .login-icon {
        position: absolute;
        top: 33px;
        left: 47px;
        width: 323px;
        height: 62px;
    }
}
.login-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 29.861%;
    padding: 0 35px;
    height: 100%;
    img {
        align-self: center;
    }
    h1 {
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #0D57BC;
        margin: 19px 0 28px;
    }
}
</style>
