<template>
  <router-view v-if="$route.name == '经营情况详情'" />
  <PageContainer v-else>
    <template slot="actionNav">
      <div class="tabs">
        <actionTabs action-nav-information="businessConditions" />
      </div>
    </template>
    <el-tabs v-model="form.type" @tab-click="getSelections" class="tabS">
      <el-tab-pane :key="'zc'" label="整车" name="整车" />
      <el-tab-pane :key="'lbj'" label="零部件" name="零部件" />

    </el-tabs>
    <div class="capacity">

      <div class="content-box">
        <div class="capacity-contain">
          <div ref="bottomElement" class="bottom">
            <el-tabs v-model="form.stock" @tab-click="getSelections" v-if="form.type==='整车'">
              <el-tab-pane :key="'a'" label="A股" name="A股" />
              <el-tab-pane :key="'g'" label="港股" name="港股" />
              <el-tab-pane :key="'m'" label="美股" name="美股" />
            </el-tabs>
            <div class="list-title">
              <div class="topRadio">
                <el-radio-group v-model="form.name" size="small" @change="getTableData">
                  <el-radio-button label="利润表" />
                  <el-radio-button label="资产负债表" />
                  <el-radio-button label="现金流量表" />
                </el-radio-group>
              </div>
            </div>
            <div class="list">
              <div class="list-sele">
                <div class="item">
                  <span class="checkTitle">企业</span>
                  <el-select
                    v-model="form.enterprise"
                    collapse-tags
                    multiple
                    placeholder="请选择类型"
                    size="small"
                    class="g-form-input"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in enterpriseList"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                  <!-- <SearchSelect placeholder="请选择类型" v-model="form.typeList" :options="typeList" size="small" /> -->
                </div>
                <div class="item item-model">
                  <span class="checkTitle">年份</span>
                  <el-select
                    v-model="form.reportDate"
                    collapse-tags
                    multiple
                    placeholder="请选择年份"
                    size="small"
                    class="g-form-input"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in reportDateList"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </div>
                <div class="item">
                  <span class="checkTitle">报告期</span>
                  <el-select
                    v-model="form.reportPeriod"
                    collapse-tags
                    multiple
                    placeholder="请选择类型"
                    size="small"
                    class="g-form-input"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in reportPeriodList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>

                <div class="itembutton" flex="c">
                  <el-button type="primary" size="mini" @click="search()">查询</el-button>
                  <span><el-button type="primary" size="mini" @click="reset()">重置</el-button></span>
<!--                  <el-button-->
<!--                    v-has-role="'freeusers'"-->
<!--                    type="primary"-->
<!--                    plain-->
<!--                    :loading="excelLoading"-->
<!--                    @click="exportExcel"-->
<!--                  >导出-->
<!--                  </el-button>-->
                </div>
              </div>
              <!--                <div class="table-title">-->
              <!--                    {{ listtitle }}-->
              <!--                </div>-->
              <div v-loading="loading" class="tablelist">
                <tableIncome2 v-if="form.name === '利润表' && form.stock === '港股' " :table-data="tableData" :loading="listloading" />
                <tableIncome3 v-else-if="form.name === '利润表' && form.stock === '美股' " :table-data="tableData" :loading="listloading" />

                <tableIncome v-else-if="form.name === '利润表'" :table-data="tableData" :loading="listloading" />

                <tableBalance v-else-if="form.name === '资产负债表'" :table-data="tableData" :loading="listloading" />
                <tableCashFlow2 v-else-if="form.name === '现金流量表' && form.stock === '港股' " :table-data="tableData" :loading="listloading" />
                <tableCashFlow3 v-else-if="form.name === '现金流量表' && form.stock === '美股' " :table-data="tableData" :loading="listloading" />

                <tableCashFlow v-else-if="form.name === '现金流量表'" :table-data="tableData" :loading="listloading" />

              </div>
            </div>
          </div>
          <div v-if="operate" class="pages">
            <el-pagination
              :current-page="pageData.pages.page"
              :page-sizes="[10, 20, 30, 40, 100]"
              :page-size="pageData.pages.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
        <!--        <indexIncome v-if="radioValue === '利润表'" />-->
        <!--        <indexBalance v-if="radioValue === '资产负债表'" />-->
        <!--        <indexCashFlow v-if="radioValue === '现金流量表'" />-->
      </div>
    </div>
  </PageContainer>
</template>
<script>
import {
  exportExcel,
  getBusinessConditionsSections, getBusinessConditionsTable
} from '@/api/business.js'
// import Chart from "./charts.vue"
// import list from "./list.vue"
import tableBalance from './components/tableBalance.vue'
import { buryPoint } from '@/api/common'
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import { downloadFile } from '@/utils/download'
import tableIncome from './components/tableIncome.vue'
import tableIncome2 from './components/tableIncome2.vue'
import tableIncome3 from './components/tableIncome3.vue'

import actionTabs from '@/views/workInformation/components/actionTabs.vue'
import tableCashFlow from './components/tableCashFlow.vue'
import tableCashFlow2 from './components/tableCashFlow2.vue'
import tableCashFlow3 from './components/tableCashFlow3.vue'

// import {policyDataExport} from "@/api/policy";

// import actionTabs from '@/views/workInformation/components/actionTabs.vue'

export default {
  components: {
    tableCashFlow, actionTabs, tableIncome,
    tableBalance, tableCashFlow2, tableIncome2,
    tableIncome3,tableCashFlow3
  },
  data() {
    return {

      excelLoading: false,
      operate: true,
      listloading: false,
      listtitle: '',
      typefirstLabel: '',
      modelfirstLabel: '',
      enterfirstLabel: '',
      maxNum: '',
      orderType: '',
      mapList: [],
      reportDateList: [],
      tempcityList: [],
      tableHead: [],
      tableData: [],
      reportPeriodList: [{ label: '一季报', value: '一季报' }, { label: '中报', value: '中报' }, { label: '三季报', value: '三季报' }, { label: '年报', value: '年报' }],
      enterpriseList: [],
      pageData: {
        pages: {
          page: 1,
          pageSize: 20
        },
        total: 0
      },
      form: {
        reportDate: [],
        reportPeriod: [],
        enterprise: [],
        type: '整车',
        name: '利润表',
        stock: 'A股'
      },
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '企业情报',
        userId: ''
      },
      loading: false
    }
  },
  computed: {},
  watch: {
    radioValue: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        logRecords('企业情报', newVal, '3', '', '', '', '', '查询')
      }
    }
  },
  created() {
    this.permissionSetting()
    this.getTableData()
    this.getSelections()
  },
  mounted() {
    // this.getMapData()

  },
  methods: {

    getSelections() {
      this.form.enterprise = []
      if(this.form.type === '零部件'){
        this.form.stock = 'A股'
      }
      this.getTableData()
      getBusinessConditionsSections({ type: this.form.type, stock: this.form.stock }).then(res => {
        this.reportDateList = res.data.reportDateList
        this.enterpriseList = res.data.enterpriseList
      })
    },
    getTableData() {
      this.listloading = true

      if (this.form.enterprise.length !== 0) {
        this.form.enterprise = this.form.enterprise.join(',')
      }
      if (this.form.reportDate.length !== 0) {
        this.form.reportDate = this.form.reportDate.join(',')
      }
      if (this.form.reportPeriod.length !== 0) {
        this.form.reportPeriod = this.form.reportPeriod.join(',')
      }
      const form = {
        ...this.form,
        ...this.pageData.pages
      }
      getBusinessConditionsTable(form).then(res => {
        if (this.form.enterprise.length !== 0) {
          this.form.enterprise = this.form.enterprise.split(',')
        }
        if (this.form.reportDate.length !== 0) {
          this.form.reportDate = this.form.reportDate.split(',')
        }
        if (this.form.reportPeriod.length !== 0) {
          this.form.reportPeriod = this.form.reportPeriod.split(',')
        }

        this.tableData = res.data.list
        this.pageData.total = res.data.count
        this.listloading = false
      })
    },
    // getMapData() {
    //   MapData({ isOverview: false }).then(res => {
    //     this.mapList = res.data.mapData
    //     this.maxNum = res.data.maxNum
    //   })
    // },
    // handleCustomSortChange(orderType) {
    //   this.orderType = orderType
    //   this.getTableData()
    // },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val
      this.getTableData()
    },

    permissionSetting() {
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.operate = false
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.operate = false
            }
          }
        }
      } else {
        this.operate = false
      }
    },
    search() {
      this.loading = true
      if (getUserInfo()) {
        logRecords('企业情报', '国内汽车产能布局', '4', 'tt_enterprise_capacity', '', '', '', '查询')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
            this.loading = false
            return
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.formLog.userId = getUserInfo().userId
              buryPoint(this.formLog)
              openSy()
              this.loading = false
              return
            } else {
              this.loading = false
            }
          }
        }
      } else {
        this.showLogin()
        this.loading = false
        return
      }

      this.pageData.pages.page = 1
      this.getTableData()
      this.loading = false
    },
    reset() {
      this.pageData.pages = {
        pageNum: 1,
        pageSize: 20
      }
      this.form = {
        reportDate: [],
        reportPeriod: [],
        enterprise: [],
        type: '整车',
        name: '利润表'
      }
      this.getTableData()
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
      this.loading = false
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
      this.loading = false
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    sqsy(url) {
      const newUrl = this.$router.resolve(url)

      window.open(newUrl.href, '_blank')
    },
    exportExcel() {
      this.excelLoading = true
      if (this.form.enterprise.length !== 0) {
        this.form.enterprise = this.form.enterprise.join(',')
      }
      if (this.form.reportDate.length !== 0) {
        this.form.reportDate = this.form.reportDate.join(',')
      }
      if (this.form.reportPeriod.length !== 0) {
        this.form.reportPeriod = this.form.reportPeriod.join(',')
      }
      const form = {
        ...this.form
      }

      exportExcel(form).then((res) => {
        if (this.form.enterprise.length !== 0) {
          this.form.enterprise = this.form.enterprise.split(',')
        }
        if (this.form.reportDate.length !== 0) {
          this.form.reportDate = this.form.reportDate.split(',')
        }
        if (this.form.reportPeriod.length !== 0) {
          this.form.reportPeriod = this.form.reportPeriod.split(',')
        }
        var reader = new FileReader()
        reader.readAsText(res)
        reader.addEventListener('loadend', function(e) {
          try {
            JSON.parse(e.target.result)
            const msg = JSON.parse(e.target.result)
            self.$message({
              showClose: true,
              message: msg.message,
              type: 'error'
            })
          } catch (err) {
            downloadFile(res, '经营情况-利润表.xlsx')
          }
        })
      })
      this.excelLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}

.topRadio {
  padding-bottom: 10px;
  padding-top: 7px;

  ::v-deep {
    .el-radio-button {
      border: 0;
      border-radius: 4px;
    }

    .el-radio-button__inner {
      border: 0;
      border-radius: 4px;
      color: #333333;
      background: #f3f3f3;
    }

    .el-radio-button {
      margin-right: 12px;
    }

    .is-active .el-radio-button__inner {
      background: #3f76ea;
      color: #fff;
    }
  }
}
.capacity-top {
  width: 100%;
  background: #fff;
  margin-bottom: 10px;
  padding-top: 30px;
}

.capacity-contain {
  width: 100%;

  .bottom {
    width: 100%;
    background: #fff;
    ::v-deep {
      .el-tabs {
        padding: 10px 0 0 40px;
      }

      .el-tabs__active-bar {
        height: 1px;
      }

      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f3f5f7;
      }

      .el-tabs__header {
        margin: 0;
      }

      .el-tabs__item {
        padding: 0 16px;
        color: #606266;
        font-weight: normal;

        &:hover {
          font-weight: normal;
        }

        &.is-active {
          color: #3f76ea;
          font-weight: bold;
        }
      }

      .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
      .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
      .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
      .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
        padding-left: 0;
      }
    }
    .list {
      width: 100%;
      padding: 0 60px 10px 40px;

      .list-sele {
        display: flex;
        flex-wrap: wrap;
      }

      .item {
        padding-right: 30px;
        padding-top: 15px;
      }

      .itembutton {
        padding-top: 15px;

        button {
          width: 120px;
        }

        span button {
          background: #fff;
          border: 0;
          font-size: 12px;
          color: rgb(153, 153, 153);
        }
      }

      .el-select {
        width: 210px;
        padding-left: 10px;
      }

      .table-title {
        font-size: 14px;
        color: rgb(34, 34, 34);
        font-weight: bold;
        padding: 30px 0 20px 0;
      }
    }

    .list-title {
      font-size: 18px;
      color: rgb(34, 34, 34);
      font-weight: bold;
      padding: 15px 60px 0 40px;
    }
  }

  .pages {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    background: #fff;
  }
}
::v-deep .tabS .el-tabs__item {
  height: 30px;
  line-height: 20px;
}
</style>
