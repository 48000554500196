<template>
  <PageContainer links footer contact service class="home-container">
    <Banner />
    <Special />

    <div class="bottom-all-left-title">
      <h1>行业资讯</h1>
      <div class="flexMore">
        <el-input
          v-model="title"
          size="small"
          placeholder="输入关键词"
          @keyup.enter.native="toList"
        >
          <i slot="suffix" @click="toList" class="el-input__icon el-icon-search"></i>
        </el-input>
        <More
        :to="{
          path: '/index/list/IndustryAndReportList',
          query: {
            releaseColumn: '',
            category: 'industry',
          },
        }"
        :name="'行业资讯'"
        :logRecordsHomeMore="logRecordsHomeMore"
      />
      </div>
    </div>
    <div class="tabs">
    <el-tabs v-model="flag" @tab-click="handleClick">
        <el-tab-pane label="推荐" name="0"></el-tab-pane>
        <el-tab-pane label="行业资讯" name="1"></el-tab-pane>
        <el-tab-pane label="技术领域" name="2"></el-tab-pane>
        <el-tab-pane label="企业动态" name="3"></el-tab-pane>
        <el-tab-pane label="国际化" name="4"></el-tab-pane>
      </el-tabs>
    </div>
    <div v-loading="loadingSeach">
      <searchList noshowDefImg :flag="flag" tableIndex :showPage="showPage" showReleaseColumn :table-data="hyzxData" @toHref="toHref">
      </searchList>
    </div>
    <div class="jz">
      <div class="jz-more" @click="jzMoreBtn()">
       <span>{{ loadinginName }} <i :class="loadingIcon"></i></span>
      </div>
    </div>

    <template slot="right">
      <News
        :newPolicyData="newPolicyData"
        :newStandardData="newStandardData"
        :policyCount="policyCount"
        :standardCount="standardCount"
        :logRecords="logRecords"
        :logRecordsHomeMore="logRecordsHomeMore"
      />

      <div class="bottom-all-right-title">
        <h1>数据产销情况</h1>
        <More
          to="/data/dataReport/overview"
          :name="'数据产销情况'"
          :logRecordsHomeMore="logRecordsHomeMore"
        />
      </div>
      <ReportData
        :reportData="reportData"
        :logRecords="logRecords"
      />

      <div class="bottom-all-right-title">
        <h1>国际化数据</h1>
        <More
          to="/international/Information/index"
          :name="'国际化数据'"
          :logRecordsHomeMore="logRecordsHomeMore"
        />
      </div>
      <International
        :internationalData="internationalData"
        :logRecords="logRecords"
      />

      <div class="bottom-all-right-title">
        <h1>企业情报</h1>
        <More
          to="/workInformation/analysis/index"
          :name="'企业情报'"
          :logRecordsHomeMore="logRecordsHomeMore"
        />
      </div>
      <div class="link-container">
        <router-link to="/workInformation/analysis/index" target="_blank">分析报告</router-link>
        <router-link to="/workInformation/enterpriseInfo/list" target="_blank">企业信息</router-link>
        <router-link to="/workInformation/industry/index" target="_blank">产业布局</router-link>
        <router-link to="/workInformation/newcapacity/index" target="_blank">产能产销</router-link>
        <router-link to="/workInformation/newFinancialReport/index" target="_blank">企业财报</router-link>
        <router-link to="/workInformation/businessConditions/index" target="_blank">经营情况</router-link>
      </div>

      <div class="bottom-all-right-title">
        <h1>公告看板</h1>
        <More
          to="/announcement/dynamic/index"
          :name="'公告看板'"
          :logRecordsHomeMore="logRecordsHomeMore"
        />
      </div>
      <Announcement
        :announcementData="announcementData"
        :logRecords="logRecords"
      />

      <div class="bottom-all-right-title">
        <h1>热点专题</h1>
        <More
          to="/index/list/specialList"
          :name="'热点专题'"
          :logRecordsHomeMore="logRecordsHomeMore"
        />
      </div>
      <div class="rdzt-box">
        <div
          v-for="item in ttIndexSpecialPageSizeTwo"
          :key="item.id"
          class="box special"
        >
          <router-link
            class="rdzt-img-box"
            :to="'/index/detail/specialDetail/' + item.id"
            tag="div"
          >
            <img :src="item.specialUrl" :alt="item.specialName" />
            <!-- <p class="zt-title text-overflow">{{ item.specialName }}</p> -->
          </router-link>
        </div>
        <div
          class="box special"
        >
          <router-link
            class="rdzt-img-box"
            :to="{ path: '/policy/search/index', query: { policyCategory: ['261b69bbedc54b648d385c49f22f3ee4','2404291008343356'] } }"
            tag="div"
          >
            <img src="@/assets/images/yjhx.jpg" alt="" />
            <!-- <p class="zt-title text-overflow">{{ item.specialName }}</p> -->
          </router-link>
        </div>

        <div class="rdzt-img-line" />

        <div
          v-for="item in ttAdvertisingManagementdData"
          :key="item.id"
          class="box special"
        >
        <div class="rdzt-img-box" @click="clickAdvertising" v-if="item.imgUrl.indexOf('广告位') !== -1">
            <img :src="item.imgUrl" alt="">
        </div>
          <a
            v-else
            class="rdzt-img-box"
            :href="item.jumpLink"
            target="_blank"
          >
            <img :src="item.imgUrl" alt="">
          </a>
        </div>
      </div>

      <div class="bottom-all-right-title">
        <h1>会议信息</h1>
        <More
          to="/index/list/meetingList"
          :name="'会议信息'"
          :logRecordsHomeMore="logRecordsHomeMore"
        />
      </div>
      <Meeting />
    </template>
  </PageContainer>
</template>

<script>
import { fmtDate } from '@/utils/date.js'
// 获取首页请求接口
import {
  ttIndexSpecialPage,
  ttLocalWatchPage,
  ttIndustryInformationPage,
  ttVideoRecommendationPage,
  ttAdvertisingManagementPage,
  queryNewStandard,
  queryNewPolicy,
  queryHomePage,
  ttLinksPage,
  fetchHomePolicyCount,
  fetchHomeStandardCount,
  fetchHomeReportData,
  fetchHomeInternational,
} from '@/api/Index.js'
import { logRecords } from '@/utils/auth.js'
import { getInfoCount } from '@/api/announcement'

import searchList from "@/components/searchList.vue";
import Banner from './Modules/Banner';
import News from './Modules/News';
import ReportData from './Modules/Data';
import International from './Modules/International';
import Announcement from './Modules/Announcement';
import Special from './Modules/Special';
import Meeting from './Modules/Meeting';
import More from './Modules/More';

export default {
  components: {
    searchList,
    Banner,
    News,
    ReportData,
    International,
    Announcement,
    Special,
    Meeting,
    More,
  },
  data() {
    return {
      policyCount: {},
      standardCount: {},
      reportData: {},
      internationalData: [],
      announcementData: {},
      flag:"0",
      title:"",
      showTz: false,
      showPage:false,
      checkAlert: true,
      hyzxData: [],
      // 友情链接
      ttLinks: [],
      // 会议字典
      meetingDict: [],
       // 最新政策
       newPolicyData: [],
      // 最新标准
      newStandardData: [],
       // 本网观察
       ttLocalWatchPageSizeOne: [],
      // 专题管理
      ttIndexSpecialPageSizeTwo: [],
      // 广告管理
      ttAdvertisingManagementdData: [],
       // 最新快讯
       ttIndustryInformationPageSizeFive: [],
       // 视频
       ttVideoRecommendationPageSizeThree: [],
      // 会议值
      hyzxPage: {
        pageSize: 9,
        page: 1
      },
      zcAcName: '政策动态',
      bzAcName: '标准动态',
      loadinginName: '点击加载更多',
      loadingIcon:'el-icon-refresh',
      fjtzUrl: require('@/assets/img/fjtz.jpg'),
      loadingSeach: false,
    }
  },
  created() {
    this.hyzx()
    this.init()
  },
  methods: {
    clickAdvertising (){
      const h = this.$createElement;
        this.$msgbox({
          title: '【提示】咨询广告服务',
          message: h('div', null, [
            h('span', null, '联系电话：183-2251-1031 '),
            h('span', { style: 'margin-left:18px' }, '022-84370000-1582')
          ]),
          confirmButtonText: '知道了',
          callback: action => {

          }
        })
    },
    toList (){
      const query = {
        releaseColumn: '',
        category: 'industry',
        title: this.title
      };
      const { href } = this.$router.resolve({
        path: '/index/list/IndustryAndReportList',
        query: query
      });
      window.open(href, '_blank');
    },
    toHref (url,item){
      this.$router.push({ path: '/index/detail/IndustryDetail/' + item.id });
      this.logRecords('首页', '行业资讯', '1', 'tt_industry_information', item.id, '', item.title, '');
    },
    logRecords(column1, column2, type, tableName, articleId, attachName, articleName, inputBox, buttonName) {
      logRecords(column1, column2, type, tableName, articleId, attachName, articleName, inputBox, buttonName)
    },
    logRecordsHomeMore(name) {
      this.logRecords('首页', name, '3', '', '', '', '', '', '查看更多')
    },
    open() {
      this.showTz = false
      this.$alert('', {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false
      }).catch(() => {
        this.showTz = true
      })

      var el = document.querySelector('.el-message-box')
      if (el)el.style.cssText = 'width: 960px;height: 450px;background-image: url(' + this.fjtzUrl + ');background-size:960px 450px;'
      var el1 = document.querySelector(' .el-message-box__headerbtn .el-message-box__close')
      if (el1)el1.style.cssText = 'color: white;'
      var el2 = document.querySelector(' .el-message-box__headerbtn')
      if (el2)el2.style.cssText = ' top: 9px;right: 14px;'
    },
    init() {
      fetchHomePolicyCount().then((res) => {
        if (res.ok) {
          this.policyCount = res.data || {}
        }
      })
      fetchHomeStandardCount().then((res) => {
        if (res.ok) {
          this.standardCount = res.data || {}
        }
      })
      fetchHomeReportData({ overallSales: null }).then((res) => {
        if (res.ok) {
          this.reportData = res.data || {}
        }
      })
      fetchHomeInternational().then((res) => {
        if (res.ok) {
          this.internationalData = res.data || []
        }
      })
      getInfoCount().then((res) => {
        if (res.ok) {
          this.announcementData = res.data || {}
        }
      })
      // 本网观察 取第一个接口
      ttLocalWatchPage({ pageSize: 1, page: 1 }).then((res) => {
        if (res.ok) {
          this.ttLocalWatchPageSizeOne = res.data.list
        }
      })
      // 最新快讯
      ttIndustryInformationPage({
        pageSize: 5,
        page: 1,
        latestNews: '是'
      }).then((res) => {
        if (res.ok) {
          this.ttIndustryInformationPageSizeFive = res.data.list
          this.ttIndustryInformationPageSizeFive.forEach((item) => {
            item.updateTime = fmtDate('YYYY-mm-dd', item.updateTime)
            if (item.shortTitle && item.shortTitle.length > 0) {
              item.nowTitle = item.shortTitle
            } else {
              item.nowTitle = item.title
            }
          })
        }
      })
      // 视频
      ttVideoRecommendationPage({ pageSize: 4, page: 1 }).then((res) => {
        if (res.ok) {
          this.ttVideoRecommendationPageSizeThree = res.data.list
        }
      })
      // 最新政策
      queryNewPolicy({ page: 1, pageSize: 4 }).then((res) => {
        if (res.ok) {
          this.newPolicyData = res.data.list
          this.newPolicyData.forEach((item) => {
            item.updateTime = fmtDate('YYYY-mm-dd', item.updateTime)
          })
        }
      })
      // 最新标准
      queryNewStandard({ page: 1, pageSize: 4 }).then((res) => {
        if (res.ok) {
          this.newStandardData = res.data.list
          this.newStandardData.forEach((item) => {
            item.updateTime = fmtDate('YYYY-mm-dd', item.updateTime)
          })
        }
      })
      // standardDynamic({page: 1, pageSize: 4}).then((res) => {
      //   if (res.ok) {
      //     this.newStandardData = res.data.list;
      //     this.newStandardData.forEach((item) => {
      //       item.updateTime = fmtDate("YYYY-mm-dd", item.updateTime);
      //     });
      //   }
      // });
      // 专题管理  取2个数据
      ttIndexSpecialPage({ pageSize: 1, page: 1 }).then((res) => {
        if (res.ok) {
          this.ttIndexSpecialPageSizeTwo = res.data.list
        }
      })
      // 广告管理
      ttAdvertisingManagementPage({ page: 1, pageSize: 3 }).then((res) => {
        if (res.ok) {
          this.ttAdvertisingManagementdData = res.data.list
        }
      })
      ttLinksPage({ page: 1, pageSize: 8 }).then((res) => {
        if (res.ok) {
          this.ttLinks = res.data.list
        }
      })
    },
    handleClick(tab, event) {
      this.hyzxPage.page=1
      const page = { ...this.hyzxPage, flag: this.flag }
      this.loadingSeach = true
      queryHomePage(page).then((res) => {
        if (res.ok) {
          this.loadingSeach = false
          this.hyzxData = res.data.list
          this.hyzxData.forEach((item) => {
            item.updateTime = fmtDate('YYYY-mm-dd', item.updateTime)
          })
        } else {
          this.loadinginName = '加载失败'
        }
      })
    },
    // 行业咨询
    hyzx(flag) {
      const page = { ...this.hyzxPage, flag:this.flag }
      this.loadinginName = '加载中......'
      this.loadingIcon = 'el-icon-loading'
      queryHomePage(page).then((res) => {
        if (res.ok) {
          this.loadingIcon='el-icon-refresh'
          if (res.data.list.length > 0) {
            this.loadinginName = '点击加载更多'
          } else {
            this.loadinginName = '已经没有啦'
          }
          this.hyzxData = this.hyzxData.concat(res.data.list)
          this.hyzxData.forEach((item) => {
            item.updateTime = fmtDate('YYYY-mm-dd', item.updateTime)
          })
        } else {
          this.loadingIcon ='el-icon-refresh'
          this.loadinginName = '加载失败'
        }
      })
    },

    // 政策切换
    zcDongBtn(name) {
      this.zcAcName = name
    },
    // 标准切换
    bzDongBtn(name) {
      this.bzAcName = name
    },
    // 加载更多
    jzMoreBtn() {
      this.hyzxPage.page++
      this.hyzx()
    },
    alertClose() {
    }
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  background-color: #fff;

  ::v-deep .more {
    display: flex;
    align-items: center;
    margin-right: 14px;
    color: #BDBDBD;
    font-size: 12px;

    i[class^="el-icon"] {
      margin-left: 2px;
    }

    &-icon {
      padding-left: 2px;
    }
  }
}

.more-icon {
  width: 15px
}

.bottom-all-left-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
    h1 {
      color: #222;
      font-size: 20px;
      font-weight: bold;
      line-height: 1;
    }
    .flexMore {
      display: flex;
      .el-input  {
        width: 200px;
        margin-right: 21px;
      }
      .el-icon-search {
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
  .tabs {
    margin-bottom: 20px;
    ::v-deep {
      .el-tabs__header {
        margin-bottom: 0;
      }
      .el-tabs__nav-wrap::after {
        width: 0;
        color: black;
      }
      .el-tabs__active-bar{
        width: 0 !important;
      }
      .el-tabs__item {
        margin-right: 22px;
        padding: 0 1px;
        color: #666;
        font-size: 16px;
        font-weight: normal;
        &:hover {
          font-weight: normal;
        }
        &.is-active {
          color: #111;
          font-weight: bold;
          &::after {
            height: 4px;
            border-radius: 2px;
            content: '';
            position: absolute;
            left: 10%;
            bottom: 0;
            width: 80%;
            background-color: #0069ea;
          }
        }
      }
    }
  }

.jz {
  display: flex;
  justify-content: center;
  margin: 30px 0 10px 0;
}
.jz-more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 42px;
  text-align: center;
  font-weight: 500;
  color: #999999;
  cursor: pointer;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.bottom-all-right-title {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;

  h1 {
    color: #222;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
  }
}

.link-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: 28px;
  a {
    display: inline-flex;
    margin-left: 10px;
    margin-bottom: 12px;
    padding: 7px 16px;
    color: #333;
    white-space: nowrap;
    background-color: #f3f3f3;
    border-radius: 6px;
    transition: all .12s;
    &:hover,
    &:active {
      color: #fff;
      background-color: #3f76ea;
    }
  }
}

.rdzt-box {
  margin-bottom: 40px;

  .box {
    position: relative;
    height: 0;
    padding-top: 40.65%;
    &.special {
      padding-top: 25.41%;
    }
    &:not(:first-child) {
      margin-top: 14px;
    }
  }

  .rdzt-img-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;

    .rdzt-img-tag {
      position: absolute;
      top: 18px;
      left: 12px;
      padding: 0px 10px;
      font-size: 14px;
      color: #0d57bc;
      background: #fff;
      font-weight: bold;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .zt-title {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      padding: 0 12px;
      font-weight: bold;
      font-size: 24px;
      line-height: 26px;
      color: #fff;
    }
  }

  .rdzt-img-line {
    width: 100%;
    height: 1px;
    background: #e0e0e0;
    margin: 16px 0;
  }
}

.text-overflow-2 {
  -webkit-line-clamp: 3;
}
</style>
