<template>
  <div class="compList">
    <el-table
      ref="newTable"
      v-loading="loading"
      :data="tableData"
      style="width: 100%;margin-top: 10px;"
      :header-cell-style="{ background: '#F5F7FA', color: '#000' }"
    >
      <el-table-column prop="enterprise" label="企业名称" align="center" />
      <el-table-column prop="reportPeriod" label="报告期" align="center">
        <template slot-scope="scope">
          {{ scope.row.reportDate }}年<br>{{ scope.row.reportPeriod }}
        </template>
      </el-table-column>
      <el-table-column label="净利润" align="center">
        <el-table-column prop="netProfit" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.netProfit) }}
          </template>
        </el-table-column>
        <el-table-column prop="netProfitYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="扣非归母净利润" align="center">
        <el-table-column prop="motherNetProfit" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.motherNetProfit) }}
          </template>
        </el-table-column>
        <el-table-column prop="motherNetProfitYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="营业总收入" align="center">
        <el-table-column prop="totalOperatingIncome" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.totalOperatingIncome) }}
          </template>
        </el-table-column>
        <el-table-column prop="totalOperatingIncomeYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="营业总支出" align="center">
        <el-table-column prop="operatingExpense" label="营业支出(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.operatingExpense) }}
          </template>
        </el-table-column>
        <el-table-column prop="operatingExpenseYoy" label="营业支出同比(%)" align="center" />
        <el-table-column prop="sellExpense" label="销售费用(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.sellExpense) }}
          </template>
        </el-table-column>
        <el-table-column prop="managementFee" label="管理费用(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.managementFee) }}
          </template>
        </el-table-column>
        <el-table-column prop="financeExpense" label="财务费用(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.financeExpense) }}
          </template>
        </el-table-column>
        <el-table-column prop="totalOperatingExpense" label="营业总支出(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.totalOperatingExpense) }}
          </template>
        </el-table-column>
        <el-table-column prop="totalOperatingExpenseYoy" label="营业总支出同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="营业利润" align="center">
        <el-table-column prop="operatingProfit" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.operatingProfit) }}
          </template>
        </el-table-column>
        <el-table-column prop="operatingProfitYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column prop="grossProfit" label="利润总额(元)" align="center">
        <template slot-scope="scope">
          {{ bigNumberTransform(scope.row.grossProfit) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

import { bigNumberTransform } from '@/utils/number'

export default {
  props: {
    tableData: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    bigNumberTransform,
    tableSort(column) {
      const orderType = this.otherzh(column)
      this.$emit('custom-sort-change', orderType)
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        return column.prop
      } else if (column.order == 'descending') {
        return column.prop + ' DESC'
      } else {
        return ''
      }
    }

  }
}
</script>

<style scoped lang="scss"></style>
