<template>
  <div>
    <router-view />
    <PageContainer breadcrumb footer>
      <Vcontent :data="data" :props="props" is-content-two="bz" name="标准" />
    </PageContainer>
  </div>
</template>
<script>
import { ttStandardInternational } from '@/api/standards.js'
export default {
  data() {
    return {
      data: {},
      props: {
        tag: 'applicableProducts',
        htmlContent: 'content'
      },
      relationComment: [],
      relationDynamic: [],
      relationInterpret: [],
      relationSelf: [],
      tableDataRight: []

    }
  },
  watch: {
    $route(to, from) {
      const id = to.params.id

      this.ttStandard(id)

      // 对路由变化作出响应...
    }
  },
  created() {
    const id = this.$route.params.id

    this.ttStandard(id)
  },
  methods: {
    ttStandard(params) {
      ttStandardInternational(params).then((res) => {
        if (res.ok) {
          this.data = res.data
        }
      })
    }

  }
}
</script>
<style lang="scss" scoped>
</style>
