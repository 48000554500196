<template>
  <div
    v-if="isShowGraphic"
    id="graphic"
    class="enterpriseDetail enterprise-dynamic-detail"
    data-name="研究报告"
  >

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h3 class="title-tag-text">
          研究报告
        </h3>
        <span class="new">NEW</span>
        <span
          class="more"
          style="float: right;"
          @click="
            toHref('/FullTextRetrieval?keywords='+enterpriseName+'&category=twjs')
          "
        >
          查看更多 <i class="el-icon-arrow-right" />
        </span>
      </div>
      <div class="card-item-box">
        <div
          v-for="(item, index) in cardList"
          :key="index"
          class="card-item"
        >
          <div class="care-item-img">
            <img
              v-if="item.imageUrl"
              v-viewer
              style="width: 100%; height: 100%;border: 1px solid #DCDFE6;"
              :src="item.imageUrl"
              title="点击放大"
              alt=""
            >
          </div>
          <div class="card-item-info">
            <div class="card-item-info-title" :title="item.title" @click="toHref2(item)" v-html="item.title" />
            <!--                  <div class="card-item-info-time">-->
            <!--                    {{ item.updateTime }}-->
            <!--                  </div>-->
          </div>
        </div>
      </div>

    </el-card>
  </div>
</template>

<script>
import { getUserInfo, logRecords } from '@/utils/auth.js'
import { buryPoint } from '@/api/common'
import { getGraphic } from '@/api/graphic'
import TextOverTooltip from '@/components/title/index'
export default {
  name: 'Dynamic',
  components: {
    TextOverTooltip
  },
  data() {
    return {
      isShowGraphic: false,
      isDisabledTooltip: true, // 是否需要禁止提示
      dynamicParams: {
        pageSize: 8,
        pageNum: 1,
        enterpriseId: this.$route.params.id
      },
      enterpriseName: this.$route.query.enterpriseName.replaceAll("汽车",""),
      cardList: [],
      dataSource: [],
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '企业情报',
        userId: ''
      }
    }
  },

  created() {
    this.getListData('isShowDynamic')
  },

  methods: {
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    toHref(routeUrl) {
      const newUrl = this.$router.resolve(routeUrl)
      if (getUserInfo()) {
        logRecords('图文检索', '企业情报', '3', '', '', '', '', '', '查看更多')
        if (
          getUserInfo().roleKey == 'paidrole' ||
          getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    toHref2(item) {
      let url
      if (item.menu === '专题分析') {
        url = '/thematicAnalysis/details/' + item.articleId
      } else if (item.menu === '前沿技术') {
        url = '/cuttingEdgeTechnology/details/' + item.articleId
      } else if (item.menu === '企业分析') {
        url = '/workInformation/analysis/analysisContent/' + item.articleId
      } else if (item.menu === '政策原创') {
        url = '/policy/dynamic/LocalOriginalDetails/' + item.articleId
      }
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('图文检索', item.menu, 1, '', item.articleId, '', item.title, '', '')
        if (getUserInfo().roleKey === 'paidrole' || getUserInfo().roleKey === 'trialrole') {
          if (getUserInfo().menuName.includes(item.menu)) {
            window.open(newUrl.href, '_blank')
          } else {
            this.sqsyLogin()
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 移入事件: 判断内容的宽度contentWidth是否大于父级的宽度
    onMouseOver(event, index) {
      let targetElement = event.target

      // 确保 targetElement 是你想要检查的 span 元素
      if (targetElement.nodeName !== 'SPAN') {
        targetElement = targetElement.querySelector('span')
      }

      const parentWidth = targetElement.parentNode.offsetWidth
      const contentWidth = this.$refs.hiddenTitle[index].offsetWidth

      // 检查内容宽度是否超出了父元素宽度
      this.isDisabledTooltip = contentWidth <= parentWidth
    },
    // 获取列表数据
    getListData(isShowGraphic) {
      // params.title = this.keywords
      getGraphic({ page: 1, pageSize: 3, title: this.enterpriseName }).then((res) => {
        if (res.ok) {
          this.isShowGraphic = !!res.data.list.length
          this.cardList = res.data.list.map((item) => {
            item.imageUrl = 'https://www.autoinfo.org.cn' + item.imageUrl
            return item
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.enterprise-dynamic-detail {
  .el-card__header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .el-col {
    max-width: 598px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &:nth-child(2n) {
      padding-left: 37px;
    }
    &:nth-child(2n-1) {
      padding-right: 37px;
    }
  }
  .enterprise-dynamic-title {
    overflow: hidden;
    max-width: calc(100% - 37px); // 根据你的布局调整这里的值

    span {
      cursor: pointer;
      display: inline-block; // 保留 inline-block
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #333;
      &:hover {
        color: #3f76ea;
      }
    }

    .hidden-title {
      position: absolute;
      left: -9999px;
      visibility: hidden;
      white-space: nowrap;
    }
  }
}
.card-item-box {
  padding: 0 1rem;
  padding-bottom: 0.833rem;
  display: flex;
  //align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; // 换行
  .card-item {
    width: 30%;
    //height: 15rem;
    margin-top: 0.7rem;
    cursor: pointer;
    // margin: 0 10px;
    .care-item-img {
      width: 100%;
      height: 5rem;
      background: url(~@/assets/img/overview/card-item-test-1.png) no-repeat;
      background-size: 100% 100%;

      height: auto;
      aspect-ratio: 16/9;
    }
    .card-item-info-title {
      margin-top: 0.333rem;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight:500;
      color: #333333;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -moz-box;
      -moz-line-clamp: 2;
      -moz-box-orient: vertical;
      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
    }
    .card-item-info-time {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
<style lang="scss" scoped>
.more {
  color: #999;
}
.title {
}
.date {
  flex: none;
  margin-left: 12px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #bbb;
  text-align: right;
}
.clearfix {
  .title-tag-text {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
  }
  position: relative;
}
.box-card .new {
  position: absolute;
  top: -8px;
  padding: 0 5px;
  font-size: 14px;
  background: #ff6e4b;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
