<template>
  <router-view v-if="$route.name == '标准解读内容页'" />
  <PageContainer v-else breadcrumb>
    <VlistTitle big-ttitle="标准解读" small-tip="会员专享精华内容推荐" :bgurl="bgurl" />

    <div style="overflow: hidden" class="box_box">
      <div style="float: left" flex="c">
        <el-checkbox-group v-model="belongAreas" size="small" @change="handleChange">
          <el-checkbox v-for="item in belongAreasData" :key="item.dictValue" :label="item.dictValue" border>
            {{ item.dictLabel }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div style="float: right">
        <el-input v-model="title" placeholder="搜索名称" size="small" @keyup.enter.native="search">
          <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer;" @click="search" />
        </el-input>
      </div>
    </div>

    <Vlist
      v-if="pageShow"
      :list="standardUnscrambleData"
      :page="standardUnscramblePage"
      :page-data="standardUnscramblePage"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @toHref="toHref"
    />
  </PageContainer>
</template>
<script>
import VlistTitle from '@/components/listTitle.vue'
import { standardUnscramble } from '@/api/standards.js'
import Vlist from '@/components/list'
import { selectDictDataByType } from '@/api/dict.js'
import { getUserInfo, logRecords } from '@/utils/auth'
import {buryPoint, selectCountByColumn} from '@/api/common'
import {Message} from "element-ui";

export default {
  components: {
    VlistTitle,
    Vlist
  },
  data() {
    return {
      bgurl: require('@/assets/img/bg-1.jpg'),
      standardUnscrambleData: [],
      standardUnscramblePage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      belongAreasData: [],
      belongAreas: [],
      title: '',
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '标准',
        userId: ''
      },
      pageShow: true
    }
  },
  created() {
    this.standardUnscramble()
    this.selectDictDataByType()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    standardUnscramble() {
      if (!getUserInfo() && this.standardUnscramblePage.pages.page > 1) {
        this.showLogin()
      } else {
        const form = Object.assign({}, this.standardUnscramblePage.pages, {
          title: this.title,
          belongAreas: this.belongAreas
        })
        form.belongAreas = form.belongAreas.join(',')
        standardUnscramble(form).then((res) => {
          if (res.ok) {
            this.standardUnscrambleData = res.data.list
            this.standardUnscramblePage.total = res.data.count
          }
        })
      }
    },
    handleSizeChange(val) {
      this.standardUnscramblePage.pages.pageSize = val
      this.standardUnscramble()
    },
    handleCurrentChange(val) {
      // const menu = this.$route.meta.acName
      // if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
      //   // 则不允许翻页，弹窗提示并埋点
      //   openSy()
      //   this.insertBuryPoint()
      //   this.pageShow = false
      //   this.$nextTick(() => {
      //     this.pageShow = true
      //   })
      //   return
      // } else {
      this.standardUnscramblePage.pages.page = val
      this.standardUnscramble()
      // }
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(item) {
      const newUrl = this.$router.resolve(
        '/standards/dynamic/contentUnscramble/' + item.id
      )
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('标准')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              selectCountByColumn({
                'userId': getUserInfo().userId,
                'column1': '标准',
                'column2': '标准解读'
              }).then((res) => {
                if (res.ok) {
                  if (res.data != null && res.data >= 10) {
                    Message({
                      showClose: true,
                      message: '您的体验次数已达上限，请正式开通！',
                      type: 'error'
                    })
                    return
                  } else {
                    logRecords('标准', '标准解读', '1', 'tt_standard_unscramble', item.id, '', item.title, '')
                    window.open(newUrl.href, '_blank')
                  }
                }
              })
            } else {
              logRecords('标准', '标准解读', '1', 'tt_standard_unscramble', item.id, '', item.title, '')
              window.open(newUrl.href, '_blank')
            }
          }
        } else {
          logRecords('标准', '标准解读', '1', 'tt_standard_unscramble', item.id, '', item.title, '')
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 数据字典
    selectDictDataByType() {
      selectDictDataByType({
        dictType: 'standard_hot_field'
      }).then((res) => {
        if (res.ok) {
          this.belongAreasData = res.data
        }
      })
    },
    // 切换
    handleChange(e) {
      if (getUserInfo()) {
        this.belongAreas = e
        this.standardUnscramble()
      } else {
        this.showLogin()
      }
    },
    search() {
      this.standardUnscramblePage.pages.page = 1
      this.standardUnscramble()
      logRecords('标准', '标准解读', '4', 'tt_standard_unscramble', '', '', '', this.title)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box_box {
  margin-top: 15px;
  padding: 10px;
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
