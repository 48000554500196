<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="政策" text="政策栏目围绕政策本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新政策" :showTips="showTips" />-->
    <div class="search-form">
      <form ref="form" class="g-form">
        <div flex="sb" style="margin-bottom: 28px">
          <div flex="c">
            <span>政策领域：</span>
            <el-select v-model="form.belongAreas" multiple collapse-tags placeholder="请选择" size="small">
              <el-option
                v-for="item in belongAreas"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              />
            </el-select>
          </div>
          <div flex="c">
            <span>政策区域：</span>
            <el-radio-group v-model="form.areas" @change="areasChange">
              <el-radio label="">全部</el-radio>
              <el-radio v-for="item in areas" :key="item.dictValue" :label="item.dictValue">{{
                item.dictLabel
              }}
              </el-radio>
            </el-radio-group>
            <el-input
              v-if="form.areas == 2"
              v-model="form.city"
              style="width: 20%; margin-left: 20px"
              placeholder="选择城市"
            />
          </div>

          <div>
            发布时间：
            <el-date-picker
              v-model="dateVal"
              type="monthrange"
              range-separator="-"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              value-format="yyyy-MM"
              size="small"
              style="width: 200px;"
              @change="changeDate"
            />
          </div>
        </div>

        <!-- <el-checkbox
v-model="checkAll"
@change="handleCheckAllChange"
style="margin-right: 30px"
>全部</el-checkbox
>
<el-checkbox-group
v-model="form.belongAreas"
@change="handleCheckedCitiesChange"
>
<el-checkbox
v-for="item in belongAreas"
:key="item.dictValue"
:label="item.dictValue"
>{{ item.dictLabel }}</el-checkbox
>
</el-checkbox-group> -->

        <div class="text-center">
          <el-button class="reset-button" @click="restForm">重置</el-button>
          <el-button class="search-button" @click="search">查询</el-button>
        </div>
      </form>
    </div>
    <div class="search-form" style="padding-top: 20px">

      <el-row>
        <div style="font-weight: bold;text-align: center;font-size: 18px;padding-bottom: 10px;color: rgb(0, 0, 0)">{{ dateVal[0] }} 至 {{ dateVal[1] }} 政策数据</div>
        <el-col :span="8">
          <!--            <div id="container" class="borderStyle"/>-->
          <Histogram :value="map1" :height="400" />
        </el-col>
        <el-col :span="8">
          <div ref="chinaMap" class="map-box borderStyle" />
        </el-col>
        <el-col :span="8">
          <Histogram2 :value="map2" :height="400" />
        </el-col>
      </el-row>
      <div style="float: right;margin: 4px 0 8px 0;">
        <span style="font-weight: bold;color: #000000;margin-right: 20px;">共计 <span style="color: red">{{ selectPolicyPage.total }}</span> 条</span>
        <el-button v-has-role="'freeusers'" class="search-button" style="width: 100px;" type="primary" plain :loading="excelLoading" @click="Export">导出</el-button>
      </div>
      <el-table
        ref="newTable"
        v-loading="loading"
        :data="selectPolicyDate"
        style="width: 100%;margin-top: 10px;"
        :header-cell-style="{ background: '#e9f4ff', color: '#000' }"
        stripe
      >
        <el-table-column prop="province" label="地区" min-width="100" />
        <el-table-column prop="source" label="发布来源" min-width="100" />

        <el-table-column prop="title" label="政策名称" min-width="300">
          <template slot-scope="scope">
            <div
              style="color: #0D57BC;cursor: pointer"
              @click="toHref('/policy/dynamic/contentNewPolicy/' + scope.row.id,scope.row.id,scope.row.title)"
            >
              {{ htmlRestore(scope.row.title) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="belongAreas" label="政策领域" min-width="150" />
        <el-table-column sortable="custom" prop="publicDate" label="公开日期" min-width="100" />
        <el-table-column sortable="custom" prop="transactionDate" label="成文日期" min-width="100" />
        <el-table-column sortable="custom" prop="implementDate" label="实施日期" min-width="100" />
        <!--          <el-table-column sortable="custom" prop="publicDateEnd" label="终止日期" width="100"/>-->
      </el-table>

      <div class="text-right">
        <el-pagination
          v-if="pageShow"
          :current-page="selectPolicyPage.pages.page"
          :page-sizes="pageSizes"
          :page-size="selectPolicyPage.pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="selectPolicyPage.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </PageContainer>
</template>
<script>
/**
 * @description: 政策查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";

import Histogram from '@/components/G2Plot/Column.vue'
import Histogram2 from '@/components/G2Plot/Pie.vue'

import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import { policyBigData, policyDataExport, selectPolicy } from '@/api/policy.js'
import { selectDictDataByType } from '@/api/dict.js'
import { download } from '@/utils/download.js'
import { fmtDate } from '@/utils/date'
import { buryPoint, selectCountByColumn } from '@/api/common'
// 在script便签内import
import echarts from 'echarts4'
import china from '@/assets/jsondata/china_1.json'
import { Message } from 'element-ui'

echarts.registerMap('china', china)
export default {
  components: { Histogram, Histogram2 },
  data() {
    return {
      show: false,
      showTips: [
        {
          title: '政策动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '政策库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '政策梳理',
          text: '专家梳理整合，真正读懂政策脉络'
        },
        {
          title: '政策大数据',
          text: '用数据深度理解政策结构和趋势'
        }
      ],
      dateVal: '',
      checkAll: false,
      form: {
        belongAreas: [],
        areas: '',
        publicDateStart: '',
        publicDateEnd: '',
        city: ''
      },
      selectPolicyDate: [],
      selectPolicyPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0
      },
      pageSizes: [10, 20, 30, 40],
      belongAreas: [],
      areas: [
        { dictValue: '1', dictLabel: '国家' },
        { dictValue: '2', dictLabel: '地方' }
      ],
      formLog: {
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '政策',
        userId: ''
      },
      loading: false,
      map1: [],
      map2: [],
      excelLoading: false,
      pageShow: true
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }
    this.defaultDate()
    this.selectPolicy()
    this.policyBigData()
    this.selectDictDataByType({ dictType: 'policy_hot_field' })
  },

  methods: {
    policyBigData() {
      const formCopy = Object.assign({}, this.form)
      if (formCopy.areas.length >= 2) {
        formCopy.areas = ''
      }
      formCopy.belongAreas = formCopy.belongAreas.join(',')
      policyBigData(formCopy).then((res) => {
        this.map1 = res.data.map1
        this.map2 = res.data.map2

        this.chinaConfigure(res.data.map3)
      })
    },
    selectPolicy() {
      const formCopy = Object.assign(
        {},
        this.form,
        this.selectPolicyPage.pages
      )
      if (formCopy.areas.length >= 2) {
        formCopy.areas = ''
      }
      formCopy.belongAreas = formCopy.belongAreas.join(',')
      formCopy.publicDateStart = formCopy.publicDateStart + '-01'
      formCopy.publicDateEnd = formCopy.publicDateEnd + '-31'
      if (!getUserInfo()) {
        this.pageSizes = [10]
      }
      if (!getUserInfo() && this.selectPolicyPage.pages.page > 1) {
        this.showLogin()
      } else {
        this.loading = true
        selectPolicy(formCopy).then((res) => {
          if (res.ok) {
            this.selectPolicyDate = res.data.list
            this.selectPolicyDate.forEach((item) => {
              item.publishDate = fmtDate(
                'YYYY-mm-dd',
                item.publishDate
              )
              item.transactionDate = fmtDate(
                'YYYY-mm-dd',
                item.transactionDate
              )
              item.implementDate = fmtDate(
                'YYYY-mm-dd',
                item.implementDate
              )
              item.publicDateEnd = fmtDate('YYYY-mm-dd', item.publicDateEnd)
            })

            this.selectPolicyPage.total = res.data.count
          }
          this.loading = false
        })
      }
    },
    // 设置默认日期   写在methods: {}中
    defaultDate() {
      const date = new Date()
      const year = date.getFullYear().toString()
      const lastYear = (date.getFullYear() - 1).toString()
      const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()
      const end = year + '-' + month
      const beg = lastYear + '-' + month
      this.dateVal = [beg, end] // 将值设置给插件绑定的数据
      this.form.publicDateStart = this.dateVal[0]
      this.form.publicDateEnd = this.dateVal[1]
    },
    // 数据字典
    selectDictDataByType(params) {
      selectDictDataByType(params).then((res) => {
        if (res.ok) {
          this.belongAreas = res.data
        }
      })
    },
    toHref(url, id, title) {
      this.formLog.eventType = '1'
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              selectCountByColumn({
                'userId': getUserInfo().userId,
                'column1': '政策',
                'column2': '政策大数据'
              }).then((res) => {
                if (res.ok) {
                  if (res.data != null && res.data >= 10) {
                    Message({
                      showClose: true,
                      message: '您的体验次数已达上限，请正式开通！',
                      type: 'error'
                    })
                    return
                  } else {
                    logRecords('政策', '政策大数据', '1', 'tt_policy', id, '', title, '')
                    window.open(newUrl.href, '_blank')
                  }
                }
              })
            } else {
              logRecords('政策', '政策大数据', '1', 'tt_policy', id, '', title, '')
              window.open(newUrl.href, '_blank')
            }
          }
        } else {
          logRecords('政策', '政策大数据', '1', 'tt_policy', id, '', title, '')

          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    handleSizeChange(val) {
      this.selectPolicyPage.pages.pageSize = val
      this.selectPolicy()
    },
    handleCurrentChange(val) {
      // const menu = this.$route.meta.acName
      // if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
      //   // 则不允许翻页，弹窗提示并埋点
      //   openSy()
      //   this.insertBuryPoint()
      //   this.pageShow = false
      //   this.$nextTick(() => {
      //     this.pageShow = true
      //   })
      //   return
      // } else {
      this.selectPolicyPage.pages.page = val
      this.selectPolicy()
      // }
    },
    // 政策类别
    handleCheckAllChange(val) {
      this.form.belongAreas = []
      if (val) {
        this.belongAreas.forEach((item) => {
          this.form.belongAreas.push(item.dictValue)
        })
      } else {
        this.form.belongAreas = []
      }
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length
      this.form.belongAreas = value
      this.checkAll = checkedCount === this.belongAreas.length
      this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.belongAreas.length
    },
    // 时间
    changeDate(v) {
      this.form.publicDateStart = v[0]
      this.form.publicDateEnd = v[1]
    },
    // 政策区域切换
    areasChange(e) {
      this.form.city = ''
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    // 查询
    search() {
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            this.policyBigData()
            this.selectPolicy()
          }
        } else {
          this.policyBigData()
          this.selectPolicy()
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 重置
    restForm() {
      this.form = {
        belongAreas: [],
        areas: '',
        publicDateStart: '',
        publicDateEnd: '',
        city: ''
      }
      this.checkAll = false
      this.dateVal = ''
      this.defaultDate()

      this.policyBigData()
      this.selectPolicy()
    },

    insertBuryPoint() {
      this.form.userId = getUserInfo().userId
      buryPoint(this.form)
    },
    // 导出
    Export() {
      const formCopy = Object.assign(
        {},
        this.form
      )
      if (formCopy.areas.length >= 2) {
        formCopy.areas = ''
      }
      formCopy.belongAreas = formCopy.belongAreas.join(',')
      formCopy.publicDateStart = formCopy.publicDateStart + '-01'
      formCopy.publicDateEnd = formCopy.publicDateEnd + '-31'
      if (getUserInfo()) {
        logRecords('政策', '政策大数据', '3', 'tt_policy', '', '', '', '', '导出')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            } else {
              this.excelLoading = true
              policyDataExport(formCopy).then((res) => {
                this.excelLoading = false
                var reader = new FileReader()
                reader.readAsText(res)
                reader.addEventListener('loadend', function(e) {
                  try {
                    JSON.parse(e.target.result)
                    const msg = JSON.parse(e.target.result)
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: 'error'
                    })
                  } catch (err) {
                    download(res, '政策大数据', 'xls')
                  }
                })
              })
            }
          }
        } else {
          this.excelLoading = true
          policyDataExport(formCopy).then((res) => {
            this.excelLoading = false
            var reader = new FileReader()
            reader.readAsText(res)
            reader.addEventListener('loadend', function(e) {
              try {
                JSON.parse(e.target.result)
                const msg = JSON.parse(e.target.result)
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: 'error'
                })
              } catch (err) {
                download(res, '政策大数据', 'xls')
              }
            })
          })
        }
      } else {
        this.showLogin()
      }
    },
    handleClick(params) {
      console.log(params)
    },
    chinaConfigure(seriesData) {
      const myChart = echarts.init(this.$refs.chinaMap) // 这里是为了获得容器所在位置
      window.onresize = myChart.resize
      myChart.setOption({
        title: {
          text: '',
          left: 'right',
          subtextStyle: {
            align: 'left'
          }
        },
        grid: {

          bottom: '2%', // 距离下边的距离
          top: '2%' // 距离上边的距离
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{b}'
          // formatter: '{b}<br/>{c} (个)'
          formatter: function(params) {
            if (isNaN(params.value)) {
              return '无'
            } else {
              return params.name + '：' + params.value
            }
          }
        },
        visualMap: {
          min: 1,
          max: 200,
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          textStyle: {
            fontSize: 8
          },
          inRange: {
            color: ['RGB(218,227,243)', 'RGB(143,170,220)', 'RGB(32,56,100)']
          }
        },
        geo: {
          map: 'china',
          roam: false, // 一定要关闭拖拽
          zoom: 1.25,
          // center: [105, 36], // 调整地图位置
          label: {
            show: true, // 省份名展示
            fontSize: '10',
            color: 'rgba(0,0,0,0.7)',
            emphasis: {
              show: false

            }
          }
        },
        series: [
          {
            type: 'map',
            map: 'china',
            geoIndex: 0, // 解决设置geo后地图重影问题
            zoom: 1.25, // 这里是关键，一定要放在 series中
            // silent: false, //鼠标移入区域变色 如果设置为true则无法高亮
            // 鼠标移入高亮区域属性

            itemSytle: {

              emphasis: {
                show: true,
                areaColor: 'blue', // 鼠标滑过区域颜色
                color: 'blue' // 鼠标滑过区域颜色
              }
            },
            data: seriesData
          }
        ]
      })
      // 点击事件
      myChart.on('click', (params) => {
        this.form.city = params.name
        this.selectPolicy()
      })
    }
  }

}
</script>
<style lang="scss" scoped>

.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
}

.g-form-item {
  position: relative;
  overflow: hidden;
  clear: both;

  li {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    label {
      width: 85px;
    }

    .check-position {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -100px;
    }

    &:nth-child(1) {
      float: left;
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(3) {
      float: right;
    }

    .g-form-input {
      flex: 1;
      width: 185px;

      /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }

}
.borderStyle{
  border: solid 1px #cfdcf1;
  margin: 5px;
  padding: 10px;
}
.map-box {
  background-color: white;
  height: 422px;
  width: auto;
  //padding: 0 40px
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #f4f9ff;
}
::v-deep .el-table td, .el-table th.is-leaf {
  border-bottom: 1px solid #ffffff;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
