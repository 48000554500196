<!--行业资讯
 * @Author: your name
 * @Date: 2021-07-22 15:27:51
 * @LastEditTime: 2021-07-22 15:41:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/views/insightReport/indexBalance.vue
-->

<template>
  <PageContainer>
    <!--        <div class="not-login-box" v-if="!show">-->
    <!--            <VnotLogin title="二手车报告" text="二手车报告栏目围绕二手车报告本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新二手车报告" :showTips="showTips" />-->
    <!--        </div>-->

    <div class="box_box">
      <div style="overflow: hidden">
        <!-- <div style="float: left; margin-bottom: 10px" flex="c">
            <el-checkbox label="全部" border size="small" style="margin-right: 8px" @change="kckAll" v-model="keywordAll"></el-checkbox>
            <el-checkbox-group v-model="form.keywords" size="small" @change="kcheck" class="lable-box">
                <el-checkbox :label="item.dictValue" border v-for="item in keywords" :key="item.dictValue">{{item.dictLabel}}</el-checkbox>

            </el-checkbox-group>
        </div> -->
        <div style="float: right" class="search-btn-box ">
          <el-input placeholder="二手车报告" v-model="values" size="small"></el-input>
          <div class="search-btn" @click="search">搜索</div>
        </div>
      </div>
    </div>
    <div class="box_box_title" v-for="(item,index) in tableData" :key="item.id" @click="toHref(item)">
      <div style="display: flex">
        <div class="text-center" style="width: 267px; height: 150px">
          <img style="width: 267px; height: 150px" :src="item.imgUrl ? item.imgUrl : defaultUrl" alt=""/>
        </div>
        <div class="flex_col">
          <div>
            <div flex="c" style="margin: 10px 0">
                            <span style="
                  display: inline-block;
                  color: #0D57BC;
                  font-size: 14px;
                  line-height: 16px;
                  padding: 4px;
                  background: rgba(13, 87, 188, 0.08);
                  margin-right: 4px;
                "
                                  v-if="item.contentCategory == 'yc'"
                            >原创</span>
              <p class="">{{ htmlRestore(item.title) }}</p>
            </div>
            <div style="font-size: 14px; color: #828282">
              {{ htmlRestore(item.introduction) }}
            </div>
          </div>
          <div style="font-size: 14px; color: #828282">
            {{ item.updateTime }}
          </div>
        </div>
      </div>
    </div>

        <!-- <div class="box_box_title">
  <div style="display: flex">
    <img
      style="width: 300px; height: 150px"
      src="@/assets/img/dnc-img.png"
      alt=""
    />
    <div class="flex_col">
      <div>
        <div flex="c" style="margin: 10px 0">
          <span
            style="
              padding: 5px 10px;
              background: #0D57BC;
              color: #ffffff;
              font-weight: 600;
              margin-right: 10px;
            "
            >力荐</span
          >
          <p class="">大陆集团2020年财报分析</p>
        </div>
        <div style="font-size: 14px; color: #828282">
          本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开
        </div>
      </div>
      <div style="font-size: 14px; color: #828282">2021-03-17</div>
    </div>
  </div>
</div> -->
    <div class="pages">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40]"
                     :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                     :total="pageData.total">
      </el-pagination>
    </div>
  </PageContainer>
</template>
<script>
/**
 * @description: 二手车报告动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, logRecords, ydlTk} from "@/utils/auth.js";
import {getKeyWords, queryByPage} from "@/api/usedCar.js";
import {buryPoint} from "@/api/common";

export default {
  // components: {
  //     VnotLogin,
  // },
  data() {
    return {
      defaultUrl: require("@/assets/img/default-img.png"),
      showTips: [
        {
          title: "二手车报告动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "二手车报告库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "二手车报告梳理",
          text: "专家梳理整合，真正读懂二手车报告脉络",
        },
        {
          title: "二手车报告大数据",
          text: "用数据深度理解二手车报告结构和趋势",
        },
      ],
      loading: false,
      tableData: [],
      show: false,
      form: {
        keywords: [],
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "",
        columnName: "二手车",
        userId: ""
      },
      keywords: [],
      pageData: {
        pages: {
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      keywordAll: true,
      values: "",
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    this.getKeyWords();
    this.queryByPage();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //搜索
    search() {
      logRecords('二手车','二手车报告','4','tt_used_car','','','',this.values)

      let newUrl = this.$router.resolve('/usedCar/search?title=' + this.values);
      if (this.values == "") {
        alert("请输入搜索内容")
      } else {
         window.open(newUrl.href, "_blank");

      }

    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    toHref(item) {
      this.formLog.eventType = "1";
      let newUrl = this.$router.resolve("/usedCar/detail/usedCarReportDetail/" + item.id);
      if (getUserInfo()) {
        let menuName = getUserInfo().menuName;
        logRecords('二手车','二手车报告','1','tt_used_car',item.id,'',item.title,'')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          if (!menuName.includes('二手车')) {

            this.insertBuryPoint();
            this.sqsyLogin();
          } else {
              window.open(newUrl.href, "_blank");
          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },

    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.queryByPage();
    },
    handleCurrentChange(val) {
      this.pageData.pages.pageNo = val;
      this.queryByPage();
    },
    //查询所有关键字
    getKeyWords() {
      getKeyWords().then((res) => {
        if (res.ok) {
          this.keywords = res.data;
        }
      });
    },
    //关键字
    kcheck(e) {
      if (e.length <= 0) {
        this.keywordAll = true;
      } else {
        this.keywordAll = false;
      }
      this.queryByPage();
    },
    //关键字全选
    kckAll() {
      this.form.keywords = [];
      this.queryByPage();
    },
    //列表查询
    queryByPage() {
      let form = Object.assign({}, this.form, this.pageData.pages);
      form.keywords = form.keywords.join(",");
      queryByPage(form).then((res) => {
        this.tableData = res.data.list;
        this.pageData.total = res.data.count;
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "二手车报告详情") {
      // 详情页路由
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
    }
    next();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box_box {
  padding: 10px;
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.box_box_title {
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
}

.lable-box {
  margin-bottom: -10px;

  .el-checkbox {
    margin-bottom: 10px;
  }
}

.search-btn-box {
  display: flex;
  //margin-top: 9px;
  /deep/ .el-input__inner {
    height: 35px;
    line-height: 35px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .el-input {
    right: -54px;
  }

  .search-btn {
    width: 90px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #0D57BC;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    position: relative;
    border-radius: 20px;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
