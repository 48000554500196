// 通用
import $http from '@/plugins/http.js'
import $download from '@/plugins/download.js'

// 通用下载请求
export function download(params) {
  return $http.Downloadget('/api/common/download', params)
}

// 通用下载请求
export function newdownload(params) {
  return $download.Downloadget('/api/common/download', params)
}

// 我的收藏 新增
export function ttCollectionAdd(params) {
  return $http.post('/collection/ttCollection', params)
}
// 我的收藏 查询列表
export function ttCollectionList(params) {
  return $http.get('/collection/ttCollection', params)
}
// 我的收藏 详情
export function ttCollectionContent(params) {
  return $http.get('/collection/ttCollection/' + params)
}
// 我的收藏 取消收藏
export function ttCollectiondel(params) {
  return $http.del('/collection/ttCollection/' + params)
}
// 内容页取消收藏
export function deleteByMessageId(params) {
  return $http.del('/collection/ttCollection/deleteByMessageId/' + params)
}
// 访问记录
export function menuRecordLog(data) {
  return $http.post('/api/log/menuRecordLog', data)
}
// 文章记录
export function recordWatch(data) {
  return $http.post('/article/log/recordWatch', data)
}

// 埋点记录
export function buryPoint(data) {
  return $http.post('/buryPoint/log/recordWatch', data)
}

export function selectYcCount(data) {
  return $http.post('/buryPoint/log/selectYcCount', data)
}

// 埋点记录
export function accessRecords(data) {
  return $http.post('/buryPoint/log/accessRecords', data)
}

// 埋点记录
export function selectCountByColumn(data) {
  return $http.get('/buryPoint/log/selectCountByColumn', data)
}


// 绑定手机号
export function updateBindPhone(data) {
  return $http.post('/buryPoint/log/updateBindPhone', data)
}

// 国际化政策翻译
export function translation(params) {
  return $http.post('/api/policy/ttPolicy/translation', params)
}

// 国际化资讯翻译
export function translation2(params) {
  return $http.post('/api/international/information/translation', params)
}

// 语种识别
export function runLanguageDetection(params) {
  return $http.post('/api/international/information/runLanguageDetection', params)
}

