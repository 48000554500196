<!--本网原创内容页面-->
<template>
  <PageContainer breadcrumb>
    <Vcontent :data="data" :props="props" is-content-two="bwyc" />

    <template slot="right">
      <VcontentItem
        title="政策解读推荐"
        type="list"
        :list="tableDataRight"
        @click="toHref"
      />
      <VcontentItem
        title="相关政策推荐"
        type="list"
        :list="relationPolicy"
        @click="tohref"
      />
    </template>
  </PageContainer>
</template>
<script>
import { localOriginal, ttPolicyInterpretYc } from '@/api/policy.js'
import { htmlRestore } from '@/utils/htmlRestore'
import { getUserInfo } from '@/utils/auth'
export default {
  data() {
    return {
      data: {},
      props: {
        htmlContent: 'content',
        summary: 'introduction'
      },
      tableDataRight: [],
      relationPolicy: []
    }
  },
  created() {
    const id = this.$route.params.id
    this.ttPolicyInterpret(id)
    this.localOriginalRight({ page: 1, pageSize: 10, unscrambleUnit: '2' })
  },
  methods: {
    ttPolicyInterpret(params) {
      ttPolicyInterpretYc(params).then((res) => {
        if (res.ok) {
          this.data = res.data
          this.relationPolicy = res.data.relationPolicy
        }
      })
    },
    // 本网原创
    localOriginalRight(params) {
      localOriginal(params).then((res) => {
        if (res.ok) {
          this.tableDataRight = res.data.list

          this.tableDataRight.forEach((element) => {
            element.title = htmlRestore(element.title)
            element.publishDate = element.updateTime
          })
        }
      })
    },
    tohref(item) {
      //政策主体推荐
      this.$router.push("/policy/dynamic/contentNewPolicy/" + item.id);
    },
    toHref(item) {
      const newUrl = this.$router.resolve(
        '/policy/dynamic/LocalOriginalDetails/' + item.id
      )
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
