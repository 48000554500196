<template>
<!--    <PageContainer>-->
<!--        <div style="margin:25px 0 20px 33px;">-->
<!--            <img src="../../../assets/img/logoSvg.svg" />-->
<!--        </div>-->
        <div class="tab-box">
            <el-tabs v-model="activeName">
                <el-tab-pane label="车辆新产品技术参数" name="车辆新产品技术参数">
                    <VehicleDetailsTable :tableData="tableData1" />
                </el-tab-pane>
                <el-tab-pane label="免征购置税目录信息" name="免征购置税目录信息" v-if="tableData3">
                    <VehicleDetailsMzgzs :tableData="tableData3" />
                </el-tab-pane>
                <el-tab-pane label="道路车辆达标车型信息" name="道路车辆达标车型信息" v-if="tableData2">
                    <VehicleDetailsDbcx :tableData="tableData2" />
                </el-tab-pane>
                <el-tab-pane label="推荐车型目录信息" name="推荐车型目录信息" v-if="tableData4">
                    <VehicleDetailsTjml :tableData="tableData4" />
                </el-tab-pane>
            </el-tabs>
        </div>
<!--    </PageContainer>-->
</template>
<script>
import VehicleDetailsTable from "./components/VehicleDetailsTable.vue";
import VehicleDetailsDbcx from "./components/VehicleDetailsDbcx.vue";
import VehicleDetailsMzgzs from "./components/VehicleDetailsMzgzs.vue";
import VehicleDetailsTjml from "./components/VehicleDetailsTjml.vue";
import { ggYzk } from "@/api/announcement.js";
import { getUserInfo } from "@/utils/auth.js";
export default {
    components: {
        VehicleDetailsTable,
        VehicleDetailsDbcx,
        VehicleDetailsMzgzs,
        VehicleDetailsTjml,
    },
    data() {
        return {
            tableData1: {},
            tableData2: {},
            tableData3: {},
            tableData4: {},
            activeName: "车辆新产品技术参数",
        };
    },
    created() {
        let id = this.$route.params.id;
        if (getUserInfo()) {
            this.ggYzk(id);
        } else {
            this.show = false;
        }
    },
    methods: {
        turnXh() {
            // console.log('msg',msg)
            // var msg='JX1032PSB6 二类 江铃汽车股份有限公司'
            // JX1032PSB6 二类   江铃汽车股份有限公司
            // console.log(msg.trim().split(/\s+/))
            // console.log()
            checkDpDetail(`/api/customerNotice/ggDp`, {
                dpid: this.$route.query.dpid,
                pc: this.$route.query.pc,
            }).then((res) => {
                if (res.ok) {
                    this.tableData1 = res.data.ggYzkEO || {};
                    this.tableData2 = res.data.ttRoadTransportVehiclesEO;
                    this.tableData3 = res.data.ttXnyMzgzsEO;
                    this.tableData4 = res.data.ttXnyTjcxmlEO;
                }
            });
        },
        //查询
        ggYzk(params) {
            ggYzk(params).then((res) => {
                if (res.ok) {
                    this.tableData1 = res.data.ggYzkEO || {};
                    this.tableData2 = res.data.ttRoadTransportVehiclesEO;
                    this.tableData3 = res.data.ttXnyMzgzsEO;
                    this.tableData4 = res.data.ttXnyTjcxmlEO;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__nav {
    float: none;
    margin: 0 10px;
}
::v-deep .el-tabs__active-bar {
    display: none;
}

::v-deep .el-tabs__nav-wrap::after {
    display: none;
}

::v-deep .el-checkbox__input {
    display: none;
}

::v-deep .el-tabs__header {
    margin-bottom: 0;
}
.container {
    width: 100%;
    background: #fff;
    padding-bottom: 20px;
    margin: 0 auto;
}
.tab-box {
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
}

</style>
