<template>
  <div class="compList">
    <el-table
      ref="newTable"
      v-loading="loading"
      :data="tableData"
      style="width: 100%;margin-top: 10px;"
      :header-cell-style="{ background: '#F5F7FA', color: '#000' }"
    >
      <el-table-column prop="enterprise" label="企业名称" align="center" />
      <el-table-column prop="reportPeriod" label="报告期" align="center">
        <template slot-scope="scope">
          {{ scope.row.reportDate }}年<br>{{ scope.row.reportPeriod }}
        </template>
      </el-table-column>
      <el-table-column label="总资产" align="center">
        <el-table-column prop="totalAssets" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.totalAssets) }}
          </template>
        </el-table-column>
        <el-table-column prop="totalAssetsYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column prop="fixedAsset" label="固定资产(元)" align="center">
        <template slot-scope="scope">
          {{ bigNumberTransform(scope.row.fixedAsset) }}
        </template>
      </el-table-column>
      <el-table-column label="货币资金" align="center">
        <el-table-column prop="monetaryFunds" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.monetaryFunds) }}
          </template>
        </el-table-column>
        <el-table-column prop="monetaryFundsYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="应收账款" align="center">
        <el-table-column prop="accountsReceivable" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.accountsReceivable) }}
          </template>
        </el-table-column>
        <el-table-column prop="accountsReceivableYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="存货" align="center">
        <el-table-column prop="stocks" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.stocks) }}
          </template>
        </el-table-column>
        <el-table-column prop="stocksYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="总负债" align="center">
        <el-table-column prop="totalLiabilities" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.totalLiabilities) }}
          </template>
        </el-table-column>
        <el-table-column prop="totalLiabilitiesYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="应付账款" align="center">
        <el-table-column prop="accountsPayable" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.accountsPayable) }}
          </template>
        </el-table-column>
        <el-table-column prop="accountsPayableYoy" label="同比(%)" align="center">
          <template slot-scope="scope">
            {{ scope.row.accountsPayableYoy==null?'-':scope.row.accountsPayableYoy }}
          </template>
        </el-table-column>
      </el-table-column>
<!--      <el-table-column label="预收账款" align="center">-->
<!--        <el-table-column prop="accountsReceivableAdvance" label="金额(元)" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            {{ bigNumberTransform(scope.row.accountsReceivableAdvance) }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="accountsReceivableAdvanceYoy" label="同比(%)" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            {{ scope.row.accountsReceivableAdvanceYoy==null?'-':scope.row.accountsReceivableAdvanceYoy }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table-column>-->
      <el-table-column label="股东权益" align="center">
        <el-table-column prop="shareholdersEquity" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.shareholdersEquity) }}
          </template>
        </el-table-column>
        <el-table-column prop="shareholdersEquityYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column prop="debtAssetRatio" label="资产负债率(%)" align="center" />
    </el-table>
  </div>
</template>
<script>

import { bigNumberTransform } from '@/utils/number'

export default {
  props: {
    tableData: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    bigNumberTransform,
    tableSort(column) {
      const orderType = this.otherzh(column)
      this.$emit('custom-sort-change', orderType)
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        return column.prop
      } else if (column.order == 'descending') {
        return column.prop + ' DESC'
      } else {
        return ''
      }
    }

  }
}
</script>

<style scoped lang="scss"></style>
