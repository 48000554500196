<template>
  <router-view v-if="$route.name == '标准动态列表' || $route.name == '标准动态内容页'" />
  <PageContainer v-else>
    <!--            <VnotLogin v-if="!show" title="标准" text="标准栏目围绕标准本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新标准" :showTips="showTips"> </VnotLogin>-->
    <el-row :gutter="16">
      <el-col :span="12">
        <newlistHead
          title="标准动态"
          url="assets/img/gradientImg.png"
          href="/standards/dynamic/listDynamic"
        />
        <ul class="newUl">
          <li v-for="item in standardDynamicData" :key="item.id">
            <div
              style="cursor: pointer"
              class="newUl-link"
              @click="toHref('/standards/dynamic/contentDynamic/' + item.id,item,'标准动态','tt_standard_dynamic')"
            >
              <template v-if="item.belongAreas">
                <div v-for="item in strSplit(item.belongAreas)" :key="item" class="newUl-tag">{{ item }}</div>
              </template>
              <!--                  <el-tooltip class="item" effect="light" :content="htmlRestore(item.title)" placement="top-start">-->

              <!--                  <div class="newUl-title">{{ htmlRestore(item.title) }}</div>-->
              <!--                  </el-tooltip>-->
              <TextOverTooltip ref-name="testName1" class-name="newUl-title" :content="item.title" />

              <div class="newUl-time">{{ item.updateTime }}</div>
            </div>
          </li>
        </ul>
      </el-col>
      <el-col :span="12">
        <newlistHead
          title="标准解读"
          url="assets/img/gradientImg.png"
          href="/standards/dynamic/listUnscramble"
        />
        <ul class="newUl">
          <li v-for="item in standardUnscrambleData" :key="item.id">
            <div
              style="cursor: pointer"
              class="newUl-link"
              @click="toHref('/standards/dynamic/contentUnscramble/' + item.id,item,'标准解读','tt_standard_unscramble')"
            >

              <template v-if="item.belongAreas">
                <div v-for="item in strSplit(item.belongAreas)" :key="item" class="newUl-tag">{{ item }}</div>
              </template>
              <!--                  <el-tooltip class="item" effect="light" :content="htmlRestore(item.title)" placement="top-start">-->

              <!--                  <div class="newUl-title">{{ htmlRestore(item.title) }}</div>-->
              <!--                  </el-tooltip>-->
              <TextOverTooltip ref-name="testName2" class-name="newUl-title" :content="item.title" />

              <div class="newUl-time">{{ item.updateTime }}</div>
            </div>
          </li>
        </ul>
      </el-col>
    </el-row>
  </PageContainer>
</template>
<script>
/**
 * @description: 标准动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo, logRecords } from '@/utils/auth.js'
import {
  standardDynamic,
  standardUnscramble,
  solicitComments,
  newStandard
} from '@/api/standards.js'
import { fmtDate } from '@/utils/date.js'
import {buryPoint, selectCountByColumn} from '@/api/common'
import { selectDictDataByType } from '@/api/dict'
import TextOverTooltip from '@/components/title/index'
import {Message} from "element-ui";
export default {
  components: {
    TextOverTooltip
  },
  data() {
    return {
      showTips: [
        {
          title: '标准动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '标准库查询',
          text: '权威标准库，多条件筛查关联比对'
        },
        {
          title: '标准梳理',
          text: '专家梳理整合，真正读懂标准脉络'
        },
        {
          title: '标准大数据',
          text: '用数据深度理解标准结构和趋势'
        }
      ],
      standardDynamicData: [],
      standardUnscrambleData: [],
      solicitCommentsData: [],
      newStandardData: [],
      loading: false,
      tableData: [],
      state: [],
      standardType: [],
      pagination: {},
      currentPage: 0,
      show: false,
      ac: '',
      newStandardPage: {
        pages: {
          page: 1,
          pageSize: 13
        },
        total: 0,
        currentPage: 0
      },
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '标准',
        userId: ''
      }
    }
  },
  computed: {
    newDate() {
      return fmtDate('YY年mm月', new Date())
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }

    this.init()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(url, item, column2, tableName) {
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('标准', column2, '1', tableName, item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('标准')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              selectCountByColumn({
                'userId': getUserInfo().userId,
                'column1': '标准',
                'column2': column2
              }).then((res) => {
                if (res.ok) {
                  if (res.data != null && res.data >= 10) {
                    Message({
                      showClose: true,
                      message: '您的体验次数已达上限，请正式开通！',
                      type: 'error'
                    })
                    return
                  } else {
                    logRecords('标准', column2, '1', tableName, item.id, '', item.title, '')
                    window.open(newUrl.href, '_blank')
                  }
                }
              })
            } else {
              logRecords('标准', column2, '1', tableName, item.id, '', item.title, '')
              window.open(newUrl.href, '_blank')
            }
          }
        } else {
          logRecords('标准', column2, '1', tableName, item.id, '', item.title, '')
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    radioChange(e, tree, url) {
      if (e.target.tagName != 'INPUT') {
        return
      }

      this.toHref(url)
    },
    // 标准状态
    getState() {
      selectDictDataByType({ dictType: 'mng_bz_standard_state' }).then(
        (res) => {
          if (res.ok) {
            res.data.unshift({ 'dictLabel': '全部', 'dictValue': '' })
            this.state = res.data
          }
        }
      )
    },
    // 获取字典
    getCategory() {
      selectDictDataByType({ dictType: 'standard_type' }).then(
        (res) => {
          if (res.ok) {
            this.standardType = res.data
          }
        }
      )
    },
    tabBtn(e) {
      if (getUserInfo()) {
        this.ac = e
        this.newStandard()
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    init() {
      // this.getState();
      // this.getCategory();
      // this.newStandard();
      this.standardDynamic({ page: 1, pageSize: 20 })
      this.standardUnscramble({ page: 1, pageSize: 20 })
      // this.solicitComments({page: 1, pageSize: 18});
    },
    // 标准动态
    standardDynamic(params) {
      standardDynamic(params).then((res) => {
        if (res.ok) {
          this.standardDynamicData = res.data.list
        }
      })
    },
    // 标准解读
    standardUnscramble(params) {
      standardUnscramble(params).then((res) => {
        if (res.ok) {
          this.standardUnscrambleData = res.data.list
        }
      })
    },
    // 标准征求意见/报批稿
    solicitComments(params) {
      solicitComments(params).then((res) => {
        if (res.ok) {
          this.solicitCommentsData = res.data.list
        }
      })
    },
    // 最新标准
    newStandard() {
      this.loading = true
      const form = {
        ...this.newStandardPage.pages,
        state: this.ac
      }
      newStandard(form).then((res) => {
        if (res.ok) {
          this.newStandardData = res.data.list
          this.newStandardPage.total = res.data.count
          this.loading = false
        }
      })
    },
    toClick() {
      // this.$store.dispatch("common/SET_qiehuan", "zqyjg");
      this.$router.push('/standards/dynamic/newComments/list')
    },
    // 字符串转数组
    strSplit(str) {
      return str.split(',')
    }
  }
}
</script>
<style lang="scss" scoped>
.newUl-link {
  cursor: pointer;
}

.el-col-14 {
  width: 58%;
}

.zcTitle {
  margin: 20px 0;
  font-weight: bold;
  font-size: 16px
}

::v-deep .el-radio__inner {
  display: none;
}

::v-deep .el-timeline-item__node--large {
  left: -1px;

}

::v-deep .el-radio__label {
  padding: 0;
}

::v-deep .el-radio {
  margin-right: 0;
}

.state {
  //padding: 4px;
  //background: #f2c94c;
  //border-radius: 2px;
  //font-size: 14px;
  //line-height: 22px;
  //color: #000;
  //font-weight: bold;
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  padding: 4px;
  background: #f2c94c;
  margin-right: 4px;
}

.state.gre {
  background: #27ae60;
  color: #fff;
}

.state.red {
  background: #eb5757;
  color: #fff;
}

.state.blue {
  background: #0D57BC;
  color: #fff;
}

.box {
  padding: 0 30px;
  background: #fff;
}

.tab-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;

  .tab-list {
    cursor: pointer;
    font-size: 15px;
    //line-height: 10px;
    color: #000000;
    margin-right: 24px;
    margin-bottom: 18px;

    &.ac {
      position: relative;
      font-weight: bold;
      color: #0d57bc;

      &::after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        bottom: -18px;
        width: 100%;
        height: 3px;
        background: #0d57bc;
      }
    }
  }

  ::v-deep .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}
.el-message-box__title {
  font-size: 16px;

}
.el-message-box__message p {
  line-height: 14px;
}
</style>
