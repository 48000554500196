<template>
  <div class="login-mask" v-if="flag">
    <div class="login-box">
      <div class="close-btn" @click="close('form')">
        <i class="el-icon-close"></i>
      </div>
      <div style="background-color: #CDE1FD;height: 70px;margin-bottom: 40px;padding: 22px 10px"><span
          style="text-align: center;font-weight: bold;font-size: 16px;">【提示】本操作需要开通此栏目后操作
        <el-popover
            placement="top-start"
            title=""
            trigger="hover">
          <div>
            <div style="font-weight: bold;margin-bottom: -8px;">
            //免费开通
            </div><br/>
            <div style="margin-bottom: -8px;">
            电话：022-84370000-分机1582/18322511031
            </div><br/>
            <div>
            邮箱：liwenjuan@catarc.ac.cn
            </div>
          </div>
          <span  slot="reference" style="color: #0D57BC;cursor: pointer">申请试用</span>
        </el-popover>
        账号</span>
      </div>
<div style="text-align: center">
  <el-button style="border: 2px solid #b0c5f6" ><i class="el-icon-monitor" style="font-size: 20px;margin-right: 10px;" ></i>方式一&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight: bold;font-size: 16px">在线申请试用</span></el-button><br>
  <el-button style="border: 2px solid #b0c5f6;margin-top: 20px"><i class="el-icon-phone" style="font-size: 20px;margin-right: 10px;" ></i>方式一&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight: bold;font-size: 16px">直接联系我们</span></el-button>

</div>

    </div>
  </div>
</template>
<script>
import {login} from "@/api/login.js";
import {
  setUserInfo,
  getUserInfo,
  restUserInfo,
  setUserState,
  restUserState,
  getUserState,
  getSecrecy,
  setSecrecy,
  restSecrecy, removeUserState
} from "@/utils/auth.js";
import store from "@/store";

export default {
  name: "Vlogin",
  data() {
    var validateUsername = (rule, value, callback) => {
      if (this.swicthName == "手机号登录") {
        if (value === "") {
          callback(new Error("请输入帐号"));
        } else {
          callback();
        }
      }
    };

    var validatePassword = (rule, value, callback) => {
      if (this.swicthName == "手机号登录") {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else {
          callback();
        }
      }
    };
    var validateUserPhone = (rule, value, callback) => {
      if (this.swicthName == "账号密码登录") {
        if (value === "") {
          callback(new Error("请输入手机号码"));
        } else if (!/^1[345789][0-9]{9}$/.test(value)) {
          callback(new Error("手机号码格式不正确"));
        } else {
          callback();
        }
      }
    };
    var validateYzm = (rule, value, callback) => {
      if (this.swicthName == "账号密码登录") {
        if (value === "") {
          callback(new Error("请输入验证码"));
        } else {
          callback();
        }
      }
    };
    // username: "jyc",
    //   password: "123456",
    return {
      form: {
        username: "",
        password: "",
        phone: "",
        yzm: "",
        remember: false,
      },
      rules: {
        username: [{validator: validateUsername, trigger: "blur"}],
        password: [{validator: validatePassword, trigger: "blur"}],
        phone: [{validator: validateUserPhone, trigger: "blur"}],
        yzm: [{validator: validateYzm, trigger: "blur"}],
      },
      flag: false,
      loading: false,
      swicthName: "手机号登录",
    };
  },
  created() {
    let userInfo = getUserInfo();
    // this.form.remember = userInfo?userInfo.remember:false;
    this.form.remember = getUserState();
    if (getUserState()) {
      this.form.username = getSecrecy().username;
      this.form.password = getSecrecy().password;
    }
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let form = {};
          if (this.swicthName == "手机号登录") {
            form.username = this.form.username;
            form.password = this.form.password;
            form.rememberMe = this.form.remember;
          } else {
            form.phone = this.form.phone;
            form.yzm = this.form.yzm;
          }
          form.browserId = store.state.common.browserId
          login(form).then((res) => {
            if (res.ok) {
              this.$message({
                showClose: true,
                message: "登录成功",
                type: "success",
              });
              sessionStorage.removeItem('version')
              form.remember = this.form.remember;
              // let data = Object.assign(form, res.data);
              if (form.remember) {
                setUserInfo(res.data);
                setUserState(true)
                setSecrecy(form)
              } else {
                restUserInfo(res.data)
                removeUserState()
                restSecrecy(form)
              }
              this.flag = false;
              window.location.reload();
            }
            this.loading = false;
          });
        }
      });
    },
    close(formName) {
      this.flag = false;
      this.$refs[formName].resetFields();
      this.swicthName = "手机号登录";
      this.$emit("close");
    },
    show() {
      this.flag = true;
    },
    loginSwitch() {
      this.swicthName =
          this.swicthName == "手机号登录" ? "账号密码登录" : "手机号登录";
      this.form.yzm = ''
      this.$refs.form.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.login-mask {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
}

.login-box {
  position: relative;
  width: 500px;
  background: #ffffff;
  padding-bottom: 40px;
  border-radius: 8px;
  margin: auto;

  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
  }

  img {
    display: block;
    width: 213px;
    height: 71px;
    margin: 99px auto 78px;
  }

  .login-form {
    width: 360px;
    margin: 0 auto;

    .yzm-btn {
      position: absolute;
      right: 9px;
    }
  }
}
</style>
