<template>
  <div class="enterprise-search">
    <div class="tabs">
      <el-tabs v-model="enterpriseListParams.enterpriseType" :before-leave="leaveTab" @tab-click="querySearch">
        <el-tab-pane label="整车企业" name="乘用车企业" />
        <el-tab-pane label="零部件企业" name="零部件企业" />
      </el-tabs>
    </div>
    <div class="type-select">
      <el-radio-group
        v-if="enterpriseListParams.enterpriseType === '乘用车企业'"
        v-model="selectedCarType"
        @input="radioquerySearch"
      >
        <el-radio class="text-button" label="乘用车企业">
          乘用车
        </el-radio>
        <el-radio class="text-button" label="商用车企业">
          商用车
        </el-radio>
      </el-radio-group>
      <div class="clickButton" flex="c">
        <el-tooltip :disabled="!prevshow" class="item" effect="dark" content="到底了" placement="top">
          <div
            ref="prevButton"
            class="swiper-button-prev swiper-special-prev"
            style="position: static;z-index:1;width:26px;height:26px;margin-right:10px"
            @mouseover="prevMouseover"
            @mouseout="prevMouseout"
          >
            <i ref="eliconarrowleft" class="el-icon-arrow-left" />
          </div>
        </el-tooltip>
        <el-tooltip :disabled="!nextshow" class="item" effect="dark" content="到底了" placement="top">
          <div
            ref="nextButton"
            class="swiper-button-next swiper-special-next"
            style="position: static;;z-index:1;width:26px;height:26px"
            @mouseover="nextMouseover"
            @mouseout="nextMouseout"
          >
            <i ref="eliconarrowright" class="el-icon-arrow-right" />
          </div>
        </el-tooltip>
      </div>
    </div>

    <div class="special-container">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in enterprisesData" :key="index">
          <div class="swiper-link">
            <div v-for="(v, i) in item" :key="i" class="swiper-box" @click="toHref(v.enterpriseId,v.enterpriseName)">
              <img :src="v.enterpriseLogoUrl" alt="">
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { getEnterpriseList } from '@/api/enterpriseAnalysis.js'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { getUserInfo, logRecords } from '@/utils/auth.js'
import OfficialSwiper, { Autoplay, Navigation } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import { buryPoint } from '@/api/common'

OfficialSwiper.use([Autoplay, Navigation])
export default {
  name: 'EnterpriseSearch',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      action: true,
      prevshow: true,
      nextshow: true,
      enterpriseListParams: {
        enterpriseType: '乘用车企业',
        searchValue: ''
      },
      selectedCarType: '乘用车企业',
      enterprisesData: [],
      selectIndex: '',
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '企业情报',
        userId: ''
      },
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-special-next',
          prevEl: '.swiper-special-prev'
        }
      }
    }
  },
  mounted() {
    this.getEnterpriseList(this.enterpriseListParams)
  },
  methods: {
    async jurisdiction() {
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
            this.enterpriseListParams.enterpriseType = '乘用车企业'
            this.action = false
            return
          } else {
            this.action = true
          }
        } else {
          this.action = true
        }
      } else {
        this.showLogin()
        this.enterpriseListParams.enterpriseType = '乘用车企业'
        this.action = false
        return
      }
    },
    leaveTab(activeName, oldActiveName) {
      this.jurisdiction()
      if (!this.action) {
        return false
      }
    },
    prevMouseover() {
      const element = document.querySelector('.swiper-button-prev')
      if (element.classList.contains('swiper-button-disabled')) {
        this.prevshow = true
      } else {
        this.prevshow = false
        this.$refs.eliconarrowleft.style.color = '#7ca2f1'
      }
    },
    prevMouseout() {
      this.$refs.eliconarrowleft.style.color = 'black'
    },
    nextMouseover() {
      const element = document.querySelector('.swiper-button-next')
      if (element.classList.contains('swiper-button-disabled')) {
        this.nextshow = true
      } else {
        this.nextshow = false
        this.$refs.eliconarrowright.style.color = '#7ca2f1'
      }
    },
    nextMouseout() {
      this.$refs.eliconarrowright.style.color = 'black'
    },
    querySearch(e) {
      this.getEnterpriseList(this.enterpriseListParams)
    },
    radioquerySearch(e) {
      this.jurisdiction()
      if (!this.action) {
        this.selectedCarType = '乘用车企业'
        return
      }
      this.getEnterpriseList(this.enterpriseListParams)
    },
    getEnterpriseList(params) {
      const enterpriseType = this.enterpriseListParams.enterpriseType === '乘用车企业' ? this.selectedCarType : this.enterpriseListParams.enterpriseType
      const enterparams = {
        ...params,
        enterpriseType: enterpriseType
      }
      getEnterpriseList(enterparams).then(res => {
        if (res.ok) {
          const flattenedArray = []
          for (const sublist of Object.values(res.data)) {
            sublist.forEach((item) => {
              flattenedArray.push(item)
            })
          }
          const chunkSize = 8
          const numOfChunks = Math.ceil(flattenedArray.length / chunkSize)
          const result = []
          for (let i = 0; i < numOfChunks; i++) {
            const start = i * chunkSize
            const end = start + chunkSize
            result.push(flattenedArray.slice(start, end))
          }
          this.enterprisesData = result
        }
      })
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    toHref(id, enterpriseName) {
      const routeUrl = `/workInformation/enterpriseInfo/detail/${id}`
      const routeUrlQuery = {
        path: routeUrl,
        query: {
          enterpriseType: this.enterpriseListParams.enterpriseType,
          enterpriseName: enterpriseName

        }
      }
      const newUrl = this.$router.resolve(routeUrlQuery)
      if (getUserInfo()) {
        logRecords('企业情报', '企业信息查询', '1', '', id, '', enterpriseName, '')
        if (
          getUserInfo().roleKey == 'paidrole' ||
                    getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_self')
          }
        } else {
          window.open(newUrl.href, '_self')
        }
      } else {
        this.showLogin()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.enterprise-search {
    margin-top: 10px;

    .type-select {
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.tabs {
    ::v-deep {
        .el-tabs__header {
            margin-bottom: 0;
        }

        .el-tabs__nav-wrap::after {
            width: 0;
        }

        .el-tabs__active-bar {
            width: 0 !important;
        }

        .el-tabs__item {
            margin-right: 22px;
            padding: 0;
            font-size: 16px;
            font-weight: normal;

            &:hover {
                font-weight: normal;
            }

            &.is-active {
                color: #111;
                font-weight: bold;

                &::after {
                    height: 4px;
                    border-radius: 2px;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 20px;
                    background-color: #0069ea;
                }
            }
        }
    }
}

.special-container {
    position: relative;
    width: 100%;
    // height: 31.4%;
    z-index: 1;
    padding: 0px 0 22px 0;

    ::v-deep .swiper-container {
        height: 100%;

        .swiper-slide {
            width: 100%;
            display: flex;
            cursor: pointer;
        }

        .swiper-link {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            width: 100%;
            justify-content: space-between;
            align-content: space-between;

            .swiper-box {
                padding-top: 22.19%;
                width: calc(25% - 11.25px);
                position: relative;
                margin-top: 14px;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-radius: 4px;
                    height: 100%;
                    width: 100%;
                    border: 1px solid #f3f3f3;
                }
            }
        }
    }
}

::v-deep {
    .text-button {
        margin: 0;
        height: 26px;

        &:nth-child(n + 2) {
            padding-right: 20px;
        }
    }

    .el-radio .el-radio__input {
        display: none;
    }

    .el-radio-button .el-radio-button__inner {
        padding: 7px 12px;
    }
}
</style>
