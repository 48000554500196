<template>
  <PageContainer breadcrumb-trail>
    <template slot="actionNav">
      <div class="tabs">
        <actionTabs action-nav-information="Information" />
      </div>
    </template>
    <BasicInfo />
    <div v-if="this.$route.query.enterpriseType !== '零部件企业'">
      <Dynamic style="margin-top: 10px;" />
      <Graphic style="margin-top: 10px;" :enterpriseName="this.$route.query.enterpriseName"/>
      <Capacity style="margin-top: 10px;" />
      <SalesAmount style="margin-top: 10px;" />
      <StrategyPlanning style="margin-top: 10px;" />
      <Supporting style="margin-top: 10px;" />
      <MainModels style="margin-top: 10px;" />
      <BusinessInfo style="margin-top: 10px;" />
      <Productionbase style="margin-top: 10px;" />
      <CarModel style="margin-top: 10px;" />
    </div>
    <div v-else>
      <Graphic style="margin-top: 10px;" :enterpriseName="this.$route.query.enterpriseName"/>
      <MainProducts style="margin-top: 10px;" />
      <supportingParts style="margin-top: 10px;" />
      <Performance style="margin-top: 10px;" />
      <History style="margin-top: 10px;" />
    </div>
    <template slot="right">
      <NavList title="企业信息详情" />
    </template>
  </PageContainer>
</template>

<script>
import actionTabs from '../../components/actionTabs.vue'
import BasicInfo from './basicInfo.vue'
import Dynamic from './completeVehicle/dynamic.vue'
import Graphic from './completeVehicle/graphic.vue'
import Capacity from './completeVehicle/capacity.vue'
import SalesAmount from './completeVehicle/salesAmount.vue'
import Supporting from './completeVehicle/supporting.vue'
import MainModels from './completeVehicle/mainModels.vue'
import BusinessInfo from './completeVehicle/businessInfo.vue'
import Productionbase from './completeVehicle/productionbase.vue'
import StrategyPlanning from './completeVehicle/strategyPlanning.vue'
import CarModel from './completeVehicle/carModel.vue'
import MainProducts from './Parts/mainProducts.vue'
import SupportingParts from './Parts/supportingParts.vue'
import Performance from './Parts/performance.vue'
import History from './Parts/history.vue'
import NavList from '../components/navList.vue'

export default {
  name: 'InformationVueIndex',
  components: {
    actionTabs,
    BasicInfo,
    Dynamic,
    Capacity,
    Graphic,
    SalesAmount,
    Supporting,
    MainModels,
    BusinessInfo,
    Productionbase,
    StrategyPlanning,
    CarModel,
    MainProducts,
    SupportingParts,
    Performance,
    History,
    NavList
  },
  data() {
    return {}
  },
  created() {
    // 在组件创建时获取路由参数
    this.enterpriseId = this.$route.params.id
    this.enterpriseName = this.$route.query.enterpriseName
  },
  methods: {}
}
</script>
