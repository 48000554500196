<template>
  <div class="feedback-box">
    <div class="feedback-inner">
      <div class="feedback-title">意见反馈</div>
      <div class="feedback-content">
        <h1>选择您想反馈的栏目，在输入框中输入您的意见和建议。</h1>
        <p>我们珍视您的意见和建议，请随时向我们反馈！</p>
        <div class="feedback-item-box">
          <div class="feedback-item-l">
            <div
              v-for="item in asideData"
              :key="item.id"
              class="feedback-item-btn"
              :class="{ act: item.id == actid }"
              @click="actFun(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="feedback-item-r">
            <!--            <div class="feedback-item-select">-->
            <!--              <a-->
            <!--                href="javascript:;"-->
            <!--                @click="swatchFun(actName)"-->
            <!--                :class="{ act: swatcName == actName }"-->
            <!--                >{{ actName }}缺失</a-->
            <!--              >-->
            <!--              <a-->
            <!--                href="javascript:;"-->
            <!--                @click="swatchFun('内容有误')"-->
            <!--                :class="{ act: swatcName == '内容有误' }"-->
            <!--                >内容有误</a-->
            <!--              >-->
            <!--            </div>-->
            <div class="feedback-item-content">
              <el-input
                v-model="textarea"
                type="textarea"
                placeholder="请输入内容"
                resize="none"
              />
            </div>
            <el-form
              ref="form"
              :model="form"
              label-width="80px"
              :inline="true"
              :rules="rules"
              style="margin-right: -10px"
            >
              <el-form-item label="电话：" prop="phone">
                <el-input v-model="form.phone" size="small" maxlength="11" />
              </el-form-item>
              <el-form-item label="公司：" prop="company">
                <el-input v-model="form.company" size="small" />
              </el-form-item>
              <el-form-item label="姓名：" prop="name">
                <el-input v-model="form.name" size="small" />
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="btn">
          <el-button type="primary" plain @click="submit">提交</el-button>
          <el-button plain @click="close">关闭</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserInfo } from '@/utils/auth.js'
export default {
  props: {
    asideData: {
      type: Array,
      default: function() {
        return []
      }
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    var validateUserPhone = (rule, value, callback) => {
      if (!/^1[345789][0-9]{9}$/.test(value)) {
        callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    }
    return {
      textarea: '',
      actid: 0,
      actName: '',
      swatcName: '',
      form: {
        phone: '',
        company: '',
        name: ''
      },
      rules: {
        phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }, { validator: validateUserPhone, trigger: 'blur' }],
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        company: [{ required: true, message: '公司不能为空', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.actName = this.asideData[0].name
    this.actid = this.asideData[0].id
  },
  methods: {
    // 关闭
    close() {
      this.$emit('close')
    },
    // 侧边栏切换
    actFun(item) {
      this.actid = item.id
      this.actName = item.name
      this.swatcName = ''
    },
    // 类别切换
    swatchFun(name) {
      this.swatcName = name
    },
    // 提交
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const obj = {
            opinionModule: this.actid,
            opinionType: this.swatcName
              ? this.swatcName + '缺失'
              : this.swatcName,
            detailedRequirements: this.textarea,
            phone: this.form.phone,
            name: this.form.name,
            company: this.form.company,
            remark: this.actName,
            userId: getUserInfo().userId,
            requirementType: 8
          }

          this.$emit('submit', obj)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.feedback-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba($color: #000, $alpha: 0.3);
  display: flex;
  .feedback-inner {
    width: 730px;
    border-radius: 8px;
    margin: auto;
    .feedback-title {
      width: 100%;
      height: 160px;
      line-height: 160px;
      background: url(../assets/img/Group84.png) no-repeat;
      background-size: 100% 100%;
      color: #fff;
      text-align: center;
      font-weight: bold;
      font-size: 32px;
      color: #fff;
    }
    .feedback-content {
      padding: 0 40px;
      // height: 530px;
      background: #fff;
      padding-bottom: 26px;
      h1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #141414;
        padding-top: 26px;
        margin-bottom: 6px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #828282;
        margin-bottom: 16px;
      }
      .feedback-item-box {
        display: flex;
        .feedback-item-l {
          height: 100%;
          overflow: hidden;
          overflow-y: auto;
          width: 109px;
          margin-right: 40px;
          .feedback-item-btn {
            height: 38px;
            line-height: 38px;
            text-align: center;
            cursor: pointer;
            &.act {
              background: #f3f8ff;
            }
          }
        }
        .feedback-item-r {
          flex: 1;
          //align-self: flex-end;
          .feedback-item-select {
            display: flex;
            margin-bottom: 24px;
            a {
              display: block;
              margin-left: 10px;
              min-width: 100px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              font-size: 14px;
              color: #828282;
              border: 1px solid #828282;
              border-radius: 4px;
              &.act {
                color: #0D57BC;
                border-color: #0D57BC;
              }
            }
          }
          .feedback-item-content {
            ::v-deep .el-textarea__inner {
              height: 160px;
            }
            margin-bottom: 10px;
          }
          ::v-deep {
            .el-form-item__content {
              width: 420px;
            }
            .el-form-item {
              margin-bottom: 20px;
            }
          }
        }
      }
      .btn {
        text-align: center;
      }
    }
  }
}
</style>
