import $http from '@/plugins/http.js'

export function login(query) {
    return $http.post('/lo/login', query)
}

export function specialLogin(query) {
  return $http.post('/lo/specialLogin', query)
}

export function infoLogin() {
    return $http.post('/lo/infoLogin')
}

export function logout() {
    return $http.get('/lo/logout')
}

export function getCurrentUser() {
    return $http.get('/lo/getCurrentUser')
}

//富奥登录
export function fuAoLogin(query) {
    return $http.post('/lo/fuAoLogin', query)
}



//申请试用
export function sqSy(query) {
    return $http.post('/lo/userSysQ', query)
}

//发送邮箱验证码
export function sendEmailCode(query) {
    return $http.get('/lo/sendEmailCode', query)
}

//发送手机验证码
export function sendPhoneMessage(query) {
    return $http.get('/lo/sendPhoneMessage', query)
}

//发送绑定手机号验证码
export function sendBindPhoneMessage(query) {
    return $http.get('/lo/sendBindPhoneMessage', query)
}

//发送登录手机号验证码
export function sendPhoneMessageByLogin(query) {
    return $http.get('/lo/sendPhoneMessageByLogin', query)
}


//文件上传
export function upload() {
    return $http.post('/lo/common/upload')
}
