<template>
  <PageContainer>
    <div v-loading="queryLoading || downloading" style="position: relative;z-index: 1;">
      <!-- 用于显示行分类以及保存查询等相关操作 -->
      <div v-if="showCategoryListBar" class="category-header">
        <category-list
          :list="categoryNameList"
          :active-name="activeCategoryName"
          :is-international="isInternational"
          @selectChange="handleCategorySelectChange"
        >
          <div class="inner-content" v-html="categoryHTML" />
          <div v-if="showContactUs" class="inner-content">
            {{ contactUsText }}请<a @click="showContactUsDialog = true">联系我们</a>
          </div>
        </category-list>
      </div>
      <div class="main-container-wrapper hidden-scrollbar">
        <div class="main-container">
          <!-- 左侧部分 -->
          <div class="left">
            <!-- 数据表字段列表 -->
            <div class="data-table-field-list-wrapper">
              <div class="title">数据表字段</div>
              <div ref="dataTableFieldListRef" class="data-table-field-list custom-scrollbar">
                <div class="title dimension">维度</div>
                <div :class="['field-list', allDimensionList.length === 0 ? 'empty' : '']">
                  <div v-if="allDimensionList.length === 0">暂无维度</div>
                  <template v-else>
                    <outside-drag-block
                      v-for="field in allDimensionList"
                      :key="field.name"
                      :field="field"
                      :can-use-plus-block="canUsePlusBlock"
                      :disabled="isOutsideDimensionDragBlockDisabled(field)"
                      :target-area-name="getDimensionTargetAreaName"
                      :preferred-target-area-key-when-click="getDimensionBlockPreferredTargetAreaKeyWhenClick"
                      @alertNeedPlus="handleShowNeedPlusAlert"
                    />
                  </template>
                </div>
                <div class="title target">指标</div>
                <div v-for="(item,index) in getindexList(allTargetList)" :key="index">
                  <div v-if="allTargetList.length === 0">暂无指标</div>
                  <div v-if="item.type" style="margin-top: 6px; margin-bottom: 8px; color: #0D57BC; font-weight: bold;">{{ item.type }}</div>
                  <div :class="['field-list', allTargetList.length === 0 ? 'empty' : '']">
                    <template v-if="allTargetList && allTargetList.length > 0">
                      <outside-drag-block
                        v-for="(field) in item.list"
                        :key="field.name"
                        :field="field"
                        :can-use-plus-block="canUsePlusBlock"
                        :disabled="isOutsideTargetDragBlockDisabled(field)"
                        :target-area-name="targetAreaName"
                        @alertNeedPlus="handleShowNeedPlusAlert"
                      />
                    </template>
                  </div>
                </div>

              </div>
            </div>
            <!-- 图表类型和图形设置 -->
            <div class="display-mode-wrapper">
              <!-- 切换 -->
              <div class="tab-header-wrapper">
                <div class="tab-header flex">
                  <div
                    :class="['tab-item', isActivateDisplayMode ? 'active' : '']"
                    @click="isActivateDisplayMode = true"
                  >图表类型</div>
                  <div
                    :class="['tab-item', !isActivateDisplayMode ? 'active' : '']"
                    @click="isActivateDisplayMode = false"
                  >图形设置</div>
                </div>
                <div :class="['slide-line-wrapper', isActivateDisplayMode ? 'is-display-mode-active' : '']" />
              </div>
              <DisplayModeSelector
                v-show="isActivateDisplayMode"
                :active-display-mode-name="selectedDisplayModeName"
                @change="handleDisplayModeChange"
              />
              <GraphSetting
                v-show="!isActivateDisplayMode"
                ref="graphSettingRef"
                :active-display-mode-name="selectedDisplayModeName"
                @change="handleGraphSettingChange"
              />
            </div>
          </div>
          <!-- 右侧部分 -->
          <div class="right">
            <div class="query-params-area">
              <center-tip ref="centerTipRef" />
              <div v-show="isDisplayWithCrossTable" class="dimension-query-container">
                <div class="query-option">
                  <div class="title">列</div>
                  <!-- 加 key 是防止该组件被 vue 复用，进而导致切换到交叉表后，指标字段拖拽的监听事件被新的组件卸载，导致无法触发指标字段的拖拽 -->
                  <drag-target-area
                    key="columnDimensionDragTargetArea"
                    ref="columnDimensionDragTargetAreaRef"
                    :area-block-list.sync="columnDimensionList"
                    :area-name="columnAndRowDimensionAreaNameArr"
                    :area-key="columnDimensionAreaKey"
                    @blockListCountChange="handleDimensionBlockListCountChange"
                  />
                </div>
                <!-- 右侧的查询相关的按钮 -->
                <div class="flex">
                  <div class="operate-query" @click="handleClickOpenQueryTemplateSelectDialog">
                    <img width="16px" src="@/assets/img/newData/open-query.png" alt="">
                    <span>打开查询</span>
                  </div>
                  <div class="operate-query" @click="handleClickSaveQueryBtn">
                    <img width="16px" src="@/assets/img/newData/saveQuery.png" alt="">
                    <span>保存查询</span>
                  </div>
                </div>
              </div>
              <div v-show="isDisplayWithCrossTable" class="query-option">
                <div class="title">行</div>
                <drag-target-area
                  key="rowDimensionDragTargetArea"
                  ref="rowDimensionDragTargetAreaRef"
                  :area-block-list.sync="rowDimensionList"
                  :area-name="columnAndRowDimensionAreaNameArr"
                  :area-key="rowDimensionAreaKey"
                  @blockListCountChange="handleDimensionBlockListCountChange"
                />
              </div>
              <div v-show="!isDisplayWithCrossTable" class="dimension-query-container">
                <div class="query-option">
                  <div class="title">维度字段</div>
                  <drag-target-area
                    key="dimensionDragTargetArea"
                    ref="dimensionDragTargetAreaRef"
                    :area-block-list.sync="dimensionList"
                    :area-name="dimensionAreaName"
                    area-key="dimensionArea"
                    @blockListCountChange="handleDimensionBlockListCountChange"
                  />
                </div>
                <!-- 右侧的查询相关的按钮 -->
                <div class="flex">
                  <div class="operate-query" @click="handleClickOpenQueryTemplateSelectDialog">
                    <img width="16px" src="@/assets/img/newData/open-query.png" alt="">
                    <span>打开查询</span>
                  </div>
                  <div class="operate-query" @click="handleClickSaveQueryBtn">
                    <img width="16px" src="@/assets/img/newData/saveQuery.png" alt="">
                    <span>保存查询</span>
                  </div>
                </div>
              </div>
              <div class="query-option">
                <div class="title">指标字段</div>
                <drag-target-area
                  key="targetDragTargetArea"
                  :area-block-list.sync="targetList"
                  :area-name="targetAreaName"
                  area-key="targetArea"
                />
              </div>
              <div class="query-option-group">
                <div v-if="toggleDatetimeMode !== null" class="query-option">
                  <div class="title">时间范围</div>
                  <datetime-range
                    v-model="datetimeRange"
                    :target-list="targetList"
                    :toggle-datetime-mode="toggleDatetimeMode"
                    :current-datetime-mode.sync="currentDatetimeMode"
                    :datetime-select-range="datetimeSelectRange"
                    :formatted-datetime-select-range="formattedDatetimeSelectRange"
                    @buryingPoint="doDialogBuryPoint"
                    @changeTimes="changeTimes"
                  />
                </div>
                <!-- 遍历所有需要显示筛选条件组件的维度列表 -->
                <div
                  v-for="({ field }, index) in newList"
                  :key="draggingTableName + field.name"
                  class="query-option closeList"
                >
                  <div class="title">{{ field.name }}</div>
                  <el-select
                    v-if="(field.compType === compTypeEnums.SINGLE_SELECT || field.compType === compTypeEnums.MULTIPLE_SELECT)&&field.prop !== 'brand'&&field.prop !== 'vehicleModel'"
                    v-model="detailParams[field.prop]"
                    size="small"
                    :multiple="field.compType === compTypeEnums.MULTIPLE_SELECT"
                    collapse-tags
                    filterable
                  >
                    <el-option
                      v-for="option in dimensionSelectOptionMap[field.prop]"
                      :key="option.value"
                      :label="option.label"
                      :value="option.value"
                    />
                  </el-select>
                  <letter-select
                    v-if="field.prop === 'brand'||field.prop === 'vehicleModel'"
                    v-model="detailParams[field.prop]"
                    :options="dimensionSelectOptionMap[field.prop]"
                    size="small"
                  />
                  <letter-select
                    v-else-if="field.compType === compTypeEnums.LETTER_SELECT"
                    v-model="detailParams[field.prop]"
                    :options="dimensionSelectOptionMap[field.prop]"
                    size="small"
                  />
                  <img ref="closeRef" :src="require('@/assets/img/newData/remove.png')" alt="" style="cursor: pointer; width: 12px; height: 12px;" @click="closeDrag(index)">
                </div>
              </div>
              <el-tooltip
                v-if="allStatisticList.length > 0"
                :disabled="!statisticToolTip"
                placement="top"
              >
                <div slot="content" v-html="statisticToolTip" />
                <div class="query-option statistic-list">
                  <div class="title">统计分析</div>
                  <div class="statistic-list-container">
                    <el-checkbox-group v-model="statisticList" :disabled="disableStatisticSelect">
                      <el-checkbox v-for="item in allStatisticList" :key="item.name" :label="item.name" />
                    </el-checkbox-group>
                  </div>
                </div>
              </el-tooltip>
              <div class="query-button-container">
                <el-button v-if="isTrialRole" type="primary" @click="isTrialRoleBtn">查询</el-button>
                <el-button v-else type="primary" @click="handleClickQueryBtn">查询</el-button>
                <el-button v-if="isTrialRole" @click="isTrialRoleBtn">清空</el-button>
                <el-button v-else @click="handleClickClearBtn">清空</el-button>
              </div>
            </div>
            <!-- 结果展示容器 -->
            <div class="result-container">
              <div v-show="showMask || !showEmptyData" class="result-header-bar">
                <div class="flex">
                  <!-- 表格的标题 -->
                  <div v-show="isShowTitleAndUnitStr" class="result-header-text">
                    <span
                      :class="['title', graphSettingOption.title ? 'show' : '']"
                    >{{ graphSettingOption.title }}</span>
                    <span
                      style="font-weight: bold;"
                      :class="['unit', getTableUnitText ? 'show' : '']"
                    >{{ getTableUnitText }}</span>
                  </div>
                  <!-- 更新时间字符串 -->
                  <span v-if="isDisplayWithTable" class="update-time-str">{{ updateTimeStr }}</span>
                  <!-- echarts 的黄色警告提示框 -->
                  <div v-show="chartWarnTipText.length > 0" class="tip-container">
                    <img :src="require('@/assets/img/newData/info-circle-fill.png')" alt="">
                    {{ chartWarnTipText }}
                  </div>
                  <div v-if="draggingTableName == 'roroshipPrice' || draggingTableName == 'newGlobalEconomyData'">
                    <div v-if="draggingTableName == 'roroshipPrice'" style="color: #0D57BC;">数据说明
                      <el-tooltip
                        effect="light"
                        placement="top-start"
                        popper-class="dataTooltip"
                      >
                        <i class="el-icon-question" style="vertical-align: top;cursor: pointer;" />
                        <template slot="content">
                          <div class="tooltip-content">
                            1. 仅限小轿车及SUV车型
                            2. COA合同指过去主机工厂跟船东之间的年度协议价格，不代表具体的主机厂及船东
                            3. 上海或者广州为起运港
                            4. 费用仅为海运费，不包含船司本地费用及码头费用
                          </div>
                        </template>
                      </el-tooltip>
                    </div>
                    <!-- <div style="color: #0D57BC;" v-if="draggingTableName == 'newGlobalEconomyData' && activeCategoryName == '汇率'">数据说明
                      <el-tooltip
                        effect="light"
                        placement="top-start"
                        popper-class="dataTooltip"
                      >
                        <i class="el-icon-question"  style="vertical-align: top;cursor: pointer;"/>
                        <template slot="content">
                          <div class="tooltip-content">
                            间接标价法（100人民币折合外币）：澳门元、马来西亚林吉特、俄罗斯卢布、南非兰特、韩元、阿联酋迪拉姆、沙特里亚尔、匈牙利福林、波兰兹罗提、丹麦克朗、瑞典克朗、挪威克朗、土耳其里拉、墨西哥比索、泰铢
                          </div>
                          <div class="tooltip-content">
                            直接标价法（100外币折合人民币）：美元、欧元、日元、港元、英镑、澳元、新西兰元、新加坡元、瑞士法郎、加元
                          </div>
                        </template>
                      </el-tooltip>
                    </div> -->
                  </div>

                </div>
                <div class="operate-bar">
                  <div v-if="isDisplayWithChart" class="item" @click="handleClickExportImage">
                    <img :src="require('@/assets/img/newData/export.png')">
                    导出图片
                  </div>
                  <div class="item" @click="handleClickDownloadData">
                    <img :src="require('@/assets/img/newData/download.png')">
                    下载数据
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'loading',
                  queryLoading || fakeQueryLoading ? 'show' : '',
                  showMask ? 'show-mask': ''
                ]"
              >数据加载中...</div>
              <template v-if="showMask">
                <not-logged-in-mask v-if="showUnsubscribedMaskInter" />
                <unsubscribed-mask v-else-if="showUnsubscribedMaskInter" />
                <Table
                  v-if="isShowTableComp"
                  visible
                  :result-data="getTableCompFakeData"
                  :dragging-table-name="draggingTableName"
                  :page-params="{}"
                  :is-display-with-cross-table="isDisplayWithCrossTable"
                  class="show-mask"
                />
                <Chart
                  ref="fakeChartRef"
                  :visible="isDisplayWithChart"
                  fake
                  class="show-mask"
                />
                <TargetCard
                  ref="fakeTargetCardRef"
                  :visible="isDisplayWithTargetCard"
                  :result-data="targetCardFakeData"
                  :dragging-table-name="draggingTableName"
                  :graph-setting-option="graphSettingOption"
                  class="show-mask"
                />
              </template>
              <template v-else>
                <!-- 无数据的提示 -->
                <div v-show="showEmptyData" class="empty-data">
                  <img width="178px" height="134px" src="@/assets/img/newData/dragging-empty-data.png">
                  <div>可将数据字段拖入上方进行查询</div>
                </div>
                <template v-if="!showEmptyData">
                  <Table
                    :visible="isShowTableComp"
                    :result-data="resultData"
                    :dragging-table-name="draggingTableName"
                    :page-params="pageParams"
                    :is-display-with-cross-table="isDisplayWithCrossTable"
                    @page="handleTablePageQuery"
                  />
                  <Chart
                    ref="chartRef"
                    :visible="isDisplayWithChart"
                  />
                  <TargetCard
                    :visible="isDisplayWithTargetCard"
                    :result-data="resultData"
                    :dragging-table-name="draggingTableName"
                    :graph-setting-option="graphSettingOption"
                  />
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们弹窗 -->
    <ContactUsDialog :dict-label="contactUsDialogDictLabel" :show.sync="showContactUsDialog" />
    <!-- 选择查询模板弹窗 -->
    <QueryTemplateSelectDialog
      :show.sync="showQueryTemplateSelectDialog"
      :query-option-list="getCurrentQueryOptionList"
      :delete-method="queryTemplateDeleteMethod"
      @select="handleSelectQueryTemplate"
      @delete="handleDeleteQueryTemplate"
    />
    <!-- 保存查询模板弹窗 -->
    <QueryTemplateSaveDialog
      ref="queryTemplateSaveDialogRef"
      :save-method="queryTemplateSaveMethod"
      @saveSuccess="handleQueryTemplateSaveSuccess"
    />
  </PageContainer>
</template>

<script>
// 行分类组件
import CategoryList from './comp/categoryList'
import moment from 'moment'
import { NotLoggedInMask, UnsubscribedMask } from '@/components/newData/mask'
// 可拖拽字段组件
import {
  DragTargetArea,
  OutsideDragBlock,
  AreaBlock
} from './comp/dragging/index.js'
import LetterSelect from '@/components/newData/letterSelect/index.vue'
// 结果展示组件
import {
  Table,
  Chart,
  TargetCard,
  // 图表类型选择器组件
  DisplayModeSelector,
  // 图形设置组件
  GraphSetting
} from './comp/display/index.js'
import DatetimeRange from './comp/datetimeRange.vue'
import CenterTip from './comp/centerTip.vue'
import QueryTemplateSelectDialog from './comp/query/queryTemplateSelectDialog.vue'
import QueryTemplateSaveDialog from './comp/query/queryTemplateSaveDialog.vue'
import ContactUsDialog from '../commonComp/contactUsDialog.vue'
import {
  draggingPageInit,
  getDimensionSelectOptions,
  draggingQuery,
  exportDraggingQuery,
  internationalDraggingPageInit,
  internationalGetDimensionSelectOptions,
  internationalDraggingQuery,
  internationalExportDraggingQuery,
  saveDraggingQueryTemplate,
  saveInternationalDraggingQueryTemplate,
  deleteDraggingQueryTemplate,
  deleteInternationalQueryTemplate,
  mockDraggingQuery
} from '@/api/data_new.js'
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import { buryPoint } from '@/api/common'
import { downloadFile } from '@/utils/download.js'
import { authMixin } from '@/mixin/newData/index.js'
import {
  tableFakeData,
  lineBarFakeData,
  stackBarFakeData,
  pieFakeData,
  mixFakeData,
  targetCardFakeData,
  crossTableFakeData
} from './comp/fakeData.js'

export default {
  name: 'DraggingQuery',
  components: {
    NotLoggedInMask,
    UnsubscribedMask,
    CategoryList,
    DragTargetArea,
    OutsideDragBlock,
    DisplayModeSelector,
    GraphSetting,
    LetterSelect,
    DatetimeRange,
    CenterTip,
    Table,
    Chart,
    TargetCard,
    ContactUsDialog,
    QueryTemplateSelectDialog,
    QueryTemplateSaveDialog
  },
  mixins: [authMixin],
  props: {
    draggingTableName: {
      type: String,
      required: true
    }
  },
  data() {
    const chartFakeDataMap = {
      '折线图': lineBarFakeData,
      '柱状图': lineBarFakeData,
      '堆积柱状图': stackBarFakeData,
      '饼图': pieFakeData,
      '组合图': mixFakeData,
      '面积图': lineBarFakeData
    }
    return {
      moment,
      // 用于缓存当前数据表的每个行分类的查询模板列表
      queryTemplateCachedMap: {},
      // 是否修改过默认的展示类型
      hasModifiedDefaultDisplayMode: false,
      // 图类型的假数据
      chartFakeDataMap,
      // 指标卡的假数据
      targetCardFakeData,
      // 数据表字段类型枚举
      fieldTypeEnums: {
        // 维度
        dimension: 0,
        // 指标
        target: 1
      },
      // 维度对应的筛选组件枚举
      compTypeEnums: {
        SINGLE_SELECT: '0',
        MULTIPLE_SELECT: '1',
        LETTER_SELECT: '2'
      },
      // 是否切换到 图表类型
      isActivateDisplayMode: true,
      // 默认以表格形式进行展示
      selectedDisplayModeName: '表格',
      // 图形设置信息
      graphSettingOption: {},
      // 行分类列表
      categoryList: [],
      // 用于标识当前用户对于该数据表是否是 plus 用户
      isPlusUser: false,
      // 行分类之后的 h5 代码
      categoryHTML: null,
      // 是否弹出 联系我们购买完整数据 的弹窗
      showContactUsDialog: false,
      // 当前行分类的详细筛选的日期的可选的年/月切换模式，0 只能用年、1 只能用月、2 年月都能切换 3 根据dateType动态切换日期筛选
      toggleDatetimeMode: 0,
      // 用户切换到的时间筛选组件的时间筛选模式
      currentDatetimeMode: '年',
      // 时间范围组件的可选时间范围
      datetimeSelectRange: [],
      // 列维度的拖拽区域名称
      columnDimensionAreaName: 'column',
      // 行维度的拖拽区域名称
      rowDimensionAreaName: 'row',
      // 维度的拖拽区域名称
      dimensionAreaName: 'dimension',
      // 指标的拖拽区域名称
      targetAreaName: 'target',
      // 可选择的维度字段列表
      allDimensionList: [],
      // 可选择的指标字段列表
      allTargetList: [],
      // 可选择的统计分析列表
      allStatisticList: [],
      // 已选择的列维度字段列表，要按顺序传给后端
      columnDimensionList: [],
      // 已选择的行维度字段列表，要按顺序传给后端
      rowDimensionList: [],
      // 已选择的维度字段列表，要按顺序传给后端
      dimensionList: [],
      // 已选择的指标字段列表，要按顺序传给后端
      targetList: [],
      // 已选择的统计分析列表，要按顺序传给后端
      statisticList: [],
      // 维度字段的下拉选项map
      dimensionSelectOptionMap: {},
      // 筛选条件参数对象
      detailParams: {},
      // 时间范围组件的绑定值
      datetimeRange: [],
      // 当前选择的行分类的名称
      activeCategoryName: null,
      // 更新时间字符串
      updateTimeStr: null,
      // 查询结果
      resultData: null,
      // 是否正在查询
      queryLoading: false,
      // 是否正在下载
      downloading: false,
      // 是否正在模拟请求
      fakeQueryLoading: false,
      // 记录查询参数，用于后续分页和切换展示类型时，调用查询接口
      queryParams: null,
      // 分页查询参数
      pageParams: {
        pageNo: 1,
        pageSize: 10
      },
      // 默认的分页查询参数
      defaultPageParams: {
        pageNo: 1,
        pageSize: 10
      },
      // 数据表初始化接口的请求序列号, 用于标识区分每一次初始化
      initPageRequestSeq: -999999,
      // 数据表的查询接口的请求序列号, 用于标识区分每一次查询
      draggingQueryRequestSeq: -999999,
      // 是否展示查询条件模板选择弹窗
      showQueryTemplateSelectDialog: false,
      // 联系我们的提示语，由后端返回
      contactUsText: null,
      // showMask: false,
      newList: [],
      dateType: '',
      isRouterLink: false
    }
  },
  computed: {
    // 获取维度的块在点击时优先添加到的那个区域的 areaKey
    getDimensionBlockPreferredTargetAreaKeyWhenClick() {
      // 如果当前展示的图表类型不是交叉表
      if (!this.isDisplayWithCrossTable) return null
      // 否则就是展示交叉表
      const { length: columnDimensionLen } = this.columnDimensionList
      const { length: rowDimensionLen } = this.rowDimensionList
      // 如果行维度的数量和列维度的数量一致
      if (columnDimensionLen === rowDimensionLen) {
        // 则优先添加到行维度上
        return this.rowDimensionAreaKey
      }
      // 否则谁的块数量少就优先添加到谁的区域里
      return columnDimensionLen < rowDimensionLen ? this.columnDimensionAreaKey : this.rowDimensionAreaKey
    },
    // 列维度的拖拽区域的key
    columnDimensionAreaKey() {
      return 'columnDimensionAreaKey'
    },
    // 行维度的拖拽区域的key
    rowDimensionAreaKey() {
      return 'rowDimensionAreaKey'
    },
    // 获取当前行分类的查询列表
    getCurrentQueryOptionList() {
      return this.queryTemplateCachedMap[this.activeCategoryName] || []
    },
    // 行分类之后，是否显示联系我们的提示语
    showContactUs() {
      return this.contactUsText?.length > 0
    },
    // 获取当前数据表的行分类的名称列表
    categoryNameList() {
      const result = []
      this.categoryList.forEach(({ categoryName }) => {
        categoryName != null && result.push(categoryName)
      })
      return result
    },
    // 是否展示 行分类 和 categoryHTML 和 showContactUs 的容器
    showCategoryListBar() {
      return this.categoryNameList.length > 0 || this.categoryHTML || this.showContactUs
    },
    // 用于控制"联系我们"弹窗提交时，传的 requirementType 取字典中哪个 label 对应的值
    // 后端或者运维人员在建字典值的时候，一定要用当前数据表的名称作为 dictLabel，数据表改名了，字典也需要改名，要不然获取不到字典值
    contactUsDialogDictLabel() {
      return this.routeTableName
    },
    // 是否展示空数据
    showEmptyData() {
      return this.resultData === null
    },
    // 返回已选择的维度字段中，需要显示筛选条件的维度
    dimensionListWithQueryComp() {
      // 先拿 普通维度 字段
      let arrToFilter = this.dimensionList
      // 如果是以交叉表进行展示，则用列维度和行维度拼成 arrToFilter 进行 filter()
      if (this.isDisplayWithCrossTable) {
        const allDimensionList = []
        // 防止某一个维度在 行 和 列 中都处于排序状态，进而导致 arrToFilter 中出现两个该维度
        // 导致遍历生成的表单查询条件筛选组件的 key 重复
        const allDimensionMap = {}
        this.columnDimensionList.forEach(item => {
          if (!allDimensionMap[item.field.name]) {
            allDimensionMap[item.field.name] = true
            allDimensionList.push(item)
          }
        })
        this.rowDimensionList.forEach(item => {
          if (!allDimensionMap[item.field.name]) {
            allDimensionMap[item.field.name] = true
            allDimensionList.push(item)
          }
        })
        arrToFilter = allDimensionList
      }
      return arrToFilter.filter(({ field }) => field.compType != null)
    },
    // 日期是否不在已选择的维度列表中
    isDateNotInSelectedDimensionList() {
      // 如果是以交叉表进行展示，则用列维度和行维度拼成的数组进行 every()
      const arrToEvery = this.isDisplayWithCrossTable
        ? [...this.columnDimensionList, ...this.rowDimensionList]
        : this.dimensionList
      return arrToEvery.every(item => item.field.name !== '日期')
    },
    // 是否没有选择任何指标字段
    isTargetListEmpty() {
      return this.targetList.length === 0
    },
    // 日期是否切换到了年
    isYearDatetimeMode() {
      return this.currentDatetimeMode === '年'
    },
    // 统计分析的提示文字，是否禁用统计分析与该值是否为空字符串有关
    statisticToolTip() {
      if (this.isDisplayWithPieChart) {
        return '饼图不支持选择统计分析'
      }
      const result = []
      if (this.isDateNotInSelectedDimensionList) {
        result.push('统计分析，需要添加日期作维度')
      }
      if (this.isTargetListEmpty) {
        result.push('统计分析，需要添加指标字段')
      }
      if (this.isYearDatetimeMode) {
        result.push('统计分析，日期筛选需要切换为月')
      }
      return result.join('<br/>')
    },
    // 是否禁用统计分析选择组件
    disableStatisticSelect() {
      return this.statisticToolTip.length > 0
    },
    // 是否正在以表格形式展示
    isDisplayWithTable() {
      return this.selectedDisplayModeName === '表格'
    },
    // 是否正在以 echarts 形式展示
    isDisplayWithChart() {
      return ['折线图', '柱状图', '堆积柱状图', '饼图', '组合图', '面积图']
        .indexOf(this.selectedDisplayModeName) > -1
    },
    // 是否可以更新 echarts 的显示
    ifCanUpdateChartView() {
      return this.isDisplayWithChart && this.resultData !== null
    },
    // 是否正在以饼图形式展示
    isDisplayWithPieChart() {
      return this.selectedDisplayModeName === '饼图'
    },
    // 是否在以组合图形式展示
    isDisplayWithCombinationChart() {
      return this.selectedDisplayModeName === '组合图'
    },
    // 是否正在以指标卡形式展示
    isDisplayWithTargetCard() {
      return this.selectedDisplayModeName === '指标卡'
    },
    // 是否通过 span 展示图形设置里的标题和查询接口返回的单位字符串
    isShowTitleAndUnitStr() {
      return ['表格', '指标卡', '交叉表'].includes(this.selectedDisplayModeName)
    },
    // 展示在中间位置的提示的文字内容
    centerTipText() {
      if (this.showPieChartCenterTip) {
        return '饼图仅支持1维度1指标或0维度多指标'
      } else if (this.showTargetCardDimensionCenterTip) {
        return '指标卡仅支持1维度或者0维度，多指标'
      } else if (this.showTargetCardStatisticCenterTip) {
        return '指标卡仅支持同比、环比作为统计分析'
      }
      return ''
    },
    // 是否展示饼图的红色提示
    showPieChartCenterTip() {
      if (this.isDisplayWithPieChart) {
        const { dimensionList, targetList } = this
        return dimensionList.length > 1 ||
          (dimensionList.length === 1 && targetList.length !== 1) ||
          (dimensionList.length === 0 && targetList.length === 0)
      }
      return false
    },
    // 是否展示指标卡的红色提示-维度限制
    showTargetCardDimensionCenterTip() {
      if (this.isDisplayWithTargetCard) {
        const { dimensionList, targetList } = this
        return dimensionList.length > 1 || targetList.length === 0
      }
      return false
    },
    // 是否展示指标卡的红色提示-统计分析限制
    showTargetCardStatisticCenterTip() {
      if (this.isDisplayWithTargetCard) {
        // 如果统计分析是可选的，并且勾选了 同比、环比 之外的统计分析字段
        return !this.disableStatisticSelect &&
          this.statisticList.some(item => item !== '同比' && item !== '环比')
      }
      return false
    },
    // 当前的数据表菜单 name
    routeTableName() {
      return this.$route.name
    },
    // 获取表格组件的假数据
    getTableCompFakeData() {
      // 如果展示类型是表格
      if (this.isDisplayWithTable) {
        return tableFakeData
      }
      // 否则如果展示类型是交叉表
      if (this.isDisplayWithCrossTable) {
        return crossTableFakeData
      }
      return null
    },
    // 是否以交叉表进行展示
    isDisplayWithCrossTable() {
      return this.selectedDisplayModeName === '交叉表'
    },
    // 是否显示 表格 组件，同时也表示是否需要分页参数
    isShowTableComp() {
      return this.isDisplayWithTable || this.isDisplayWithCrossTable
    },
    // 获取展示的单位文字
    getTableUnitText() {
      // 如果不展示标题单位，或者无结果数据
      if (!this.isShowTitleAndUnitStr || this.showEmptyData) {
        return ''
      }
      return this.resultData.unitStr
    },
    formattedDatetimeSelectRange() {
      if (this.toggleDatetimeMode == 3 && this.dateType == 1) {
        return this.datetimeSelectRange.map(item => {
          if (item) {
            return moment(item).format('YYYY-MM-DD')
          }
        })
      } else {
        return this.datetimeSelectRange.map(item => {
          if (item) {
            return item.substr(0, 4) + '-' + item.substr(4, 2)
          }
        })
      }
    },
    chartWarnTipText() {
      const { selectedDisplayModeName } = this
      if (selectedDisplayModeName === '折线图' || selectedDisplayModeName === '柱状图') {
        return '为保证展示效果，建议1个维度，不限指标'
      } else if (selectedDisplayModeName === '堆积柱状图' || selectedDisplayModeName === '面积图') {
        return '为保证展示效果，建议2个维度、1个指标'
      } else if (this.isDisplayWithPieChart) {
        let result = '仅支持1维度1指标或0维度多指标'
        if (this.resultData?.series && this.resultData.series[0].data.length > 20) {
          result += '；数据项太多，显示效果不佳，建议切换表格'
        }
        return result
      } else if (selectedDisplayModeName === '组合图') {
        return '为保证展示效果，建议1个维度、多个指标'
      } else if (this.isDisplayWithTargetCard) {
        return '仅支持1维度或者0维度，多指标；为保证性能，只展示前100个卡片'
      }
      return ''
    },
    // 是否为国际化的拖拉拽查询页面
    isInternational() {
      return this.$route.meta.acName === '国际化'
    },
    // 页面初始化API方法
    draggingPageInitAPIMethod() {
      return this.isInternational ? internationalDraggingPageInit : draggingPageInit
    },
    // 获取维度下拉API方法
    getDimensionSelectOptionsAPIMethod() {
      return this.isInternational ? internationalGetDimensionSelectOptions : getDimensionSelectOptions
    },
    // 查询API方法
    draggingQueryAPIMethod() {
      return this.isInternational ? internationalDraggingQuery : draggingQuery
    },
    // 下载数据API方法
    exportDraggingQueryAPIMethod() {
      return this.isInternational ? internationalExportDraggingQuery : exportDraggingQuery
    },
    // 保存查询模板API方法
    queryTemplateSaveMethod() {
      return this.isInternational ? saveInternationalDraggingQueryTemplate : saveDraggingQueryTemplate
    },
    // 删除查询模板API方法
    queryTemplateDeleteMethod() {
      return this.isInternational ? deleteInternationalQueryTemplate : deleteDraggingQueryTemplate
    },
    // 获取列维度和行维度的拖拽目标区域的名称数组
    columnAndRowDimensionAreaNameArr() {
      return [this.columnDimensionAreaName, this.rowDimensionAreaName]
    },
    // 获取维度字段的目标拖拽区域名称
    getDimensionTargetAreaName() {
      // 展示交叉表的时候，维度字段有 行维度 和 列维度 两个目标拖拽区域
      return this.isDisplayWithCrossTable ? this.columnAndRowDimensionAreaNameArr : this.dimensionAreaName
    },
    // 能否使用 plus 字段
    canUsePlusBlock() {
      const userInfo = getUserInfo()
      // 如果是内部用户
      if (userInfo && (userInfo.roleKey === null || (userInfo.roleKey == 'trialrole' && userInfo.menuName.includes('国际化')))) {
        // 则可以使用
        return true
      }
      // 否则使用后端返回的 isPlusUser 来判断能否使用 plus 字段
      return this.isPlusUser
    }
  },
  watch: {
    '$route': {
      handler: function(to, from) {
        this.isRouterLink = true
      },
      immediate: true
    },
    activeCategoryName() {
      this.isRouterLink = true
    },
    // 监听选中的指标列表
    targetList() {
      // 判断是否需要将 指标名称 加入/删除 到 行/列维度
      this.judgeIfAppendTargetNameToColumnDimensionArea()
    },
    dimensionListWithQueryComp(val, oldVal) {
      if (val && oldVal && val.length > oldVal.length) {
        // 合并两个数组
        const combined = this.newList.concat(JSON.parse(JSON.stringify(this.dimensionListWithQueryComp)))
        // 去除重复项
        const unique = combined.filter((item, index, self) =>
          index === self.findIndex(t => (
            t.field.name === item.field.name
          ))
        )
        this.newList = unique
      }
      if (this.isRouterLink) {
        this.newList = JSON.parse(JSON.stringify(this.dimensionListWithQueryComp))
        this.isRouterLink = false
      }
    },
    // 筛选条件变化时更新查询条件
    newList() {
      this.handleDimensionBlockListCountChanges()
    },
    // 监听数据表名称，名称通过切换其他拖拉拽查询页面触发改变
    draggingTableName() {
      this.handleInitPage()
    }
  },
  created() {
    this.handleInitPage()
  },
  methods: {
    getindexList(arr) {
      const array = arr.reduce((acc, item) => {
        const existingType = acc.find(typeObj => typeObj.type === item.itemClassify)
        if (existingType) {
          existingType.list.push(item)
        } else {
          acc.push({ type: item.itemClassify, list: [item] })
        }
        return acc
      }, [])
      return array
    },
    // 筛选条件列表数量变化的回调，用来更新 detailParams 对象，
    // 以及异步获取每个维度所对应的下拉的选项列表
    handleDimensionBlockListCountChanges(changeDetailInfo) {
      const newDetailParams = {}
      // 如果是交叉表，则要遍历 列维度和行维度 的并集，否则遍历普通的维度字段即可
      const newDimensionList = this.isDisplayWithCrossTable
        ? [...this.columnDimensionList, ...this.rowDimensionList]
        : this.newList
      newDimensionList.forEach(({ field }) => {
        // 如果该维度字段需要显示对应的筛选条件组件, 且暂无该维度字段的下拉选项列表
        if (field.compType != null) {
          // 保留该维度对应的查询条件的值
          if (this.detailParams[field.prop]) {
            newDetailParams[field.prop] = this.detailParams[field.prop]
          } else if (field.compType === this.compTypeEnums.MULTIPLE_SELECT || field.compType === this.compTypeEnums.LETTER_SELECT) {
            newDetailParams[field.prop] = []
          } else {
            newDetailParams[field.prop] = undefined
          }
          // 如果还没获取到该维度所对应的下拉列表
          if (!this.dimensionSelectOptionMap[field.prop]) {
            // 查询并保存该维度的下拉选项列表
            this.getDimensionSelectOptionsAPIMethod({
              draggingTableName: this.draggingTableName,
              categoryName: this.activeCategoryName,
              dataTableFieldName: field.name
            })
              .then((res) => {
                if (res) {
                  this.$set(this.dimensionSelectOptionMap, field.prop, res.data)
                }
              })
          }
        }
      })
      this.$set(this, 'detailParams', newDetailParams)
    },
    closeDrag(index) {
      this.newList.splice(index, 1)
    },
    // 初始化页面
    handleInitPage() {
      // 开启 loading
      // 如果不展示假数据, 那么查询接口在后面会自动调用, 到时候查询接口返回数据后, 会关闭 loading
      // 如果展示假数据, 那就会关闭 loading
      this.queryLoading = true
      this.hasModifiedDefaultDisplayMode = false
      this.initPageRequestSeq++
      const initPageRequestSeq = this.initPageRequestSeq
      // 重置前端缓存的行分类查询模板map
      this.$set(this, 'queryTemplateCachedMap', {})
      // 调第二层接口：拖拉拽页面初始化接口
      this.draggingPageInitAPIMethod({
        draggingTableName: this.draggingTableName,
        userId: getUserInfo()?.userId
      })
        .then((res) => {
          if (initPageRequestSeq !== this.initPageRequestSeq) return
          if (!res) return
          this.$set(this, 'categoryHTML', res.data.categoryHTML)
          this.$set(this, 'contactUsText', res.data.contactUsText)
          this.$set(this, 'categoryList', res.data.categoryList)
          this.isPlusUser = !!res.data.isPlusUser
          if (this.categoryList.length > 0) {
            // 默认选中第一个行分类
            this.handleCategorySelectChange(this.categoryList[0].categoryName)
          }
        })
    },
    // 行分类切换的回调
    handleCategorySelectChange(categoryName) {
      this.$refs.dataTableFieldListRef?.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      // 切换到展示类型
      this.isActivateDisplayMode = true
      // 清空已选择的维度、指标和统计分析字段
      this.handleClickClearBtn()
      // 清空维度的下拉选项map
      this.$set(this, 'dimensionSelectOptionMap', {})
      // 清空详细查询参数
      this.$set(this, 'detailParams', {})
      // 清空查询成功后保存的查询参数
      this.$set(this, 'queryParams', null)
      // 修改为表格 展示类型
      this.selectedDisplayModeName = '表格'
      // 重置图形设置
      this.$set(this, 'graphSettingOption', {})
      // 重置分页参数
      this.$set(this, 'pageParams', this.defaultPageParams)
      this.activeCategoryName = categoryName
      const category = this.categoryList.find(item => item.categoryName === categoryName)
      if (!category) {
        return
      }
      // 获取该行分类的 更新时间字符串
      this.updateTimeStr = category.updateTimeStr
      // 获取已保存的查询条件列表
      const cachedQueryOptionList = this.queryTemplateCachedMap[categoryName]
      // 如果前端缓存了这个行分类的查询列表，则使用前端缓存的(要考虑到保存了查询条件但是没刷新页面的情况)
      this.$set(this.queryTemplateCachedMap, categoryName, cachedQueryOptionList || category.queryOptionList || [])
      // 更新日期组件的切换模式
      this.toggleDatetimeMode = category.toggleDatetimeMode
      // 如果可以选择日期
      if (this.toggleDatetimeMode !== null) {
        // 更新日期组件的可选范围
        this.$set(this, 'datetimeSelectRange', [category.earliestDatetime, category.latestDatetime])
        // 如果只能用月或者年月都能切换
        if (this.toggleDatetimeMode === 1 || this.toggleDatetimeMode === 2) {
          this.currentDatetimeMode = '月'
        } else if (this.toggleDatetimeMode === 0) {
          this.currentDatetimeMode = '年'
        }
        const newValue = []
        // 开始时间使用后端返回的 startDatetime 所代表的时间
        newValue[0] = category.startDatetime.substr(0, 4) + '-' + category.startDatetime.substr(4, 2)
        // 结束时间默认使用最晚时间
        newValue[1] = this.formattedDatetimeSelectRange[1]
        // 如果为年份模式
        if (this.currentDatetimeMode === '年') {
          newValue[0] = newValue[0].substr(0, 4)
          newValue[1] = newValue[1].substr(0, 4)
        }
        let maxPriority = -1
        let dateType = ''
        const priorityMap = {
          '1': 3,
          '2': 2,
          '3': 1,
          '4': 0
        }
        this.$nextTick(() => {
          this.targetList.forEach(item => {
            const currentPriority = priorityMap[(item.field.dateType + '')]
            if (currentPriority > maxPriority) {
              dateType = item.field.dateType
              maxPriority = currentPriority
            }
          })
          // console.log(dateType, this.targetList, 444)
          if (this.toggleDatetimeMode === 3 && dateType == 4) {
            newValue[0] = moment(category.startDatetime).format('YYYY')
            newValue[1] = moment(this.datetimeSelectRange[1]).format('YYYY')
          }
          if (this.toggleDatetimeMode === 3 && (dateType == 3 || dateType == 2)) {
            newValue[0] = moment(category.startDatetime).format('YYYY-MM')
            newValue[1] = moment(this.datetimeSelectRange[1]).format('YYYY-MM')
          }
          if (this.toggleDatetimeMode === 3 && dateType == 1) {
            newValue[0] = moment(category.startDatetime).format('YYYY-MM-DD')
            newValue[1] = moment(this.datetimeSelectRange[1]).format('YYYY-MM-DD')
          }
          // 计算出当前的时间范围的值
          this.$set(this, 'datetimeRange', newValue)
        })
      } else {
        this.currentDatetimeMode = null
        this.$set(this, 'datetimeRange', [])
      }
      // 可选的维度列表
      const allDimensionList = []
      // 可选的指标列表
      const allTargetList = []
      // 已选择的维度列表
      const dimensionList = []
      // 已选择的指标列表
      const targetList = []
      // 获取行分类下的维度和指标字段列表
      category.dataTableFieldList.forEach(field => {
        if (field.fieldType === this.fieldTypeEnums.dimension) {
          allDimensionList.push(field)
          // 如果该字段默认选中
          if (field.isDefault) {
            dimensionList.push(new AreaBlock(field, dimensionList.length))
          }
        } else if (field.fieldType === this.fieldTypeEnums.target) {
          allTargetList.push(field)
          // 如果该字段默认选中
          if (field.isDefault) {
            targetList.push(new AreaBlock(field, targetList.length))
          }
        }
      })
      // 保存可选择的维度列表
      this.$set(this, 'allDimensionList', allDimensionList)
      // 保存可选择的指标列表
      this.$set(this, 'allTargetList', allTargetList)
      // 保存已选择的维度列表
      this.$set(this, 'dimensionList', dimensionList)
      // 重置交叉表的列维度
      this.$set(this, 'columnDimensionList', [])
      // 重置交叉表的行维度
      this.$set(this, 'rowDimensionList', [])
      // 异步获取默认选择的维度所对应的下拉框的下拉选项列表
      this.handleDimensionBlockListCountChange()
      // 保存已选择的指标列表
      this.$set(this, 'targetList', targetList)
      // 保存可选择的统计分析列表
      this.$set(this, 'allStatisticList', category.statisticList)
      // 获取并设置自动勾选的统计分析列表
      const statisticList = []
      this.allStatisticList.forEach(item => {
        if (item.isDefault) {
          statisticList.push(item.name)
        }
      })
      this.$set(this, 'statisticList', statisticList)
      if (this.showMask) {
        // 关闭在 handleInitPage() 中开启的 loading
        this.queryLoading = false
      } else { // 如果不展示遮罩, 则自动进行查询
        this.handleClickQueryBtn()
      }
    },
    // 点击查询按钮
    handleClickQueryBtn() {
      // 如果未登录
      if (this.showNotLoggedInMask) {
        // 显示登录弹窗
        this.$Vlogin2({ flag: true })
        return
      }
      // 如果登录了
      const userInfo = getUserInfo()
      // 如果是已购买用户，或者是试用用户
      if (
        userInfo.roleKey == 'paidrole' ||
        userInfo.roleKey == 'trialrole'
      ) {
        // 浏览埋点
        this.doLogRecords('查询', this.selectedDisplayModeName)
        // 是否购买数据栏目/国际化
        const hasBought = userInfo.menuName && (this.isInternational ? userInfo.menuName.includes('国际化') : userInfo.menuName.includes('数据'))
        // 如果是试用用户，则不用购买也能看数据，否则就必须购买才能看数据
        if (this.isTrialRole ? false : !hasBought) {
          // 显示申请试用弹窗
          this.$Vlogin3({ flag: true })
          // 弹窗埋点
          this.doDialogBuryPoint()
          return
        }
      }
      // 如果用户当前设置的查询条件 不可以执行查询，则结束
      if (!this.judgeIfCanDoQuery()) return
      this.$nextTick(() => {
        // 根据筛选条件及展示类型来生成查询参数
        const queryParams = this.generateQueryParamsByForm()
        // 重置分页参数的页码
        this.$set(this.pageParams, 'pageNo', this.defaultPageParams.pageNo)
        // 根据是否需要分页，获取到追加分页参数后的查询参数
        const pagedQueryParams = this.appendPageParams(queryParams)
        this.doQuery(pagedQueryParams)
      })
    },
    isTrialRoleBtn() {
      openSy()
      return
    },
    // 判断用户当前设置的查询条件是否可以执行查询
    judgeIfCanDoQuery(showMessage = true) {
      // 如果有红色警告提示语
      if (this.centerTipText) {
        showMessage && this.$refs.centerTipRef.show(this.centerTipText)
        return false
      }
      showMessage && this.$refs.centerTipRef.hidden()
      // 如果不是饼图展示、也不是指标卡展示、也不是交叉表展示，并且没有选择任何维度
      // 因为只有饼图、指标卡、交叉表允许0个维度
      let message = ''
      if (
        !this.isDisplayWithPieChart &&
        !this.isDisplayWithTargetCard &&
        !this.isDisplayWithCrossTable &&
        this.dimensionList.length === 0
      ) {
        message = '请至少选择一个维度'
      }
      if (this.targetList.length === 0) {
        message = '请至少选择一个指标'
      }
      if (message) {
        // 则结束
        showMessage && this.$message({
          type: 'error',
          message,
          offset: 230
        })
        return false
      }
      return true
    },
    // 表格组件触发分页事件的回调
    handleTablePageQuery(pageParams) {
      this.$set(this, 'pageParams', pageParams)
      // 根据当前保存的查询参数，再追加分页参数，来获取查询参数
      const pagedQueryParams = this.appendPageParams(this.queryParams)
      this.doQuery(pagedQueryParams)
    },
    // 根据筛选条件及展示类型来生成查询参数
    generateQueryParamsByForm() {
      // 用于存储 行/列维度 或者 普通的维度
      const dimensionParams = {}
      // 如果是交叉表
      if (this.isDisplayWithCrossTable) {
        // 则需要传 columnList 和 rowList
        const newColumnList = [] // 存储排好序的列维度名称
        const columnDimensionSortToNameMap = {}
        // list 转 map，sort 为 key，字段中文名做 value
        this.columnDimensionList.forEach(item => {
          columnDimensionSortToNameMap[item.sort] = item.field.name
        })
        // 根据变量 i 来按顺序获取字段中文名
        for (let i = 0; i < this.columnDimensionList.length; i++) {
          columnDimensionSortToNameMap[i] && newColumnList.push(columnDimensionSortToNameMap[i])
        }
        // 需要传 columnList
        dimensionParams.columnList = newColumnList
        const newRowList = [] // 存储排好序的行维度名称
        const rowDimensionSortToNameMap = {}
        // list 转 map，sort 为 key，字段中文名做 value
        this.rowDimensionList.forEach(item => {
          rowDimensionSortToNameMap[item.sort] = item.field.name
        })
        // 根据变量 i 来按顺序获取字段中文名
        for (let i = 0; i < this.rowDimensionList.length; i++) {
          rowDimensionSortToNameMap[i] && newRowList.push(rowDimensionSortToNameMap[i])
        }
        // 需要传 rowList
        dimensionParams.rowList = newRowList
      } else { // 否则只传普通的 dimensionList 就行
        const newDimensionList = [] // 存储排好序的普通维度名称
        const newDimensionList1 = [] // 存储排好序的普通维度名称
        const dimensionSortToNameMap = {}
        const dimensionSortToNameMap1 = {}
        // list 转 map，sort 为 key，字段中文名做 value
        this.dimensionList.forEach(item => {
          dimensionSortToNameMap[item.sort] = item.field.name
        })
        // list 转 map，sort 为 key，字段中文名做 value
        this.newList.forEach((item, index) => {
          dimensionSortToNameMap1[index] = item.field.name
        })
        // 根据变量 i 来按顺序获取字段中文名
        for (let i = 0; i < this.dimensionList.length; i++) {
          dimensionSortToNameMap[i] && newDimensionList.push(dimensionSortToNameMap[i])
        }
        for (let i = 0; i < this.newList.length; i++) {
          dimensionSortToNameMap1[i] && newDimensionList1.push(dimensionSortToNameMap1[i])
        }
        // 需要传 dimensionList
        dimensionParams.dimensionList = newDimensionList
        // dimensionParams.dimensionList1 = newDimensionList1
      }
      // 指标排序
      const targetList = []
      for (let i = 0; i < this.targetList.length; i++) {
        const target = this.targetList.find(item => item.sort === i)
        if (target) {
          targetList.push(target.field.name)
        }
      }
      const statisticList = []
      // 如果可选择统计分析
      if (this.statisticToolTip.length === 0) {
        // 则获取排好序的统计分析列表
        const statisticMap = {}
        this.statisticList.forEach(item => {
          statisticMap[item] = true
        })
        this.allStatisticList.forEach(item => {
          if (statisticMap[item.name]) {
            statisticList.push(item.name)
          }
        })
      }
      let toggleDatetimeMode = null
      let startDatetime = null
      let endDatetime = null
      if (this.toggleDatetimeMode !== null) {
        toggleDatetimeMode = this.isYearDatetimeMode ? 0 : 1
        startDatetime = this.datetimeRange[0]?.replaceAll('-', '')
        endDatetime = this.datetimeRange[1]?.replaceAll('-', '')
      }
      return {
        // 拖拉拽数据表名称
        draggingTableName: this.draggingTableName,
        // 行分类名称
        categoryName: this.activeCategoryName,
        // 展示类型
        displayMode: this.selectedDisplayModeName,
        // 切换到的年月模式
        toggleDatetimeMode: toggleDatetimeMode,
        // 普通维度 或 行/列维度
        ...dimensionParams,
        // 指标列表
        targetList,
        // 统计分析列表
        statisticList,
        // 筛选条件对象
        detailParams: {
          startDatetime,
          endDatetime,
          ...this.detailParams
        }
      }
    },
    // 根据是否需要分页，获取到追加分页参数后的查询参数(不会影响传入的对象的属性)
    appendPageParams(queryParams) {
      // 如果是表格，还要传分页参数
      const pageParams = this.isShowTableComp ? this.pageParams : {}
      return JSON.parse(JSON.stringify({
        ...queryParams,
        // 分页参数
        ...pageParams
      }))
    },
    // 手动切换图表类型的回调
    handleDisplayModeChange(newDisplayModeName) {
      const oldDisplayModeName = this.selectedDisplayModeName
      // 修改已选择的展示类型
      this.selectedDisplayModeName = newDisplayModeName
      // 如果还未修改过默认的展示类型
      if (!this.hasModifiedDefaultDisplayMode) {
        // 清空页面上的统计分析的选择
        this.$set(this, 'statisticList', [])
        // 清空查询条件中的统计分析的选择
        if (this.queryParams !== null) {
          this.$set(this.queryParams, 'statisticList', [])
        }
      }
      this.hasModifiedDefaultDisplayMode = true
      // 如果从 交叉表 切换到了其他展示类型
      if (oldDisplayModeName === '交叉表' && newDisplayModeName !== '交叉表') {
        // 则把 列维度 和 行维度 进行合并（合并时必须把 sort 重新处理，防止不同的块的 sort 重复）
        const newDimensionList = []
        // 尝试从 列维度 中找 '指标名称' 所在的位置
        let targetNameBlock = this.columnDimensionList.find(item => item.field.name === '指标名称')
        // 如果 '指标名称' 在 列 里
        if (targetNameBlock) {
          // 遍历 列维度
          this.columnDimensionList.forEach(item => {
            // 排除 '指标名称' 块
            if (item.field.name !== '指标名称') {
              // 把 sort 大于 '指标名称' 块的其他块的排序值减1，再放入 newDimensionList
              if (item.sort > targetNameBlock.sort) {
                newDimensionList.push({
                  ...item,
                  sort: item.sort - 1
                })
              } else {
                newDimensionList.push(item)
              }
            }
          })
          // 把 行维度 中的所有块，按最新的排序值，放入 newDimensionList
          this.rowDimensionList.forEach(item => {
            newDimensionList.push({
              ...item,
              sort: newDimensionList.length
            })
          })
        } else { // 否则如果 '指标名称' 不在 列 里
          // 则先把 列维度 中的所有块，按原来的排序值，放入 newDimensionList
          this.columnDimensionList.forEach(item => {
            newDimensionList.push(item)
          })
          // 尝试从 行维度 中找 '指标名称' 所在的位置
          targetNameBlock = this.rowDimensionList.find(item => item.field.name === '指标名称')
          // 如果 '指标名称' 在 行 里
          if (targetNameBlock) {
            // 遍历 行维度
            this.rowDimensionList.forEach(item => {
              // 排除 '指标名称' 块
              if (item.field.name !== '指标名称') {
                // 把 sort 大于 '指标名称' 块的其他块的排序值减1，再放入 newDimensionList
                if (item.sort > targetNameBlock.sort) {
                  newDimensionList.push({
                    ...item,
                    sort: item.sort - 1
                  })
                } else {
                  newDimensionList.push(item)
                }
              }
            })
          } else { // '指标名称' 不在 行 也不在 列 里
            // 把 行维度 中的所有块，按最新的排序值，放入 newDimensionList 即可
            this.rowDimensionList.forEach(item => {
              newDimensionList.push({
                ...item,
                sort: newDimensionList.length
              })
            })
          }
        }
        // 把重新排序且过滤掉可能存在的 '指标名称' 字段的 列/行维度 的总数组 赋值给 普通维度
        // 修改页面上的普通维度的选择
        this.$set(this, 'dimensionList', newDimensionList)
        // 修改查询条件中的普通维度的选择
        this.$set(this.queryParams, 'dimensionList', newDimensionList.map(item => item.field.name))
        // 重置页面上的 行维度 的选择
        this.$set(this, 'rowDimensionList', [])
        // 重置查询条件中的 行维度 的选择
        this.$set(this.queryParams, 'rowList', undefined)
        // 重置页面上的 列维度 的选择
        this.$set(this, 'columnDimensionList', [])
        // 重置查询条件中的 列维度 的选择
        this.$set(this.queryParams, 'columnList', undefined)
      } else if (newDisplayModeName === '交叉表' && oldDisplayModeName !== '交叉表') { // 否则如果是切换到了 交叉表
        // 清空 列 维度，修改页面上的 列 维度的选择
        this.$set(this, 'columnDimensionList', [])
        // 判断是否需要给 列维度 追加 '指标名称' 字段
        this.judgeIfAppendTargetNameToColumnDimensionArea()
        // 修改查询条件中的 列 维度的选择
        this.$set(this.queryParams, 'columnList', this.columnDimensionList.map(item => item.field.name))
        // 则把普通维度放到 行 上，修改页面上的 行 维度的选择
        this.$set(this, 'rowDimensionList', this.dimensionList)
        // 修改查询条件中的 行 维度的选择
        this.$set(this.queryParams, 'rowList', this.rowDimensionList.map(item => item.field.name))
        // 重置页面上的普通维度的选择
        this.$set(this, 'dimensionList', [])
        // 重置查询条件中的 普通维度的选择
        this.$set(this.queryParams, 'dimensionList', undefined)
      }
      // 重新获取当前展示类型的图形设置
      const newGraphSettingOption = this.$refs.graphSettingRef?.resetToDefaultGraphSetting(newDisplayModeName)
      this.$set(this, 'graphSettingOption', newGraphSettingOption)
      // 如果登录了
      if (!this.showNotLoggedInMask) {
        const userInfo = getUserInfo()
        // 如果是已购买用户，或者是试用用户
        if (
          userInfo.roleKey == 'paidrole' ||
          userInfo.roleKey == 'trialrole'
        ) {
          // 浏览埋点
          this.doLogRecords('切换展示类型并自动查询', this.selectedDisplayModeName)
        }
      }
      // 如果正在展示假数据
      if (this.showMask) {
        // 模拟真实 http 请求
        this.fakeQueryLoading = true
        setTimeout(() => {
          // 如果正在以图展示
          if (this.isDisplayWithChart) {
            // 则更新图
            this.$refs.fakeChartRef.loadView(
              this.selectedDisplayModeName,
              this.chartFakeDataMap[this.selectedDisplayModeName],
              this.graphSettingOption
            )
          }
          this.fakeQueryLoading = false
        }, 200)
        return
      }
      this.$refs.centerTipRef.hidden()
      if (this.queryParams === null) return
      // 否则如果查询条件不为空, 则判断查询条件和展示类型是否匹配
      // 如果展示饼图的红色提示
      if (this.showPieChartCenterTip) {
        // 则进行提示
        this.$refs.centerTipRef.show(this.centerTipText)
        // 清空查询参数中的统计分析
        this.$set(this.queryParams, 'statisticList', [])
        // 清空已选中的维度列表
        this.$set(this, 'dimensionList', [])
        // 清空查询参数中的维度列表，以及 detailPrams 中的维度的下拉选信息
        this.clearQueryParamsDimensionData()
      } else if (this.showTargetCardDimensionCenterTip) { // 如果展示指标卡的红色提示-维度限制
        // 则进行提示
        this.$refs.centerTipRef.show(this.centerTipText)
        // 清空已选中的维度列表
        this.$set(this, 'dimensionList', [])
        // 清空查询参数中的维度列表，以及 detailPrams 中的维度的下拉选信息
        this.clearQueryParamsDimensionData()
      } else if (this.showTargetCardStatisticCenterTip) { // 如果展示指标卡的红色提示-统计分析限制
        // 则进行提示
        this.$refs.centerTipRef.show(this.centerTipText)
        // 自动去除已选择的非 同比、环比 的统计分析字段
        // TODO 用不用给统计分析列表返回的数据里，给每个项添加一个是否支持指标卡的字段，
        // 防止后端把同比环比的名字改了
        this.$set(this, 'statisticList', ['同比', '环比'])
        // 同时修改查询参数
        this.$set(this.queryParams, 'statisticList', this.statisticList)
      } else if (this.isDisplayWithCrossTable) { // 如果以交叉表进行展示
        const { targetList } = this.queryParams
        if (targetList.length === 0) { // 如果指标数量为 0
          // 则无法查询，清空查询结果，结束方法
          this.$set(this, 'resultData', null)
          return
        }
      } else if (this.isDisplayWithPieChart) { // 防止当前查询条件符合饼图的查询条件，但是不去查询
        const { dimensionList, targetList } = this.queryParams
        // 如果 维度数量大于 1，或者 维度有 1 个但是指标不是 1个，或者指标数量为 0
        if (dimensionList.length > 1 || (dimensionList.length === 1 && targetList.length !== 1) || targetList.length === 0) {
          // 则无法查询，清空查询结果，结束方法
          this.$set(this, 'resultData', null)
          return
        }
      } else if (this.isDisplayWithTargetCard) { // 防止当前查询条件符合指标卡的查询条件，但是不去查询
        const { dimensionList, targetList } = this.queryParams
        // 维度数量大于 1，或者指标数量为 0，则指标卡无法查询
        if (dimensionList.length > 1 || targetList.length === 0) {
          // 如果不匹配则清空查询结果，结束方法
          this.$set(this, 'resultData', null)
          return
        }
      } else {
        // 否则检查其余的图表类型和当前的查询条件是否匹配（一般来说其他的图表类型都是不允许维度为[]或者指标为[]）
        const { dimensionList, targetList } = this.queryParams
        if (targetList.length === 0 || (!dimensionList || dimensionList.length === 0)) {
          // 如果不匹配则清空查询结果，结束方法
          this.$set(this, 'resultData', null)
          return
        }
      }
      // 如果切换到的是表格或交叉表这种需要展示表格组件的展示类型
      if (this.isShowTableComp) {
        // 则重置分页参数
        this.$set(this, 'pageParams', this.defaultPageParams)
      }
      // 根据是否需要分页，获取到追加分页参数后的查询参数(不会影响传入的对象的属性)
      const queryParams = {
        ...this.appendPageParams(this.queryParams),
        // 使用新的 displayMode
        displayMode: newDisplayModeName
      }
      this.doQuery(queryParams)
    },
    // 清空查询参数中的维度列表，以及 detailPrams 中的维度的下拉选信息
    clearQueryParamsDimensionData() {
      this.$set(this, 'detailParams', {})
      if (this.queryParams === null) {
        return
      }
      const newDetailParams = {
        startDatetime: this.queryParams.detailParams.startDatetime,
        endDatetime: this.queryParams.detailParams.endDatetime
      }
      this.$set(this.queryParams, 'dimensionList', [])
      this.$set(this.queryParams, 'detailParams', newDetailParams)
    },
    // 手动修改图形设置的回调
    handleGraphSettingChange(graphSettingOption) {
      this.$set(this, 'graphSettingOption', graphSettingOption)
      // 如果正在展示假数据
      if (this.showMask) {
        if (this.isDisplayWithChart) {
          this.$refs.fakeChartRef?.updateViewByGraphSetting(this.graphSettingOption)
        }
      } else if (this.ifCanUpdateChartView) {
        // 修改图形设置后，要实时修改图表的展示效果
        this.$refs.chartRef?.updateViewByGraphSetting(this.graphSettingOption)
      }
    },
    changeTimes(data) {
      if (data || data === 0) {
        this.dateType = data
      }
    },
    // 执行查询
    doQuery(params) {
      // 如果未登录或者未订阅
      if (this.showMask) {
        // 显示登录弹窗
        this.$Vlogin2({ flag: true })
        return
      }
      this.queryLoading = true
      this.draggingQueryRequestSeq++
      const seq = this.draggingQueryRequestSeq
      // console.log('查询参数', params)
      if (['交叉表'].includes(this.selectedDisplayModeName)) {
        const displayModeUrlMap = {
          '折线图': '/draggingQueryForBarLine',
          '柱状图': '/draggingQueryForBarLine',
          '堆积柱状图': '/draggingQueryForStackBar',
          '饼图': '/draggingQueryForPie',
          '组合图': '/draggingQueryForMix',
          '指标卡': '/draggingQueryForTargetCard',
          '交叉表': '/draggingQueryForCrossTable'
        }
        const url = displayModeUrlMap[params.displayMode]
        mockDraggingQuery(url, params)
          .then(res => {
            if (seq !== this.draggingQueryRequestSeq) return
            if (!res) return
            if (res.error) {
              this.$message.error(res.error)
              return
            }
            // 如果 res.data 为空对象，则结束
            if (!res.data || Object.keys(res.data).length === 0) return
            // 查询成功后，保存查询参数，用于后期分页
            this.$set(this, 'queryParams', params)
            // 保存查询结果
            this.$set(this, 'resultData', res.data)
            // 如果是展示的 组合图
            if (this.isDisplayWithCombinationChart) {
              // 让图形设置组件 根据响应的数据，生成图表设置
              this.$refs.graphSettingRef?.generateCombinationChartSetting(this.resultData)
            }
            if (this.ifCanUpdateChartView) {
              // 由于 展示表格和图的容器是用 v-if="resultData" 控制的，所以这里必须用 $nextTick
              this.$nextTick(() => {
                this.$refs.chartRef?.loadView(
                  this.selectedDisplayModeName, this.resultData, this.graphSettingOption
                )
              })
            }
          })
          .catch(err => {
            this.$message.error(err.message)
          })
          .finally(() => {
            this.queryLoading = false
          })
        return
      }
      this.$nextTick(() => {
        // 如果是都动态的日期
        params.toggleDatetimeMode = this.toggleDatetimeMode
        if (this.toggleDatetimeMode == 3 && this.dateType == 4) { // 年度
          params.detailParams.startDatetime = moment(params.detailParams.startDatetime).format('YYYY')
          params.detailParams.endDatetime = moment(params.detailParams.endDatetime).format('YYYY')
        }
        if (this.toggleDatetimeMode == 3 && (this.dateType == 3 || this.dateType == 2)) { // 季度
          params.detailParams.startDatetime = moment(params.detailParams.startDatetime).format('YYYYMM')
          params.detailParams.endDatetime = moment(params.detailParams.endDatetime).format('YYYYMM')
        }
        if (this.toggleDatetimeMode == 3 && this.dateType == 1) { // 日度
          params.detailParams.startDatetime = moment(params.detailParams.startDatetime).format('YYYYMMDD')
          params.detailParams.endDatetime = moment(params.detailParams.endDatetime).format('YYYYMMDD')
        }
        if (this.toggleDatetimeMode == 3) {
          params.dateType = this.dateType
          params.toggleDatetimeMode = 3
        }
        if (this.toggleDatetimeMode == 2) {
          if (params.detailParams.startDatetime && params.detailParams.startDatetime.length == 4) {
            params.toggleDatetimeMode = 0
          }
          if (params.detailParams.startDatetime && params.detailParams.startDatetime.length == 6) {
            params.toggleDatetimeMode = 1
          }
        }
        this.draggingQueryAPIMethod(params)
          .then(res => {
            if (seq !== this.draggingQueryRequestSeq) return
            if (!res) return
            if (res.error) {
              this.$message.error(res.error)
              return
            }
            // 如果 res.data 为空对象，则结束
            if (!res.data || Object.keys(res.data).length === 0) return
            // 查询成功后，保存查询参数，用于后期分页
            this.$set(this, 'queryParams', params)
            // 保存查询结果
            this.$set(this, 'resultData', res.data)
            // 如果是展示的 组合图
            if (this.isDisplayWithCombinationChart) {
            // 让图形设置组件 根据响应的数据，生成图表设置
            this.$refs.graphSettingRef?.generateCombinationChartSetting(this.resultData)
            }
            // 如果能更新 echarts 图
            if (this.ifCanUpdateChartView) {
            // 由于 展示表格和图的容器是用 v-if="!showEmptyData" 控制的，
            // 而showEmptyData 取决于 resultData，所以这里必须用 $nextTick
              this.$nextTick(() => {
              // 则重新加载 echarts 图
              this.$refs.chartRef?.loadView(
                this.selectedDisplayModeName, this.resultData, this.graphSettingOption
              )
              })
            }
          })
          .catch(err => {
            this.$message.error(err.message)
          })
          .finally(() => {
            this.queryLoading = false
          })
      })
      console.log(params, '第一次进来', this.targetList)
    },
    // 维度列表数量变化的回调，用来更新 detailParams 对象，
    // 以及异步获取每个维度所对应的下拉的选项列表
    handleDimensionBlockListCountChange(changeDetailInfo) {
      if (changeDetailInfo) {
        const { addedBlock } = changeDetailInfo // removedBlock、removedBlocks 如果有需要也可以解构
        // 如果是增加了一个字段
        if (addedBlock) {
          // 如果增加的该字段配置了冲突字段
          const { conflictFieldNames } = addedBlock.field
          if (conflictFieldNames) {
            this.$nextTick(() => {
              // 如果当前在以交叉表进行展示
              if (this.isDisplayWithCrossTable) {
                // TODO 看看这种冲突的判断逻辑，后端支不支持正常查出来数据
                //  则需要从 rowDimensionList 和 columnDimensionList 中一起进行冲突判断
                // 用于保存 发生冲突的拖拽区域的 ref
                let conflictAreaRef = ''
                // 从列区域中找是否存在冲突字段
                let conflictField = this.columnDimensionList.find(item => {
                  return conflictFieldNames.includes(item.field.name)
                })
                // 如果从列区域中找到了冲突字段
                if (conflictField) {
                  // 则将 发生冲突的拖拽区域的 ref 置为列区域的 ref
                  conflictAreaRef = 'columnDimensionDragTargetAreaRef'
                } else {
                  // 否则从 行区域 中寻找冲突字段
                  conflictField = this.rowDimensionList.find(item => {
                    return conflictFieldNames.includes(item.field.name)
                  })
                  // 如果从行区域中找到了冲突字段，则将 发生冲突的拖拽区域的 ref 置于 行区域的 ref
                  if (conflictField) {
                    conflictAreaRef = 'rowDimensionDragTargetAreaRef'
                  }
                }
                if (conflictField && conflictAreaRef) {
                  this.$message({
                    type: 'warning',
                    message: `${addedBlock.field.name}和${conflictField.field.name}不可同时查询`
                  })
                  this.$refs[conflictAreaRef]?.removeBlockByFieldNames(conflictFieldNames)
                }
              } else { // 否则从普通维度中进行判断
                // 判断如果当前维度列表中含有该字段的冲突字段，则移除冲突字段
                const conflictField = this.dimensionList.find(item => {
                  return conflictFieldNames.includes(item.field.name)
                })
                if (conflictField) {
                  this.$message({
                    type: 'warning',
                    message: `${addedBlock.field.name}和${conflictField.field.name}不可同时查询`
                  })
                  this.newList = this.newList.filter(function(item) {
                    return item.field.name !== conflictField.field.name
                  })
                  this.$refs.dimensionDragTargetAreaRef?.removeBlockByFieldNames(conflictFieldNames)
                }
              }
            })
          }
        }
        // else if (removedBlock) { // 否则如果删除了一个字段
        //   console.log('删除了字段', removedBlock) // 如果看情况有需要，就在这里判断删除单个字段的事件
        // }
        // else if (removedBlocks) { // 否则如果删除了多个字段
        //   console.log('删除了多个字段', removedBlocks) // 如果看情况有需要，就在这里判断批量删除字段的事件
        // }
      }
      // const newDetailParams = {}
      // // 如果是交叉表，则要遍历 列维度和行维度 的并集，否则遍历普通的维度字段即可
      // const newDimensionList = this.isDisplayWithCrossTable
      //   ? [...this.columnDimensionList, ...this.rowDimensionList]
      //   : this.dimensionList
      // newDimensionList.forEach(({ field }) => {
      //   // 如果该维度字段需要显示对应的筛选条件组件, 且暂无该维度字段的下拉选项列表
      //   if (field.compType != null) {
      //     // 保留该维度对应的查询条件的值
      //     if (this.detailParams[field.prop]) {
      //       newDetailParams[field.prop] = this.detailParams[field.prop]
      //     } else if (field.compType === this.compTypeEnums.MULTIPLE_SELECT || field.compType === this.compTypeEnums.LETTER_SELECT) {
      //       newDetailParams[field.prop] = []
      //     } else {
      //       newDetailParams[field.prop] = undefined
      //     }
      //     // 如果还没获取到该维度所对应的下拉列表
      //     if (!this.dimensionSelectOptionMap[field.prop]) {
      //       // 查询并保存该维度的下拉选项列表
      //       this.getDimensionSelectOptionsAPIMethod({
      //         draggingTableName: this.draggingTableName,
      //         categoryName: this.activeCategoryName,
      //         dataTableFieldName: field.name
      //       })
      //         .then((res) => {
      //           if (res) {
      //             this.$set(this.dimensionSelectOptionMap, field.prop, res.data)
      //           }
      //         })
      //     }
      //   }
      // })
      // this.$set(this, 'detailParams', newDetailParams)
    },
    // 点击清空按钮
    handleClickClearBtn() {
      this.$set(this, 'columnDimensionList', [])
      this.$set(this, 'rowDimensionList', [])
      this.$set(this, 'dimensionList', [])
      this.$set(this, 'targetList', [])
      this.$set(this, 'statisticList', [])
      this.$set(this, 'newList', [])
      // 维度列表变化，需要更新 detailParams 对象
      this.handleDimensionBlockListCountChange()
    },
    // 字段列表的某个维度字段是否禁用
    isOutsideDimensionDragBlockDisabled({ name }) {
      // 如果在以交叉表的形式展示
      if (this.isDisplayWithCrossTable) {
        // 则判断该维度是否出现在列维度或行维度
        // 如果出现在列维度或行维度，则禁用
        const inColumnDimension = this.columnDimensionList.some(item => item.field.name === name)
        return inColumnDimension || this.rowDimensionList.some(item => item.field.name === name)
      }
      return this.dimensionList.some(item => item.field.name === name)
    },
    // 字段列表的某个指标字段是否禁用
    isOutsideTargetDragBlockDisabled({ name }) {
      return this.targetList.some(item => item.field.name === name)
    },
    // 点击 下载数据
    handleClickDownloadData() {
      // 如果未登录
      if (this.showNotLoggedInMask) {
        // 提示需要登录
        this.$Vlogin2({ flag: true })
        return
      }
      // 否则就是已登录用户
      const userInfo = getUserInfo()
      // 如果是已购买用户，或者是试用用户
      if (
        userInfo.roleKey == 'paidrole' ||
        userInfo.roleKey === 'trialrole'
      ) {
        // 浏览埋点
        this.doLogRecords('下载数据')
        // 如果没有数据栏目/国际化权限
        if (userInfo.menuName && (this.isInternational ? !userInfo.menuName.includes('国际化') : !userInfo.menuName.includes('数据'))) {
          // 弹出申请试用的弹窗
          this.$Vlogin3({ flag: true })
          // 埋点 弹窗提示
          this.doDialogBuryPoint()
          return
        } else if (userInfo.roleKey == 'trialrole') {
          // 否则如果有数据栏目权限，但是是试用用户
          // 弹窗 【提示】此操作需要正式开通该栏目后操作
          openSy()
          // 埋点 弹窗提示
          this.doDialogBuryPoint()
          return
        }
        // 执行下载逻辑
        this.doDownloadData()
        return
      }
      // 执行下载逻辑
      this.doDownloadData()
    },
    // 执行下载数据
    doDownloadData() {
      this.downloading = true
      this.exportDraggingQueryAPIMethod(this.queryParams)
        .then(res => {
          if (!res) return
          const reader = new FileReader()
          reader.readAsText(res)
          reader.addEventListener('loadend', (e) => {
            try {
              JSON.parse(e.target.result)
              const msg = JSON.parse(e.target.result)
              this.$message({
                showClose: true,
                message: msg.message,
                type: 'error'
              })
            } catch (err) {
              // 文件名用数据表的菜单名称
              downloadFile(res, `${this.routeTableName}.xls`)
            }
          })
        })
        .finally(() => {
          this.downloading = false
        })
    },
    // 点击 导出图片
    handleClickExportImage() {
      // 如果未登录
      if (this.showNotLoggedInMask) {
        // 提示需要登录
        this.$Vlogin2({ flag: true })
        return
      }
      // 否则就是已登录用户
      const userInfo = getUserInfo()
      // 如果是已购买用户，或者是试用用户
      if (
        userInfo.roleKey == 'paidrole' ||
        userInfo.roleKey === 'trialrole'
      ) {
        // 浏览埋点
        this.doLogRecords('导出图片')
        // 如果没有数据栏目/国际化权限
        if (userInfo.menuName && (this.isInternational ? !userInfo.menuName.includes('国际化') : !userInfo.menuName.includes('数据'))) {
          // 弹出申请试用的弹窗
          this.$Vlogin3({ flag: true })
          // 埋点 弹窗提示
          this.doDialogBuryPoint()
          return
        } else if (userInfo.roleKey == 'trialrole') { // 否则如果有数据栏目权限，但是是试用用户
          // 弹窗 【提示】此操作需要正式开通该栏目后操作
          openSy()
          // 埋点 弹窗提示
          this.doDialogBuryPoint()
          return
        }
        // 执行导出图片逻辑
        this.doExportImg()
        return
      }
      // 执行导出图片逻辑
      this.doExportImg()
    },
    // 执行导出图片
    doExportImg() {
      if (
        !this.showEmptyData &&
        !this.queryLoading &&
        this.isDisplayWithChart
      ) {
        this.$refs.chartRef?.exportImg()
      }
    },
    // 埋点
    doLogRecords(buttonName, inputBox = '') {
      logRecords(
        '数据', // column1 写死
        this.routeTableName, // column2 数据表名称
        '3', // type 写死，3 表示操作
        '', // tableName 不传也行
        '', // articleId 不传也行
        '', // attachName 不传也行
        '', // articleName 不传也行
        inputBox, // inputBox 如果是查询操作，则要传表格/饼图/折线图
        buttonName // buttonName 传查询/切换展示类型/下载数据/导出图片
      )
    },
    // 弹窗埋点
    doDialogBuryPoint() {
      buryPoint({
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '数据',
        userId: getUserInfo()?.userId
      })
    },
    // p1 打开查询模板
    handleClickOpenQueryTemplateSelectDialog() {
      // 如果未登录
      if (this.showNotLoggedInMask) {
        // 显示登录弹窗
        this.$Vlogin2({ flag: true })
        return
      }
      // 如果登录了
      const userInfo = getUserInfo()
      // 如果是已购买用户，或者是试用用户
      if (
        userInfo.roleKey == 'paidrole' ||
        userInfo.roleKey == 'trialrole'
      ) {
        // 操作埋点
        this.doLogRecords('点击打开查询按钮')
        // 如果没有数据栏目/国际化权限
        if (userInfo.menuName && (this.isInternational ? !userInfo.menuName.includes('国际化') : !userInfo.menuName.includes('数据'))) {
          // 显示申请试用弹窗
          this.$Vlogin3({ flag: true })
          // 弹窗埋点
          this.doDialogBuryPoint()
          return
        }
      }
      this.showQueryTemplateSelectDialog = true
    },
    // p1 点击右上角的 保存查询按钮
    handleClickSaveQueryBtn() {
      // 如果未登录
      if (this.showNotLoggedInMask) {
        // 显示登录弹窗
        this.$Vlogin2({ flag: true })
        return
      }
      // 如果登录了
      const userInfo = getUserInfo()
      // 如果是已购买用户，或者是试用用户
      if (
        userInfo.roleKey == 'paidrole' ||
        userInfo.roleKey == 'trialrole'
      ) {
        // 操作埋点
        this.doLogRecords('点击保存查询按钮')
        // 如果没有数据栏目/国际化权限
        if (userInfo.menuName && (this.isInternational ? !userInfo.menuName.includes('国际化') : !userInfo.menuName.includes('数据'))) {
          // 显示申请试用弹窗
          this.$Vlogin3({ flag: true })
          // 弹窗埋点
          this.doDialogBuryPoint()
          return
        }
      }
      // 需要从当前的表单中获取查询条件，而不是当前的查询正在用的查询条件
      const queryParams = this.generateQueryParamsByForm()
      // 如果用户当前设置的查询条件 不可以执行查询
      // judgeIfCanDoQuery(false)，false 表示不需要在 judgeIfCanDoQuery() 方法里弹出提示
      this.$refs.queryTemplateSaveDialogRef.show(queryParams, this.judgeIfCanDoQuery(false))
    },
    // 查询模板保存成功的回调
    handleQueryTemplateSaveSuccess(categoryName, queryOptionId, templateName, optionJson) {
      // 将该保存成功的查询模板 插入 当前的查询模板列表的首位
      this.$set(
        this.queryTemplateCachedMap,
        categoryName,
        [{
          queryOptionId,
          name: templateName,
          optionJson
        }].concat(
          this.queryTemplateCachedMap[categoryName] || []
        )
      )
    },
    // 用户手动选择某个查询模板的回调（恢复各种查询条件，然后判断如果能查询则自动点击查询按钮）
    handleSelectQueryTemplate(queryParams) {
      // 如果该查询条件是表格或者交叉表
      if (['表格', '交叉表'].includes(queryParams.displayMode)) {
        // 则需要添加分页参数
        queryParams.pageNo = this.defaultPageParams.pageNo
        queryParams.pageSize = this.defaultPageParams.pageSize
      }
      // 用于将当前数据表的指标字段列表映射成 map
      const allTargetMap = {}
      // 遍历当前数据表的指标字段列表，用于映射成 map
      this.allTargetList.forEach(item => {
        // 字段的中文 name 作为 key，字段的配置对象作为 value
        allTargetMap[item.name] = item
      })
      const newTargetList = queryParams.targetList.map(
        (item, index) => new AreaBlock(allTargetMap[item], index)
      )
      // 回显指标字段
      this.$set(this, 'targetList', newTargetList)
      // 恢复为默认的分页参数
      this.$set(this, 'pageParams', this.defaultPageParams)
      // 将所有可用的 维度字段 转为 map
      const allDimensionMap = {} // 用于将当前数据表的维度字段列表映射成 map
      // 遍历当前数据表的维度字段列表，用于映射成 map
      this.allDimensionList.forEach(item => {
        // 字段的中文 name 作为 key，字段的配置对象作为 value
        allDimensionMap[item.name] = item
      })
      // 回显统计分析
      this.$set(this, 'statisticList', queryParams.statisticList)
      // 重新获取当前展示类型的图形设置
      const newGraphSettingOption = this.$refs.graphSettingRef?.resetToDefaultGraphSetting(queryParams.displayMode)
      this.$set(this, 'graphSettingOption', newGraphSettingOption)
      // 用于保存查询条件的筛选详情数据
      const newDetailParams = {}
      // 如果是以交叉表进行展示
      if (queryParams.displayMode === '交叉表') {
        // 则添加 行 和 列 维度字段
        const newColumnList = queryParams.columnList.map(
          (item, index) => {
            if (item === '指标名称') {
              return new AreaBlock({ name: item, noClose: true }, index)
            }
            // 根据维度名称，获取维度对象
            const dimension = allDimensionMap[item]
            // 获取 detailParams 的各个属性及值
            newDetailParams[dimension.prop] = queryParams.detailParams[dimension.prop]
            // 用维度对象 生成 拖拽区域的对象
            return new AreaBlock(dimension, index)
          }
        )
        const newRowList = queryParams.rowList.map(
          (item, index) => {
            if (item === '指标名称') {
              return new AreaBlock({ name: item, noClose: true }, index)
            }
            // 根据维度名称，获取维度对象
            const dimension = allDimensionMap[item]
            // 获取 detailParams 的各个属性及值
            newDetailParams[dimension.prop] = queryParams.detailParams[dimension.prop]
            // 用维度对象 生成 拖拽区域的对象
            return new AreaBlock(dimension, index)
          }
        )
        this.$set(this, 'columnDimensionList', newColumnList) // 回显 列维度 字段
        this.$set(this, 'rowDimensionList', newRowList) // 回显 行维度 字段
      } else { // 否则如果不是以交叉表进行展示
        // 则开始添加维度字段
        const newDimensionList = queryParams.dimensionList.map(
          (item, index) => {
            // 根据维度名称，获取维度对象
            const dimension = allDimensionMap[item]
            // 获取 detailParams 的各个属性及值
            newDetailParams[dimension.prop] = queryParams.detailParams[dimension.prop]
            // 用维度对象 生成 拖拽区域的对象
            return new AreaBlock(dimension, index)
          }
        )
        // 回显维度字段
        this.$set(this, 'dimensionList', newDimensionList)

        // // 则开始添加查询字段
        // const newDimensionList1 = queryParams.dimensionList1.map(
        //   (item, index) => {
        //     // 根据维度名称，获取维度对象
        //     const dimension = allDimensionMap[item]
        //     // 获取 detailParams 的各个属性及值
        //     newDetailParams[dimension.prop] = queryParams.detailParams[dimension.prop]
        //     // 用维度对象 生成 拖拽区域的对象
        //     return new AreaBlock(dimension, index)
        //   }
        // )
        // 回显维度字段
        // this.$set(this, 'newList', newDimensionList1)
      }
      // 回显查询条件的筛选详情
      this.$set(this, 'detailParams', newDetailParams)
      // 获取保存的时间范围的 开始时间和结束时间
      const { startDatetime, endDatetime } = queryParams.detailParams
      // 使用保存的 开始时间
      let newStartDatetime = startDatetime.substr(0, 4)
      // 如果存的 开始时间 的长度 > 4
      if (startDatetime.length > 4) {
        // 则追加 月份
        newStartDatetime += '-' + startDatetime.substr(4, 2)
      }
      // 结束时间的值 默认采用 最晚可用时间的值
      let newEndDatetime = this.formattedDatetimeSelectRange[1]
      // 如果存了 结束时间
      if (endDatetime) {
        //  则用 结束时间
        newEndDatetime = endDatetime.substr(0, 4)
        // 如果存的 结束时间 的长度 > 4
        if (endDatetime.length > 4) {
          // 则追加月份
          newEndDatetime += '-' + endDatetime.substr(4, 2)
        }
      }
      const newDatetimeRange = [newStartDatetime, newEndDatetime]
      // 将 newDatetimeRange 的两个值转成 Date 类型
      // 判断如果结束时间早于开始时间
      if (new Date(newDatetimeRange[1]) < new Date(newDatetimeRange[0])) {
        // 则交换结束时间和开始时间
        const temp = newDatetimeRange[0]
        newDatetimeRange[0] = newDatetimeRange[1]
        newDatetimeRange[1] = temp
      }
      const earliestDate = new Date(this.formattedDatetimeSelectRange[0])
      const latestDate = new Date(this.formattedDatetimeSelectRange[1])
      // 如果开始时间早于最早可用时间
      if (new Date(newDatetimeRange[0]) < earliestDate) {
        // 则使用最早可用时间
        newDatetimeRange[0] = this.formattedDatetimeSelectRange[0]
      }
      // 如果结束时间晚于最晚可用时间
      if (new Date(newDatetimeRange[1]) > latestDate) {
        // 则使用最晚可用时间
        newDatetimeRange[1] = this.formattedDatetimeSelectRange[1]
      }
      // 如果为年份模式
      if (this.currentDatetimeMode === '年') {
        newDatetimeRange[0] = newDatetimeRange[0].substr(0, 4)
        newDatetimeRange[1] = newDatetimeRange[1].substr(0, 4)
      }
      // 回显时间范围
      this.$set(this, 'datetimeRange', newDatetimeRange)
      // 如果当前设置的查询条件 可以执行查询
      if (this.judgeIfCanDoQuery()) {
        // 回显图表类型
        this.selectedDisplayModeName = queryParams.displayMode
        this.judgeIfAppendTargetNameToColumnDimensionArea()
        // 自动点击查询按钮
        this.handleClickQueryBtn()
      } else { // 否则如果用户设置的查询条件不可以执行查询
        // 防止当前已经是折线图，然后打开了一个不可查询的折线图的查询条件之后又一次执行查询，这个执行查询是多余的，需要判断来避免
        if (this.selectedDisplayModeName !== queryParams.displayMode) { // 如果当前展示的图表类型和查询条件中保存的图表类型不一致
          // 则用已有的查询条件进行查询
          this.handleDisplayModeChange(queryParams.displayMode)
        } // 否则不用查询，展示已有的内容即可
      }
    },
    // 处理删除查询模板
    handleDeleteQueryTemplate(queryOptionId) {
      // 根据被删除的查询条件的 queryOptionId，filter 出新的查询条件列表
      const currentQueryOptionList = this.queryTemplateCachedMap[this.activeCategoryName]
      if (currentQueryOptionList) {
        this.$set(this.queryTemplateCachedMap, this.activeCategoryName, currentQueryOptionList.filter(item => item.queryOptionId !== queryOptionId))
      }
    },
    // 判断是否需要将 '指标名称' 这个动态字段加入列维度或从行/列维度删除
    judgeIfAppendTargetNameToColumnDimensionArea() {
      // 如果当前以 交叉表 进行展示
      if (this.isDisplayWithCrossTable) {
        // 如果指标数量大于 1
        if (this.targetList.length > 1) {
          // 则添加 指标名称 到 列维度 上（默认加到列维度上）
          this.$refs.columnDimensionDragTargetAreaRef?.addFixedBlockByFieldNames(['指标名称'])
        } else { // 否则删除 指标名称
          // 获取 指标名称 这个字段是否在 列维度 上
          const inColumnDimension = this.columnDimensionList.find(item => item.field.name === '指标名称')
          const dragAreaRefName = inColumnDimension ? 'columnDimensionDragTargetAreaRef' : 'rowDimensionDragTargetAreaRef'
          // 从 列维度 或 行维度 上删除 指标名称
          this.$refs[dragAreaRefName]?.removeBlockByFieldNames(['指标名称'])
        }
      }
    },
    handleShowNeedPlusAlert() {
      this.$alert(
        `<div style="font-size: 17px; margin-top: -15px">升级至PLUS版本，畅享数据查看权限！</div>
         <div style="padding-top: 14px;">联系人李文娟：18322511031</div>`,
        {
          confirmButtonText: '知道了',
          showClose: false,
          dangerouslyUseHTMLString: true
        }
      )
    }
  }
}
</script>
<style lang="scss" >
.dataTooltip{
  background: #EFF7FF!important;
  border: 1px solid rgba(52, 145, 250, 0.3)!important;
  color: #333333;
  .popper__arrow{
    border-top-color: rgba(52, 145, 250, 0.3)!important;
  }
  .popper__arrow::after{
    border-top-color: #EFF7FF!important;
  }
}
</style>
<style lang="scss" scoped>
@import "~@/styles/newDataCommon.scss";
@import "~@/styles/newDataVariables.scss";
.closeList{
  display: flex;
  align-items: center;
  img{
    margin-left: 5px;
  }
}

$category-header-height: 55px;

.dragging-container {
}

// 顶部的行分类容器
.category-header {
  height: $category-header-height;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #FFFFFF;
  padding: 0 24px;
  border-bottom: 1px solid #E5E6EB;
  border-radius: 3px 3px 0 0;

  .inner-content {
    white-space: nowrap;
  }
}

.main-container-wrapper {
  // height: calc(100% - #{$category-header-height});
  // overflow: auto;
  background-color: #fff;
  border-radius: 0 0 3px 3px;

  // 主体部分是左右布局
  .main-container {
    min-height: 100%;
    display: flex;

    .left {
      width: 270px;
      box-sizing: content-box;
      border-right: 1px solid #E5E6EB;
    }

    .right {
      width: 0;
      flex: 1;
      padding: 24px;
    }
  }
}

// 数据表字段容器对其内部滚动条的右内边距
$data-table-field-list-wrapper-scroll-padding-right: 2px;

// 数据表字段容器
.data-table-field-list-wrapper {
  padding: 0 $data-table-field-list-wrapper-scroll-padding-right 6px 0;
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  $padding-left-right: 24px;

  &>.title {
    height: 48px;
    display: flex;
    padding: 0 $padding-left-right;
    align-items: center;
    font-size: 14px;
    // font-weight: bold;
  }

  // 数据表字段列表，包括 维度和指标
  .data-table-field-list {
    flex: 1;
    overflow: auto;
    padding:
      0
      #{$padding-left-right - $data-table-field-list-wrapper-scroll-padding-right - $custom-scrollbar-size / 2}
      0
      $padding-left-right;

    .title {
      display: flex;
      align-items: center;
      font-size: 14px;

      &.dimension {
        margin-bottom: 16px;
      }

      &.target {
        margin: 6px 0 16px;
      }

      &::before {
        content: '';
        width: 6px;
        height: 6px;
        background-color: #0D57BC;
        border-radius: 3px;
        margin-right: 8px;
      }
    }

    .field-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &.empty {
        justify-content: center;
      }
    }
  }
}

// 图形设置容器
.display-mode-wrapper {

  .tab-header-wrapper {
    $tab-header-gap: 32px;

    border-top: 1px solid #E5E6EB;
    border-bottom: 1px solid #E5E6EB;

    .tab-header {
      height: 48px;
      box-sizing: border-box;

      .tab-item {
        flex: 0.5;
        height: 100%;
        color: #4E5969;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
        user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
        // border-bottom: 2px solid transparent;

        &:hover {
          color: #0D57BC;
        }

        &.active {
          color: #0D57BC;
          // border-bottom: 2px solid #0D57BC;
        }

        &:first-child {
          margin-right: $tab-header-gap;
        }
      }
    }

    .slide-line-wrapper {
      position: relative;
      height: 2px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(50% + #{$tab-header-gap} / 2);
        width: calc(50% - #{$tab-header-gap} / 2);
        background-color: #0D57BC;
        transition: left .3s;
      }

      &.is-display-mode-active::after {
        left: 0;
      }
    }
  }
}

.query-params-area {
  position: relative;
  border-bottom: 1px solid #E5E6EB;

  // 查询条件选项的行
  .query-option {
    display: flex;
    margin-bottom: 24px;

    .title {
      line-height: 32px;
      margin-right: 16px;
      color: #606266;
      white-space: nowrap;
    }

    &.statistic-list {
      width: fit-content;
    }
  }

  .query-option-group {
    display: flex;
    flex-wrap: wrap;

    .query-option {
      position: relative;
      margin-right: 40px;
    }

    ::v-deep {
      .el-select {
        width: 200px !important;

        .el-select__tags {
          display: flex;
          flex-wrap: nowrap;

          .el-select__input {
            margin-left: 5px !important;
          }

          .el-tag__close {
            margin: 0 !important;
            right: -3px !important;
          }
        }

        .el-tag:first-child {
          display: flex;
          align-items: center;

          .el-select__tags-text {
            display: block;
            max-width: 65px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .statistic-list-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    ::v-deep .el-checkbox {
      line-height: 32px;
    }
  }

  // 查询相关的按钮容器
  .query-button-container {
    padding-bottom: 24px;
    display: flex;
    justify-content: center;

    ::v-deep {
      .el-button {
        padding: 9px 30px;
      }
    }
  }
}

// 空数据的容器
.empty-data {
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #606266;
  pointer-events: none;
  user-select: none;

  img {
    margin-bottom: 20px;
  }
}

::v-deep {
  .not-logged-in-mask, .unsubscribed-mask {
    transform: translate(-50%, -50%);
    top: 30%;
  }
}

.result-container {
  position: relative;
  // 为了让 echarts 导出的图片有点内边距，所以这里先增宽 result-container，再由 echarts 的 grid 抵消增宽
  margin: 0 -#{$dragging-result-container-external-width};

  .result-header-bar {
    position: relative;
    // height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 14px $dragging-result-container-external-width;

    .result-header-text {
      display: flex;
      align-items: center;
      margin-right: 12px;

      .title {
        font-size: 18px;
        font-weight: bold;

        &.show {
          margin-right: 12px;
        }
      }
    }

    .result-header-text .unit, .update-time-str {
      font-size: 12px;
      color: #828282;
    }

    $inner-height: 32px;

    .operate-bar {
      // position: absolute;
      // right: $dragging-result-container-external-width;
      // top: 50%;
      // transform: translateY(-50%);
      height: $inner-height;
      display: flex;
      align-items: center;
      color: #4F4F4F;

      .item {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }

        &:nth-child(2) {
          margin-left: 24px;
        }

        &:hover {
          color: #0D57BC;
        }
      }
    }

    .tip-container {
      height: $inner-height;
      display: flex;
      align-items: center;
      background-color: #FEF7E4;
      border-radius: 4px;
      padding: 0 12px;
      color: #333333;
      font-size: 13px;

      img {
        width: 14px;
        height: 14px;
        pointer-events: none;
        margin-right: 5px;
        user-select: none;
      }
    }
  }

  .loading {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #333;
    z-index: 9;
    display: none;
    opacity: 0;
    transition: opacity 0.3s;

    &.show {
      display: flex;
      opacity: 1;
    }
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep {
  // 防止该遮罩遮住上面的 module-nav
  .el-loading-mask {
    z-index: 100;
  }
}

.dimension-query-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  .query-option {
    margin-bottom: 0;
    flex: 1;
  }

  .operate-query {
    height: 32px;
    display: flex;
    align-items: center;
    color: #0D57BC;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;

    img {
      pointer-events: none;
    }

    span {
      margin-left: 8px;
      white-space: nowrap;
    }

    &:first-of-type {
      margin-right: 28px;
    }
  }
}
</style>
