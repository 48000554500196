<template>
  <div class="box-top" flex="sb">
    <p class="box-title">{{ title }}</p>
    <p class="box-text" @click="moreClick">
      查看更多 <i class="el-icon-arrow-right" />
    </p>
  </div>
</template>

<script>
import { buryPoint } from '@/api/common'
import { getUserInfo, logRecords } from '@/utils/auth.js'

export default {
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '企业情报',
        userId: ''
      }
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {
    moreClick() {
      let newUrl = ''
      switch (this.title) {
        case '企业分析报告':
          newUrl = this.$router.resolve('/workInformation/analysis/index')
          break
        case '产业布局':
          newUrl = this.$router.resolve('/workInformation/industry/index')
          break
        case '企业财报':
          newUrl = this.$router.resolve('/workInformation/newFinancialReport/index')
          break
        case '企业动态':
          // this.$router.push();
          newUrl = this.$router.resolve('/workInformation/enterpriseInfo/news')
          break
        case '企业信息库':
          newUrl = this.$router.resolve('/workInformation/enterpriseInfo')
          break
        default:
          // 默认情况
          break
      }
      if (getUserInfo()) {
        logRecords('企业情报', this.title, '3', 'tt_enterprise_analysis', '', '', '', '', '查看更多')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
            return
          }
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
        return
      }
      switch (this.title) {
        case '企业分析报告':
          this.$router.push('/workInformation/analysis/index')
          break
        case '产业布局':
          this.$router.push('/workInformation/industry/index')
          break
        case '企业财报':
          this.$router.push('/workInformation/newFinancialReport/index')
          break
        case '企业动态':
          // this.$router.push();
          const newUrl = this.$router.resolve('/workInformation/enterpriseInfo/news')
          window.open(newUrl.href, '_blank')
          break
        case '企业信息库':
          this.$router.push('/workInformation/enterpriseInfo')
          break
        default:
          // 默认情况
          break
      }
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    }
  }
}
</script>
<style lang="scss" scoped>
.box-top {
    // padding-left: 13px;

    .box-title {
        font-size: 18px;
        color: rgb(34, 34, 34);
        font-weight: bold;
    }

    .box-text {
        font-size: 12px;
        cursor: pointer;
        color: #999999;
    }
}
</style>
