<template>
  <router-view v-if="$route.name == '政策解读内容页'" />
  <PageContainer v-else breadcrumb>
    <VlistTitle big-ttitle="政策解读" small-tip="会员专享精华内容推荐" :bgurl="bgurl" />
    <div style="overflow: hidden" class="box_box">
      <div style="float: left" flex="c">
        <el-checkbox-group v-model="belongAreas" size="small" @change="handleChange">
          <el-checkbox v-for="item in belongAreasData" :key="item.dictValue" :label="item.dictValue" border>
            {{ item.dictLabel }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div style="float: right">
        <el-input v-model="title" placeholder="搜索名称" size="small" @keyup.enter.native="search">
          <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer;" @click="search" />
        </el-input>
      </div>
    </div>

    <Vlist
      v-if="pageShow"
      :list="policyExplainData"
      :page="policyExplainPage"
      :page-data="policyExplainPage"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @toHref="toHref"
    />

    <!-- <ul class="list-box">
<li v-for="item in policyExplainData" :key="item.id">
  <div class="list-content">
    <router-link
      class="list-l text-overflow"
      :to="'/policy/dynamic/contentExplain/' + item.id"
    >
      {{ item.title }}
    </router-link>
    <span class="list-r">{{ item.publishDate }}</span>
  </div>
</li>
</ul>
<div class="pages">
<el-pagination
  @size-change="handleSizeChange"
  @current-change="handleCurrentChange"
  :current-page="policyExplainPage.currentPage"
  :page-sizes="[10, 20, 30, 40]"
  :page-size="policyExplainPage.pages.pageSize"
  layout="total, sizes, prev, pager, next, jumper"
  :total="policyExplainPage.total"
>
</el-pagination>
</div> -->
  </PageContainer>
</template>
<script>
import VlistTitle from '@/components/listTitle.vue'
import { policyExplain } from '@/api/policy.js'
import { selectDictDataByType } from '@/api/dict.js'
import Vlist from '@/components/list'
import { getUserInfo, logRecords } from '@/utils/auth'
import { buryPoint, selectCountByColumn } from '@/api/common'
import { Message } from 'element-ui'

export default {
  components: {
    VlistTitle,
    Vlist
  },
  data() {
    return {
      bgurl: require('@/assets/img/bg-1.jpg'),
      policyExplainData: [],
      policyExplainPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      belongAreasData: [],
      belongAreas: [],
      title: '',
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '政策',
        userId: ''
      },
      pageShow: true
    }
  },
  created() {
    this.policyExplain()
    this.selectDictDataByType()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    policyExplain() {
      if (!getUserInfo() && this.policyExplainPage.pages.page > 1) {
        this.showLogin()
      } else {
        const form = Object.assign({}, this.policyExplainPage.pages, {
          title: this.title,
          belongAreas: this.belongAreas
        })
        form.belongAreas = form.belongAreas.join(',')
        policyExplain(form).then((res) => {
          if (res.ok) {
            this.policyExplainData = res.data.list
            this.policyExplainPage.total = res.data.count
          }
        })
      }
    },
    handleSizeChange(val) {
      this.policyExplainPage.pages.pageSize = val
      this.policyExplain()
    },
    handleCurrentChange(val) {
      // const menu = this.$route.meta.acName
      // if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
      //   // 则不允许翻页，弹窗提示并埋点
      //   openSy()
      //   this.insertBuryPoint()
      //   this.pageShow = false
      //   this.$nextTick(() => {
      //     this.pageShow = true
      //   })
      //   return
      // } else {
      this.policyExplainPage.pages.page = val
      this.policyExplain()
      // }
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(item) {
      const newUrl = this.$router.resolve('/policy/dynamic/contentExplain/' + item.id)
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              selectCountByColumn({
                'userId': getUserInfo().userId,
                'column1': '政策',
                'column2': '政策解读'
              }).then((res) => {
                if (res.ok) {
                  if (res.data != null && res.data >= 10) {
                    Message({
                      showClose: true,
                      message: '您的体验次数已达上限，请正式开通！',
                      type: 'error'
                    })
                    return
                  } else {
                    logRecords('政策', '政策解读', '1', 'tt_policy_interpret', item.id, '', item.title, '')
                    window.open(newUrl.href, '_blank')
                  }
                }
              })
            } else {
              logRecords('政策', '政策解读', '1', 'tt_policy_interpret', item.id, '', item.title, '')

              window.open(newUrl.href, '_blank')
            }
          }
        } else {
          logRecords('政策', '政策解读', '1', 'tt_policy_interpret', item.id, '', item.title, '')

          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 数据字典
    selectDictDataByType() {
      selectDictDataByType({ dictType: 'policy_hot_field' }).then(
        (res) => {
          if (res.ok) {
            this.belongAreasData = res.data
          }
        }
      )
    },
    // 切换
    handleChange(e) {
      if (getUserInfo()) {
        this.belongAreas = e
        this.policyExplain()
      } else {
        this.showLogin()
      }
    },
    search() {
      this.policyExplainPage.pages.page = 1
      this.policyExplain()
      logRecords('政策', '政策解读', '4', 'tt_policy_interpret', '', '', '', this.title)
    }
  }
}
</script>
<style lang="scss" scoped>
.list-box {
  margin-top: 16px;

  li {
    background: #fff;
    padding: 24px;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      border-bottom: none;
    }

    .list-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list-l {
        padding-right: 20px;
        flex: 1;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #141414;
      }

      .list-r {
        font-size: 16px;
        line-height: 18px;
        color: #828282;
      }
    }
  }
}

::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box_box {
  margin-top: 15px;
  padding: 10px;
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
