<template>
  <div class="container">
    <div class="container-top">
      <h1 v-if="newData.category==4" class="container-top-title">{{ contentFotm(newData.titleEnglish) }} </h1>
      <h1 v-else class="container-top-title">{{ contentFotm(newData.title) }} </h1>

      <template v-if="isContentTwo == 'bz'">
        <div flex="sb">
          <p class="bh">
            <span>标准号：</span><span>{{ contentFotm(newData.code) }}</span>
          </p>
          <!-- <span class="time">{{ newData.updateTime }}</span> -->
        </div>

        <div v-if="newData.replaceCode!=null && newData.replaceCode!=''" style="font-weight: bold;font-size: 16px;margin: 0 0 10px 16px">替代标准：{{ newData.replaceCode }}</div>
        <div v-if="newData.state!=null && newData.state!=''" style="font-weight: bold;font-size: 16px;margin: 0 0 10px 16px">标准状态：{{ newData.state }}</div>

        <ul v-if="newData.fileInfo" class="fj-list">
          <li v-for="(item, index) in newData.fileInfo" :key="index">
            <div>
              <el-button
                class="orangeButton"
                size="mini"
                icon="el-icon-view"
                @click="preview1(item)"
              >在线预览</el-button>
              <el-button
                v-if="newData.category!=4"
                type="primary"
                size="mini"
                :loading="loadObj['loading_' + index]"
                icon="el-icon-download"
                @click="downBtn(item, newData, index)"
              >下载附件</el-button>
            </div>

            <div class="down-title">{{ item.fileName }}</div>
          </li>
        </ul>
      </template>

      <!-- <template v-else-if="isContentTwo == 'zc' || isContentTwo == 'bzdt' || isContentTwo == 'bzjd'"> -->

      <template v-else>
        <p class="laiyuan">
          <span class="padding-right" style="padding-left: 0;">{{ newData.updateTime }}</span>
          <template v-if="isContentTwo == 'qyjs' || isContentTwo == 'ztfx'|| isContentTwo =='qyfx'">
            <span class="border-left"> <span>作者：</span><span>{{ newData.author }}</span></span>
          </template>
          <template v-if="newData.source">
            <span v-if="isContentTwo == 'hyxx'" class="border-left">会议主办方：</span>
            <span v-else class="border-left">来源：</span>
            <span>{{ newData.source }}</span>
          </template>
          <template v-if="isContentTwo == 'bzdt'">
            <span class="border-left">来源：{{ newData.dynamicResource }}</span>
          </template>
          <template v-if="name==&quot;专题&quot;" :title="name?name + '信息':'信息'" class="grou">
            <span class="border-left">细分领域：{{ newData.categoryIdsDes }}</span>
          </template>
        </p>
        <ul v-if="newData.fileInfo" class="fj-list">
          <li v-for="(item, index) in newData.fileInfo" :key="index">
            <div class="down-btn" @click="downBtn(item, newData, index)">
              <!-- <img src="@/assets/img/download.png" alt="" class="down-icon">下载2附件{{ index + 1 }} -->
              <el-button type="primary" size="mini" :loading="loadObj['loading_' + index]">下载附件{{ index + 1 }}</el-button>
            </div>

            <div class="down-title">{{ index + 1 }}.{{ item.fileName }}</div>
          </li>
        </ul>
      </template>
    </div>

    <div class="container-bottom">
      <template v-if="isContentTwo == 'bz'">
        <VtimeLine
          style="margin-bottom:39px"
          :times="newData"
          :time-data="[
            { name: '发布', time: 'publishDate', code: 'replaceCode' },
            { name: '实施', time: 'implementDate' },
            { name: '被替代', time: 'beReplacedDate', code: 'beReplaceCode' },
            { name: '废止', time: 'voidDate' },
          ]"
          @commond="commond"
        />
        <div style="margin:0 0 20px 20px">
          <template v-if="newData.category==4">
            <div style="display: flex;">
              <span class="colorStyle">【中文名称】</span>
              <span style="width: 800px">{{ newData.title }}</span>
            </div>
            <el-divider />
          </template>

          <template v-else>
            <div style="display: flex;">
              <span class="colorStyle">【英文名称】</span>
              <span style="width: 800px">{{ newData.titleEnglish }}</span>
            </div>
            <el-divider />
          </template>

          <template v-if="newData.categoryExtend!=null && newData.categoryExtend!=''">
            <div style="display: flex;">
              <span class="colorStyle">【标准类别】</span>
              <span style="width: 800px">{{ newData.categoryExtend }}</span>
            </div>
            <el-divider />
          </template>

          <template v-if="newData.standardSystem!=null && newData.standardSystem!=''">
            <div style="display: flex;">
              <span class="colorStyle">【影响主体】</span>
              <span style="width: 800px">{{ newData.standardSystem }}</span>
            </div>
            <el-divider />
          </template>

          <template v-if="newData.belongAreas!=null && newData.belongAreas!=''">
            <div style="display: flex;">
              <span class="colorStyle">【热门领域】</span>
              <span style="width: 800px">{{ newData.belongAreas }}</span>
            </div>
            <el-divider />
          </template>

          <template v-if="newData.managerUnit!=null && newData.managerUnit!=''">
            <div style="display: flex;">
              <span class="colorStyle">【归口单位】</span>
              <span style="width: 800px">{{ newData.managerUnit }}</span>
            </div>
            <el-divider />
          </template>

          <template v-if="newData.publishUnit!=null && newData.publishUnit!=''">
            <div style="display: flex;">
              <span class="colorStyle">【发布单位】</span>
              <span style="width: 800px">{{ newData.publishUnit }}</span>
            </div>
            <el-divider />
          </template>

          <template v-if="newData.draftUnit!=null && newData.draftUnit!=''">
            <div style="display: flex;">
              <span class="colorStyle">【起草单位】</span>
              <span style="width: 800px">{{ newData.draftUnit }}</span>
            </div>
            <el-divider />
          </template>

          <template v-if="newData.summary!=null && newData.summary!=''">
            <div style="display: flex;">
              <span class="colorStyle">【简&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;介】</span>
              <span style="width: 800px">{{ htmlRestore(newData.summary) }}</span>
            </div>
          </template>
        </div>
      </template>
      <template v-else-if="isContentTwo == 'gjhzc'">
        <!--        <VtimeLine-->
        <!--          style="margin-bottom:39px"-->
        <!--          :times="newData"-->
        <!--          :time-data="[-->
        <!--            { name: '成文', time: 'transactionDate' },-->
        <!--            { name: '公开', time: 'publicDate' },-->
        <!--            { name: '实施', time: 'implementDate' },-->
        <!--            { name: '终止', time: 'endDate' },-->
        <!--          ]"-->
        <!--        />-->
        <UnfurlAndFoldBG v-if="newData.summary!='' && newData.summary!=null" :title="name + '摘要'" class="grou">
          <p class="textIndent" style="white-space: pre-wrap;">{{ htmlRestore(newData.summary) }}</p>
        </UnfurlAndFoldBG>
        <UnfurlAndFoldBG v-if="props.interpret" title="国际化政策解读" class="grou">
          <p class="textIndent" style="white-space: pre-wrap;">{{ props.interpret }}</p>
        </UnfurlAndFoldBG>
        <UnfurlAndFoldBG :title="name + '信息'" class="grou">
          <div>
            <p v-if="newData.certificateUnit!=null && newData.certificateUnit!=''"><b>颁布单位</b>：{{ newData.certificateUnit }}</p>
            <p v-if="newData.internationalPolicyType!=null && newData.internationalPolicyType!=''"><b>类型</b>：{{ newData.internationalPolicyType }}</p>
            <p v-if="newData.country!=null && newData.country!=''"><b>国家</b>：{{ newData.country }}</p>
          </div>
        </UnfurlAndFoldBG>
      </template>
      <template v-else-if="isContentTwo == 'zc'">
        <VtimeLine
          style="margin-bottom:39px"
          :times="newData"
          :time-data="[
            { name: '成文', time: 'transactionDate' },
            { name: '公开', time: 'publicDate' },
            { name: '实施', time: 'implementDate' },
            { name: '终止', time: 'endDate' },
          ]"
        />
        <UnfurlAndFoldBG :title="name + '简介'" class="grou">
          <p class="textIndent">{{ htmlRestore(newData.summary) }}</p>
        </UnfurlAndFoldBG>
        <UnfurlAndFoldBG :title="name + '信息'" class="grou">
          <div>
            <p><b>发布类型</b>：{{ newData.publishTypeDes }}</p>
            <p v-if="newData.areas == '1'"><b>区域范围</b>：国家</p>
            <p v-else-if="newData.areas == '2'">
              <b>区域范围</b>：{{ newData.provinceDes }}-{{ newData.city }}
            </p>
            <p><b>颁布单位</b>：{{ newData.certificateUnit }}</p>
            <p><b>政策类别</b>：{{ newData.policyCategory }}</p>
            <p><b>热门领域</b>：{{ newData.belongAreas }}</p>
          </div>
        </UnfurlAndFoldBG>
      </template>

      <template v-else-if="isContentTwo == 'bzzqyjg'">

        <UnfurlAndFoldBG title="简介" class="grou">
          <p class="textIndent">{{ htmlRestore(newData.introduction) }}</p>
        </UnfurlAndFoldBG>
        <UnfurlAndFoldBG title="标准信息" class="grou">
          <div class="marginLeft">
            <p v-if="newData.titleEnglish!=null && newData.titleEnglish!=''">英文名称：{{ newData.titleEnglish }}</p>
            <p v-if="newData.manageUnit!=null && newData.manageUnit!=''">归口单位：{{ newData.manageUnit }}</p>
            <p v-if="newData.publishUnit!=null && newData.publishUnit!=''">发布单位：{{ newData.publishUnit }}</p>
            <p v-if="newData.requireStartDate!=null && newData.requireStartDate!=''">征求开始时间：{{ newData.requireStartDate }}</p>
            <p v-if="newData.requireEndDate!=null && newData.requireEndDate!=''">征求结束时间：{{ newData.requireEndDate }}</p>
          </div>
        </UnfurlAndFoldBG>

      </template>

      <template v-else-if="isContentTwo == 'bzjd'">
        <UnfurlAndFoldBG :title="name + '信息'" class="grou">
          <div>
            <!-- <p>录入时间：{{ newData.updateTime }}</p> -->
            <p v-if="newData.category!=null && newData.category!=''">标准类型：{{ newData.category }}</p>
            <p v-if="newData.designCriteria!=null && newData.designCriteria!=''">涉及的标准：{{ newData.designCriteria }}</p>

            <p v-if="newData.belongAreas!=null && newData.belongAreas!=''">热门领域：{{ newData.belongAreas }}</p>
          </div>
        </UnfurlAndFoldBG>
      </template>

      <template v-else>
        <div v-if="htmlRestore(newData.summary)" class="synopsis">
          <img class="quotes" src="@/assets/img/quotes.png" alt="">
          <div class="synopsisText">{{ htmlRestore(newData.summary) }}</div>
        </div>
        <!-- <UnfurlAndFoldBG title="简介" class="grou">
          <p class="textIndent">{{ htmlRestore(newData.summary) }}</p>
        </UnfurlAndFoldBG> -->
        <!-- <UnfurlAndFoldBG v-if="name==&quot;专题&quot;" :title="name?name + '信息':'信息'" class="grou">
          <div>
            <p>作者：{{ newData.author }}</p>
            <p>细分领域：{{ newData.categoryIdsDes }}</p>
          </div>
        </UnfurlAndFoldBG> -->
      </template>

      <template v-if="isContentTwo == 'bz'">
        <p v-if="newData.htmlContent" style="margin-left: 20px">
          <span class="colorStyle">【正&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;文】</span>
        </p>
        <Content
          :html-content="newData.htmlContent"
          :name="name"
          :menu-name="menuName"
          :logined="logined"
          :un-authed="unAuthed"
          :render-full-content="renderFullContent"
          :show-login="showLogin"
        />
      </template>

      <template v-else>
        <div>
          <div style="text-align: left"><el-button v-if="(name == '国际化政策' || name == '国际化资讯') && loginFlags ==true && lang != 'zh' && newData.htmlContent!=undefined" type="danger" :disabled="readOnlyFlag" @click="translation(newData.id,name)">全文翻译</el-button></div>

          <Content
            :html-content="newData.htmlContent"
            :name="name"
            :menu-name="menuName"
            :logined="logined"
            :un-authed="unAuthed"
            :render-full-content="renderFullContent"
            :show-login="showLogin"
          />
        </div>
        <!-- <UnfurlAndFoldBG :title="name ? name + '正文' : '正文'" class="grou">
          <Content
            :htmlContent="newData.htmlContent"
            :name="name"
            :menuName="menuName"
            :logined="logined"
            :unAuthed="unAuthed"
            :renderFullContent="renderFullContent"
            :showLogin="showLogin"
          />
        </UnfurlAndFoldBG> -->
      </template>

      <template v-if="newisCollection">
        <div class="collection">
          <div v-if="newData.isCollection > 0" class="collection-btn" @click="cancelCollectionBtn">
            <img src="@/assets/img/Union.png" alt="" width="16" height="20">
            <p>取消收藏</p>
          </div>
          <div v-else class="collection-btn" @click="collectionBtn">
            <img src="@/assets/img/stroke.png" alt="" width="16" height="20">
            <p>收藏</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { htmlRestore, htmlString } from '@/utils/htmlRestore.js'
import { ttCollectionAdd, deleteByMessageId, recordWatch, buryPoint, runLanguageDetection } from '@/api/common.js'
import { getUserInfo, breadcrumbData, openSy, logRecords } from '@/utils/auth.js'
import { mapGetters } from 'vuex'
import { download, translation, translation2 } from '@/api/common.js'
import { downloadFile } from '@/utils/download.js'
import VtimeLine from '@/components/timeLine.vue'
import UnfurlAndFoldBG from '@/components/UnfurlAndFoldBG.vue'
import Content from './content'

export default {
  name: 'Vcontent',
  components: {
    VtimeLine,
    UnfurlAndFoldBG,
    Content
  },
  props: {
    authMenuName: {
      required: false,
      type: String,
      default: undefined
    },
    data: {
      type: Object
    },
    lang: {
      type: String,
      default: 'zh'
    },
    loginFlags: {
      type: Boolean,
      default: false
    },
    props: {
      default() {
        return {
          tag: 'tag',
          title: 'title',
          publishDate: 'updateTime',
          source: 'source',
          summary: 'summary',
          link: 'link',
          htmlContent: 'htmlContent',
          author: 'author',
          interpret: false
        }
      }
    },
    isCollection: {
      type: Boolean,
      default: true
    },
    isContentTwo: '',
    name: ''
  },
  data() {
    return {
      bgurl: require('@/assets/img/Group299.png'),
      newPolicyDate: [],
      newPolicyPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      belongAreasData: [],
      belongAreas: [],
      tabData: [
        { value: '', label: '全部政策' },
        { value: '1', label: '国家政策' },
        { value: '2', label: '地方政策' }
      ],
      ac: '',
      areas: '',
      all: true,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '政策',
        userId: ''
      },
      pdfUrl: 'http://localhost:8080/ISO 11154-2023.pdf',
      src: '',
      loadObj: {
        loading_0: false,
        loading_1: false,
        loading_2: false,
        loading_3: false,
        loading_4: false,
        loading_5: false,
        loading_6: false,
        loading_7: false,
        loading_8: false,
        loading_9: false,
        loading_10: false
      },
      previewShow: false,
      previewUrl: '',
      newisCollection: this.isCollection,
      aa: this.$route.meta.breadcrumb,
      numPages: '',
      logined: false,
      unAuthed: false,
      readOnlyFlag: false
    }
  },
  computed: {
    ...mapGetters({
      ListFlag: 'ListFlag',
      pRouterId: 'pRouterId'
    }),
    newData() {
      if (JSON.stringify(this.data) != '{}') {
        for (const key in this.props) {
          this.data[key] = this.data[this.props[key]]
        }
        return this.data
      } else {
        return {}
      }
    },
    menuName() {
      const { name: current, matched } = this.$route

      let root = matched[0].name
      if (root === '数据-MENU') {
        root = matched[1].name
      }

      return { root, current }
    },
    renderFullContent() {
      const { menuName } = this

      if (
        !(
          ['专题分析', '前沿技术', '市场洞察'].includes(menuName.root) ||
          (menuName.root === '企业情报' && menuName.current === '企业分析详情') ||
          (menuName.root === '政策' && menuName.current === '评价与趋势分析内容页')
        ) || !menuName.root.includes('商用车')
      ) {
        return true
      }

      return this.logined && !this.unAuthed
    }
  },
  watch: {
    $route() {
      this.$store.dispatch('common/SET_ListRest')
    },
    data(to, form) {
      // const { column1, column2 } = breadcrumbData(this.$route)
      // if (JSON.stringify(this.data) != '{}') {
      //   recordWatch({ title: to.title, column1, column2 })
      // }
    }
  },
  created() {
    // this.$store.state.common.ListFlag = false
    this.$store.dispatch('common/SET_ListRest')

    const userInfo = getUserInfo()

    if (userInfo) {
      this.logined = true

      if (['paidrole', 'trialrole'].includes(userInfo.roleKey)) {
        if (!userInfo.menuName.includes(this.authMenuName || this.menuName.root)) {
          this.unAuthed = true
        }
      }
    } else {
      this.newisCollection = false
    }
  },
  methods: {
    // 全文翻译
    translation(id, name) {
      const data = {
        id: id,
        lang: this.lang

      }
      if (name == '国际化政策') {
        translation(data).then((res) => {
          if (res.ok) {
            console.log(res)
            this.newData.htmlContent = res.data
            this.readOnlyFlag = true
          }
        })
      } else if (name == '国际化资讯') {
        translation2(data).then((res) => {
          if (res.ok) {
            console.log(res)
            this.newData.htmlContent = res.data
            this.readOnlyFlag = true
          }
        })
      }
    },
    LanguageDetection(id, name) {
      if (id == undefined) {
        return
      }
      if (name == '国际化政策' || name == '国际化资讯') {
        const data = {
          id: id,
          name: name
        }
        runLanguageDetection(data).then((res) => {
          if (res.ok) {
            this.lang = res.data
            console.log(res.data)
            if (res.data == 'zh') {
              return false
            } else {
              console.log(true)

              return true
            }
          }
        })
      } else {
        return false
      }
    },
    checkChinese(id, name) {
      console.log(id)
      if (id != undefined) {
        const data = {
          id: id,
          name: name
        }
        runLanguageDetection(data).then((res) => {
          if (res.ok) {
            if (res.data == 'zh') {
              return false
            } else {
              return true
            }
          }
        })
      }
    },
    bindLoading(index) {
      this.$set(this.loadObj, 'loading_' + index, false)
      return this.loadObj['loading_' + index]
    },

    splitArr(data) {
      if (data && data.length > 0) {
        return data.split(',')
      } else {
        return []
      }
    },
    contentFotm(data) {
      if (!data) {
        return
      }

      // const content = htmlRestore(data);
      return htmlRestore(data)
    },
    collectionBtn() {
      const data = Object.assign({}, this.data)
      data.htmlContent = htmlString(this.data.htmlContent)
      data.menuId = this.pRouterId
      const obj = {
        menuId: data.menuId,
        title: data.title,
        content: data.htmlContent,
        publishDate: data.updateTime,
        messageId: data.id
      }
      ttCollectionAdd(obj).then((res) => {
        if (res.ok) {
          this.$message.success('收藏成功')
          this.newData.isCollection = 1
        }
      })
      // this.$emit('collection',this.data)
    },
    cancelCollectionBtn() {
      this.$confirm('是否取消收藏', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteByMessageId(this.newData.id).then((res) => {
          if (res.ok) {
            this.newData.isCollection = 0
          }
        })
      })
    },
    downBtnYc() {
      const self = this
      download({
        fileUrl: this.newData.attachUrl,
        articleId: this.newData.id
      }).then((res) => {
        var reader = new FileReader()
        reader.readAsText(res)
        reader.addEventListener('loadend', function(e) {
          try {
            JSON.parse(e.target.result)
            const msg = JSON.parse(e.target.result)
            self.$message({
              showClose: true,
              message: msg.message,
              type: 'error'
            })
          } catch (err) {
            downloadFile(res, self.newData.acctachName)
          }
        })
      })
    },
    downBtns() {
      const self = this
      download({
        fileUrl: this.newData.attachUrl,
        articleId: this.newData.standardContentId
      }).then((res) => {
        var reader = new FileReader()
        reader.readAsText(res)
        reader.addEventListener('loadend', function(e) {
          try {
            JSON.parse(e.target.result)
            const msg = JSON.parse(e.target.result)
            self.$message({
              showClose: true,
              message: msg.message,
              type: 'error'
            })
          } catch (err) {
            downloadFile(res, self.newData.acctachName)
          }
        })
      })
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 直接传入一个地址
    preview1(item) {
      const valid = this.validator({ item, type: '3', buttonName: '预览' })

      if (!valid) {
        return
      }

      // window.open('/pdf/web/viewer.html?file=' + window.encodeURIComponent('http://10.8.8.38:8080/ISO 11154-2023.pdf'));
      window.open('/pdf/web/viewer.html?file=' + window.encodeURIComponent('https://www.autoinfo.org.cn' + item.url))
    },

    // 下载(修改的部分)
    downBtn(item, obj, index) {
      // if (this.unAuthed && item.url.includes('/autoinfo/profile/upload/policy/originaInterpret/20240306/2024年《政府工作报告》解读.pptx')) {
      //   this.$Vlogin3({ flag: true })
      //   return
      // }
      if (item.url.includes('industry')) {
        window.location.href = 'https://www.autoinfo.org.cn' + item.url
        return
      }
      const valid = this.validator({ item, type: '2', buttonName: '' })

      if (!valid) {
        return
      }

      this.loadObj['loading_' + index] = true
      const self = this
      const { column1, column2 } = breadcrumbData(this.$route)
      download({ fileUrl: item.url, articleId: obj.id, column1, column2 }).then((res) => {
        var reader = new FileReader()
        reader.readAsText(res)
        reader.addEventListener('loadend', function(e) {
          try {
            JSON.parse(e.target.result)
            const msg = JSON.parse(e.target.result)
            self.$message({
              showClose: true,
              message: msg.message,
              type: 'error'
            })
          } catch (err) {
            downloadFile(res, item.fileName)
          }
        })
        this.loadObj['loading_' + index] = false
      })
    },
    validator(options) {
      const userInfo = getUserInfo()

      if (!userInfo) {
        this.showLogin()
        return false
      }

      if (userInfo.roleKey !== 'paidrole' && userInfo.roleKey !== 'trialrole') {
        return true
      }

      this.logRecords(options)

      // if (!userInfo.menuName.includes(this.authMenuName || this.menuName.root)) {
      //   this.sqsyLogin();
      //   this.insertBuryPoint();
      //   return false;
      // } else {
      if (userInfo.roleKey == 'trialrole') {
        openSy()
        this.insertBuryPoint()
        return false
      } else {
        return true
      }
      // }
    },
    logRecords({ item, type, buttonName }) {
      buttonName = buttonName || ''
      item.fileName = item.fileName || ''
      const { menuName, newData: data } = this
      let [column1, column2, tableName, articleId, attachName, articleName, inputBox] = Array(7).fill('')

      if (item.url.includes('/policy/main')) {
        column1 = '政策'
        column2 = '最新政策'
        tableName = 'tt_policy'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/policy/originaInterpret')) {
        column1 = '政策'
        column2 = '本网原创'
        tableName = 'tt_policy_interpret'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/policy/interpret')) {
        column1 = '政策'
        column2 = '政策解读'
        tableName = 'tt_policy_interpret'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/stardard/main')) {
        column1 = '标准'
        column2 = '最新标准'
        tableName = 'tt_standard'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/stardard/comments')) {
        column1 = '标准'
        column2 = '标准征求意见稿'
        tableName = 'tt_solicit_comments'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/stardard/dynamic')) {
        column1 = '标准'
        column2 = '标准动态'
        tableName = 'tt_standard_dynamic'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/stardard/unscramble')) {
        column1 = '标准'
        column2 = '标准解读'
        tableName = 'tt_standard_unscramble'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/usedCar')) {
        column1 = '二手车'
        column2 = '二手车报告'
        tableName = 'tt_used_car'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/marketInsight')) {
        column1 = '市场洞察'
        column2 = '洞察报告'
        tableName = 'tt_market_insight'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/special')) {
        column1 = '专题分析'
        column2 = '分析报告'
        tableName = 'tt_special'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/technology')) {
        column1 = '前沿技术'
        column2 = '技术报告'
        tableName = 'tt_technology'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/enterprise/analysisproduct')) {
        column1 = '企业情报'
        column2 = '企业分析报告'
        tableName = 'tt_enterprise_analysis'
        articleId = data.id
        attachName = item.fileName
      } else if (item.url.includes('/upload/internationalReport')) {
        column1 = '国际化'
        column2 = '研究报告'
        tableName = 'tt_international_report'
        articleId = data.id
        attachName = item.fileName
        articleName = data.title
      } else if (item.url.includes('/upload/international')) {
        column1 = '国际化'
        column2 = '国际资讯'
        tableName = 'tt_international_information'
        articleId = data.id
        attachName = item.fileName
        articleName = data.title
      } else if (item.url.includes('/profile/upload/port')) {
        column1 = '国际化'
        column2 = '政务及口岸发布'
        tableName = 'tt_port_information'
        articleId = data.id
        attachName = item.fileName
        articleName = data.title
      }

      if (menuName.root === '首页') {
        column1 = menuName.root
      }
      if (!column2) {
        column2 = menuName.current
      }

      logRecords(column1, column2, type, tableName, articleId, attachName, articleName, inputBox, buttonName)
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    // 时间轴跳转
    commond(v) {
      // console.log(v.code.code);
      this.$emit('commond', v)
    }
  }
}
</script>

<style lang="scss" scoped>
.synopsis {
  font-size: 14px;
  background: #f8f8f8;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  color: rgb(51, 51, 51);
  padding: 0 20px;
  .quotes {
    padding: 16px 16px 18px 0;
  }
  .synopsisText {
    white-space: pre-wrap;
    padding: 35px 0 25px 0;
  }
}
.container {
  .container-tag {
    margin-bottom: 10px;
    margin-left: -10px;

    .tags {
      margin-left: 10px;
    }
  }

  .container-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #333;
    margin-bottom: 10px;
  }

  .container-summary,
  .container-tip {
    font-size: 16px;
    line-height: 24px;
    color: #828282;
  }

  .container-summary {
    margin-bottom: 16px;
  }

  .container-tip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .container-tip-l {
      span {
        padding-right: 15px;
      }
    }
  }
}

.collection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 0 24px;
  height: 106px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;

  .collection-btn {
    font-size: 16px;
    line-height: 26px;
    color: #000;
    text-align: center;
    cursor: pointer;
  }
}

.line {
  width: 100%;
  height: 1px;
  background: #e0e0e0;
  margin: 15px 0;
}

.fj {
  font-size: 13px;
  line-height: 13px;
  color: #828282;
}

.xgtj-box {
  // background: #fff;
  min-height: 200px;

  h1 {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    padding: 0 16px;
    color: #0d57bc;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
  }
}

.zwtj {
  text-align: center;
  line-height: 150px;
}

//新样式S
.container-top {
  background: #fff;
  padding: 0 16px;

  .container-top-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #333;
    padding-top: 32px;
    padding-bottom: 24px;
  }

  .bh {
    display: inline-block;
    padding: 10px 8px 10px 16px;
    //background: #f4f5f6;
    border-radius: 2px;
    //margin-bottom: 14px;

    span {
      font-weight: bold;
      font-size: 16px;
      //line-height: 16px;
      color: red;
    }
  }

  .time {
    font-size: 14px;
    line-height: 20px;
    color: #4f4f4f;
  }

  .laiyuan {
    line-height: 16px;
    font-size: 14px;
    color: rgb(153, 153, 153);
    .padding-right {
      padding-right: 15px;
      padding-left: 15px;
    }
    .border-left {
      padding-right: 15px;
      padding-left: 15px;
      border-left: 1px solid  rgb(153, 153, 153);
    }
  }

  .down-btn {
    display: inline-block;
    /*padding: 7px 12px;*/
    font-size: 12px;
    line-height: 12px;
    background: #0d57bc;
    border-radius: 2px;
    border-color: #0d57bc;
    color: #fff;
    cursor: pointer;

    .down-icon {
      vertical-align: sub;
    }
  }

  .fj-list {
    padding-top: 24px;
  }
  .fj-list li {
    display: flex;
    margin-bottom: 8px;
  }

  .down-title {
    flex: 1;
    position: relative;
    font-size: 13px;
    line-height: 31px;
    color: #333;
    margin-left: 12px;

    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      bottom: -4px;
      left: 0;
      border-bottom: 1px solid #f2f2f2;
    }
  }

  .fj-list li:last-child {
    margin-bottom: 0px;
  }

  .fj-list li:last-child .down-title::after {
    display: none;
  }
}

.container-bottom {
  background: #fff;
  padding: 24px 16px 16px;

  .grou {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.colorStyle{
  color: #0D57BC;
  font-weight: bold;
  width: 100px;
}
.el-divider--horizontal {

  margin: 12px 0;
}
.el-divider {
  background-color: #f4f4f4;
}
.orangeButton {
  border-color: #f49802;
  background: #f49802;
  color: #fff;
  margin-right: 20px;
  margin-left: 14px;
}
.marginLeft{
  margin-left: 16px;
}
//新样式E
</style>

<style>
.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
