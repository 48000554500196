<template>
  <el-tabs v-model="contentCategorys" :before-leave="leaveTab" @tab-click="tabClick">
    <el-tab-pane v-for="(o, i) in menuArray" :key="i" :route-path="o.path" :label="o.label" :name="o.name" >
      <span slot="label" v-if="o.label==='经营情况'">{{o.label}} <span class="newNew">NEW</span></span>
      <span slot="label" v-else>{{o.label}}</span>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { buryPoint } from '@/api/common'
import { getUserInfo, logRecords } from '@/utils/auth.js'

export default {
  data() {
    return {
      contentCategorys: this.$route.meta.acName,
      userInfo: null,
      menuArray: [
        { label: '概览', path: '/workInformation/survey/index', name: '企业情报' },
        {
          label: '分析报告',
          path: '/workInformation/analysis/index',
          name: '分析报告'
        },
        {
          label: '企业信息',
          path: '/workInformation/enterpriseInfo',
          name: '企业信息列表'
        },
        {
          label: '产业布局',
          path: '/workInformation/industry/index',
          name: '产业布局'
        },
        {
          label: '产销产能',
          path: '/workInformation/newcapacity/index',
          name: '产销产能'
        },
        {
          label: '企业财报',
          path: '/workInformation/newFinancialReport/index',
          name: '企业财报'
        },
        {
          label: '经营情况',
          path: '/workInformation/businessConditions/index',
          name: '经营情况'
        }
      ]
    }
  },
  created() {
    this.userInfo = getUserInfo()
    if (getUserInfo()) {
      if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
        const menuName = getUserInfo().menuName
        if (!menuName.includes('企业情报')) {
          this.$router.push('/workInformation/survey/index')
          return
        }
      }
    } else {
      this.$router.push('/workInformation/survey/index')
      return
    }
    const acName = this.$route.meta.acName
    if (acName === '企业对标报告' || acName === '企业信息详情') {
      this.contentCategorys = '企业信息列表'
    } else {
      this.contentCategorys = acName
    }
  },
  methods: {
    leaveTab() {
      if (this.userInfo) {
        if (
          this.userInfo.roleKey == 'paidrole' ||
          this.userInfo.roleKey == 'trialrole'
        ) {
          const menuName = this.userInfo.menuName
          if (!menuName.includes('企业情报')) {
            return false
          }
        }
      } else {
        return false
      }
      return true
    },
    tabClick(e) {
      console.log(this.contentCategorys)
      if (this.userInfo) {
        logRecords('企业情报', e.label, '3', 'tt_enterprise_analysis', '', '', '', '', '查看更多')
        if (
          this.userInfo.roleKey == 'paidrole' ||
          this.userInfo.roleKey == 'trialrole'
        ) {
          const menuName = this.userInfo.menuName
          if (menuName.includes('企业情报')) {
            console.log(e.$attrs)
            this.$router.push(e.$attrs['route-path'])
          } else {
            this.sqsyLogin()
            this.insertBuryPoint()
          }
        } else {
          this.$router.push(e.$attrs['route-path'])
        }
      } else {
        const newUrl = this.$router.resolve(e.$attrs['route-path'])
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    insertBuryPoint() {
      this.formLog.userId = this.userInfo.userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__header {
  margin: 0;
}

::v-deep {
  .el-tabs__nav-wrap::after {
    width: 0;
  }

  .el-tabs__header {
    margin: 0;
  }

  .el-tabs__item {
    padding: 0 24px;
    color: #666;
    height: 48px;
    line-height: 48px;
    font-weight: normal;

    &:hover {
      font-weight: normal;
    }

    &.is-active {
      color: #3f76ea;
      font-weight: bold;
    }
  }
}
.newNew {
  position: absolute;
  top: 7px;
  line-height: 20px;
  left: 82px;
  padding: 0 5px;
  font-size: 12px;
  background: #ff6e4b;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
