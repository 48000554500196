<!--
 * @Author: your name
 * @Date: 2021-07-22 13:25:42
 * @LastEditTime: 2021-07-22 15:41:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/views/thematicAnalysis/indexBalance.vue
-->

<template>
  <PageContainer>
    <!--    <div class="not-login-box" v-if="!show">-->
    <!--      <VnotLogin title="专题分析" text="专题分析栏目围绕专题分析本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新专题分析"-->
    <!--        :showTips="showTips" />-->
    <!--    </div>-->
    <template>
      <el-tabs v-model="form.contentCategory" type="card" tab-position="left" @tab-click="tabClick" :before-leave="leaveTab"
               style="float: left; margin-left: -42px;display: flex;position: fixed;z-index: 1;">
        <el-tab-pane label="全部" name=" " :key="'qb'"></el-tab-pane>
        <el-tab-pane label="原创" name="yc" :key="'yc'"></el-tab-pane>

      </el-tabs>

      <div class="box" v-loading="loading">
        <div class="box_box">

          <div style="overflow: hidden">

            <div style="float: right" class="search-btn-box ">
              <el-input placeholder="洞察分析报告" v-model="values" size="small"></el-input>
              <div class="search-btn" @click="search" >搜索</div>
            </div>
          </div>
        </div>
        <div class="box_box_title" v-for="item in tableData" :key="item.id"
             @click="toHref(item)">
          <div style="display: flex">
            <div class="text-center" style="width: 267px; height: 150px">
              <img style="width: 267px; height: 150px" :src="item.imgUrl ? item.imgUrl : defaultUrl" alt=""/>
            </div>
            <div class="flex_col" flex="1">
              <div>
                <div flex="c" style="margin: 10px 0">
                  <span style="
                      display: inline-block;
                      color: #0d57bc;
                      font-size: 14px;
                      line-height: 16px;
                      padding: 4px;
                      background: rgba(13, 87, 188, 0.08);
                      margin-right: 4px;
                    " v-if="item.contentCategory == 'yc'">原创</span>
                  <span class="newUl-areas" v-if="item.tableName == '市场洞察'">市场</span>
                  <span class="newUl-areas" v-else-if="item.tableName == '政策原创'">政策</span>

                  <p class="">{{ htmlRestore(item.title) }}</p>
                </div>
                <div style="font-size: 14px; color: #828282">
                  {{ htmlRestore(item.introduction) }}
                </div>
              </div>
              <div style="font-size: 14px; color: #828282">
                {{ item.publishDate }}
              </div>
            </div>
          </div>
        </div>
        <div class="pages">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40]"
                         :page-size="pageData.pages.pageSize"
                         layout="total, sizes, prev, pager, next, jumper" :total="pageData.total">
          </el-pagination>
        </div>
      </div>
    </template>

    <template slot="right">
      <!-- <div style="width: 290px; height: 200px; float: right; background: #fff">
        <h1 style="padding-left: 15px; padding-top: 15px">最新发布</h1>
      </div> -->
      <div class="index-banner-title" style="background: #fff;">
        <div class="title-tag">
          <!-- <img src="@/assets/img/Group1.png" alt="" /> -->

          <h3 class="title-tag-text" style="margin-top: 10px;margin-bottom: 18px;"><span
              style="padding-right: 8px;font-weight: bold;color: #0d57bc;">//</span>{{ tagText }}</h3>

        </div>

        <ul class="index-new-timeLine">
          <li v-for="item in tableDataRight" :key="item.id">
            <div class="index-new-timeLine-content">
              <div style="cursor: pointer" @click="toHref(item)"
                   class="index-new-link">
                <div flex="c">
                  <img src="@/assets/img/bg.png" alt=""/>
                  <span class="text-overflow" style=" padding-left: 10px;font-size: 14px;line-height: 20px;"><span
                      style="
                        display: inline-block;
                        color: #0d57bc;
                        font-size: 12px;
                        line-height: 16px;
                        padding: 2px;
                        background: rgba(13, 87, 188, 0.08);
                        margin-right: 4px;
                      " v-if="item.contentCategory == 'yc'">原创</span>
                    {{ htmlRestore(item.title) }}</span>
                  <span>{{ item.publishDate }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div @click="moreClick" class="more">查看更多<img style="margin-bottom: 4px;" src="@/assets/img/jt01.png" alt=""
                                                      class="more-icon"/></div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 专题分析动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {fmtDate} from "@/utils/date.js";
import {getUserInfo, ydlTk} from "@/utils/auth.js";
import {getAllTages, page, pageSyc} from "@/api/thematicAnalysis.js";
import {selectDictDataByType} from "@/api/dict.js";
import {buryPoint} from "@/api/common";

export default {
  // components: {
  //   VnotLogin,
  // },
  data() {
    return {
      defaultUrl: require("@/assets/img/default-img.png"),
      activeName: "全部分类",
      values: "",
      showTips: [
        {
          title: "专题分析动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "专题分析库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "专题分析梳理",
          text: "专家梳理整合，真正读懂专题分析脉络",
        },
        {
          title: "专题分析大数据",
          text: "用数据深度理解专题分析结构和趋势",
        },
      ],
      tagText: "最新发布",
      loading: false,
      tableData: [],
      tableDataRight: [],
      show: false,
      form: {
        categoryIds: [], //关键字
        articleCategory: "全部分类", //文章类型
        contentCategory: " ",
      },
      pageData: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "",
        columnName: "专题分析",
        userId: ""
      },
      ditcData: [],
      xflbData: [],
      xflbAll: true,
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

      // this.getAllTages();
    } else {
      this.show = false;
    }
    this.page();
    this.pageRight();
    this.selectDictDataByTypewz();
    this.selectDictDataByTypexflb();
  },
  methods: {
    leaveTab(activeName, oldActiveName) {
      if(!getUserInfo()){
        return false;
      }
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //搜索
    search() {
      let newUrl = this.$router.resolve('/thematicAnalysis/syc/search?title=' + this.values);
      if (this.values == "") {
        alert("请输入搜索内容")
      } else {

        window.open(newUrl.href, "_blank");

      }

    },
    toHref(item) {
      let url = "/policy/dynamic/LocalOriginalDetails/" + item.id;
      if(item.tableName=='专题分析'){
        url = '/thematicAnalysis/details/' + item.id;
      }else if(item.tableName=='前沿技术'){
        url = '/cuttingEdgeTechnology/details/' + item.id;

      }else if(item.tableName=='市场洞察'){
        url = "/marketInsight/detail/insightReportDetail/" + item.id;
      }
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");


    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.page();
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val;
      this.page();
    },
    handleClick(tab) {
      this.form.articleCategory = tab.name;
      this.selectDictDataByTypexflb();
      this.page();
    },
    tabClick(tab) {

        this.form.contentCategory = tab.name;
        if (tab.name == "yc") {
          this.tagText = "最新发布";
        } else if (tab.name == " ") {
          this.tagText = "原创推荐";
        }
        this.page();
        this.pageRight();


    },
    moreClick(tab, event) {

        if (this.form.contentCategory == "yc") {
          this.tagText = "最新发布";
          this.form.contentCategory = " ";
        } else if (this.form.contentCategory == " ") {
          this.tagText = "原创推荐";
          this.form.contentCategory = "yc";
        }
        this.page();
        this.pageRight();

    },
    //获取列表
    page() {
      let form = Object.assign({}, this.form, this.pageData.pages);
      this.loading = true;
      pageSyc(form).then((res) => {
        this.tableData = res.data.list;
        this.pageData.total = res.data.count;
        this.loading = false;
      });
    },

    //获取右侧列表
    pageRight() {
      let form = Object.assign({}, this.form, this.pageData.pages);

      if (form.contentCategory == "yc") {
        form.contentCategory = "";
      } else if (form.contentCategory == " ") {
        form.contentCategory = "yc";
      }
      pageSyc(form).then((res) => {
        this.tableDataRight = res.data.list.map((item) => {

          item.publishDate = fmtDate(
              "mm-dd",
              item.publishDate
          );
          return item;
        });
      });
    },
    //字典获取文章类别
    selectDictDataByTypewz() {
      selectDictDataByType({dictType: "ztfx_wzlb"}).then((res) => {
        this.ditcData = res.data;
      });
    },
    //细分类别
    selectDictDataByTypexflb() {
      selectDictDataByType({dictType: "ztfx_xflb"}).then((res) => {
        this.xflbData = res.data;
      });
    },
    //获取标签
    getAllTages() {
      let articleCategory =
          this.form.articleCategory == "全部分类"
              ? ""
              : this.form.articleCategory;
      getAllTages({articleCategory}).then((res) => {
        this.xflbData = res.data;
      });
    },
    //关键字
    kcheck(e) {
      if (e.length <= 0) {
        this.xflbAll = true;
      } else {
        this.xflbAll = false;
      }
      this.page();
    },
    //关键字全选
    kckAll() {
      this.form.categoryIds = [];
      this.page();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "专题分析详情") {
      // 详情页路由
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
    }
    next();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box_box {
  position: relative;
  z-index: 1;
  padding: 10px;
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.box_box_title {
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;
  border-bottom: 1px solid #F2F2F2;
  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.lable-box {
  margin-bottom: -10px;

  .el-checkbox {
    margin-bottom: 10px;
  }
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  background-color: #0d57bc;
  color: #ffffff;
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  height: 57px;
  width: 41px;
  padding-top: 15px;
  writing-mode: vertical-lr;
  padding-left: 0;
  padding-right: 0;
}

.index-banner-title {
  // display: flex;
  justify-content: space-between;
  // align-items: center;
}

.title-tag {
  // display: flex;
  align-items: flex-start;

  .title-tag-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    // color: #0d57bc;
    margin-top: -30px;
    margin-left: 20px;
  }
}

.more {
  // display: flex;
  margin-top: 10px;
  align-items: center;
  color: #0d57bc;
  font-size: 14px;
  font-weight: 500;
  // &-icon {
  //   padding-left: 2px;
  // }
  margin-left: 230px;
  margin-bottom: 20px;
}

.index-new-timeLine,
.index-new-timeLine li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.index-new-timeLine {
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 12px;

  li {

    // position: relative;
    // &::before {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   top: 7px;
    //   width: 8px;
    //   height: 8px;
    //   border-radius: 50%;
    //   background: #fff;
    //   border: 2px solid #0d57bc;
    //   z-index: 1;
    // }

    .index-new-timeLine-content {
      position: relative;
      padding-left: 4px;
      padding-bottom: 11px;

      .index-new-link {
        display: block;
        color: #000;
        padding-bottom: 11px;
        border-bottom: 1px solid #dcdcdc;

        // padding-left: 20px;
        div {
          span {
            font-size: 14px;
            line-height: 16px;

            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              color: #bdbdbd;
              padding-left: 10px;
            }
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0px;

      &::after {
        top: 0;
      }

      .index-new-timeLine-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.search-btn-box {
  display: flex;
  //margin-top: 9px;
  /deep/ .el-input__inner {
    height: 35px;
    line-height: 35px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .el-input {
    right: -54px;
  }

  .search-btn {
    width: 90px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #0D57BC;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    position: relative;
    border-radius: 20px;
  }
}

</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
.newUl-areas {
  display: inline-block;
  padding: 4px;
  font-size: 14px;
  line-height: 14px;
  color: #823d00;
  background: rgba(242, 153, 74, .08);
  margin-right: 4px;
}
</style>
