<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" is-content-two="gjhzc" name="国际化政策" :lang="lang"/>
  </PageContainer>
</template>
<script>
import { ttPolicyInternational } from '@/api/policy.js'
import { runLanguageDetection } from '@/api/common'
export default {
  data() {
    return {
      data: {},
      props: {
        htmlContent: 'content',
        summary: 'introduction',
        interpret:'',
      },
      relationPolicy: [],
      relationReport: [],
      relationInterpret: [],
      relationCourse: [],
      tableDataRight: [],
      lang: 'zh'
    }
  },
  watch: {
    $route(to, from) {
      const id = to.params.id
      this.ttPolicy(id)
      // 对路由变化作出响应...
    }
  },
  created() {
    const id = this.$route.params.id
    this.ttPolicy(id)
    this.LanguageDetection(id, '国际化政策')
  },
  methods: {
    ttPolicy(params) {
      ttPolicyInternational(params).then((res) => {
        if (res.ok) {
          this.data = res.data
        }
      })
    },
    LanguageDetection(id, name) {
      const data = {
        id: id,
        name: name
      }
      runLanguageDetection(data).then((res) => {
        if (res.ok) {
          this.lang = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
