<template>
  <div class="nav-box">
    <div class="box max-container">
      <div :class="['main', { variant: !(flag && sycFlag) }]">
        <router-link class="logo" to="/" target="_blank">
          <img v-if="userName!='' && userName=='shfule'" src="@/assets/img/shefule-logo.svg" width="112" class="logo-icon">
          <img v-else src="@/assets/images/logo.png" alt="中国汽车工业信息网">
        </router-link>

        <div class="nav-box-menu">
          <template v-if="flag && sycFlag">
            <a-menu
              v-model="current"
              mode="horizontal"
            >
              <a-menu-item class="link-box">
                <router-link
                  :to="'/'"
                  :class="{ active: '首页' == activeName }"
                  @click.native="hrefClick('/')"
                >首页</router-link>
              </a-menu-item>
              <a-menu-item class="link-box">
                <router-link
                  :to="'/policy/dynamic/sycIndex'"
                  :class="{ active: '商用车政策标准' == activeName }"
                  @click.native="hrefClick('/policy/dynamic/sycIndex')"
                >政策标准</router-link>
              </a-menu-item>

              <!-- <el-submenu index="行业信息" :popper-append-to-body="false">
                <template slot="title">政策标准</template>
                <el-menu-item class="menuStyle fn" index="/policy/dynamic/sycIndex" @click="hrefClick('/policy/dynamic/sycIndex')">政策</el-menu-item>
                <el-menu-item class="menuStyle fn" index="/standards/dynamic/syc/index" @click="hrefClick('/standards/dynamic/syc/index')">标准
                </el-menu-item>
              </el-submenu> -->

              <a-menu-item class="link-box">
                <router-link
                  :to="'/data/data/sycIndex'"
                  :class="{ active: '商用车数据监测' == activeName }"
                  @click.native="hrefClick('/data/data/sycIndex')"
                >数据监测</router-link>
              </a-menu-item>
              <a-menu-item class="link-box">
                <router-link
                  :to="'/thematicAnalysis/syc/index'"
                  :class="{ active: '商用车洞察报告' == activeName }"
                  @click.native="hrefClick('/thematicAnalysis/syc/index')"
                >洞察报告</router-link>
              </a-menu-item>
              <a-menu-item class="link-box">
                <router-link
                  :to="'/workInformation'"
                  :class="{ active: '商用车企业情报' == activeName }"
                  @click.native="hrefClick('/workInformation')"
                >企业情报</router-link>
              </a-menu-item>
            </a-menu>
          </template>

          <a-menu
            v-else
            v-model="current"
            mode="horizontal"
          >
            <template v-for="item in menu">
              <a-menu-item
                v-if="item.meta && item.meta.hidden == 0 && !item.meta.sub"
                :key="item.name"
                :class="['link-box', { unpaid: classNameMatcher(item) }]"
              >
                <router-link
                  :to="item.path"
                  :class="{ active: item.name == activeName }"
                  @click.native="clickLink(item)"
                >{{ item.meta.title || item.name }}</router-link>
                <template v-if="item.name == '企业情报'">
                  <span style="font-size: 12px;" class="new">重磅升级</span>
                  <!--                  <img :src="item.meta.icon" alt="" width="100" height="100" v-if="item.meta.icon && flag == true" style="position:absolute;width: 40px;height: 15px;margin-top: -46px;margin-left: 46px;">-->
                </template>
              </a-menu-item>

              <a-sub-menu
                v-else-if="item.meta && item.meta.hidden == 0 && item.meta.sub"
                :key="item.name"
                :class="['link-box', { unpaid: classNameMatcher(item) }]"
                popup-class-name="sub-menu-container"
              >
                <router-link
                  slot="title"
                  to="/data"
                  :class="[{ active: item.name == activeName }]"
                >{{ item.meta.title || item.name }}<i class="el-icon-arrow-down el-icon--right" />
                </router-link>
                <template v-for="menu in item.children">
                  <a-menu-item
                    v-if="checkUsedCar(menu)"
                    :key="menu.name"
                  >
                    <router-link
                      :to="menu.path"
                      :class="[{ active: menu.name == activeName }]"
                      @click.native="clickLink(menu)"
                    >{{ menu.meta.title || menu.name }}</router-link>
                  </a-menu-item>
                </template>
              </a-sub-menu>
            </template>

<!--            <a-menu-item-->
<!--              v-if="!sycFlag && userNames != 'shfule'"-->
<!--              key="图书馆"-->
<!--              class="link-box unpaid"-->
<!--            >-->
<!--              <a href="http://www.autolib.org.cn" target="_blank">图书馆</a>-->
<!--            </a-menu-item>-->
          </a-menu>
        </div>
      </div>

      <div class="extra">
        <el-input
          v-model="searchValue"
          class="search-input"
          placeholder="搜索全网内容"
          @keyup.enter.native="searchBtn"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            @click="searchBtn"
          />
        </el-input>

        <el-dropdown v-if="show" @command="handleCommand">
          <div class="user-info">
            <img class="avatar" src="@/assets/img/avatar.png" alt="avatar">
            <span v-if="userName!=null && userName!=''">{{ userName }}</span>
            <span v-else>{{ loginName }}</span>
            <i class="el-icon-arrow-down el-icon--right" />
          </div>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="roleKey == 'paidrole'" command="bindPhone">绑定手机号</el-dropdown-item>
            <el-dropdown-item command="collection">我的收藏</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <template v-else>
          <el-button
            class="try"
            type="primary"
            @click="handleTry()"
          >申请试用</el-button>
          <el-button
            class="login"
            type="primary"
            plain
            @click="handleClick()"
          >登录</el-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Menu } from 'ant-design-vue'
import { getUserInfo, logRecords } from '@/utils/auth.js'

export default {
  components: {
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Menu.SubMenu.name]: Menu.SubMenu,
    [Menu.ItemGroup.name]: Menu.ItemGroup,
    [Menu.Divider.name]: Menu.Divider
  },
  props: {
    menu: [],
    show: {
      type: Boolean,
      default: false
    },
    userName: {
      type: String,
      default: ''
    },
    cc: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      current: [],
      activeIndex: '1',
      searchValue: '',
      jiansuo: false,
      defautImg: require('@/assets/img/new.png'),
      defautImg1: require('@/assets/img/new10.jpg'),
      menuName: '',
      flag: false,
      loginFlag: false,
      sycFlag: false,
      dialogVisible: false,
      iframeSrc: 'https://www.wjx.cn/vm/hyVeQ3K.aspx',
      userNames: '',
      loginName: '',
      roleKey: '',
      qyFlag: true
    }
  },
  computed: {
    activeName() {
      const routeName = this.$route.matched[0].name || ''
      this.$store.dispatch('permission/getRouter', routeName)
      return routeName
    }
  },
  watch: {
    activeName: {
      immediate: true,
      handler(name) {
        let subName
        if (name === '数据-MENU') {
          subName = this.$route.matched[1]?.name
        }
        this.current = subName ? [name, subName] : [name]
      }
    },
    menu: {
      immediate: true,
      handler() {
        if (getUserInfo() && (getUserInfo().roleKey == 'business' || getUserInfo().roleKey == 'market' || getUserInfo().roleKey == 'freeusers') && getUserInfo().isInternational == '0') {
          for (let i = this.menu.length - 1; i >= 0; i--) {
            if (this.menu[i].name === '国际化') {
              this.menu.splice(i, 1) // 删除当前元素
            }
          }
        }
      }
    }
  },
  created() {
    this.menuName = '首页，数据-MENU，数据，预测，专题分析，前沿技术，市场洞察，国际化，政策，标准，公告，企业情报'

    if (getUserInfo()) {
      this.roleKey = getUserInfo().roleKey
      this.loginName = getUserInfo().userName

      if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
        this.userNames = getUserInfo().userName
        this.menuName = getUserInfo().menuName + '，首页'
        if (this.menuName.includes('商用车')) {
          this.sycFlag = true
        }
        // if (!this.menuName.includes('企业情报SY')) {
        //   this.qyFlag = false
        // }
      }
      this.flag = true
    }
  },
  methods: {
    classNameMatcher(item) {
      return !item.meta.paid && item.name !== '首页'
    },
    checkUsedCar(item) {
      return (getUserInfo() && getUserInfo().roleKey != 'paidrole' && getUserInfo().roleKey != 'trialrole') || item.name != '二手车' || (item.name == '二手车' && getUserInfo() && getUserInfo().roleKey == 'paidrole' && getUserInfo().menuName.includes('二手车'))
    },
    activedMenu() {
      const name = this.$route.name
      const path = this.$route.path
      if (name.includes('政策')) {
        return '/policy/dynamic/sycIndex'
      } else if (name.includes('标准')) {
        return '/policy/dynamic/sycIndex'
      } else if (path.includes('/data') || path.includes('/sale')) {
        return '/data/data/sycIndex'
      } else if (path.includes('/workInformation/syc')) {
        return '/workInformation/syc/analysis/index'
      } else {
        return path
      }
    },

    // 提交
    hrefClick(href) {
      this.$router.push(href)
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClick() {
      this.$emit('click')
    },
    handleCommand(command) {
      this.$emit('command', command)
    },
    searchBtn() {
      if (this.searchValue == '') {
        this.$notify({
          title: '提示',
          message: '请输入搜索内容',
          type: 'warning',
          duration: 1800,
          offset: 23
        })
        return false
      }
      // if (getUserInfo()) {
      //   logRecords('全文检索', '全文检索', '3', '', '', '', '', this.searchValue, '查询')
      //   // if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
      //   // }
      // }
      this.$emit('searchs', this.searchValue)
      this.searchValue = ''
    },
    open() {
      this.$alert('<div style="font-size: 17px;margin-top: -15px;line-height: 28px;"><span style="font-weight: bold">温馨提示:</span></br>本栏目<span style="font-weight: bold">限时免费已经关闭</span>，可申请免费试用/正式订阅</div></br><div style="padding-left: 9px;padding-top: 4px;">电话：022-84379145/18846828756</div></br><div style="padding-left: 9px;">邮箱：liwenjuan@catarc.ac.cn</div>', {
        confirmButtonText: '知道了',
        showClose: false,
        dangerouslyUseHTMLString: true
      })
    },

    clickLink(item) {
      // if (getUserInfo()) {
      //   if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
      //     // getCurrentUser();
      //     // console.log(this.loginFlag)
      //     // this.loginFlag = getUserInfo().loginFlag;
      //     // console.log(this.loginFlag)
      //     // if (this.loginFlag && (item.name.includes("二手车") || item.name.includes("市场洞察"))) {
      //     //   this.open();
      //     // }
      //     // console.log(item)
      //   }
      // }
      // console.log(item)
      this.$emit('clickLink', item)
    },

    handleTry() {
      window.open(`${this.rootURL}/sqSy`, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";

.nav-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: $header-zindex;
  height: $header-height;
  color: #ededed;
  background-color: #1e1e7c;

  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .main {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 0;
      height: 100%;

      .logo {
        display: inline-flex;
        margin-right: 52px;
        height: 40px;
        img {
          height: 100%;
        }
      }

      ::v-deep .nav-box-menu {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        min-width: 0;
        height: 100%;
        white-space: nowrap;

        .ant-menu {
          width: 100%;
          color: #fff;
          background-color: transparent;
          border: none;
          > .ant-menu-item,
          > .ant-menu-submenu {
            border: none;
          }
          .ant-menu-item,
          .ant-menu-submenu-title {
            padding-left: 19px;
            padding-right: 19px;
          }
        }
        .link-box,
        .ant-menu-item,
        .ant-menu-submenu,
        .ant-menu-submenu-title {
          >a, >span {
            color: #ededed;
            font-size: 16px;
            &.active {
              color: #409EFF;
              font-weight: bold;
            }
          }

          .el-icon-arrow-down {
            margin-left: 14px;
          }
        }

        .link-box.unpaid {
          opacity: 0.68;
        }
      }

      &.variant {
        .logo {
          margin-right: 32px;
        }

        ::v-deep .nav-box-menu {
          .ant-menu {
            .ant-menu-item,
            .ant-menu-submenu-title {
              padding-left: 9px;
              padding-right: 9px;
            }
          }
          .link-box,
          .ant-menu-item,
          .ant-menu-submenu,
          .ant-menu-submenu-title {
            >a, >span {
              font-size: 12px;
            }

            .el-icon-arrow-down {
              margin-left: 8px;
            }
          }
        }

        @media screen and (min-width: 1270px) {
          ::v-deep .nav-box-menu {
            .link-box,
            .ant-menu-item,
            .ant-menu-submenu,
            .ant-menu-submenu-title {
              >a, >span {
                font-size: 13px;
              }
            }
          }
        }
        @media screen and (min-width: 1280px) {
          .logo {
            margin-right: 24px;
          }

          ::v-deep .nav-box-menu {
            .ant-menu {
              .ant-menu-item,
              .ant-menu-submenu-title {
                padding-left: 10px;
                padding-right: 10px;
              }
            }
          }
        }
        @media screen and (min-width: 1300px) {
          .logo {
            margin-right: 28px;
          }
        }
        @media screen and (min-width: 1330px) {
          ::v-deep .nav-box-menu {
            .link-box,
            .ant-menu-item,
            .ant-menu-submenu,
            .ant-menu-submenu-title {
              >a, >span {
                font-size: 14px;
              }
            }
          }
        }
        @media screen and (min-width: 1360px) {
          ::v-deep .nav-box-menu {
            .link-box,
            .ant-menu-item,
            .ant-menu-submenu,
            .ant-menu-submenu-title {
              >a, >span {
                font-size: 15px;
              }
            }
          }
        }
        @media screen and (min-width: 1380px) {
          ::v-deep .nav-box-menu {
            .link-box,
            .ant-menu-item,
            .ant-menu-submenu,
            .ant-menu-submenu-title {
              >a, >span {
                font-size: 16px;
              }
            }
          }
        }
        @media screen and (min-width: 1410px) {
          ::v-deep .nav-box-menu {
            .ant-menu {
              .ant-menu-item,
              .ant-menu-submenu-title {
                padding-left: 11px;
                padding-right: 11px;
              }
            }
          }
        }
        @media screen and (min-width: 1430px) {
          ::v-deep .nav-box-menu {
            .ant-menu {
              .ant-menu-item,
              .ant-menu-submenu-title {
                padding-left: 12px;
                padding-right: 12px;
              }
            }
          }
        }
        @media screen and (min-width: 1450px) {
          ::v-deep .nav-box-menu {
            .ant-menu {
              .ant-menu-item,
              .ant-menu-submenu-title {
                padding-left: 13px;
                padding-right: 13px;
              }
            }
          }
        }
        @media screen and (min-width: 1470px) {
          ::v-deep .nav-box-menu {
            .ant-menu {
              .ant-menu-item,
              .ant-menu-submenu-title {
                padding-left: 14px;
                padding-right: 14px;
              }
            }
          }
        }
        @media screen and (min-width: 1490px) {
          ::v-deep .nav-box-menu {
            .ant-menu {
              .ant-menu-item,
              .ant-menu-submenu-title {
                padding-left: 15px;
                padding-right: 15px;
              }
            }
          }
        }
        @media screen and (min-width: 1516px) {
          ::v-deep .nav-box-menu {
            .ant-menu {
              .ant-menu-item,
              .ant-menu-submenu-title {
                padding-left: 16px;
                padding-right: 16px;
              }
            }
          }
        }
        @media screen and (min-width: 1540px) {
          ::v-deep .nav-box-menu {
            .ant-menu {
              .ant-menu-item,
              .ant-menu-submenu-title {
                padding-left: 17px;
                padding-right: 17px;
              }
            }
          }
        }
        @media screen and (min-width: 1560px) {
          ::v-deep .nav-box-menu {
            .ant-menu {
              .ant-menu-item,
              .ant-menu-submenu-title {
                padding-left: 18px;
                padding-right: 18px;
              }
            }
            .link-box,
            .ant-menu-item,
            .ant-menu-submenu,
            .ant-menu-submenu-title {
              .el-icon-arrow-down {
                margin-left: 10px;
              }
            }
          }
        }
        @media screen and (min-width: 1580px) {
          .logo {
            margin-right: 38px;
          }
          ::v-deep .nav-box-menu {
            .link-box,
            .ant-menu-item,
            .ant-menu-submenu,
            .ant-menu-submenu-title {
              .el-icon-arrow-down {
                margin-left: 12px;
              }
            }
          }
        }
        @media screen and (min-width: 1590px) {
          .logo {
            margin-right: 48px;
          }
          ::v-deep .nav-box-menu {
            .link-box,
            .ant-menu-item,
            .ant-menu-submenu,
            .ant-menu-submenu-title {
              .el-icon-arrow-down {
                margin-left: 14px;
              }
            }
          }
        }
        @media screen and (min-width: 1600px) {
          .logo {
            margin-right: 52px;
          }
        }
      }
    }
  }
}
.fixed-top-nav {
  .nav-box {
    top: $top-nav-height;
  }
}

.fixed-top-Banna {
  .nav-box {
    top: $top-nav-height + $top-banna-height;
  }
}

.extra {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ::v-deep .search-input {
    margin-right: 20px;
    width: auto;
    .el-input__inner {
      padding-left: 0;
      min-width: 200px;
      color: #fff;
      background-color: transparent;
      border-color: transparent;
      border-bottom-color: #8888b1;
      border-radius: 0;
      &::placeholder {
        color: #8888b1;
      }
    }
    .el-input__suffix {
      font-size: 18px;
      color: #9a9ac4;
      cursor: pointer;
    }
  }

  ::v-deep .el-button {
    padding-left: 6px;
    padding-right: 6px;
    min-width: 90px;
    height: 38px;
    + .el-button {
      margin-left: 20px;
    }
    &.try {
      background-color: #0069ea;
      border-color: #0069ea;
    }
    &.login {
      color: rgba(255, 255, 255, 0.6);
      border-color: currentColor;
    }
  }

  .user-info {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: #fff;
    .avatar {
      margin-right: 8px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
}
.new {
  position: absolute;
  line-height: 18px;
  top: -4px;
  right: -10px;
  padding: 0 5px;
  font-size: 12px;
  background: #ff6e4b;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>

<style lang="scss">
.sub-menu-container {
  .el-dropdown-menu__item {
    display: block;
    &.router-link-active {
      color: #3f76ea;
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu {
  > .ant-menu-item:hover,
  .ant-menu-submenu:hover,
  .ant-menu-item-active,
  .ant-menu-submenu-active,
  .ant-menu-item-open,
  .ant-menu-submenu-open,
  .ant-menu-item-selected,
  .ant-menu-submenu-selected,
  .ant-menu-item > a:hover,
  .ant-menu-item-selected > a,
  .ant-menu-item-selected > a:hover {
    color: #3f76ea;
  }
}
</style>
