<!--
 * @Author: your name
 * @Date: 2021-07-21 13:10:05
 * @LastEditTime: 2021-07-21 14:57:55
 * @LastEditors: Please set LastEditors
 * @Description: 批发数据
 * @FilePath: /information-vue/code/src/views/data/engineData/indexBalance.vue
-->

<template>
  <div class="outer-layer-box">
    <!--    <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据"-->
    <!--               :showTips="showTips"/>-->
    <template>
      <Vtitle url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png" flex="sc">
        <div class="data-top-title">
          <div class="data-top-title-inner">
            <span class="text">国内产销批发量</span>
            <span class="time">已更新至{{ updataDate }}</span>
          </div>
          <div>
            <!-- <button class="title-down-btn" @click="historyOpen" style="margin-right:5px">历史数据</button> -->
            <!-- <button class="title-down-btn" v-has-role="'trialrole'" @click="dialogVisible = true"><i
                    class="el-icon-download"></i>下载电子版本</button> -->
          </div>
        </div>
      </Vtitle>
      <el-form :model="form" ref="form" inline label-width="114px" class="n-data-content">
        <h1 class="n-title">显示字段与筛选设置</h1>
        <div style="font-weight: bold;padding-left: 16px">基本设置</div>
        <el-row>
          <el-col>
            <el-form-item label="时间字段">
              <el-radio-group v-model="radio" size="small" style="margin-top: -3px;" @change="radioChange">
                <el-radio-button label="年"></el-radio-button>
                <el-radio-button label="月"></el-radio-button>
              </el-radio-group>
              <VdateEndStart ref="VdateEndStart" v-model="times" :isTimeSwitch="false"
                             @change="timeChange" :checkDate="checkDate" :close="false"
                             style="width:180px;display: inline;margin-left: 20px"/>
            </el-form-item>

          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="显示字段">
<!--              <el-checkbox v-model="form.cllx" @change="changeFun('cllxValue', 'cllx')">车辆类型</el-checkbox>-->
              <el-checkbox v-model="form.xflb" @change="changeFun('xfLbValue', 'xflb')">细分类别</el-checkbox>
              <el-checkbox v-model="form.qymc" @change="changeFun('qymcValue', 'qymc')">企业名称</el-checkbox>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="筛选字段">
              <div style="margin-top: -1px;">
<!--                <el-form-item label="车辆类型：" prop="cllxValue" label-width="82px">-->
<!--                  <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.cllx">-->
<!--                    <el-select v-model="form.cllxValue" placeholder="请选择" size="small"-->
<!--                               @change="selectChangeCllx" :disabled="!form.cllx" class="new-width">-->
<!--                      <el-option v-for="item in bj1" :key="item.value" :label="item.name" :value="item.value">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
<!--                  </el-tooltip>-->
<!--                </el-form-item>-->

                <el-form-item label="细分类别：" prop="xfLbValue" label-width="82px">
                  <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.xflb">
                    <el-select v-model="form.xfLbValue" placeholder="请选择" size="small" collapse-tags
                               @change="selectChangeXflb" :disabled="!form.xflb" class="new-width">
                      <el-option v-for="item in xflbData" :key="item.value" :label="item.name"
                                 :value="item.value">
                      </el-option>
                    </el-select>
                  </el-tooltip>
                </el-form-item>

                <el-form-item label="企业名称：" class="no-position" prop="qymcValue" label-width="500px">
                  <el-tooltip content="请在显示字段先勾选该字段" placement="top" :disabled="form.qymc">

                    <selectCheck v-model="form.qymcValue" :datas="qymcData" :disabled="!form.qymc"
                                 :props="props" style="margin-top: 4px;"></selectCheck>
                  </el-tooltip>

                </el-form-item>
              </div>

            </el-form-item>
          </el-col>
        </el-row>
        <div style="font-weight: bold;padding-left: 16px;padding-bottom: 10px">更多字段和筛选<span
            style="color: red;margin-left: 10px">注意 该部分字段内容依赖基本设置，请先确定基本设置内容</span>
          <el-tooltip placement="top">
            <div slot="content">1、查看质量分类，需要选中细分类别中和商用车有关的内容<br/>2、查看细分维度需要选中细分类别，且不可筛选非完整和半挂牵引车<br/>3、燃料类型、组装地、细分维度不可以和企业名称同时使用<br/>4、细分维度不可以和质量分类同时使用<br/>5、查看组装地，和其他字段一起需要选中车辆类型/细分类别中和乘用车相关的内容；也可以取消其他字段，再在更多里单独查看组装地字段；
            </div>
            <a style="color: #0D57BC;margin-left: 10px">详细规则</a>
          </el-tooltip>
        </div>
        <el-row style="margin:0px 0 24px;">
          <el-col class="n-check-m">

            <el-checkbox v-model="form.rllb" @change="changeFun('rllbValue', 'rllb')" v-if="form.rllbShow">燃料类型
            </el-checkbox>


            <el-checkbox v-model="form.zhiLiangLeiBie"
                         @change="changeFun('zhiLiangLeiBieValue', 'zhiLiangLeiBie')" v-if="form.zhiLiangLeiBieShow">
              质量分类
            </el-checkbox>

            <el-checkbox v-model="form.zuZhuangDi" @change="changeFun('zuZhuangDiValue', 'zuZhuangDi')"
                         v-if="form.zuZhuangDiShow">组装地
            </el-checkbox>
            <el-checkbox v-model="xfwd" @change="changeFun('xfwdValue', 'xfwd')" v-if="xfwdShow">细分维度</el-checkbox>
          </el-col>
        </el-row>
        <el-row style="margin-left:14px">


          <el-col :span="8" v-if="form.rllbShow">
            <el-form-item label="燃料类型：">

              <el-tooltip content="请在上方先勾选该字段" placement="top" :disabled="form.rllb">
                <el-select v-model="form.rllbValue" placeholder="请选择" size="small" multiple collapse-tags
                           :disabled="!form.rllb" clearable class="new-width">
                  <el-option v-for="(item, index) in rllxData" :key="index" :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>

          <el-col :span="8" v-if="form.zhiLiangLeiBieShow">
            <el-form-item label="质量分类：">
              <el-tooltip content="请在上方先勾选该字段" placement="top" :disabled="form.zhiLiangLeiBie">

                <el-select v-model="form.zhiLiangLeiBieValue" placeholder="请选择" size="small" multiple
                           collapse-tags :disabled="!form.zhiLiangLeiBie" class="new-width">
                  <el-option v-for="item in zhiLiangLeiBieData" :key="item.value" :label="item.name"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.zuZhuangDiShow">
            <el-form-item label="组装地：">
              <!-- <el-select v-model="form.zuZhuangDiValue" placeholder="请选择" size="small" multiple collapse-tags :disabled="!form.zuZhuangDi || form.qymc">
                              <el-option v-for="(item,index) in zuZhuangDiData" :key="index" :label="item.label" :value="item.value"></el-option>
                          </el-select> -->
              <el-tooltip content="请在上方先勾选该字段" placement="top" :disabled="form.zuZhuangDi">

                <el-select v-model="form.zuZhuangDiValue" placeholder="请选择" size="small" multiple
                           collapse-tags :disabled="!form.zuZhuangDi" class="new-width">
                  <el-option v-for="(item, index) in zuZhuangDiData" :key="index" :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="xfwdShow">
            <el-form-item label="细分维度：">
              <el-tooltip content="请在上方先勾选该字段" placement="top" :disabled="xfwd">
                <el-select v-model="xfwdValue" placeholder="请选择" size="small" :disabled="!xfwd"
                           @change="xfwdSelect" clearable class="new-width">
                  <el-option v-for="(item, index) in xfwdData" :key="index" :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.paiLiangDuan">
            <el-form-item label="排量：">
              <el-select v-model="form.paiLiangDuanValue" placeholder="请选择" size="small" multiple
                         collapse-tags>
                <el-option v-for="(item, index) in pailiangData" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="档别：" v-if="form.dangBie">
              <el-select v-model="form.dangBieValue" placeholder="请选择" size="small" multiple
                         collapse-tags>
                <el-option v-for="(item, index) in dangbieData" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.cheMen">
            <el-form-item label="车门：">
              <el-select v-model="form.cheMenValue" placeholder="请选择" size="small" multiple collapse-tags>
                <el-option v-for="(item, index) in chemenData" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.dunWei && xfwdValue">
            <el-form-item label="吨位：">
              <el-select v-model="form.dunWeiValue" placeholder="请选择" size="small" multiple collapse-tags>
                <el-option v-for="(item, index) in dunweiData" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.huoCheYongTu">
            <el-form-item label="货车用途：">
              <el-select v-model="form.huoCheYongTuValue" placeholder="请选择" size="small" multiple
                         collapse-tags>
                <el-option v-for="(item, index) in yongtuHcData" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8" v-if="form.keCheYongTu">
            <el-form-item label="客车用途：">
              <el-select v-model="form.keCheYongTuValue" placeholder="请选择" size="small" multiple
                         collapse-tags>
                <el-option v-for="(item, index) in yongtuKcData" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="车长：" v-if="form.cheChang">
              <el-select v-model="form.cheChangValue" placeholder="请选择" size="small" multiple
                         collapse-tags>
                <el-option v-for="(item, index) in chechangData" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="n-data-line"></div>
        <h1 class="n-title">统计结果</h1>
        <el-row>
          <el-col class="n-check-m">
            <el-checkbox v-model="form.wcShow">生产量</el-checkbox>
            <el-checkbox v-model="form.xsShow">销售量</el-checkbox>
            <el-checkbox v-model="form.tjShow">统计</el-checkbox>
            <!-- <el-checkbox v-model="form.bqzlj1Show">生产本期止累计</el-checkbox>
            <el-checkbox v-model="form.bqzlj2Show">销售本期止累计</el-checkbox>
            <el-checkbox v-model="form.tqzlj1Show">生产同期止累计</el-checkbox>
            <el-checkbox v-model="form.tqzlj2Show">销售同期止累计</el-checkbox> -->
          </el-col>
        </el-row>
        <div class="text-center" style="margin-top: 28px;">
          <el-button @click="rest">重置</el-button>
          <el-button type="primary" @click="search">查询</el-button>
        </div>
      </el-form>
      <!----分割线--->
      <div style="margin-top:10px">
        <div class="search-form">
          <div flex="sb" style="margin-bottom: 16px">
            <span class="table_title"></span>
            <div>
              <el-button type="primary" plain @click="Export"  v-has-role="'freeusers'"
                         :loading="excelLoading">导出
              </el-button>
            </div>
          </div>
          <div >
            <el-table :span-method="arraySpanMethod" v-loading="loading" width="100%" :data="tableData"
                      style="width: 100%; margin-bottom: 20px" stripe
                      :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
              <el-table-column v-for="item in tableHeader" :label="item.label" :prop="item.prop"
                               :key="item.prop"></el-table-column>
              <template v-for="item in timeList">
                <el-table-column :label="item" :key="item" align="center">
                  <el-table-column label="生产" v-if="sclShow" align="center">
                    <el-table-column :label="monthShow ? '本月完成' : '本年完成'" :prop="item + '-wc'"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-wc']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="本期止累计" :prop="item + '-bqzlj1'" v-if="monthShow && tjlShow"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-bqzlj1']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="同期止累计" :prop="item + '-tqzlj1'" v-if="monthShow && tjlShow"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-tqzlj1']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比上月增长" :prop="item + '-schb'" v-if="monthShow && tjlShow"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-schb']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比同期增长" :prop="item + '-sctb'" v-if="tjlShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-sctb']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比同期累计增长" :prop="item + '-scbtqljzz'"
                                     v-if="monthShow && tjlShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-scbtqljzz']) }}
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="销售" v-if="xslShow" align="center">
                    <el-table-column :label="monthShow ? '本月完成' : '本年完成'" :prop="item + '-xs'"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-xs']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="本期止累计" :prop="item + '-bqzlj2'" v-if="monthShow && tjlShow"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-bqzlj2']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="同期止累计" :prop="item + '-tqzlj2'" v-if="monthShow && tjlShow"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toLocaleString(row[item + '-tqzlj2']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比上月增长" :prop="item + '-xshb'" v-if="monthShow && tjlShow"
                                     align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-xshb']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比同期增长" :prop="item + '-xstb'" v-if="tjlShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-xstb']) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="比同期累计增长" :prop="item + '-xsbtqljzz'"
                                     v-if="monthShow && tjlShow" align="center">
                      <template slot-scope="{ row }">
                        {{ toFixed(row[item + '-xsbtqljzz']) }}
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </template>
              <!-- <template v-for="nitem in tableHeaderOther">
                  <el-table-column label="本月完成（生产）" :key="nitem.prop" v-if="nitem.prop == 'wc'">
                      <el-table-column v-for="(item , index) in tableHeaderTime.wc" :key="'wc-'+index" :prop="item.wc" :label="item.year1">
                      </el-table-column>
                  </el-table-column>
              </template>
              <template v-for="nitem in tableHeaderOther">
                  <el-table-column label="本期止累计（生产）" :key="nitem.prop" v-if="nitem.prop == 'bqzlj1'">
                      <el-table-column v-for="(item , index) in tableHeaderTime.bqzlj1" :key="'bqzlj1-'+index" :prop="item.bqzlj1" :label="item.year1">
                      </el-table-column>
                  </el-table-column>
              </template>
              <template v-for="nitem in tableHeaderOther">
                  <el-table-column label="同期止累计（生产）" :key="nitem.prop" v-if="nitem.prop == 'tqzlj1'">
                      <el-table-column v-for="(item , index) in tableHeaderTime.tqzlj1" :key="'tqzlj1-'+index" :prop="item.tqzlj1" :label="item.year1">
                      </el-table-column>
                  </el-table-column>
              </template>
              <template v-for="nitem in tableHeaderOther">
                  <el-table-column label="比上月增长（生产）" :key="nitem.prop" v-if="nitem.prop == 'schb'">
                      <el-table-column v-for="(item , index) in tableHeaderTime.schb" :key="'schb-'+index" :prop="item.schb" :label="item.year1">
                      </el-table-column>
                  </el-table-column>
              </template>
              <template v-for="nitem in tableHeaderOther">
                  <el-table-column label="比同期增长（生产）" :key="nitem.prop" v-if="nitem.prop == 'sctb'">
                      <el-table-column v-for="(item , index) in tableHeaderTime.sctb" :key="'sctb-'+index" :prop="item.sctb" :label="item.year1">
                      </el-table-column>
                  </el-table-column>
              </template> -->
              <!-- <el-table-column label="生产量合计" key="schj" prop="scTotal" v-if="showTotal&&wcShowFlag"></el-table-column> -->
              <!-- <template v-for="nitem in tableHeaderOther">
                  <el-table-column label="本月完成（销售）" :key="nitem.prop" v-if="nitem.prop == 'xs'">
                      <el-table-column v-for="(item , index) in tableHeaderTime.xs" :key="'xs-'+index" :prop="item.xs" :label="item.year1">
                      </el-table-column>
                  </el-table-column>
              </template>
                <template v-for="nitem in tableHeaderOther">
                  <el-table-column label="本期止累计（销售）" :key="nitem.prop" v-if="nitem.prop == 'bqzlj2'">
                      <el-table-column v-for="(item , index) in tableHeaderTime.bqzlj2" :key="'bqzlj2-'+index" :prop="item.bqzlj2" :label="item.year1">
                      </el-table-column>
                  </el-table-column>
              </template>
              <template v-for="nitem in tableHeaderOther">
                  <el-table-column label="同期止累计（销售）" :key="nitem.prop" v-if="nitem.prop == 'tqzlj2'">
                      <el-table-column v-for="(item , index) in tableHeaderTime.tqzlj2" :key="'tqzlj2-'+index" :prop="item.tqzlj2" :label="item.year1">
                      </el-table-column>
                  </el-table-column>
              </template>
               <template v-for="nitem in tableHeaderOther">
                  <el-table-column label="比上月增长（销售）" :key="nitem.prop" v-if="nitem.prop == 'xshb'">
                      <el-table-column v-for="(item , index) in tableHeaderTime.xshb" :key="'xshb-'+index" :prop="item.xshb" :label="item.year1">
                      </el-table-column>
                  </el-table-column>
              </template>
              <template v-for="nitem in tableHeaderOther">
                  <el-table-column label="比同期增长（销售）" :key="nitem.prop" v-if="nitem.prop == 'xstb'">
                      <el-table-column v-for="(item , index) in tableHeaderTime.xstb" :key="'xstb-'+index" :prop="item.xstb" :label="item.year1">
                      </el-table-column>
                  </el-table-column>
              </template> -->
              <!-- <el-table-column label="销售量合计" key="xshj" prop="xsTotal" v-if="showTotal&&xsShowFlag"></el-table-column> -->
              <!-- <el-table-column prop="total" label="合计">
              </el-table-column> -->
            </el-table>
            <div>单位：辆，%</div>
            <div class="text-right">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                             :current-page="pageData.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pageSize"
                             layout="total, sizes, prev, pager, next, jumper" :total="pageData.count">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </template>

    <el-dialog title="国内产销数据电子版下载" :visible.sync="dialogVisible" width="888px">
      <!-- <el-button :disabled="uploadData.length<=0" @click="downAll">批量下载</el-button> -->
      <div style="height:338px;overflow-x:auto">
        <!-- <el-table :data="uploadList" class="rest_table" @select="handleSelectionChange" @select-all="handleSelectionAllChange"> -->
        <el-table :data="uploadList" class="rest_table">
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column property="attachName" label="名称"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button size="small" type="primary" icon="el-icon-download"
                         @click="handleDown(scope.$index, scope.row)">下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false" type="primary" plain style="width:204px">关 闭</el-button>
      </div>
    </el-dialog>

    <!--        <el-dialog title="历史数据" :visible.sync="historyVisible" width="1200px">-->
    <!--            <span>时间：</span>-->
    <!--            <el-select v-model="hisForm.nian" placeholder="请选择" size="small">-->
    <!--                <el-option v-for="item in historyYear" :key="item.nian" :label="item.nian" :value="item.nian">-->
    <!--                </el-option>-->
    <!--            </el-select>-->
    <!--            <el-select v-model="hisForm.yue" placeholder="请选择" size="small" style="margin-left:5px">-->
    <!--                <el-option v-for="item in historyYue" :key="item" :label="item" :value="item"> </el-option>-->
    <!--            </el-select>-->
    <!--            <el-button size="small" type="primary" style="margin-left:5px" @click="hisSearch">查询</el-button>-->
    <!--            <div style="height:338px;padding-top:10px;overflow-x:auto">-->
    <!--                <table border="1">-->
    <!--                    <tr v-for="(td,index) in historyList" :key="index">-->
    <!--                        <td v-for="item in td" :key="item.id" style="padding:5px">-->
    <!--                            <a href="javascript:;" @click="openIframe(item)">{{item.title}}</a>-->
    <!--                        </td>-->
    <!--                    </tr>-->
    <!--                </table>-->
    <!--                &lt;!&ndash; <div class="text-right">-->
    <!--                    <el-pagination @size-change="hishandleSizeChange" @current-change="hishandleCurrentChange" :current-page="hisForm.page" :page-sizes="[12, 24, 36, 48]" :page-size="hisForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="hisForm.count">-->
    <!--                    </el-pagination>-->
    <!--                </div> &ndash;&gt;-->
    <!--            </div>-->
    <!--            <div slot="footer" class="dialog-footer text-center">-->
    <!--                <el-button @click="historyVisible = false" type="primary" plain style="width:204px">关 闭</el-button>-->
    <!--            </div>-->
    <!--        </el-dialog>-->
    <el-dialog :visible.sync="iframeVisible" width="1200px">
      <div style="height:500px;">
        <iframe :src="iframeSrc" width="100%" height="100%"></iframe>
      </div>
      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="iframeVisible = false" type="primary" plain style="width:204px">关 闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
/**
 * @description: 批发数据
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import Vtitle from "../components/title.vue";
import {
  getMonthBetween,
  getYearArr
} from "@/utils/date.js";
import {
  YkbQcCxDataPage,
  lastTime,
  YkbQcCxDataEnterpriseList,
  YkbQcCxDataTimeLis,
  ttDataUpload,
  YkbQcCxDataDynamicExport,
  getYearListHistory,
  getHistoryPage,
} from "@/api/data.js";
import VdateEndStart from "@/components/dateEndStartTwo";
import {
  getUserInfo, openSy
} from "@/utils/auth.js";
import {
  buryPoint,
  download,
  newdownload
} from "@/api/common.js";
import {
  downloadFile
} from "@/utils/download.js";
import selectCheck from "@/components/selectCheck";

export default {
  components: {
    // VnotLogin,
    VdateEndStart,
    selectCheck,
    Vtitle,
  },
  data() {
    return {
      lastNewTime: [],
      show: false,
      radio: '月',
      showTips: [{
        title: "数据动态",
        text: "最新权威动向和独家原创解读",
      },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      tableData: [],
      //车辆类型
      bj1: [{
        value: "",
        name: "请选择"
      },
        {
          value: "2",
          name: "商用车",
          children: [{
            value: "",
            name: "请选择",
          },
            {
              value: "7",
              name: "货车",
              children: [{
                value: "8",
                name: "重型载货车",
              },
                {
                  value: "9",
                  name: "中型载货车",
                },
                {
                  value: "10",
                  name: "轻型载货车",
                },
                {
                  value: "11",
                  name: "微型载货车",
                },
              ],
            },
            {
              value: "12",
              name: "客车",
              children: [{
                value: "13",
                name: "大型客车",
              },
                {
                  value: "14",
                  name: "中型客车",
                },
                {
                  value: "15",
                  name: "轻型客车",
                },
              ],
            },
            {
              value: "16",
              name: "半挂牵引车",
              children: [{
                value: "26",
                name: "25吨<准拖挂车总质量≤40吨",
              },
                {
                  value: "27",
                  name: "40吨<准拖挂车总质量",
                },
                {
                  value: "28",
                  name: "准拖挂车总质量≤25吨",
                },
              ],
            },
            {
              value: "17",
              name: "非完整型货车",
              children: [{
                value: "19",
                name: "重型非完整型货车",
              },
                {
                  value: "20",
                  name: "中型非完整型货车",
                },
                {
                  value: "21",
                  name: "轻型非完整型货车",
                },
                {
                  value: "22",
                  name: "微型非完整型货车",
                },
              ],
            },
            {
              value: "18",
              name: "非完整型客车",
              children: [{
                value: "23",
                name: "重型非完整型客车",
              },
                {
                  value: "24",
                  name: "中型非完整型客车",
                },
                {
                  value: "25",
                  name: "轻型非完整型客车",
                },
              ],
            },
          ],
        },
      ],
      rllxData: [{
        value: "1",
        label: "柴油汽车",
      },
        {
          value: "2",
          label: "汽油汽车",
        },
        // {
        //     value: "3",
        //     label: "其他燃料汽车",
        // },
        {
          value: "4",
          label: "普通混合动力",
        },
        {
          value: "5",
          label: "插电式混合动力",
        },
        {
          value: "6",
          label: "纯电动",
        },
        {
          value: "7",
          label: "燃料电池",
        },
        {
          value: "8",
          label: "天然气",
        },
        {
          value: "9",
          label: "其他替代燃料",
        },
      ],
      loading: false,
      xflbData: [],

      zhiLiangLeiBieData: [],
      form: {
        nian: false,
        timeBegin: "",
        timeEnd: "",
        qymc: false,
        qymcValue: [],
        wcShow: true,
        xsShow: true,
        tjShow: true,
        bqzlj1Show: true,
        bqzlj2Show: true,
        tqzlj1Show: true,
        tqzlj2Show: true,

        cllx: true,
        cllxValue: "2",
        cheMenValue: [],
        zhiLiangLeiBie: false,
        zhiLiangLeiBieShow: false,
        zhiLiangLeiBieValue: [],
        xflb: true,
        xfLbValue: "",
        rllb: false,
        rllbShow: false,
        rllbValue: [],
        zuZhuangDi: false,
        zuZhuangDiShow: false,
        zuZhuangDiValue: [],
        // dangBie: false,
        // dangBieValue: [],

        cheMen: false,
        // cheMenValue: [],
        paiLiangDuan: false,
        paiLiangDuanValue: [],
        // cheMen: false,
        // cheMenValue: [],
        dangBie: false,
        dangBieValue: [],
        dunWei: false,
        dunWeiValue: [],
        huoCheYongTu: false,
        huoCheYongTuValue: [],
        keCheYongTu: false,
        keCheYongTuValue: [],
        cheChang: false,
        cheChangValue: [],
      },
      pageData: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      xfwdValue: "",
      xfwdData: [],
      xfwd: false,
      xfwdShow: false,
      zuZhuangDiData: [{
        value: "1",
        label: "国内制造",
      },
        {
          value: "2",
          label: "CKD",
        },
      ],
      nian: false,
      year: true,
      month: true,
      times: [],
      checkDate: "month",
      qymcData: [],
      yongtuData: [],
      yongtuValue: [],
      pailiangData: [{
        value: "1",
        label: "排量≤1升"
      },
        {
          value: "2",
          label: "1升<排量≤1.6升"
        },
        {
          value: "3",
          label: "1.6升<排量≤2.0升"
        },
        {
          value: "4",
          label: "2.0升<排量≤2.5升"
        },
        {
          value: "5",
          label: "2.5升<排量≤3.0升"
        },
        {
          value: "6",
          label: "3.0升<排量≤4.0升"
        },
        {
          value: "7",
          label: "4.0升<排量"
        },
      ],
      dangbieData: [{
        value: "1",
        label: "手动挡"
      },
        {
          value: "2",
          label: "自动挡"
        },
        {
          value: "3",
          label: "其他档"
        },
      ],
      chemenData: [{
        value: "1",
        label: "三厢四门"
      },
        {
          value: "2",
          label: "二厢四门"
        },
        {
          value: "3",
          label: "三厢二门"
        },
        {
          value: "4",
          label: "二厢二门"
        },
        {
          value: "5",
          label: "其他"
        },
      ],
      dunweiData: [{
        value: "1",
        label: "总质量≤1.8吨"
      },
        {
          value: "2",
          label: "1.8吨<总质量≤3.5吨"
        },
        {
          value: "3",
          label: "3.5吨<总质量≤4.5吨"
        },
        {
          value: "4",
          label: "4.5吨<总质量≤6吨"
        },
        {
          value: "5",
          label: "6吨<总质量≤8吨"
        },
        {
          value: "6",
          label: "8吨<总质量≤10吨"
        },
        {
          value: "7",
          label: "10吨<总质量≤12吨"
        },
        {
          value: "8",
          label: "12吨<总质量≤14吨"
        },
        {
          value: "9",
          label: "14吨<总质量≤19吨"
        },
        {
          value: "10",
          label: "19吨<总质量≤26吨"
        },
        {
          value: "11",
          label: "26吨<总质量≤32吨"
        },
        {
          value: "12",
          label: "32吨<总质量"
        },
      ],
      yongtuKcData: [{
        value: "1",
        label: "城市客车"
      },
        {
          value: "2",
          label: "长途客车"
        },
        {
          value: "3",
          label: "旅游客车"
        },
        {
          value: "4",
          label: "铰接客车"
        },
        {
          value: "5",
          label: "无轨客车"
        },
        {
          value: "6",
          label: "越野客车"
        },
        {
          value: "7",
          label: "专用客车"
        },
        {
          value: "8",
          label: "其他客车"
        },
        {
          value: "9",
          label: "校车"
        },
      ],
      yongtuHcData: [{
        value: "1",
        label: "普通货车"
      },
        {
          value: "2",
          label: "多用途货车"
        },
        {
          value: "3",
          label: "全挂牵引车"
        },
        {
          value: "4",
          label: "越野货车"
        },
        {
          value: "5",
          label: "专用作业车"
        },
        {
          value: "6",
          label: "专用货车"
        },
      ],
      chechangData: [{
        value: "1",
        label: "车长≤6米"
      },
        {
          value: "2",
          label: "6米<车长≤7米"
        },
        {
          value: "3",
          label: "67米<车长≤8米"
        },
        {
          value: "4",
          label: "8米<车长≤9米"
        },
        {
          value: "5",
          label: "9米<车长≤10米"
        },
        {
          value: "6",
          label: "10米<车长≤12米"
        },
        {
          value: "7",
          label: "12米<车≤7米"
        },
      ],
      tableHeader: [],
      tableHeaderTime: [],
      tableHeaderOther: [],
      dialogVisible: false,
      uploadList: [],
      uploadData: [],
      props: {
        label: "id",
        key: "id",
        name: "enterprise_name",
      },
      excelLoading: false,
      updataDate: "",
      showTotal: false,
      wcShowFlag: true,
      xsShowFlag: true,
      tjShowFlag: true,
      bqzlj1ShowFlag: true,
      tqzlj1ShowFlag: true,
      bqzlj2ShowFlag: true,
      tqzlj2ShowFlag: true,
      historyVisible: false,
      iframeVisible: false,
      iframeSrc: "",
      historyYear: [],
      historyYue: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      hisForm: {
        nian: "",
        yue: "01",
        page: 1,
        pageSize: 999999,
        count: 0,
      },
      historyList: [],
      timeList: [],
      sclShow: true,
      xslShow: true,
      tjlShow: true,
      monthShow: true,
      showP:false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  mounted() {
    this.init();
    // let data1 = this.bj1.filter((item) => item.value == 1);
    let data2 = this.bj1.filter((item) => item.value == 2);
    data2[0].children.splice(0, 1);
    this.xflbData = data2[0].children;
    if (getUserInfo()) {
      this.show = true;
      // this.selectChangeCllx(this.form.cllxValue);
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if(!menuName.includes('数据')){
          this.showP = true;

        }
      }
      this.ttDataUpload();
      this.sclShow = this.form.wcShow;
      this.xslShow = this.form.xsShow;
      this.tjlShow = this.form.tjShow;
      this.monthShow = this.month;

    } else {
      this.show = false;
    }
  },
  methods: {
    toLocaleString(val) {
      if (val == null || val == '') {
        return val;
      } else {
        return val.toLocaleString();
      }

    },
    toFixed(val) {

      if (val == null || val == '') {
        return val;
      } else {
        return val.toFixed(2);
      }

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    historyOpen() {
      let newUrl = this.$router.resolve({
        path: "/history",
      });
      window.open(newUrl.href, "_blank");
    },
    // showExport(){
    //     if(getUserInfo().roleKey=="trialrole"){
    //         return true
    //     }else {
    //         return false
    //     }
    // },
    init() {

      lastTime().then((res) => {
        if (res.ok) {
          this.times = [res.data, res.data];
          this.timeList = [res.data];
          this.updataDate = res.data;
          if (this.show) {
            this.YkbQcCxDataPage();

          }
          this.YkbQcCxDataEnterpriseList();
        }
      });
      // getYearListHistory().then((res) => {
      //   if (res.ok) {
      //     this.historyYear = res.data;
      //     this.hisForm.nian = res.data[0].nian;
      //     this.getHistoryPage();
      //
      //   }
      // });
    },
    YkbQcCxDataEnterpriseList() {
      let form = {
        isYear: this.form.nian,
        startTime: this.times[0],
        endTime: this.times[1],
        cllx: this.form.cllxValue,
      };
      YkbQcCxDataEnterpriseList(form).then((res) => {
        if (res.ok) {
          this.qymcData = res.data;
        }
      });
    },
    YkbQcCxDataPage() {
      let form = Object.assign({}, this.form, this.pageData);
      form.timeBegin = this.times[0];
      form.timeEnd = this.times[1];
      if (form.timeBegin == form.timeEnd) {
        this.showTotal = false;
      } else {
        this.showTotal = true;
      }
      this.wcShowFlag = form.wcShow;
      this.xsShowFlag = form.xsShow;
      this.tjShowFlag = form.tjShow;
      this.bqzlj1ShowFlag = form.wcShow;
      this.bqzlj2ShowFlag = form.xsShow;
      this.tqzlj1ShowFlag = form.wcShow;
      this.tqzlj2ShowFlag = form.xsShow;
      // this.YkbQcCxDataTimeLis(
      //     this.getNewDate(this.times[0]),
      //     this.getNewDate(this.times[1]),
      //     form.nian
      // );
      delete form.total;
      this.loading = true;
      YkbQcCxDataPage(form).then((res) => {
        if (res.ok) {
          this.tableData = res.data.list;

          this.tableHeader = this.tableHeaderForm().tableArr;
          this.tableHeaderOther = this.tableHeaderForm().other;
          this.pageData.count = res.data.count;
          if (this.month) {
            this.timeList = getMonthBetween(
                form.timeBegin,
                form.timeEnd
            );
          } else {
            this.timeList = getYearArr(
                form.timeBegin,
                form.timeEnd
            );
          }

          this.monthShow = this.month;
          this.sclShow = this.form.wcShow;
          this.xslShow = this.form.xsShow;
          this.tjlShow = this.form.tjShow;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    search() {
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.YkbQcCxDataPage();
          }
        } else {
          this.YkbQcCxDataPage();        }
      } else {
        this.showLogin();
      }
    },
    selectChangeCllx(e) {

      let dstas = [];

      if (e == "") {
        let data1 = this.bj1.filter((item) => item.value == 1);
        let data2 = this.bj1.filter((item) => item.value == 2);
        if (data2[0].children[0].name == "请选择") {
          data2[0].children.splice(0, 1);
        }
        dstas = data1[0].children.concat(data2[0].children);
      } else {
        let data = this.bj1.filter((item) => item.value == e);
        if (data[0].children) {
          console.log(data[0].children)
          let qxz = {value: "", name: "请选择"};
          dstas = data[0].children;
          if (e == 2 && dstas[0].name != "请选择") {
            dstas.unshift(qxz);
          }

        }
      }
      if (e == 1) {//车辆类型选择乘用车，显示燃料类型，组装地
        if (this.form.xflb == false) {
          this.form.rllbShow = true;
        }

        this.form.zuZhuangDiShow = true;


        this.xfwdData = [{
          value: 1,
          label: "排量"
        },
          {
            value: 2,
            label: "档别"
          },
        ];
        this.zuZhuangDiData = [{
          value: "1",
          label: "国内制造",
        },
          {
            value: "2",
            label: "CKD",
          },
        ];
      } else {//车辆类型选择商用车或者什么都不选，显示燃料类型
        if (this.form.xflb == false) {
          this.form.rllbShow = true;
        }
        this.form.zuZhuangDiShow = false;
        this.form.zuZhuangDi = false;
        this.xfwdData = [];
        this.zuZhuangDiData = [];
      }
      if (e == 1 || e == '') {

        this.form.zhiLiangLeiBieShow = false;
        this.form.zhiLiangLeiBie = false;

      }
      if (this.form.qymc == true) {
        this.form.rllbShow = false;
        this.form.zuZhuangDiShow = false;

      }
      this.form.paiLiangDuan = false;
      this.form.paiLiangDuanValue = [];
      this.form.cheMen = false;
      this.form.cheMenValue = [];
      this.form.dangBie = false;
      this.form.dangBieValue = [];
      this.form.dunWei = false;
      this.form.dunWeiValue = [];
      this.form.huoCheYongTu = false;
      this.form.huoCheYongTuValue = [];
      this.form.keCheYongTu = false;
      this.form.keCheYongTuValue = [];
      this.form.cheChang = false;
      this.form.cheChangValue = [];
      this.form.xfLbValue = "";
      this.xflbData = dstas;
      this.xfwdValue = "";
      this.zuZhuangDiValue = [];
      this.form.qymcValue = [];
      this.form.zhiLiangLeiBieValue = [];
      this.zhiLiangLeiBieData = [];
      this.YkbQcCxDataEnterpriseList();
    },
    selectChangeXflb(val) {
      if (this.form.qymc == false) {
        if (val == 5 || val == 4 || val == 3 || val == 6) {
          this.form.cllxValue = "1";
          this.form.zuZhuangDiShow = true;
          this.form.zhiLiangLeiBieShow = false;
          this.form.zhiLiangLeiBie = false;
        } else {
          this.form.cllxValue = "2";
          this.form.zhiLiangLeiBieShow = true;
          this.form.zuZhuangDiShow = false;
          this.form.zuZhuangDi = false;


        }
        this.xfwd = true;
        this.xfwdShow = true;
      } else {
        if (val == 5 || val == 4 || val == 3 || val == 6) {
          this.form.cllxValue = "1";
          this.form.zuZhuangDiShow = false;
          this.form.zhiLiangLeiBieShow = false;
          this.form.zuZhuangDi = false;
          this.form.zhiLiangLeiBie = false;

        } else {
          this.form.cllxValue = "2";
          this.form.zhiLiangLeiBieShow = true;
          this.form.zuZhuangDiShow = false;
          this.form.zuZhuangDi = false;

        }
        this.xfwd = false;
        this.xfwdShow = false;
      }
      if (val == "") {
        this.form.zhiLiangLeiBieShow = false;
        this.xfwd = false;
        this.xfwdShow = false;
        this.form.cllxValue = "";
        let data1 = this.bj1.filter((item) => item.value == 1);
        let data2 = this.bj1.filter((item) => item.value == 2);
        // data2[0].children.splice(0, 1);
        this.xflbData = data1[0].children.concat(data2[0].children);
      }

      let e = [val];

      let isCM = e.includes("5");
      let isKC = e.includes("12");
      let isFKC = e.includes("18");
      let isHC = e.includes("7");
      let isFHC = e.includes("17");
      let isBGQY = e.includes("16");
      if (e.length <= 0) {
        this.form.paiLiangDuan = false;
        this.form.paiLiangDuanValue = [];
        this.form.cheMen = false;
        this.form.cheMenValue = [];
        this.form.dangBie = false;
        this.form.dangBieValue = [];
        this.form.dunWei = false;
        this.form.dunWeiValue = [];
        this.form.huoCheYongTu = false;
        this.form.huoCheYongTuValue = [];
        this.form.keCheYongTu = false;
        this.form.keCheYongTuValue = [];
        this.form.cheChang = false;
        this.form.cheChangValue = [];
      }
      if (this.form.cllxValue == 2) {
        this.xfwdData = [];
        if (isKC || isFKC) {
          this.xfwdData.push({
            value: 4,
            label: "车长"
          }, {
            value: 5,
            label: "客车用途"
          });
        }

        if (isHC || isFHC) {
          this.xfwdData.push({
            value: 6,
            label: "吨位"
          }, {
            value: 7,
            label: "货车用途"
          });
        }
        this.form.keCheYongTu = false;
        this.form.keCheYongTuValue = [];
        this.form.cheChang = false;
        this.form.cheChangValue = [];
        this.form.dunWei = false;
        this.form.dunWeiValue = [];
        this.form.huoCheYongTu = false;
        this.form.huoCheYongTuValue = [];
        this.xfwdValue = "";

        this.form.cheMen = false;
        this.form.cheMenValue = [];
        this.form.dangBie = false;
        this.form.dangBieValue = [];
        this.form.paiLiangDuan = false;
        this.form.paiLiangDuanValue = [];
      } else {
        if (isCM) {
          this.xfwdData = [{
            value: 1,
            label: "排量"
          },
            {
              value: 2,
              label: "档别"
            },
            {
              value: 3,
              label: "车门"
            },
          ];
        } else {
          this.xfwdData = [{
            value: 1,
            label: "排量"
          },
            {
              value: 2,
              label: "档别"
            },
          ];

        }
        this.form.keCheYongTu = false;
        this.form.keCheYongTuValue = [];
        this.form.cheChang = false;
        this.form.cheChangValue = [];
        this.form.dunWei = false;
        this.form.dunWeiValue = [];
        this.form.huoCheYongTu = false;
        this.form.huoCheYongTuValue = [];
        this.xfwdValue = "";

        this.form.cheMen = false;
        this.form.cheMenValue = [];
        this.form.dangBie = false;
        this.form.dangBieValue = [];
        this.form.paiLiangDuan = false;
        this.form.paiLiangDuanValue = [];
      }

      let dstas = [];
      for (let i = 0; i < e.length; i++) {
        for (let j = 0; j < this.xflbData.length; j++) {
          if (e[i] == this.xflbData[j].value) {
            if (this.xflbData[j].children) {
              let children = this.xflbData[j].children;
              dstas.push(children);
            }
          }
        }
      }
      let datas = [];
      for (let i = 0; i < dstas.length; i++) {
        for (let j = 0; j < dstas[i].length; j++) {
          datas.push(dstas[i][j]);
        }
      }
      this.form.zhiLiangLeiBieValue = [];
      this.zhiLiangLeiBieData = datas;
    },
    //开始结束时间
    timeChange(e) {
      this.times = e.value;
      if (e.swatchDateType == "month") {
        this.form.nian = false;
        this.checkDate = "month";
      } else {
        this.form.nian = true;
        this.checkDate = "year";
      }
      this.YkbQcCxDataEnterpriseList();
    },
    //切换清空值
    changeFun(type, check) {


      let isArr = Array.isArray(this.form[type]);
      if (isArr) {
        this.$set(this.form, type, []);
      } else {
        if (type == "cllxValue") {
          this.$set(this.form, type, "");
        } else if (type == "xfwdValue") {
          this.$set(this, type, "");
        } else {
          this.$set(this.form, type, "");
          this.xfwdValue = "";
        }
      }


      // if (this.form[check] && check == "xflb" && !this.form.cllx) {
      //   this.$message("请选择车辆类型");
      // }

      // if (
      //     this.form[check] &&
      //     check == "zhiLiangLeiBie" &&
      //     this.form.cllxValue != 2
      //
      // ) {
      //   this.$message("请选择车辆类型商用车和细分市场");
      // }


      if (this.form.xflb == false) {
        if (this.form.rllbValue != 1) {
          this.form.zuZhuangDiShow = false;
        }
        this.xfwdShow = false;
        this.form.zhiLiangLeiBieShow = false;

        this.form.paiLiangDuan = false;
        this.form.paiLiangDuanValue = [];
        this.form.cheMen = false;
        this.form.cheMenValue = [];
        this.form.dangBie = false;
        this.form.dangBieValue = [];
        this.form.dunWei = false;
        this.form.dunWeiValue = [];
        this.form.huoCheYongTu = false;
        this.form.huoCheYongTuValue = [];
        this.form.keCheYongTu = false;
        this.form.keCheYongTuValue = [];
        this.form.cheChang = false;
        this.form.cheChangValue = [];
      }
      if (this.xfwdShow == true && this.form.zhiLiangLeiBie == true && check == "zhiLiangLeiBie") {//选择质量分类，细分维度取消选中
        this.xfwd = false;
        this.$message.warning("细分维度不可与质量分类同时选中");
      }
      if (this.form.zhiLiangLeiBieShow == true && this.xfwd == true && check == "xfwd") {//选择细分维度，质量分类取消选中
        this.form.zhiLiangLeiBie = false;
        this.$message.warning("细分维度不可与质量分类同时选中");

      }
      if (this.xfwd == false) {
        this.form.keCheYongTu = false;
        this.form.keCheYongTuValue = [];
        this.form.cheChang = false;
        this.form.cheChangValue = [];
        this.form.dunWei = false;
        this.form.dunWeiValue = [];
        this.form.huoCheYongTu = false;
        this.form.huoCheYongTuValue = [];
        this.xfwdValue = "";

        this.form.cheMen = false;
        this.form.cheMenValue = [];
        this.form.dangBie = false;
        this.form.dangBieValue = [];
        this.form.paiLiangDuan = false;
        this.form.paiLiangDuanValue = [];
      }
      // if (this.form.rllb == true && check == "rllb") {//选择燃料类型，细分维度，质量分类，取消选中
      //   this.xfwd = false;
      //   this.form.zhiLiangLeiBie = false;
      // }

      if (!this.form.cllx && !this.form.xflb && !this.form.qymc) { //什么都不选，燃料类型隐藏
        this.form.rllb = false;
        this.form.rllbShow = false;
        this.form.zuZhuangDiShow = false;

      }
      if (this.form.cllx == true) {//选中车辆类型，显示燃料类型
        this.form.rllbShow = true;
      }
      if (this.form.cllx == false) {
        let data1 = this.bj1.filter((item) => item.value == 1);
        let data2 = this.bj1.filter((item) => item.value == 2);
        // data2[0].children.splice(0, 1);
        this.xflbData = data1[0].children.concat(data2[0].children);
      }
      if (this.form.xflb == true || this.form.qymc == true) {//选择细分类别或者企业名称，燃料类型隐藏
        this.form.rllbShow = false;
        this.form.rllb = false;
      }
      if (this.form.qymc == true) {//选择企业名称，细分维度与组装地隐藏
        this.xfwdShow = false;
        this.form.zuZhuangDiShow = false;
        this.form.zuZhuangDi = false;

        this.form.keCheYongTu = false;
        this.form.keCheYongTuValue = [];
        this.form.cheChang = false;
        this.form.cheChangValue = [];
        this.form.dunWei = false;
        this.form.dunWeiValue = [];
        this.form.huoCheYongTu = false;
        this.form.huoCheYongTuValue = [];
        this.xfwdValue = "";

        this.form.cheMen = false;
        this.form.cheMenValue = [];
        this.form.dangBie = false;
        this.form.dangBieValue = [];
        this.form.paiLiangDuan = false;
        this.form.paiLiangDuanValue = [];
      }
      if (this.form.qymc == true && this.form.xflb == false) {//选择企业名称，不选择细分类别，质量分类隐藏
        this.form.zhiLiangLeiBieShow = false;
        this.form.zhiLiangLeiBie = false;

      }
      if (this.form.qymc == false && this.form.cllxValue == "1") {//不选择质量分类，车辆类型选择乘用车，组装地显示
        this.form.zuZhuangDiShow = true;

      }
      if (this.form.qymc == false && this.form.xfLbValue != "") {//不选择质量分类，细分类别选择商用车，细分维度显示
        this.xfwdShow = true;

      }
    },
    //点击月份
    changeMonth(e) {
      this.form.nian = !e;
      if (e) {
        this.$refs.VdateEndStart.deteHandleCommand(
            "month",
            this.updataDate
        );
      } else {
        this.$refs.VdateEndStart.deteHandleCommand("year");
      }
    },
    radioChange(val) {
      if (val == '月') {
        this.$refs.VdateEndStart.deteHandleCommand(
            "month",
            this.updataDate
        );
        this.form.nian = false;
        this.month = true;
      } else {
        this.$refs.VdateEndStart.deteHandleCommand("year");
        this.form.nian = true;
        this.month = false;
      }
    },
    xfwdSelect(e) {
      switch (e) {
        case 1:
          this.form.paiLiangDuan = true;
          this.form.paiLiangDuanValue = [];
          this.form.cheMen = false;
          this.form.cheMenValue = [];
          this.form.dangBie = false;
          this.form.dangBieValue = [];
          this.form.dunWei = false;
          this.form.dunWeiValue = [];
          this.form.huoCheYongTu = false;
          this.form.huoCheYongTuValue = [];
          this.form.keCheYongTu = false;
          this.form.keCheYongTuValue = [];
          this.form.cheChang = false;
          this.form.cheChangValue = [];
          break;
        case 2:
          this.form.paiLiangDuan = false;
          this.form.paiLiangDuanValue = [];
          this.form.cheMen = false;
          this.form.cheMenValue = [];
          this.form.dangBie = true;
          this.form.dangBieValue = [];
          this.form.dunWei = false;
          this.form.dunWeiValue = [];
          this.form.huoCheYongTu = false;
          this.form.huoCheYongTuValue = [];
          this.form.keCheYongTu = false;
          this.form.keCheYongTuValue = [];
          this.form.cheChang = false;
          this.form.cheChangValue = [];
          break;
        case 3:
          this.form.paiLiangDuan = false;
          this.form.paiLiangDuanValue = [];
          this.form.cheMen = true;
          this.form.cheMenValue = [];
          this.form.dangBie = false;
          this.form.dangBieValue = [];
          this.form.dunWei = false;
          this.form.dunWeiValue = [];
          this.form.huoCheYongTu = false;
          this.form.huoCheYongTuValue = [];
          this.form.keCheYongTu = false;
          this.form.keCheYongTuValue = [];
          this.form.cheChang = false;
          this.form.cheChangValue = [];
          break;
        case 4:
          this.form.paiLiangDuan = false;
          this.form.paiLiangDuanValue = [];
          this.form.cheMen = false;
          this.form.cheMenValue = [];
          this.form.dangBie = false;
          this.form.dangBieValue = [];
          this.form.dunWei = false;
          this.form.dunWeiValue = [];
          this.form.huoCheYongTu = false;
          this.form.huoCheYongTuValue = [];
          this.form.keCheYongTu = false;
          this.form.keCheYongTuValue = [];
          this.form.cheChang = true;
          this.form.cheChangValue = [];
          break;
        case 5:
          this.form.paiLiangDuan = false;
          this.form.paiLiangDuanValue = [];
          this.form.cheMen = false;
          this.form.cheMenValue = [];
          this.form.dangBie = false;
          this.form.dangBieValue = [];
          this.form.dunWei = false;
          this.form.dunWeiValue = [];
          this.form.huoCheYongTu = false;
          this.form.huoCheYongTuValue = [];
          this.form.keCheYongTu = true;
          this.form.keCheYongTuValue = [];
          this.form.cheChang = false;
          this.form.cheChangValue = [];
          break;
        case 6:
          this.form.paiLiangDuan = false;
          this.form.paiLiangDuanValue = [];
          this.form.cheMen = false;
          this.form.cheMenValue = [];
          this.form.dangBie = false;
          this.form.dangBieValue = [];
          this.form.dunWei = true;
          this.form.dunWeiValue = [];
          this.form.huoCheYongTu = false;
          this.form.huoCheYongTuValue = [];
          this.form.keCheYongTu = false;
          this.form.keCheYongTuValue = [];
          this.form.cheChang = false;
          this.form.cheChangValue = [];
          break;
        case 7:
          this.form.paiLiangDuan = false;
          this.form.paiLiangDuanValue = [];
          this.form.cheMen = false;
          this.form.cheMenValue = [];
          this.form.dangBie = false;
          this.form.dangBieValue = [];
          this.form.dunWei = false;
          this.form.dunWeiValue = [];
          this.form.huoCheYongTu = true;
          this.form.huoCheYongTuValue = [];
          this.form.keCheYongTu = false;
          this.form.keCheYongTuValue = [];
          this.form.cheChang = false;
          this.form.cheChangValue = [];
          break;
        default:
          this.form.paiLiangDuan = false;
          this.form.paiLiangDuanValue = [];
          this.form.cheMen = false;
          this.form.cheMenValue = [];
          this.form.dangBie = false;
          this.form.dangBieValue = [];
          this.form.dunWei = false;
          this.form.dunWeiValue = [];
          this.form.huoCheYongTu = false;
          this.form.huoCheYongTuValue = [];
          this.form.keCheYongTu = false;
          this.form.keCheYongTuValue = [];
          this.form.cheChang = false;
          this.form.cheChangValue = [];
      }
    },
    rest() {
      this.form.paiLiangDuan = false;
      this.form.paiLiangDuanValue = [];
      this.form.cheMen = false;
      this.form.cheMenValue = [];
      this.form.cllxValue = "";
      this.form.cheMenValue = [];
      this.form.zhiLiangLeiBie = false;
      this.form.zhiLiangLeiBieValue = [];
      this.form.zhiLiangLeiBieShow = false;
      this.form.xflb = false;
      this.form.xfLbValue = "";
      this.form.rllb = false;
      this.form.rllbValue = [];
      this.form.rllbShow = false;
      this.form.zuZhuangDi = false;
      this.form.zuZhuangDiValue = [];
      this.form.zuZhuangDiShow = false;
      this.form.dangBie = false;
      this.form.dangBieValue = [];
      this.form.qymc = false;
      this.form.qymcValue = [];
      this.xfwdValue = "";
      this.xfwd = false;
      this.xfwdShow = false;
      this.form.paiLiangDuan = false;
      this.form.paiLiangDuanValue = [];
      this.form.cheMen = false;
      this.form.cheMenValue = [];
      this.form.dangBie = false;
      this.form.dangBieValue = [];
      this.form.dunWei = false;
      this.form.dunWeiValue = [];
      this.form.huoCheYongTu = false;
      this.form.huoCheYongTuValue = [];
      this.form.keCheYongTu = false;
      this.form.keCheYongTuValue = [];
      this.form.cheChang = false;
      this.form.cheChangValue = [];
      this.form.wcShow = true;
      this.form.xsShow = true;
      this.form.tjShow = true;
      this.form.bqzlj1Show = true;
      this.form.bqzlj2Show = true;
      this.form.tqzlj1Show = true;
      this.form.tqzlj2Show = true;
      this.form.nian = false;
      this.month = true;
      this.times = [this.updataDate, this.updataDate];
      this.$refs.VdateEndStart.deteHandleCommand("month");
      this.init();
    },
    //时间头部
    getNewDate(nowdate) {
      let timeHeader = "";
      let date = new Date(),
          year = date.getFullYear(),
          month =
              date.getMonth() + 1 >= 10 ?
                  date.getMonth() + 1 :
                  "0" + date.getMonth() + 1;
      if (nowdate) {
        if (this.form.nian) {
          timeHeader = nowdate + "-01-01";
        } else {
          timeHeader = nowdate + "-01";
        }
      } else {
        if (this.form.nian) {
          timeHeader = year + "-01-01";
        } else {
          timeHeader = year + "-" + month + "-01";
        }
      }

      return timeHeader;
    },
    //前面头部
    tableHeaderForm() {
      let tableArr = [],
          other = [];
      for (let key in this.form) {
        if (typeof this.form[key] == "boolean") {
          if (this.form[key]) {
            switch (key) {
              case "qymc":
                tableArr.push({
                  label: "企业名称",
                  prop: "qyName",
                });
                break;
              case "cllx":
                tableArr.push({
                  label: "车辆类型",
                  prop: "cxlx",
                });
                break;
              case "xflb":
                tableArr.push({
                  label: "细分类别",
                  prop: "xflb",
                });
                break;
              case "paiLiangDuan":
                tableArr.push({
                  label: "排量",
                  prop: "paiLiangDuanValue",
                });
                break;
              case "dangBie":
                tableArr.push({
                  label: "档别",
                  prop: "dangBie",
                });
                break;
              case "cheMen":
                tableArr.push({
                  label: "车门",
                  prop: "cheMen",
                });
                break;
              case "dunWei":
                tableArr.push({
                  label: "吨位",
                  prop: "dunWei",
                });
                break;
              case "huoCheYongTu":
                tableArr.push({
                  label: "货车用途",
                  prop: "huoCheYongTu",
                });
                break;
              case "keCheYongTu":
                tableArr.push({
                  label: "客车用途",
                  prop: "keCheYongTu",
                });
                break;
              case "cheChang":
                tableArr.push({
                  label: "车长",
                  prop: "cheChang",
                });
                break;
              case "zhiLiangLeiBie":
                tableArr.push({
                  label: "质量分类",
                  prop: "zhiLiangLeiBie",
                });
                break;
              case "zuZhuangDi":
                tableArr.push({
                  label: "组装地",
                  prop: "zuZhuangDi",
                });
                break;
              case "rllb":
                tableArr.push({
                  label: "燃料类型",
                  prop: "rllb",
                });
                break;
              case "wcShow":
                other.push({
                  label: "生产",
                  prop: "wc",
                });
                other.push({
                  label: "生产本期止累计",
                  prop: "bqzlj1",
                });
                other.push({
                  label: "生产同期止累计",
                  prop: "tqzlj1",
                });
                other.push({
                  label: "比上月增长（生产）",
                  prop: "schb",
                });
                other.push({
                  label: "比同期增长（生产）",
                  prop: "sctb",
                });
                break;
              case "xsShow":
                other.push({
                  label: "销售",
                  prop: "xs",
                });
                other.push({
                  label: "销售本期止累计",
                  prop: "bqzlj2",
                });
                other.push({
                  label: "销售同期止累计",
                  prop: "tqzlj2",
                });
                other.push({
                  label: "比上月增长（销售）",
                  prop: "xshb",
                });
                other.push({
                  label: "比同期增长（销售）",
                  prop: "xstb",
                });
                break;
                // case "wcShow":

                // break;
                // case "xsShow":
                //     other.push({
                //         label: "销售本期止累计",
                //         prop: "bqzlj2",
                //     });
                //     break;
                // case "wcShow":
                //     other.push({
                //         label: "生产同期止累计",
                //         prop: "tqzlj1",
                //     });
                // break;
                // case "xsShow":
                //     other.push({
                //         label: "销售同期止累计",
                //         prop: "tqzlj2",
                //     });
                // break;
            }
          }
        }
      }
      return {
        tableArr,
        other
      };
    },
    //分页
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.YkbQcCxDataPage();
    },
    //分页
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.YkbQcCxDataPage();
    },
    YkbQcCxDataTimeLis(begin, end, isNian) {
      YkbQcCxDataTimeLis({
        begin,
        end,
        isNian
      }).then((res) => {
        if (res.ok) {
          // this.tableHeaderTime = res.data;
          this.tableHeaderTime = this.catgoryGrop(res.data);
        }
      });
    },
    checkName(value) {
      let id = value[0];
      let str = this.qymcData.filter((item) => item.id == id);
      if (str.length && str.length > 0) {
        return str[0].enterprise_name;
      }
    },
    //分组表头
    catgoryGrop(data) {
      let newData = data;
      let wc = [];
      let xs = [];
      let bqzlj1 = [];
      let bqzlj2 = [];
      let tqzlj1 = [];
      let tqzlj2 = [];
      let sctb = [];
      let schb = [];
      let xstb = [];
      let xshb = [];
      newData.forEach((el) => {
        wc.push({
          wc: el.wc,
          year1: el.year1
        });
        bqzlj1.push({
          bqzlj1: el.bqzlj1,
          year1: el.year1
        });
        tqzlj1.push({
          tqzlj1: el.tqzlj1,
          year1: el.year1
        });
        sctb.push({
          sctb: el.sctb,
          year1: el.year1
        });
        schb.push({
          schb: el.schb,
          year1: el.year1
        });
        xs.push({
          xs: el.xs,
          year1: el.year1
        });
        bqzlj2.push({
          bqzlj2: el.bqzlj2,
          year1: el.year1
        });
        tqzlj2.push({
          tqzlj2: el.tqzlj2,
          year1: el.year1
        });
        xstb.push({
          xstb: el.xstb,
          year1: el.year1
        });
        xshb.push({
          xshb: el.xshb,
          year1: el.year1
        });
      });
      return {
        wc,
        bqzlj1,
        tqzlj1,
        sctb,
        schb,
        xs,
        bqzlj2,
        tqzlj2,
        xstb,
        xshb,
      };
    },
    //附件列表
    ttDataUpload() {
      ttDataUpload({
        modelName: "data_upload_1,data_upload_2"
      }).then(
          (res) => {
            if (res.ok) {
              this.uploadList = res.data;
            }
          }
      );
    },
    //下载附件
    handleDown(index, row) {
      this.download(row);
    },
    //勾选单行数据
    handleSelectionChange(selection, row) {
      this.uploadData = selection;
    },
    //勾选全部
    handleSelectionAllChange(selection) {
      this.uploadData = selection;
    },
    downAll() {
      console.log(this.uploadData);
    },
    download(item) {
      let self = this;
      newdownload({
        fileUrl: item.attachUrl,
        articleId: item.id
      }).then(
          (res) => {
            var reader = new FileReader();
            reader.readAsText(res.data);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                let contentDisposition =
                    res.headers["content-disposition"];
                let patt = new RegExp(
                    "filename=([^;]+\\.[^\\.;]+);*"
                );
                let result = patt.exec(contentDisposition);
                let filename = decodeURI(result[1]);
                downloadFile(res.data, filename);
              }
            });
          }
      );
    },

    //导出
    Export() {
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('数据')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if(getUserInfo().roleKey == "trialrole"){
              openSy();
              this.insertBuryPoint();
            }else {
              this.form.timeBegin = this.times[0];
              this.form.timeEnd = this.times[1];
              let self = this;
              this.excelLoading = true;
              YkbQcCxDataDynamicExport(this.form).then((res) => {
                this.excelLoading = false;
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                  try {
                    JSON.parse(e.target.result);
                    let msg = JSON.parse(e.target.result);
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "国内产销批发量.xlsx");
                  }
                });
              });
            }
          }
        } else {
          this.form.timeBegin = this.times[0];
          this.form.timeEnd = this.times[1];
          let self = this;
          this.excelLoading = true;
          YkbQcCxDataDynamicExport(this.form).then((res) => {
            this.excelLoading = false;
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "国内产销批发量.xlsx");
              }
            });
          });        }
      } else {
        this.showLogin();
      }

    },
    getHistoryPage() {
      let form = Object.assign({}, this.hisForm);
      getHistoryPage(form).then((res) => {
        if (res.ok) {
          let data = res.data.list;
          var arr = [];
          for (var i = 0; i < data.length; i++) {
            if (i % 3 == 0) {
              var a = [];
              arr.push(a);
            }
            a.push(data[i]);
          }
          this.historyList = arr;
          this.hisForm.count = res.data.count;
        }
      });
    },
    hishandleSizeChange(v) {
      this.hisForm.pageSize = v;
      this.getHistoryPage();
    },
    hishandleCurrentChange(v) {
      this.hisForm.page = v;
      this.getHistoryPage();
    },
    hisSearch() {
      this.getHistoryPage();
    },
    openIframe(item) {
      window.open(item.url);
      // this.iframeSrc = item.url;
      // this.iframeVisible = true;
    },
    arraySpanMethod({
                      row,
                      column,
                      rowIndex,
                      columnIndex
                    }) {
      let col = this.tableHeader.length;
      if (this.pageData.page == 1) {
        if (col > 0) {
          if (rowIndex === 0) {
            if (columnIndex === 0) {
              return [1, this.tableHeader.length];
            } else if (columnIndex < col) {
              return [1, 0];
            }
          }
        } else {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }

    }
  },
};
</script>
<style lang="scss" scoped>
[flex="sb"] {
  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;
}

.is_active {
  background-image: linear-gradient(#eaeef6, #ffffff);
}

::v-deep .el-tabs__item.is-active {
  color: #0d57bc;
}

::v-deep .el-tabs__active-bar {
  background-color: #0d57bc;
}

.search-form {
  padding: 28px;
  background: #fff;
  margin-bottom: 10px;

  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-checkbox {
      margin-right: 16px;
    }

    .el-form-item__content {
      width: 179px;
    }

    .el-card {
      overflow: visible;
    }
  }

  .search-form-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
  }

}

.n-check-m {
  margin: 0 46px;
}


//.el-row{
//  margin-left:14px
//}

.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}
.el-message-box__title {
  font-size: 16px;

}
.el-message-box__message p {
  line-height: 14px;
}
</style>
