<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 17:16:06
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-11 11:30:49
-->
<template>
  <PageContainer>
    <!-- <not-logged-in-mask v-if="showNotLoggedInMask" />
    <unsubscribed-mask v-if="showUnsubscribedMask" /> -->
    <!-- <div :class="[showMask ? 'show-mask' : '']"> -->
    <div>
      <card title="月度数据分析报告" cion-show>
        <template slot="rightBtnBox">
          <div class="btn-box">
            <span class="seletc-time">选择发布时间:</span>
            <el-select
              v-model="reportDate"
              placeholder="请选择"
              size="small"
              style="width:180px"
              @change="reportDateChange"
            >
              <el-option label="全部" value="" />
              <el-option
                v-for="item in reportDateList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
            <span
              class="seletc-time"
              style="margin-left:32px
            "
            >报告主题:</span>
            <el-select
              v-model="reportType"
              multiple
              collapse-tags
              placeholder="请选择"
              size="small"
              style="width:180px"
              @change="reportTypeChange"
            >
              <el-option
                v-for="item in reportTypeList"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              />
            </el-select>
          </div>
        </template>
        <template slot="body">
          <div class="ppt-list">
            <div
              v-for="(item, index) in reportData"
              :key="index"
              class="ppt-item"
            >
              <div class="down-mask">
                <div class="mask-btn view" @click="pptView(item)" />
                <div class="mask-btn down" @click="pptDown(item)" />
              </div>
              <div class="ppt-item-bg">
                <div class="ppt-item-bg-lable" />
                <div class="ppt-item-bg-icon" />
                <div class="ppt-item-bg-title">{{ item.short_name }}</div>
                <div class="ppt-item-bg-time">
                  {{ getItemBgTime(item.report_date) }}
                </div>
              </div>
              <div class="ppt-item-title">
                <div class="ppt-item-title-text">{{ item.name }}</div>
                <div class="ppt-item-title-time">
                  <!-- {{ getItemTitleTime(item.report_date) }} -->
                </div>
              </div>
            </div>
          </div>
        </template>
      </card>
    </div>
  </PageContainer>
</template>
<script>
import { authMixin } from '@/mixin/newData/index.js' // 遮罩混入
// import { NotLoggedInMask, UnsubscribedMask } from '@/components/newData/mask'
import card from './common/card.vue' // 卡片组件
import Utils from './common/utils.js' // 工具类
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import { download, selectYcCount } from '@/api/common.js'
import { downloadFile } from '@/utils/download.js'
// 预览
// window.open(
//   "https://www.autoinfo.org.cn/autoinfo/profile/upload/autoReport/2023年5月汽车整体市场分析.pptx"
// );
// 下载
// window.open(
//   "https://www.autoinfo.org.cn/autoinfo/profile/upload/autoReport/2023年5月汽车整体市场分析.pptx"
// );
import {
  dataReportList,
  selectReportDate,
  selectDictDataByType
} from '@/api/data_new.js'
import { Message } from 'element-ui'
export default {
  name: 'PPT',
  components: {
    card
    // NotLoggedInMask,
    // UnsubscribedMask
  },
  mixins: [authMixin],
  data() {
    return {
      reportDate: '',
      reportDateList: [],
      reportType: [],
      reportTypeList: [],
      reportData: [],
      formLog: {
        eventName: '开通卡片点击',
        eventType: '',
        columnName: '数据',
        userId: ''
      },
    }
  },
  mounted() {
    this.getSelectReportDate()
    this.getSelectDictDataByType()
  },
  methods: {
    /**
     * @description: 时间格式化
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-30 17:43:49
     * @LastEditTime: Do not edit
     */
    getItemBgTime(date) {
      const str = Utils.insertStr(date, 4, '年')
      return Utils.insertStr(str, 7, '月')
    },
    getItemTitleTime(date) {
      return Utils.insertStr(date, 4, '-')
    },
    /**
     * @description: 获取PPT报告list
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-30 16:38:13
     * @LastEditTime: Do not edit
     */

    getData() {
      // if (this.reportDate && this.reportType.length > 0) {
      dataReportList(
        `reportDate=${this.reportDate}&type=${this.reportType}`
      ).then((res) => {
        if (res.ok) {
          this.reportData = res.data.mapList
          // this.$set(this, "reportData", res.data.mapList);
        }
      })
      // }
    },
    /**
     * @description: 获取时间下拉数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-30 16:38:42
     * @LastEditTime: Do not edit
     */

    getSelectReportDate() {
      selectReportDate().then((res) => {
        if (res.data && res.data.length > 0) {
          this.reportDateList = res.data
          // this.reportDate = res.data[0];
          this.getData()
        }
      })
    },
    /**
     * @description: 获取类型下拉数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-30 16:39:01
     * @LastEditTime: Do not edit
     */
    getSelectDictDataByType() {
      selectDictDataByType().then((res) => {
        if (res.data && res.data.length > 0) {
          this.reportTypeList = res.data
          // this.reportType = [res.data[0].dictValue];
          this.getData()
        }
      })
    },
    /**
     * @description:类型选择change事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-30 16:41:10
     * @LastEditTime: Do not edit
     */

    reportTypeChange() {
      this.getData()
    },
    /**
     * @description:时间选择change事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-30 16:41:10
     * @LastEditTime: Do not edit
     */

    reportDateChange() {
      this.getData()
    },
    /**
     * @description: 预览
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-30 18:02:32
     * @LastEditTime: Do not edit
     */

    pptView(item) {
      this.formLog.eventType = '1'
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('数据')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              selectYcCount({ 'userId': getUserInfo().userId }).then((res) => {
                if (res.ok) {
                  if (res.data.dataYc != null && res.data.dataYc >= 2) {
                    Message({
                      showClose: true,
                      message: '每日两篇数据报告浏览次数已达上限！',
                      type: 'error'
                    })
                    return
                  } else {
                    logRecords('数据', 'PPT报告', '1', 'data_report', item.id, '', item.name, 'yc', '预览')
                    window.open(
                      '/pdf/web/viewer.html?file=' +
                      window.encodeURIComponent('https://www.autoinfo.org.cn' + item.pdf_url)
                    )
                  }
                }
              })
            } else {
              logRecords('数据', 'PPT报告', '1', 'data_report', item.id, '', item.name, 'yc', '预览')
              window.open(
                '/pdf/web/viewer.html?file=' +
                window.encodeURIComponent('https://www.autoinfo.org.cn' + item.pdf_url)
              )
            }
          }
        } else {
          logRecords('数据', 'PPT报告', '1', 'data_report', item.id, '', item.name, 'yc', '预览')
          window.open(
            '/pdf/web/viewer.html?file=' +
            window.encodeURIComponent('https://www.autoinfo.org.cn' + item.pdf_url)
          )
        }
      } else {
        this.showLogin()
      }
    },
    /**
     * @description: 下载
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-08-30 18:02:53
     * @LastEditTime: Do not edit
     */

    pptDown(item) {
      const userInfo = getUserInfo()
      if (!userInfo) {
        this.$Vlogin2({ flag: true })
        return
      }
      if (userInfo.roleKey == 'paidrole' || userInfo.roleKey == 'trialrole') {
        const unSubscribed =
          !userInfo.menuName || !userInfo.menuName.includes('数据')
        if (unSubscribed) {
          this.$Vlogin3({ flag: true })
          return
        }
      }
      // 如果是试用用户
      if (userInfo.roleKey == 'trialrole') {
        openSy()
        return
      }
      logRecords('数据', 'PPT报告', '2', 'data_report', item.id, item.name, '', '')
      download({ fileUrl: item.attach_url }).then((res) => {
        var reader = new FileReader()
        reader.readAsText(res)
        reader.addEventListener('loadend', function(e) {
          try {
            JSON.parse(e.target.result)
            const msg = JSON.parse(e.target.result)
            self.$message({
              showClose: true,
              message: msg.message,
              type: 'error'
            })
          } catch (err) {
            downloadFile(res, item.attach_name)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataCommon.scss";
.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;

  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  .seletc-time {
    margin-right: 16px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
  }
}

$ppt-item-width: 260px;

.ppt-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  .ppt-item {
    position: relative;
    width: $ppt-item-width;
    margin-bottom: 14px;

    &:not(:nth-child(4n)) {
      margin-right: calc((100% - #{$ppt-item-width} * 4) / 3);
    }

    .ppt-item-bg {
      display: flex;
      padding: 34px 20px 32px;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 158px;
      background: url(~@/assets/img/overview/ppt-bg.png) no-repeat;
      background-size: 100% 100%;
      border-radius: 3px;

      .ppt-item-bg-lable {
        position: absolute;
        top: 12px;
        left: 12px;
        height: 10px;
        width: 100px;
        background: url(~@/assets/img/overview/ppt-lable.png) no-repeat;
        background-size: 100% 100%;
      }
      .ppt-item-bg-icon {
        position: absolute;
        bottom: 8px;
        right: 8px;
        height: 24px;
        width: 24px;
        background: url(~@/assets/img/overview/ppt-icon.png) no-repeat;
        background-size: 100% 100%;
      }
      .ppt-item-bg-title {
        font-size: 22px;
        font-family: DingTalk JinBuTi;
        line-height: 1.2;
        color: #ffffff;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        margin-top: 15px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .ppt-item-bg-time {
        padding: 2px 5px;
        width: 84px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 99px 99px 99px 99px;
      }
    }
    .ppt-item-title {
      margin-top: 8px;

      .ppt-item-title-text {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      .ppt-item-title-time {
        margin-top: 4px;
        font-size: 12px;
        height: 18px;
        font-family: PingFang SC-Light, PingFang SC;
        font-weight: 300;
        // color: #999999;
        color: #fff;
      }
    }
    .down-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 158px;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: 5;
      display: flex;
      align-content: center;
      justify-content: center;
      border-radius: 3px;

      .mask-btn {
        width: 88px;
        height: 32px;
        margin: auto 15px;
        cursor: pointer;
      }
      .view {
        background: url(~@/assets/img/overview/ppt-view.png) no-repeat;
        background-size: 100% 100%;
      }
      .down {
        background: url(~@/assets/img/overview/ppt-down.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .down-mask:hover {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1750px) {
  .ppt-list {
    .ppt-item {
      &:not(:nth-child(4n)) {
        margin-right: 0;
      }
      &:not(:nth-child(3n)) {
        margin-right: calc((100% - #{$ppt-item-width} * 3) / 2);
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .ppt-list {
    .ppt-item {
      &:not(:nth-child(4n)) {
        margin-right: 0;
      }

      &:not(:nth-child(3n)) {
        margin-right: 0;
      }

      &:not(:nth-child(2n)) {
        margin-right: calc(100% - #{$ppt-item-width} * 2);
      }
    }
  }
}
</style>
