<template>
  <PageContainer>
    <div class="mainContent">
      <!--        <VnotLogin v-if="!show" title="法规/标准" text="法规/标准栏目围绕法规/标准本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新法规/标准" :showTips="showTips" />-->
      <div v-if="!isSearch">
        <div class="search-form search-form-top issearch">
          <h3 class="titles"><span class="radios" />国际法规</h3>
          <div v-for="(item, index) in countrys" :key="index">
            <searchGrop :title="`${item.name} (${ item.children.length }${ ['北美洲', '南美洲', '非洲'].includes(item.name) ? '个国家' : '个国家/地区' })`" is-show-expand>
              <div style="width: 100%;overflow: hidden;">
                <div v-for="(t, i) in item.children" :key="i" class="countryName" @click="changeCountry(t, 'country')">
                  <div class="countryImg"><img :src="'https://www.autoinfo.org.cn'+t.remarks"></div>
                  <!-- <div class="countryImg"><img :src="t.remarks"></div> -->
                  <div class="countryTitle">
                    <div>
                      <div class="title1 texts"> {{ t.name }}</div>
                      <div class="title2 texts"> {{ t.type }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </searchGrop>
          </div>
        </div>
        <!--      <div style="height:14px;width:100%;background-color: rgba(248,248,248,1);"></div>-->
        <div class="search-form search-form-top issearch">
          <h3 class="titles"><span class="radios" />国际标准</h3>
          <!--        <div style="margin: 0 25px;">-->
          <!--          <span v-for="(item, index) in gjbzCategory" :key="index" class="standName" @click="changeCountry(item, 'stand')">-->
          <!--            {{ item.dictLabel }}-->
          <!--          </span>-->

          <div style="width: 100%;overflow: hidden;padding: 0 5px;">
            <div v-for="(t, i) in gjbzCategory" :key="i" class="standName" @click="changeCountry(t, 'stand')">
              <div style="display: flex; align-items: center; height: 100%; ">
                <div style="margin-right: 8px; " class="imgs">
                  <img :src="'https://www.autoinfo.org.cn' + t.remark" alt="" style=" width: 45px; max-width: 45px">
                </div>
                <span class="texts">{{ t.dictLabel }}</span>
                <span class="texts" style="font-size: 14px; color: #999; margin-left: 8px;">{{ t.listClass }}</span>
                <img src="@/assets/images/rightAraw.png" alt="" class="icons" style="width: 30px; margin-left: auto">
              </div>
            </div>
          </div>
          <!--        </div>-->
        </div>
      </div>
      <div v-if="isSearch">
        <div class="back" style="display: flex; align-items: center;" @click="isSearch = false">
          <img src="@/assets/images/goback.png" alt="" style=" width: 16px; margin-right: 12px;">
          <span v-if="isCountryOrStand == 'country'" style="font-weight: 600;font-size: 18px;">{{ currentCountry.name }}</span>
          <span v-if="isCountryOrStand == 'stand'" style="font-weight: 600;font-size: 18px;">{{ form.gjbzCategory }}</span>
        </div>

        <div class="search-form search-form-top" style="padding-bottom: 20px;">
          <el-form ref="form" class="g-form" :model="form" label-width="150px">
            <!-- <div style="padding-bottom: 10px;font-weight: 600;font-size: 18px;" v-if="isCountryOrStand == 'country'">{{currentCountry.name}}</div>
            <div style="padding-bottom: 10px;font-weight: 600;font-size: 18px;" v-if="isCountryOrStand == 'stand'">{{form.gjbzCategory}}</div> -->

            <!-- <searchGrop title="查询字段"> -->
            <el-row>
              <el-col :span="8">
                <el-form-item prop="title" label="法规/标准中文名称：">
                  <el-input
                    v-model="form.title"
                    placeholder="请输入内容"
                    style="width: 100%"
                    size="small"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="titleEnglish" label="法规/标准英文名称：" label-width="200px">
                  <el-input
                    v-model="form.titleEnglish"
                    placeholder="请输入内容"
                    style="width: 100%"
                    size="small"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="code" label="法规/标准编号：" label-width="200px">
                  <el-input
                    v-model="form.code"
                    placeholder="请输入内容"
                    style="width: 100%"
                    size="small"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <div class="text-right">
              <el-button class="searchbtns reset-button" @click="resetForm">
                <img src="@/assets/images/icon-refresh.png" alt="">
                重置
              </el-button>
              <el-button class="searchbtns search-button" @click="searchForm">
                <img src="@/assets/images/icon-search.png" alt="">
                查询
              </el-button>
            </div>
            <!--            <el-row v-if="isCountryOrStand == 'stand'">-->
            <!--              <el-col :span="24">-->
            <!--                <el-form-item prop="country" label="法规/标准分类：">-->
            <!--                  <div class="bigbox">-->
            <!--                    <div class="flexC">-->
            <!--                      <el-radio-group v-model="form.gjbzCategory" size="small">-->
            <!--                        <el-radio-button-->
            <!--                          v-for="item in gjbzCategory"-->
            <!--                          :key="item.dictLabel"-->
            <!--                          disabled-->
            <!--                          :label="item.dictLabel"-->
            <!--                        >{{ item.dictLabel }}-->
            <!--                        </el-radio-button>-->
            <!--                      </el-radio-group>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </el-form-item>-->
            <!--              </el-col>-->
            <!--            </el-row>-->
            <!-- </searchGrop> -->
            <!--          <el-divider />-->
            <!--        <searchGrop title="">-->

          </el-form>
        </div>

        <div class="search-form" style="padding-top: 24px;">
          <div flex="sb" style="margin-bottom: 20px;">
            <span class="table_title" />
            <el-button
              v-show="activeName == 'bzzt'"
              v-has-role="'freeusers'"
              class="exportBts"
              type="primary"
              plain
              @click="excelBtn"
            >
              <img src="@/assets/images/icon-upload.png" alt="">
              导出
            </el-button>
            <el-button
              v-show="activeName == 'zqyjg'"
              v-has-role="'freeusers'"
              class="exportBts"
              type="primary"
              plain
              @click="excelBtn2"
            >
              <img src="@/assets/images/icon-upload.png" alt="">
              导出
            </el-button>
          </div>

          <div style="position:relative">
            <el-table
              ref="tableBox"
              v-loading="loading"
              :data="standardLibraryDate"
              style="width: 100%"
              :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
              row-key="id"
              stripe
              border
              @selection-change="handleSelectionChange"
              @sort-change="tableSort"
            >
              <el-table-column
                type="selection"
                width="50"
                reserve-selection
                :selectable="checkSelectTable"
              />
              <el-table-column
                label="序号"
                type="index"
                width="80"
              />
              <el-table-column prop="code" label="法规/标准编号" min-width="130">
                <template slot-scope="scope">
                  <span
                    style="cursor: pointer; color: #3f76ea;"
                    @click="
                      viewDetail(
                        '/international/standard/detail/',
                        scope.row.id,
                        scope.row.title,
                        '法规/标准库',
                        'tt_standard'
                      )
                    "
                  >{{ htmlRestore(scope.row.code) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="titleEnglish"
                label="原文名称"
                min-width="160"
              >
                <template slot-scope="{ row }">
                  {{ htmlRestore(row.titleEnglish) }}
                </template>
              </el-table-column>
              <el-table-column prop="title" label="中译名称" min-width="160">
                <template slot-scope="{ row }">
                  {{ htmlRestore(row.title) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="80">
                <template slot-scope="scope">
                  <div class="table-text-btn">
                    <el-button
                      type="text"
                      @click="
                        viewDetail(
                          '/international/standard/detail/',
                          scope.row.id,
                          scope.row.title,
                          '法规/标准库',
                          'tt_standard'
                        )
                      "
                    >查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="text-right">
              <el-pagination
                v-if="pageShow"
                background
                :current-page="standardLibraryPage.pages.page"
                :page-sizes="pageSizes"
                :page-size="standardLibraryPage.pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="standardLibraryPage.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageContainer>
</template>
<script>
/**
 * @description: 法规/标准查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import {
  standardDataExportZip,
  solicitComments,
  solicitCommentsExportZip,
  queryByPageInternational
} from '@/api/standards.js'
import { selectDictDataByType, ttDemension } from '@/api/dict.js'
import { downloadFile } from '@/utils/download.js'
import searchGrop from '@/components/searchGrop.vue'
import { buryPoint, selectCountByColumn } from '@/api/common'
import { Message } from 'element-ui'
// import LetterSelect from '@/components/newData/letterSelect/index.vue'

export default {
  components: {
    // LetterSelect,
    // VnotLogin,
    searchGrop
  },
  data() {
    return {
      forms: {},
      countrys: [],
      regulationPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0
      },
      currentCountry: {},
      regulationData: [],
      showDrawer: false,
      isSearch: false,
      show: false,
      showTips: [
        {
          title: '法规/标准动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '法规/标准库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '法规/标准梳理',
          text: '专家梳理整合，真正读懂法规/标准脉络'
        },
        {
          title: '法规/标准大数据',
          text: '用数据深度理解法规/标准结构和趋势'
        }
      ],
      viewSwitchFlag: false,
      checked: [],
      form: {
        // applicableProducts: [],
        // 适用产品
        // category: '',
        // 法规/标准类别
        code: '',
        // 法规/标准号
        state: '',
        // inspectionItems: [],
        // 检测项目
        title: '',
        // 法规/标准名称
        // startImplementDate: '',
        // // 实施时间开始
        // endImplementDate: '',
        // // 实施时间结束
        // standardForm: '',
        // // 法规/标准形式
        // standardSystem: '',
        // 国际法规/标准分类："",
        gjbzCategory: '',
        // belongAreas: '',
        // startPublishDate: '',
        // // 颁布开始时间
        // endPublishDate: '',
        // // 颁布结束时间
        // startVoidDate: '',
        // // 废止开始时间
        // endVoidDate: '',
        // // 废止结束时间
        // manageUnit: '',
        // // 归口单位
        // startBeginDate: '',
        // startEndDate: '',
        // overStartDate: '',
        // overEndDate: '',
        titleEnglish: ''
        // publishUnit: '',
        // draftUnit: '',
        // managerUnit: '',
        // region: [],
        // country: []
      },
      standardLibraryDate: [],
      regionData: [],
      countryData: [],
      standardLibraryPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0
      },
      category: [],
      applicableProducts: [],
      inspectionItems: [],
      selectTable: [],
      selectTable2: [],
      limitNum: 10,
      activeName: 'bzzt',
      // 征求意见稿
      solicitCommentsData: [],
      solicitCommentsPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0
      },
      // 法规/标准形式
      standardForm: [],
      // 法规/标准状态
      state: [],
      gjbzCategory: [],
      // 法规/标准分类目录
      standardSystem: [],
      categoryProps: {
        value: 'id',
        label: 'name',
        checkStrictly: true,
        emitPath: false
      },
      // 所属领域
      belongAreas: [],
      belongAreasProps: {
        multiple: true,
        value: 'id',
        label: 'name'
      },
      publishDate: '',
      implementDate: '',
      voidDate: '',
      startBeginDate: [],
      overStartDate: [],
      loading: false,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '',
        columnName: '法规/标准',
        userId: ''
      },
      pageSizes: [10, 20, 30, 40],
      pageShow: true,
      bzfl: true,
      countryList: [],
      isCountryOrStand: ''
    }
  },
  computed: {
    isClear() {
      return (arr, id) => {
        return arr == id
      }
    }
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.bzfl = newVal.gjbzCategory?.length <= 0
      }
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }

    // this.standardLibrary(this.standardLibraryPage.pages)
    // this.getCategory()
    // this.getStandardForm()
    // this.getState()
    this.getGjbzCategory()
    // this.getBelongAreas()
    // this.getApplicableProducts();
    // this.getInspectionItems()
    this.ttDemension()
    // this.getStandardInternational()
  },
  methods: {
    searchs() {
      this.showDrawer = false
      this.isSearch = true
    },
    initTable() {},
    handleSizeChanges(val) {
      this.regulationPage.pages.pageSize = val
      this.initTable()
    },
    handleCurrentChanges(val) {
      this.regulationPage.pages.page = val
      this.initTable()
    },
    changeCountry(row, isCountryOrStand) {
      const newUrl = this.$router.resolve({
        path: '/international/standard/index'
      })
      if (getUserInfo()) {
        this.isSearch = true
        this.isCountryOrStand = isCountryOrStand
        this.standardLibraryPage.pages.page = 1
        this.standardLibraryPage.pages.pageSize = 10
        this.$set(this.form, 'title', '')
        this.$set(this.form, 'titleEnglish', '')
        this.$set(this.form, 'code', '')
        if (isCountryOrStand == 'country') {
          this.currentCountry = row
          this.form.gjbzCategory = null
        } else {
          this.form.gjbzCategory = row.dictLabel
        }
        this.searchForm()
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    queryByPageInternational() {
      const query = {
        country: this.currentCountry.id,
        page: this.regulationPage.pages.page || 1,
        pageSize: this.regulationPage.pages.pageSize || 10
      }
      queryByPageInternational(query).then(res => {
        if (res.ok) {
          this.regulationData = res.data.list
          this.regulationPage.total = res.data.count
        } else {
          this.$message.error(res.message)
        }
      })
    },
    bzflchange(e) {
      this.bzfl = true
      this.form.gjbzCategory = ''
      this.searchForm()
    },
    handleChange(e) {
      console.log(e)
      this.form.gjbzCategory = e
      this.searchForm()
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    viewDetail(path, id, title, column2, tableName) {
      this.formLog.eventType = '1'
      const newUrl = this.$router.resolve({
        path: path + id
      })

      if (getUserInfo()) {
        if (
          getUserInfo().roleKey == 'paidrole' ||
          getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('国际化')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              selectCountByColumn({
                'userId': getUserInfo().userId,
                'column1': '国际化',
                'column2': column2
              }).then((res) => {
                if (res.ok) {
                  if (res.data != null && res.data >= 10) {
                    Message({
                      showClose: true,
                      message: '您的体验次数已达上限，请正式开通！',
                      type: 'error'
                    })
                    return
                  } else {
                    logRecords('国际化', column2, '1', tableName, id, '', title, '')
                    window.open(newUrl.href, '_blank')
                  }
                }
              })
            } else {
              logRecords('国际化', column2, '1', tableName, id, '', title, '')
              window.open(newUrl.href, '_blank')
            }
          }
        } else {
          logRecords('国际化', column2, '1', tableName, id, '', title, '')

          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    standardLibrary(params) {
      if (!getUserInfo()) {
        this.pageSizes = [10]
      }
      if (!getUserInfo() && this.standardLibraryPage.pages.page > 1) {
        this.showLogin()
      } else {
        this.loading = true
        // params.category = '4'
        queryByPageInternational(params).then((res) => {
          if (res.ok) {
            this.standardLibraryDate = res.data.list
            this.standardLibraryPage.total = res.data.count
          }
          this.loading = false
        })
      }
    },
    handleSizeChange(val) {
      this.standardLibraryPage.pages.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      // const menu = this.$route.meta.acName
      // if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
      //   // 则不允许翻页，弹窗提示并埋点
      //   openSy()
      //   this.insertBuryPoint()
      //   this.pageShow = false
      //   this.$nextTick(() => {
      //     this.pageShow = true
      //   })
      //   return
      // } else {
      this.standardLibraryPage.pages.page = val
      this.init()
      // }
    },
    // 输入国家输出对应val
    countryChange(val) {
      const foundItem = this.countryList.find((item) => {
        return item.dictLabel === val
      })
      return foundItem ? foundItem.dictValue : null // 如果找到了，返回dictValue，否则返回null
    },
    // 表单查询
    init() {
      const form = Object.assign({}, this.form, this.standardLibraryPage.pages)
      // form.applicableProducts = form.applicableProducts.join(",");
      // form.region = form.region.join(',')
      // form.country = form.country.join(',')
      // form.country = form.country.map((item) => {
      //   return this.countryChange(item)
      // }).join(',')
      if (this.isCountryOrStand == 'country') {
        form.country = this.currentCountry.id
      }

      // form.inspectionItems = form.inspectionItems.join(',')
      // form.startImplementDate = this.implementDate[0]
      // form.endImplementDate = this.implementDate[1]
      // form.startPublishDate = this.publishDate[0]
      // form.endPublishDate = this.publishDate[1]
      // form.startVoidDate = this.voidDate[0]
      // form.endVoidDate = this.voidDate[1]
      // if (form.belongAreas) {
      //   form.belongAreas = this.unique(
      //     form.belongAreas.join(',').split(',')
      //   ).join(',')
      // }

      delete form.startBeginDate
      delete form.startEndDate
      delete form.overStartDate
      delete form.overEndDate
      delete form.manageUnit
      this.standardLibrary(form)
    },
    searchForm() {
      this.formLog.eventType = '2'
      if (getUserInfo()) {
        logRecords(
          '国际化',
          '法规/标准库',
          '4',
          'tt_standard',
          '',
          '',
          '',
          this.form.title
        )
        if (
          getUserInfo().roleKey == 'paidrole' ||
          getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('国际化')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            this.standardLibraryPage.pages.page = 1
            this.solicitCommentsPage.pages.page = 1
            this.init()
            // this.init2()
          }
        } else {
          this.standardLibraryPage.pages.page = 1
          this.solicitCommentsPage.pages.page = 1
          this.init()
          // this.init2()
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 视图切换
    viewSwitch() {
      this.viewSwitchFlag = !this.viewSwitchFlag
    },
    // 法规/标准类别字典查询
    getCategory() {
      // mng_bz_standard_typeOne
      selectDictDataByType({ dictType: 'standard_type' }).then((res) => {
        if (res.ok) {
          this.category = res.data
        }
      })
    },
    // 法规/标准形式字典查询
    getStandardForm() {
      selectDictDataByType({ dictType: 'mng_bz_standard_modality' }).then(
        (res) => {
          if (res.ok) {
            this.standardForm = res.data
          }
        }
      )
    },
    ttDemension() {
      // ttDemension({ code: 'standardAffectBody' }).then((res) => {
      //   this.standardSystem = res.data
      // })
      ttDemension({ code: 'country' }).then((res) => {
        this.countrys = res.data
      })
    },
    // 法规/标准状态
    getState() {
      selectDictDataByType({ dictType: 'mng_bz_standard_state' }).then(
        (res) => {
          if (res.ok) {
            this.state = res.data
          }
        }
      )
      selectDictDataByType({ dictType: 'international_state' }).then((res) => {
        if (res.ok) {
          this.regionData = res.data
        }
      })

      selectDictDataByType({ dictType: 'international_country' }).then((res) => {
        if (res.ok) {
          // this.countryData = res.data
          this.countryList = res.data
          this.countryData = res.data.map(item => {
            return {
              label: item.dictLabel,
              value: item.dictLabel
            }
          })
        }
      })
    },
    // 法规/标准分类
    getGjbzCategory() {
      selectDictDataByType({ dictType: 'standard_gjbz_type' }).then((res) => {
        if (res.ok) {
          this.gjbzCategory = res.data
        }
      })
    },
    // 所属领域
    getBelongAreas() {
      // tagsThree
      ttDemension({ code: 'standardTagsThree' }).then((res) => {
        this.belongAreas = res.data
      })
    },
    // 适用产品
    getApplicableProducts() {
      selectDictDataByType({ dictType: 'mng_cpfl' }).then((res) => {
        if (res.ok) {
          this.applicableProducts = res.data
        }
      })
    },
    // 检测项目
    getInspectionItems() {
      selectDictDataByType({ dictType: 'mng_jcxm' }).then((res) => {
        if (res.ok) {
          this.inspectionItems = res.data
        }
      })
    },
    // 重置表单
    resetForm() {
      this.$refs.form.resetFields()
      this.form.orderType = ''
      this.standardLibraryPage.pages.page = 1
      this.solicitCommentsPage.pages.page = 1
      this.implementDate = ''
      this.publishDate = ''
      this.voidDate = ''
      this.startBeginDate = []
      this.overStartDate = []
      // this.form.gjbzCategory = ''
      this.$refs.tableBox.clearSort()
      this.init()
      // this.init2()
    },
    // 选择条数
    limitFun(list, form) {
      this.$refs[form].clearSelection()
      for (let i = 0; i < this.limitNum; i++) {
        this.$refs[form].toggleRowSelection(list[i])
      }
    },
    // 多选
    handleSelectionChange(val) {
      if (val.length > this.limitNum) {
        this.limitFun(val, 'tableBox')
        return
      }
      this.selectTable = [...val]
    },
    handleSelectionChange2(val) {
      if (val.length > this.limitNum) {
        this.limitFun(val, 'tableBox2')
        return
      }
      this.selectTable2 = [...val]
    },
    // 选中回调
    checkSelectTable(row) {
      const index = this.selectTable.findIndex((v) => v.id == row.id)
      if (this.selectTable.length >= this.limitNum) {
        if (index != -1) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    checkSelectTable2(row) {
      const index = this.selectTable2.findIndex((v) => v.id == row.id)
      if (this.selectTable2.length >= this.limitNum) {
        if (index != -1) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    // 导出
    excelBtn() {
      this.formLog.eventType = '0'
      if (getUserInfo()) {
        if (
          getUserInfo().roleKey == 'paidrole' ||
          getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('国际化')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            } else {
              if (this.selectTable.length <= 0) {
                this.$message({
                  message: '请选择导出数据',
                  type: 'warning'
                })
                return false
              }
              const ids = this.selectTable.map((item) => item.id).join(',')
              const self = this
              standardDataExportZip({ ids }).then((res) => {
                var reader = new FileReader()
                reader.readAsText(res)
                reader.addEventListener('loadend', function(e) {
                  try {
                    JSON.parse(e.target.result)
                    const msg = JSON.parse(e.target.result)
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: 'error'
                    })
                  } catch (err) {
                    downloadFile(res, '法规/标准库.zip')
                  }
                })
              })
            }
          }
        } else {
          if (this.selectTable.length <= 0) {
            this.$message({
              message: '请选择导出数据',
              type: 'warning'
            })
            return false
          }
          const ids = this.selectTable.map((item) => item.id).join(',')
          const self = this
          standardDataExportZip({ ids }).then((res) => {
            var reader = new FileReader()
            reader.readAsText(res)
            reader.addEventListener('loadend', function(e) {
              try {
                JSON.parse(e.target.result)
                const msg = JSON.parse(e.target.result)
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: 'error'
                })
              } catch (err) {
                downloadFile(res, '法规/标准库.zip')
              }
            })
          })
        }
      } else {
        this.showLogin()
      }
    },
    handleSizeChange2(val) {
      this.solicitCommentsPage.pages.pageSize = val
      this.init2()
    },
    handleCurrentChange2(val) {
      this.solicitCommentsPage.pages.page = val
      this.init2()
    },
    init2() {
      const form = Object.assign({}, this.form, this.solicitCommentsPage.pages)
      // form.applicableProducts = form.applicableProducts.join(",");
      form.inspectionItems = form.inspectionItems.join(',')
      delete form.code
      delete form.state
      delete form.inspectionItems
      delete form.startImplementDate
      delete form.endImplementDate
      delete form.startPublishDate
      delete form.endPublishDate
      delete form.startVoidDate
      delete form.endVoidDate
      delete form.orderType
      form.startBeginDate = this.startBeginDate[0] || ''
      form.startEndDate = this.startBeginDate[1] || ''
      form.overStartDate = this.overStartDate[0] || ''
      form.overEndDate = this.overStartDate[1] || ''

      if (form.belongAreas) {
        form.belongAreas = this.unique(
          form.belongAreas.join(',').split(',')
        ).join(',')
      }
      this.solicitComments(form)
    },
    // 法规/标准征求意见/报批稿
    solicitComments(params) {
      if (!getUserInfo()) {
        this.pageSizes = [10]
      }
      if (!getUserInfo() && this.solicitCommentsPage.pages.page > 1) {
        this.showLogin()
      } else {
        solicitComments(params).then((res) => {
          if (res.ok) {
            this.solicitCommentsData = res.data.list
            this.solicitCommentsPage.total = res.data.count
          }
        })
      }
    },
    // 法规/标准分类目录切换
    categoryChange(val) {
      // eslint-disable-next-line no-unused-vars
      let categoryType = ''
      if (val == 1 || val == 2 || val == 4 || val == 6) {
        categoryType = 'system'
      }
      if (val == 3) {
        categoryType = 'city'
      }
      if (val == 5) {
        categoryType = 'team'
      }
    },
    // 导出2
    excelBtn2() {
      this.formLog.eventType = '0'
      if (getUserInfo()) {
        if (
          getUserInfo().roleKey == 'paidrole' ||
          getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('国际化')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            } else {
              if (this.selectTable2.length <= 0) {
                this.$message({
                  message: '请选择导出数据',
                  type: 'warning'
                })
                return false
              }
              const ids = this.selectTable2.map((item) => item.id).join(',')
              const self = this
              solicitCommentsExportZip({ ids }).then((res) => {
                var reader = new FileReader()
                reader.readAsText(res)
                reader.addEventListener('loadend', function(e) {
                  try {
                    JSON.parse(e.target.result)
                    const msg = JSON.parse(e.target.result)
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: 'error'
                    })
                  } catch (err) {
                    downloadFile(res, '法规/标准征求意见/报批稿.zip')
                  }
                })
              })
            }
          }
        } else {
          if (this.selectTable2.length <= 0) {
            this.$message({
              message: '请选择导出数据',
              type: 'warning'
            })
            return false
          }
          const ids = this.selectTable2.map((item) => item.id).join(',')
          const self = this
          solicitCommentsExportZip({ ids }).then((res) => {
            var reader = new FileReader()
            reader.readAsText(res)
            reader.addEventListener('loadend', function(e) {
              try {
                JSON.parse(e.target.result)
                const msg = JSON.parse(e.target.result)
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: 'error'
                })
              } catch (err) {
                downloadFile(res, '法规/标准征求意见/报批稿.zip')
              }
            })
          })
        }
      } else {
        this.showLogin()
      }
    },
    // 数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1)
            j--
          }
        }
      }
      return arr
    },
    // tab切换
    handleClick() {
      this.resetForm()
      this.checkSelectTable({ id: 0 })
      this.checkSelectTable2({ id: 0 })
      this.selectTable2 = []
      this.selectTable = []
      this.$refs.tableBox.clearSelection()
      this.$refs.tableBox2.clearSelection()
    },
    tableSort(column) {
      this.form.orderType = this.otherzh(column)
      this.init()
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        if (column.prop == 'publishDate') {
          return 'publish_date'
        } else if (column.prop == 'implementDate') {
          return 'implement_date'
        } else {
          return column.prop
        }
      } else if (column.order == 'descending') {
        if (column.prop == 'publishDate') {
          return 'publish_date' + ' DESC'
        } else if (column.prop == 'implementDate') {
          return 'implement_date' + ' DESC'
        } else {
          return column.prop + ' DESC'
        }
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1366px) {
  .mainContent{
    zoom: 0.76;
  }
  // .texts{
  //   font-size: 12px!important;
  // }
  // .icons{
  //   width: 24px!important;
  // }
  // .imgs{
  //   width: 25px!important;
  //   img{
  //     width: 25px!important;
  //   }
  // }
  // .issearch{
  //   .countryName{
  //     .countryTitle{
  //       width: calc(100% - 83px)!important;
  //       height: 38px!important
  //     }
  //     .countryImg{
  //       width: 72px!important;
  //       height: 36px!important;
  //     }
  //     img{
  //       height:36px!important
  //     }
  //   }
  // }
}
.searchbtns{
  /deep/span{
    width: 100%;
  }
  img{
    width: 15px;
    height: 15px;
    margin-right: 4px;
    margin-bottom: 3px;
  }
}
.searchbtns.reset-button{
  border: 1px solid #0D57BC;
  padding: 6px 0;
  width: 90px;
  /deep/span{
    color: #0D57BC;
  }
}
.searchbtns.search-button{
  width: 90px;
  height: 38px;
  padding: 3px 0 0;

}
.exportBts{
  height: 32px;
  padding: 6px 18px;
  img{
    width: 15px;
    margin-bottom: 2px;
  }
}
/deep/.title{
  border: none!important;
}
.back{
  background: #fff;
  padding: 20px 40px 0 40px;
  font-size: 14px;line-height: 20px;
  cursor: pointer;
  i.el-icon-back{
    margin-right: 10px;
    color: #3974DE;font-size: 20px;
    vertical-align: bottom;
  }
}
.mains{
  margin: 25px;
}
.issearch{
  padding: 28px 0px!important;
  h3{
    margin-left: 20px;
  }
  /deep/.inner{
    color: #333;
    margin-left: 20px;
  }
  /deep/.content-b{
    padding: 0px 5px;
  }
  .countryName{
    width: calc(100% / 5 - 15px);
    cursor: pointer;
    float: left;
    margin:0 0 18px 15px;
    border-radius: 4px;
    background: linear-gradient( 90deg, #F6F6F6 0%, #FFFFFF 100%);
    border: 1px solid #e8e8e8;
    padding:9px 10px;
    .countryImg{
      width: 120px;
      height: 60px;
      display: inline-block;
      text-align: center;
      img{
        height: 60px;
        text-align: center;
      }
    }
    .countryTitle{
      margin-left: 10px;
      display: flex;
      align-items: center;
      float:right;
      height: 60px;
      width: calc(100% - 130px);
      .title1{
        color: #040404;
        margin-bottom: 0px;
        line-height: 24px;
        margin-bottom: 2px;
        border: none;
      }
      .title2{
        color: #909090;
        margin-bottom: 0px;
        line-height: 14px;
        border: none;
      }
    }

  }
  .standName{
    width: calc(100% / 5 - 15px);
    cursor: pointer;
    float: left;
    margin:0 0 18px 15px;
    border-radius: 4px;
    padding:0 12px;
    height:70px;
    line-height: 14px;
    color: #040404;
    text-align: center;
    background: linear-gradient( 90deg, #F6F6F6 0%, #FFFFFF 100%);
    border: 1px solid #e8e8e8;
  }
}
.titles{
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  .radios{
    width: 6px;
    height: 6px;
    background: #0D57BC;
    border-radius: 10px;
    margin-right: 9px;
    display: inline-block
  }
}
.outer-layer-box {
  padding-top: 0.666rem;
  padding-right: 0.666rem;
  padding-bottom: 0.666rem;
}
.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;

  .el-form-item {
    margin-bottom: 16px;
  }
}

.shijian {
  /deep/ .el-date-editor .el-range-input {
    width: 44%;
  }
}

.state {
  padding: 4px 6px;
  background: #ffd450;
  border-radius: 2px;
  font-size: 14px;
  line-height: 22px;
  color: #000;
  font-weight: bold;
}

.state.gre {
  background: #27ae60;
  color: #fff;
}

.state.red {
  background: #eb5757;
  color: #fff;
}

.state.blue {
  background: #0d57bc;
  color: #fff;
}

.import-input {
  position: relative;

  /deep/ .el-input__inner {
    background: rgba(13, 87, 188, 0.03);
    border: 1px solid rgba(13, 87, 188, 0.7);
    border-radius: 2px;
    padding-right: 40px;

    &::placeholder {
      color: rgba(13, 87, 188, 0.5);
    }
  }

  &::after {
    position: absolute;
    right: 5px;
    top: 10px;
    display: block;
    content: "常用";
    text-align: center;
    width: 30px;
    height: 20px;
    line-height: 20px;
    background: rgba(13, 87, 188, 0.5);
    border-radius: 1px;
    font-weight: bold;
    font-size: 12px;
    color: #fff;
  }
}
::v-deep .el-divider {
  background-color: #f2f2f2;
}
.bigbox {
  background: #ffffff;
  padding: 6px 20px;

  /deep/.el-select .el-input__inner::placeholder {
    color: rgb(51, 51, 51);
  }

  .flexC {
    // margin-bottom: 20px;
    display: flex;
    align-items: center;
    label {
      display: inline-block;
      width: 100px;
    }
  }

  .checkTitle {
    width: 60px;
    margin-right: 16px;
    display: inline-block;
    color: rgb(153, 153, 153);
  }

  ::v-deep {
    .el-input__inner {
      border-radius: 4px;
    }

    .el-radio-button {
      margin-right: 30px;

      .el-radio-button__inner {
        padding: 7px 13px;
        min-width: 58px;
        color: #333;
        font-size: 14px;
        font-weight: normal;
        border-radius: 22px !important;
        border: 0;

        span {
          margin-top: 1px;
          margin-right: -4px;
        }
      }
    }

    .el-radio-button .el-radio-button__inner:hover {
      color: #3f76ea;
    }

    .is-active .el-radio-button__inner {
      background: #3f76ea !important;
      color: #FFF !important;
      border-color: #fff !important;
    }

    .el-form--inline .el-form-item__content {
      line-height: normal;
    }
  }
}

</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;
}

.el-message-box__message p {
  line-height: 14px;
}
</style>
