<template>
  <div class="compList">
    <el-table
      ref="newTable"
      v-loading="loading"
      :data="tableData"
      style="width: 100%;margin-top: 10px;"
      :header-cell-style="{ background: '#F5F7FA', color: '#000' }"
    >
      <el-table-column prop="enterprise" label="企业名称" align="center" />
      <el-table-column prop="reportPeriod" label="报告期" align="center">
        <template slot-scope="scope">
          {{ scope.row.reportDate }}年<br>{{ scope.row.reportPeriod }}
        </template>
      </el-table-column>

      <el-table-column label="营业总收入" align="center">
        <el-table-column prop="totalOperatingIncome" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.totalOperatingIncome) }}
          </template>
        </el-table-column>
        <el-table-column prop="totalOperatingIncomeYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="利润总额" align="center">
        <el-table-column prop="grossProfit" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.grossProfit) }}
          </template>
        </el-table-column>
        <el-table-column prop="grossProfitYoy" label="同比(%)" align="center" />

      </el-table-column>
      <el-table-column label="营业利润" align="center">
        <el-table-column prop="operatingProfit" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.operatingProfit) }}
          </template>
        </el-table-column>
        <el-table-column prop="operatingProfitYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="除所得税前利润" align="center">
        <el-table-column prop="preTaxProfit" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.preTaxProfit) }}
          </template>
        </el-table-column>
        <el-table-column prop="preTaxProfitYoy" label="同比(%)" align="center" />

      </el-table-column>
      <el-table-column label="净利润" align="center">
        <el-table-column prop="netProfit" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.netProfit) }}
          </template>
        </el-table-column>
        <el-table-column prop="netProfitYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="归母净利润" align="center">
        <el-table-column prop="motherNetProfit" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.motherNetProfit) }}
          </template>
        </el-table-column>
        <el-table-column prop="motherNetProfitYoy" label="同比(%)" align="center" />
      </el-table-column>

    </el-table>
  </div>
</template>
<script>

import { bigNumberTransform } from '@/utils/number'

export default {
  props: {
    tableData: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    bigNumberTransform,
    tableSort(column) {
      const orderType = this.otherzh(column)
      this.$emit('custom-sort-change', orderType)
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        return column.prop
      } else if (column.order == 'descending') {
        return column.prop + ' DESC'
      } else {
        return ''
      }
    }

  }
}
</script>

<style scoped lang="scss"></style>
