<template>
  <div class="compList">
    <el-table
      ref="newTable"
      v-loading="loading"
      :data="tableData"
      style="width: 100%;margin-top: 10px;"
      :header-cell-style="{ background: '#F5F7FA', color: '#000' }"
    >
      <el-table-column prop="enterprise" label="企业名称" align="center" />
      <el-table-column prop="reportPeriod" label="报告期" align="center">
        <template slot-scope="scope">
          {{ scope.row.reportDate }}年<br>{{ scope.row.reportPeriod }}
        </template>
      </el-table-column>
      <el-table-column label="营运资金变动前经营溢利" align="center">
        <el-table-column prop="opBfWc" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.opBfWc) }}
          </template>
        </el-table-column>
        <el-table-column prop="opBfWcYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="经营产生的现金" align="center">
        <el-table-column prop="cfop" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.cfop) }}
          </template>
        </el-table-column>
        <el-table-column prop="cfopYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="经营活动产生的净现金" align="center">
        <el-table-column prop="ncfo" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.ncfo) }}
          </template>
        </el-table-column>
        <el-table-column prop="ncfoYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="投资活动使用的净现金" align="center">
        <el-table-column prop="ncuia" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.ncuia) }}
          </template>
        </el-table-column>
        <el-table-column prop="ncuiaYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="投资活动使用的净现金" align="center">
        <el-table-column prop="ncufa" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.ncufa) }}
          </template>
        </el-table-column>
        <el-table-column prop="ncufaYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="现金及现金等价物的减少净额" align="center">
        <el-table-column prop="ndccec" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.ndccec) }}
          </template>
        </el-table-column>
        <el-table-column prop="ndccecYoy" label="同比(%)" align="center" />
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

import { bigNumberTransform } from '@/utils/number'

export default {
  props: {
    tableData: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    bigNumberTransform,
    tableSort(column) {
      const orderType = this.otherzh(column)
      this.$emit('custom-sort-change', orderType)
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        return column.prop
      } else if (column.order == 'descending') {
        return column.prop + ' DESC'
      } else {
        return ''
      }
    }

  }
}
</script>

<style scoped lang="scss"></style>
