<!--
 * @Author: your name
 * @Date: 2021-07-23 09:01:13
 * @LastEditTime: 2021-07-23 18:07:01
 * @LastEditors: Please set LastEditors
 * @Description: 企业信息页面
 * @FilePath: /information-vue/code/src/views/workInformation/information/indexBalance.vue
-->

<template>
  <PageContainer>
    <div ref="controllerRef" class="sticky-container">
      <div class="box_box" style="background:#fff">
        <div flex="sb">
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane v-for="item in qiyeData" :key="item.dictCode" :label="item.dictLabel" :name="item.dictLabel" />
              <!-- <el-tab-pane label="乘用车企业" name="乘用车企业"></el-tab-pane>
              <el-tab-pane label="商用车企业" name="商用车企业"></el-tab-pane>
              <el-tab-pane label="零部件企业" name="零部件企业"></el-tab-pane> -->
            </el-tabs>
          </div>
          <div class="search-input-box">
            <el-input
              v-model="enterpriseName"
              placeholder="输入检索内容"
              size="small"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-search"
                @click="queryGroupByInitial"
              />
            </el-input>
          </div>
        </div>
      </div>
      <div class="alphabetic-sorting" style="background:#fff">
        <div
          v-for="item in alphabetic"
          :key="item"
          class="alphabetic-sorting-item"
          :class="{
            ac: alphabeticName == item,
            dis: filterAlphabetic.indexOf(item) == -1,
          }"
          v-scroll-to="filterAlphabetic.indexOf(item) == -1 ? null : {
            el: `#${item}`,
            duration: 100,
            lazy: false,
            easing: 'easing',
            offset: -scrollOffset,
            force: true,
            cancelable: true,
            x: false,
            y: true,
          }"
        >{{ item }}</div>
      </div>
    </div>

    <div class="list-group">
      <ul
        v-for="item in list"
        :id="item.firstChar"
        :key="item.firstChar"
        class="list-group-ul"
      >
        <li
          v-for="(citem, index) in item.org"
          :key="citem.id"
          @click="toHref(citem)"
        >
          <h1 v-if="index == 0" class="list-group-title">
            {{ item.firstChar }}
          </h1>
          <div class="list-group-inner">
            <img :src="citem.imgUrl?citem.imgUrl:defultImg" alt="">
            <div class="list-group-inner-r">
              <h1>{{ citem.enterpriseName }}</h1>
              <!-- <p>4个热门子公司</p> -->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </PageContainer>
</template>
<script>
/**
 * @description: 企业情报动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo, logRecords } from '@/utils/auth.js'
import { queryGroupByInitial } from '@/api/enterpriseAnalysis.js'
import { selectDictDataByType } from '@/api/dict.js'
import { buryPoint } from '@/api/common'
import * as scssVars from '@/styles/variables.module';

export default {
  // components: {
  //   VnotLogin,
  // },
  data() {
    return {
      input3: '',
      activeName: '乘用车企业',
      qiyeData: [],
      showTips: [
        {
          title: '企业情报动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '企业情报库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '企业情报梳理',
          text: '专家梳理整合，真正读懂企业情报脉络'
        },
        {
          title: '企业情报大数据',
          text: '用数据深度理解企业情报结构和趋势'
        }
      ],
      show: false,
      alphabetic: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'W',
        'X',
        'Y',
        'Z'
      ],
      list: [],
      alphabeticName: '',
      filterAlphabetic: [],
      enterpriseName: '',
      defultImg: require('@/assets/img/default-img.png'),
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '企业情报',
        userId: ''
      },
      controllerHeight: 0,
      thershold: 20,
    }
  },
  computed: {
    scrollOffset() {
      return scssVars.topNavHeight + scssVars.headerHeight + this.controllerHeight + this.thershold;
    },
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }
    this.queryGroupByInitial()
    this.selectDictDataByType()
  },
  mounted() {
    document.addEventListener('scroll', this.scrollFun);
    this.setControllerHeight();
  },
  beforeDestory() {
    document.removeEventListener('scroll', this.scrollFun);
  },
  methods: {
    setControllerHeight() {
      this.controllerHeight = this.$refs.controllerRef.clientHeight;
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 搜索
    handleSizeChange() {},
    handleCurrentChange() {},
    // 获取
    selectDictDataByType() {
      selectDictDataByType({ dictType: 'enterprise_type' }).then(res => {
        if (res.ok) {
          this.qiyeData = res.data.filter((item) => {
            return item.dictLabel != '整车集团' && item.dictLabel != '零部件企业'
          })
        }
      })
    },
    handleClick(tab, event) {
      this.queryGroupByInitial()
    },
    // 字母获取列表
    queryGroupByInitial() {
      const data = {
        enterpriseName: this.enterpriseName,
        enterpriseType: this.activeName
      }

      queryGroupByInitial(data).then((res) => {
        if (res.ok) {
          this.list = res.data.map((item) => {
            item.org = JSON.parse(item.org)
            // item.org.map(citem=>citem.name = item.firstChar)
            return item
          })
          this.filterAlphabeticFun(this.list)
        }
      })
    },
    // 滚动锁定字母
    scrollFun() {
      const offset = this.controllerHeight + this.thershold;
      const scrollTop = document.documentElement.scrollTop ?? document.body.scrollTop;
      const blocks = document.querySelectorAll('.list-group-ul')
      blocks.forEach((item, index) => {
        if (scrollTop >= item.offsetTop - offset) {
          this.alphabeticName = this.list[index].firstChar
        }
      })
    },
    // 过滤字母
    filterAlphabeticFun(data) {
      this.filterAlphabetic = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].org.length > 0) {
          this.filterAlphabetic.push(data[i].firstChar)
        }
      }
      this.alphabeticName = this.filterAlphabetic[0]
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    // 跳转
    toHref(item) {
      const newUrl = this.$router.resolve({ path: '/workInformation/information/workDetail/', query: { enterpriseId: item.id, enterpriseType: item.enterpriseType }})
      if (getUserInfo()) {
        logRecords('企业情报', '企业信息', '1', 'tt_enterprise_info', item.id, '', item.enterpriseName, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__nav {
  float: none;
  margin: 0 10px;
}
::v-deep .el-tabs__active-bar {
  display: none;
}

::v-deep .el-tabs__nav-wrap::after {
  display: none;
}

::v-deep .el-checkbox__input {
  display: none;
}
.box_box {
  padding: 10px;
  background: #ffffff;
  border-bottom: 1px solid #f2f2f2;
  /deep/ .el-tabs__header {
    margin-bottom: 0;
  }
}
.alphabetic-sorting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  .alphabetic-sorting-item {
    width: 36px;
    height: 36px;
    border: 1px solid rgb(217, 217, 217);
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    &.ac {
      color: #0D57BC;
      border-color: #0D57BC;
    }
    &.dis {
      color: #d9d9d9;
      border-color: #d9d9d9;
      cursor: default;
    }
  }
}
.list-group {
  flex: 1;
  overflow-y: auto;
  padding-top: 26px;
  background: #fff;
  ul {
    width: 75%;
    margin: 0 auto;
    li {
      margin-bottom: 16px;
    }
  }
  .list-group-title {
    width: 100%;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    background: #abbed8;
    color: #0D57BC;
  }
  .list-group-inner {
    display: flex;
    align-items: center;
    background: #f8f8f8;
    cursor: pointer;
    img {
      width: 83px;
      height: 83px;
    }
    .list-group-inner-r {
      flex: 1;
      margin-left: 15px;
      h1 {
        font-size: 18px;
        line-height: 19px;
        color: #333;
        margin-bottom: 12px;
      }
      p {
        font-size: 14px;
        line-height: 19px;
        color: #828282;
      }
    }
  }
}
.search-input-box {
  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
