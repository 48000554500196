<template>
  <div class="compList">
    <el-table
      ref="newTable"
      v-loading="loading"
      :data="tableData"
      style="width: 100%;margin-top: 10px;"
      :header-cell-style="{ background: '#F5F7FA', color: '#000' }"
    >
      <el-table-column prop="enterprise" label="企业名称" align="center" />
      <el-table-column prop="reportPeriod" label="报告期" align="center">
        <template slot-scope="scope">
          {{ scope.row.reportDate }}年<br>{{ scope.row.reportPeriod }}
        </template>
      </el-table-column>
      <el-table-column label="净现金流" align="center">
        <el-table-column prop="netCashFlow" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.netCashFlow) }}
          </template>
        </el-table-column>
        <el-table-column prop="netCashFlowYoy" label="同比(%)" align="center" />
      </el-table-column>
      <el-table-column label="经营性现金流量净额" align="center">
        <el-table-column prop="netOperatingCashFlow" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.netOperatingCashFlow) }}
          </template>
        </el-table-column>
        <el-table-column prop="netOperatingCashFlowPercentage" label="占比(%)" align="center" />
      </el-table-column>
      <el-table-column label="销售商品、提供劳务收到的现金" align="center">
        <el-table-column prop="cashReceivedSale" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.cashReceivedSale) }}
          </template>
        </el-table-column>
        <el-table-column prop="cashReceivedSalePercentage" label="占比(%)" align="center" />
      </el-table-column>
      <el-table-column label="投资性现金流量净额" align="center">
        <el-table-column prop="netInvestmentCashFlows" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.netInvestmentCashFlows) }}
          </template>
        </el-table-column>
        <el-table-column prop="netInvestmentCashFlowsPercentage" label="占比(%)" align="center" />
      </el-table-column>
      <el-table-column label="取得投资收益收到的现金" align="center">
        <el-table-column prop="cashReceivedInvestmentIncome" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.cashReceivedInvestmentIncome) }}
          </template>
        </el-table-column>
        <el-table-column prop="cashReceivedInvestmentIncomePercentage" label="占比(%)" align="center">
          <template slot-scope="scope">
            {{ scope.row.cashReceivedInvestmentIncomePercentage==null?'-':scope.row.cashReceivedInvestmentIncomePercentage }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="购建固定资产、无形资产和其他长期资产支付的现金" align="center">
        <el-table-column prop="cashPaid" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.cashPaid) }}
          </template>
        </el-table-column>
        <el-table-column prop="cashPaidPercentage" label="占比(%)" align="center" />
      </el-table-column>
      <el-table-column label="融资性现金流量净额" align="center">
        <el-table-column prop="netFinancingCashFlow" label="金额(元)" align="center">
          <template slot-scope="scope">
            {{ bigNumberTransform(scope.row.netFinancingCashFlow) }}
          </template>
        </el-table-column>
        <el-table-column prop="netFinancingCashFlowPercentage" label="占比(%)" align="center" />
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

import { bigNumberTransform } from '@/utils/number'

export default {
  props: {
    tableData: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    bigNumberTransform,
    tableSort(column) {
      const orderType = this.otherzh(column)
      this.$emit('custom-sort-change', orderType)
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        return column.prop
      } else if (column.order == 'descending') {
        return column.prop + ' DESC'
      } else {
        return ''
      }
    }

  }
}
</script>

<style scoped lang="scss"></style>
