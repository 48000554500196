<template>
  <div class="index-banner-title">
    <titlemore title="企业财报" />
    <ul class="index-new-timeLine">
      <li v-for="item in finaList" :key="item.id">
        <div class="index-new-timeLine-content">
          <div style="cursor: pointer" class="index-new-link" @click="toHreffile(item.fileUrl, item)">
            <div flex="c">
              <div style="padding-right: 10px;"><img src="@/assets/img/fileicon.png"></div>
              <TextOverTooltip ref-name="testName1" class-name="newUl-title" :content="item.fileName" />
              <div class="publishDate">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import titlemore from './components/titlemore.vue'
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import { archiveReport } from '@/api/enterpriseAnalysis.js'
import { buryPoint } from '@/api/common'
import TextOverTooltip from '@/components/title/index'
export default {
  components: {
    TextOverTooltip,
    titlemore
  },
  data() {
    return {
      finaList: [],
      show: false,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '企业情报',
        userId: ''
      }
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }
    this.getarchiveReport()
  },
  methods: {
    toHreffile(newUrl, item) {
      if (getUserInfo()) {
        logRecords('企业情报', '企业财报', '2', 'tt_enterprise_financial_report', '', item.fileName, '', '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.insertBuryPoint()
              openSy()
            } else {
              window.open('https://www.autoinfo.org.cn' + newUrl, '_blank')
            }
          }
        } else {
          window.open('https://www.autoinfo.org.cn' + newUrl, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', 'https://www.autoinfo.org.cn' + newUrl)
        this.showLogin()
      }
    },
    moreClick() {
      const newUrl = this.$router.resolve('/workInformation/newFinancialReport/index')
      window.open(newUrl.href, '_blank')
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(item) {
      const newUrl = this.$router.resolve('/workInformation/analysis/analysisContent/' + item.id)
      if (getUserInfo()) {
        logRecords('企业情报', '企业分析报告', '1', 'tt_enterprise_analysis', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    getarchiveReport() {
      archiveReport().then((res) => {
        if (res.ok) {
          this.finaList = res.data.map(item => item.fileInfo).flat().splice(0, 10)
        }
      })
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    }
  }
}
</script>
<style lang="scss" scoped>
.index-banner-title {
    padding: 25px 20px 15px 20px;
    background: #fff;
    .box-top {
        padding-left: 0;
    }
}

.index-new-timeLine {
    padding-top: 12px;
    background: #fff;

    li {
        .index-new-timeLine-content {
            position: relative;

            .index-new-link {
                padding: 8px 0;
                display: block;
                color: #000;
                border-bottom: 1px solid #f4f4f4;

                // padding-left: 20px;
                .publishDate {
                    color: #e6e6e6;
                }

                span {
                    font-size: 16px;
                    padding-right: 10px;
                    font-weight: 600;
                }

                div {
                    span {
                        font-size: 14px;
                        line-height: 16px;

                        &:nth-child(2) {
                            flex: 1;
                        }

                        &:nth-child(3) {
                            color: #bdbdbd;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }

        &:last-child {
            padding-bottom: 0px;

            &::after {
                top: 0;
            }

            .index-new-timeLine-content {
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.newUl-title {
    flex: 1;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    padding-right: 24px;
}
</style>
<style>
.el-message-box {
    width: 440px;
}

.el-message-box__title {
    font-size: 16px;

}

.el-message-box__message p {
    line-height: 14px;
}
</style>
