<template>
  <router-view v-if="$route.name == '政策本网原创内容页'" />
  <PageContainer v-else breadcrumb>
    <!--                <VlistTitle bigTtitle="本网原创" smallTip="会员专享精华原创内容推荐" :bgurl="bgurl" />-->
    <!--                <ListFiltering :list="belongAreasData" @handleChange="handleChange" @search="search" />-->
    <!--                <Vlist :list="localOriginalData" :pageData="localOriginalPage" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" @toHref="toHref" />-->

    <VlistTitle big-ttitle="本网原创" small-tip="会员专享精华原创内容推荐" :bgurl="bgurl" />
    <div class="box_box">
      <div style="overflow: hidden">
        <!-- <div style="float: left; margin-bottom: 10px" flex="c">
            <el-checkbox label="全部" border size="small" style="margin-right: 8px" @change="kckAll" v-model="keywordAll"></el-checkbox>
            <el-checkbox-group v-model="form.keywords" size="small" @change="kcheck" class="lable-box">
                <el-checkbox :label="item.dictValue" border v-for="item in keywords" :key="item.dictValue">{{item.dictLabel}}</el-checkbox>

            </el-checkbox-group>
        </div> -->
        <div style="float: right" class="search-btn-box ">
          <el-input v-model="title" placeholder="本网原创" size="small" />
          <div class="search-btn" @click="search">搜索</div>
        </div>
      </div>
    </div>
    <div v-for="(item) in localOriginalData" :key="item.id" class="box_box_title" @click="toHref(item)">
      <div style="display: flex">
        <div class="text-center" style="width: 267px; height: 150px">
          <img style="width: 267px; height: 150px" :src="item.imageUrl ? item.imageUrl : defaultUrl" alt="">
        </div>
        <div class="flex_col">
          <div>
            <div flex="c" style="margin: 10px 0">

              <p>{{ htmlRestore(item.title) }}</p>
            </div>
            <div class="displayIntroduction" style="font-size: 14px; color: #828282;">
              {{ htmlRestore(item.introduction) }}
            </div>
          </div>
          <div style="font-size: 14px; color: #828282">
            {{ item.updateTime }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="box_box_title">
<div style="display: flex">
<img
  style="width: 300px; height: 150px"
  src="@/assets/img/dnc-img.png"
  alt=""
/>
<div class="flex_col">
  <div>
    <div flex="c" style="margin: 10px 0">
      <span
        style="
          padding: 5px 10px;
          background: #0D57BC;
          color: #ffffff;
          font-weight: 600;
          margin-right: 10px;
        "
        >力荐</span
      >
      <p class="">大陆集团2020年财报分析</p>
    </div>
    <div style="font-size: 14px; color: #828282">
      本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开本报告特别主要围绕集团2020内部信息展开
    </div>
  </div>
  <div style="font-size: 14px; color: #828282">2021-03-17</div>
</div>
</div>
</div> -->
    <div class="pages">
      <el-pagination
        v-if="pageShow"
        :current-page="localOriginalPage.pages.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="localOriginalPage.pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="localOriginalPage.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <template slot="right">
      <div class="index-banner-title">
        <div class="title-tag">
          <!-- <img src="@/assets/img/Group1.png" alt="" /> -->

          <h3 class="title-tag-text" style="margin-top: 10px;margin-bottom: 18px;"><span
            style="padding-right: 8px;font-weight: bold;color: #0d57bc;"
          >//</span>官方解读推荐</h3>

        </div>

        <ul class="index-new-timeLine">
          <li v-for="item in tableDataRight" :key="item.id">
            <div class="index-new-timeLine-content">
              <div
                style="cursor: pointer"
                class="index-new-link"
                @click="toHref('/policy/dynamic/contentExplain/'+item.id)"
              >
                <div flex="c">
                  <img src="@/assets/img/bg.png" alt="">
                  <span class="text-overflow" style=" padding-left: 10px;font-size: 14px;line-height: 20px;">
                    {{ htmlRestore(item.title) }}</span>
                  <span>{{ item.updateTime }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div class="more">
          <router-link
            to="/policy/dynamic/listExplain"
          >查看更多<img
            style="margin-bottom: 4px;"
            src="@/assets/img/jt01.png"
            alt=""
            class="more-icon"
          ></router-link>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
import VlistTitle from '@/components/listTitle.vue'
// import Vlist from "@/components/list.vue";
import { localOriginal } from '@/api/policy.js'
// import ListFiltering from "@/components/ListFiltering.vue";
import { selectDictDataByType } from '@/api/dict.js'
import { getUserInfo, logRecords } from '@/utils/auth'
import { buryPoint, selectYcCount } from '@/api/common'
import { htmlRestore } from '@/utils/htmlRestore'
import { Message } from 'element-ui'

export default {
  components: {
    VlistTitle
    // Vlist,
    // ListFiltering,
  },
  data() {
    return {
      bgurl: require('@/assets/img/bg-1.jpg'),
      localOriginalData: [],
      tableDataRight: [],
      defaultUrl: require('@/assets/img/default-img.png'),
      localOriginalPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      belongAreasData: [],
      belongAreas: [],
      title: '',
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '政策',
        userId: ''
      },
      pageShow: true
    }
  },
  created() {
    this.localOriginal()
    this.localOriginalRight({ page: 1, pageSize: 7, unscrambleUnit: '1' })

    this.selectDictDataByType()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 评价与趋势分析
    localOriginalRight(params) {
      localOriginal(params).then((res) => {
        if (res.ok) {
          this.tableDataRight = res.data.list
          this.tableDataRight.forEach((element) => {
            element.title = htmlRestore(element.title)
          })
        }
      })
    },
    localOriginal() {
      if (!getUserInfo() && this.localOriginalPage.pages.page > 1) {
        this.showLogin()
      } else {
        const form = Object.assign({}, this.localOriginalPage.pages, {
          title: this.title,
          belongAreas: this.belongAreas,
          unscrambleUnit: '1'
        })
        form.belongAreas = form.belongAreas.join(',')
        localOriginal(form).then((res) => {
          if (res.ok) {
            this.localOriginalData = res.data.list
            this.localOriginalPage.total = res.data.count
          }
        })
      }
    },
    handleSizeChange(val) {
      this.localOriginalPage.pages.pageSize = val
      this.localOriginal()
    },
    handleCurrentChange(val) {
      // const menu = this.$route.meta.acName
      // if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName.includes(menu)) {
      //   // 则不允许翻页，弹窗提示并埋点
      //   openSy()
      //   this.insertBuryPoint()
      //   this.pageShow = false
      //   this.$nextTick(() => {
      //     this.pageShow = true
      //   })
      //   return
      // } else {
      this.localOriginalPage.pages.page = val
      this.localOriginal()
      // }
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(item) {
      const url = typeof item === 'string'
        ? item
        : '/policy/dynamic/LocalOriginalDetails/' + item.id
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              selectYcCount({ 'userId': getUserInfo().userId }).then((res) => {
                if (res.ok) {
                  if (res.data.policyYc != null && res.data.policyYc >= 2) {
                    Message({
                      showClose: true,
                      message: '每日两篇政策原创报告浏览次数已达上限！',
                      type: 'error'
                    })
                    return
                  } else {
                    logRecords('政策', '政策原创', '1', 'tt_policy_interpret', item.id, '', item.title, 'yc')
                    window.open(newUrl.href, '_blank')
                  }
                }
              })
            } else {
              logRecords('政策', '政策原创', '1', 'tt_policy_interpret', item.id, '', item.title, 'yc')
              window.open(newUrl.href, '_blank')
            }
          }
        } else {
          logRecords('政策', '政策原创', '1', 'tt_policy_interpret', item.id, '', item.title, 'yc')
          window.open(newUrl.href, '_blank')
        }
      } else {
        sessionStorage.setItem('toUrl', newUrl.href)
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 数据字典
    selectDictDataByType() {
      selectDictDataByType({ dictType: 'mng_zc_policy_type' }).then(
        (res) => {
          if (res.ok) {
            this.belongAreasData = res.data
          }
        }
      )
    },
    // 切换
    handleChange(e) {
      this.belongAreas = e
      this.localOriginal()
    },
    search() {
      this.localOriginalPage.pages.page = 1
      this.localOriginal()
      logRecords('政策', '政策原创', '4', 'tt_policy_interpret', '', '', '', this.title)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input {
  display: none;
}

::v-deep .el-checkbox {
  margin-right: 8px;
  margin-left: 0;
}

::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}

.box_box {
  padding: 10px;
  background: #ffffff;

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.box_box_title {
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
}

.lable-box {
  margin-bottom: -10px;

  .el-checkbox {
    margin-bottom: 10px;
  }
}

.search-btn-box {
  display: flex;
  //margin-top: 9px;
  /deep/ .el-input__inner {
    height: 35px;
    line-height: 35px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .el-input {
    right: -54px;
  }

  .search-btn {
    width: 90px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #0D57BC;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    position: relative;
    border-radius: 20px;
  }
}

.index-banner-title {
  // display: flex;
  justify-content: space-between;
  // align-items: center;
  background-color: #fff;
  padding: 10px 0;
}
.displayIntroduction{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-tag {
  // display: flex;
  align-items: flex-start;

  .title-tag-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    // color: #0d57bc;
    margin-top: -30px;
    margin-left: 20px;
  }
}

.more {
  padding-right: 10px;
  margin-top: 10px;
  color: #0d57bc;
  font-weight: 500;
  text-align: right;
}

.index-new-timeLine,
.index-new-timeLine li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.index-new-timeLine {
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 12px;

  li {

    // position: relative;
    // &::before {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   top: 7px;
    //   width: 8px;
    //   height: 8px;
    //   border-radius: 50%;
    //   background: #fff;
    //   border: 2px solid #0d57bc;
    //   z-index: 1;
    // }

    .index-new-timeLine-content {
      position: relative;
      padding-left: 4px;
      padding-bottom: 11px;

      .index-new-link {
        display: block;
        color: #000;
        padding-bottom: 11px;
        border-bottom: 1px solid #dcdcdc;

        // padding-left: 20px;
        div {
          span {
            font-size: 14px;
            line-height: 16px;

            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              color: #bdbdbd;
              padding-left: 10px;
            }
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0px;

      &::after {
        top: 0;
      }

      .index-new-timeLine-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
