<template>
  <div ref="aiServiceRef" v-if="open" class="ai-container">
    <div class="header">
      <span class="title">在线智能问答</span>
      <span class="desc">
        <span>说明：试运行功能，如有任何问题可点击处</span>
        <span class="btn" @click="handleMessage">留言反馈</span>
        <span>给我们</span>
      </span>
      <i class="el-icon-close" @click="handleClose"></i>
    </div>

    <div ref="wrapperRef" class="wrapper" :style="{ height: `${height}px` }">
      <el-scrollbar ref="scrollbarRef" wrap-class="scrollbar-wrapper">
        <div ref="mainRef" class="main">
          <div class="item">
            <div class="avatar">
              <img src="@/assets/service.svg" alt="avatar" />
            </div>
            <div class="box">
              <div class="content">
                <span>您好，我可以为您提供有用的信息，可以向我提问，比如 </span>
                <ol>
                  <li><b>比亚迪23年12月的销量</b></li>
                  <li><b>宋PLUS的销量</b></li>
                  <li><b>汽车行业最新动态</b></li>
                  <li><b>......</b></li>
                </ol>
              </div>
            </div>
          </div>

          <div
            v-for="(item, index) in messages"
            :key="index"
            :class="['item', {
              user: item.type === 'u',
              ai: item.type === 'ai',
            }]"
          >
            <div class="avatar">
              <img v-if="item.type === 'ai'" src="@/assets/service.svg" alt="avatar" />
              <img v-else src="@/assets/user.svg" alt="avatar" />
            </div>
            <div class="box">
              <div class="content" v-html="item.content" />
              <div v-if="item.type === 'ai'" class="desc">注意：答案来自AI智能生成，仅供参者，不代表本网观点</div>
            </div>
          </div>

          <div v-show="fetching" class="loading">
            <i class="el-icon-loading"></i>
            <span class="text">加载中...</span>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <div class="actions">
      <el-input
        ref="questionRef"
        v-model="question"
        class="question-input"
        type="textarea"
        :autosize="{ minRows: 5, maxRows: 8 }"
        :disabled="disabled || fetching"
        placeholder="请输入问题"
      />
      <el-button
        class="submit"
        type="primary"
        :disabled="disabled || fetching"
        @click="handleSend"
      >
        <img src="@/assets/send.svg" />
      </el-button>

      <div v-if="disabled" class="desc">
        <span>提示：每个用户仅限使用 20 次免费对话</span>
        <span>仍需使用请联系 18309754779</span>
      </div>
    </div>

    <el-dialog
      :visible.sync="visible"
      title="留言反馈"
      width="520px"
      append-to-body
      @closed="onClosed"
    >
      <el-form ref="formRef" :model="data" label-position="right" label-width="80px">
        <el-form-item
          label="企业名称"
          prop="company"
          :rules="{
            required: true,
            min: 2,
            message: '请输入企业名称',
          }"
        >
          <el-input
            v-model="data.company"
            placeholder="请输入企业名称"
          />
        </el-form-item>
        <el-form-item
          label="姓名"
          prop="name"
          :rules="{
            required: true,
            min: 2,
            message: '请输入姓名',
          }"
        >
          <el-input
            v-model="data.name"
            placeholder="请输入姓名"
          />
        </el-form-item>
        <el-form-item
          label="联系方式"
          prop="phone"
          :rules="[
            {
              required: true,
              message: '请输入联系方式',
            },
            {
              validator: validateUserPhone,
            },
          ]"
        >
          <el-input
            v-model="data.phone"
            placeholder="请输入联系方式"
          />
        </el-form-item>
        <el-form-item
          label="留言内容"
          prop="detailedRequirements"
          :rules="{
            required: true,
            min: 3,
            message: '请输入留言内容',
          }"
        >
          <el-input
            v-model="data.detailedRequirements"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 5 }"
            placeholder="请输入留言内容"
          />
        </el-form-item>
      </el-form>

      <template slot="footer">
        <el-button type="primary" @click="handleSubmit">提 交</el-button>
        <el-button @click="visible = false">关 闭</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getUserInfo } from '@/utils/auth.js';
import { insertRequirementRecord } from '@/api/data_new.js';

export default {
  inject: {
    appContainerIns: {
      default: () => ({}),
    },
  },
  data() {
    return {
      userInfo: {},
      question: '',
      fetching: false,
      disabled: false,
      visible: false,
      height: 400,
      data: {},
      validateUserPhone: (rule, value, callback) => {
        if (!/^1[345789][0-9]{9}$/.test(value)) {
          callback(new Error('手机号码格式不正确'));
        } else {
          callback();
        }
      },
    };
  },
  computed: {
    ...mapState({
      openAI: (state) => state.AIService.open,
      messages: (state) => state.AIService.messages,
    }),
    open() {
      const { appContainerIns, openAI } = this;
      return  openAI;
    },
  },
  watch: {
    open(open) {
      if (open) {
        this.scroll();
        this.$nextTick(() => {
          this.$refs.questionRef.focus();
          this.updateHeight();
        });
      }
    },
  },
  created() {
    const userInfo = getUserInfo();

    if (userInfo) {
      this.userInfo = { ...userInfo };
      this.userName = userInfo.userName || userInfo.loginName;
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeight);
  },
  methods: {
    ...mapActions({
      closeAIService: 'AIService/closeAIService',
      updateAIService: 'AIService/updateAIService',
      fetchAnswer: 'AIService/fetchAnswer',
    }),
    handleClose() {
      this.closeAIService();
    },
    handleMessage() {
      this.visible = true;
    },
    async handleSend() {
      try {
        const question = `${this.question || ''}`.trim();

        if (!question) {
          this.$refs.questionRef.focus();
          return;
        }

        this.updateAIService({
          type: 'u',
          content: question,
        });

        this.question = '';
        this.fetching = true;
        this.scroll();

        const { flag } = await this.fetchAnswer({
          content: question,
          userId: this.userInfo.userId,
        });
        this.disabled = flag === false;
        this.fetching = false;
        this.scroll();
      } catch {
        setTimeout(() => {
          this.fetching = false;
        }, 1050);
      }
    },
    scroll() {
      this.$nextTick(() => {
        const wrapperHeight = this.$refs.wrapperRef?.clientHeight ?? 0;
        const mainHeight = this.$refs.mainRef?.clientHeight ?? 0;
        this.$refs.scrollbarRef.wrap.scrollTop = Math.max(0, mainHeight - wrapperHeight);
      });
    },

    onClosed() {
      this.$refs.formRef.clearValidate();
    },
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.submit();
        }
      });
    },
    submit() {
      insertRequirementRecord({
        userId: this.userInfo.userId,
        requirementType: 7,
        ...this.data,
      }).then(() => {
        this.$message.success('留言反馈成功');
        this.visible = false;
      });
    },

    updateHeight() {
      const { open } = this;
      const ele = this.$refs.aiServiceRef;

      if (!open || !ele) {
        return;
      }

      const height = Math.ceil(ele.clientWidth * 400 / 570);
      this.height = Math.max(Math.min(height, 400), 260);
    },
  },
};
</script>

<style lang="scss" scoped>
.ai-container {
  position: fixed;
  z-index: 10;
  max-width: 570px;
  min-width: 420px;
  width: 33.8%;
  display: table;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #333;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.02),
    0 0 4px rgba(0, 0, 0, 0.02),
    0 0 6px rgba(0, 0, 0, 0.04),
    0 0 8px rgba(0, 0, 0, 0.04);

  .header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 12px;
    padding: 12px 16px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 12px;
      right: 12px;
      height: 1px;
      background-color: #eee;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .desc {
      margin-left: 4px;
      color: #999;
      font-weight: bold;
      .btn {
        color: #1670df;
        cursor: pointer;
      }
    }

    i {
      position: absolute;
      top: 20px;
      right: 16px;
      cursor: pointer;
    }
  }

  .wrapper {
    width: 100%;
    height: 400px;
    overflow: hidden;

    ::v-deep .el-scrollbar {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
  }

  .main {
    padding: 4px 28px;

    ::v-deep .item {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 18px;
      margin-bottom: 18px;

      .avatar {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: #0d57bc;
        border-radius: 50%;
        img {
          width: 70%;
          height: 70%;
        }
      }

      .box {
        flex: 1;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-start;
        padding: 2px 14px 0 14px;
        .content {
          position: relative;
          padding: 12px 16px;
          word-break: break-word;
          background-color: #fff;
          border-radius: 2px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
          ol, ul {
            padding-left: 1.4em;
          }
          p {
            text-indent: 2em;
          }
        }

        .desc {
          display: flex;
          justify-content: flex-end;
          margin-top: 4px;
          text-align: right;
          color: #999;
          font-size: 12px;
          transform-origin: 100% 50%;
          transform: scale(0.96);
        }
      }

      &.user {
        .avatar {
          background-color: #a8cef1;
        }
        .box {
          .content {
            background-color: #f2f7fd;
          }
        }
      }

      &.reverse {
        flex-direction: row-reverse;
      }
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      .text {
        margin-left: 2px;
        color: #666;
        font-size: 12px;
      }
    }
  }

  .actions {
    position: relative;
    ::v-deep .question-input {
      .el-textarea__inner {
        padding: 16px 28px;
        border-radius: 0 0 6px 6px;
      }
    }
    ::v-deep .submit {
      position: absolute;
      right: 28px;
      bottom: 28px;
      padding: 5px;
      min-width: 72px;
      background-color: #4c97f3;
      border-color: #4c97f3;
      img {
        width: 24px;
        height: 24px;
      }
      &.is-disabled {
        background-color: #ddd;
        border-color: #ddd;
      }
    }
    .desc {
      position: absolute;
      top: 30px;
      left: 50%;
      display: inline-flex;
      flex-direction: column;
      width: 70%;
      padding: 14px 18px;
      font-size: 12px;
      transform-origin: 0 50%;
      transform: scale(0.96);
      background-color: #fde8e8;
      transform: translateX(-50%);
    }
  }
}
</style>
