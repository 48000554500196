// 企业情报 经营情况
import $http from '@/plugins/http.js'

// 经营情况下拉内容
export function getBusinessConditionsSections(params) {
  return $http.get('/api/businessConditions/getBusinessConditionsSections', params)
}

// 经营情况分页查询内容
export function getBusinessConditionsTable(params) {
  return $http.get('/api/businessConditions/page', params)
}


// 经营情况导出
export function exportExcel(params) {
  return $http.Downloadget('/api/businessConditions/exportExcel', params)
}

