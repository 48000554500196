<!--
 * @Author: your name
 * @Date: 2021-07-22 13:25:42
 * @LastEditTime: 2021-07-22 15:41:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/views/thematicAnalysis/indexBalance.vue
-->

<template>
  <PageContainer>
    <div v-if="!show" class="not-login-box">
      <VnotLogin
        title="专题分析"
        text="专题分析栏目围绕专题分析本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。"
        table-title="最新专题分析"
        :show-tips="showTips"
      />
    </div>
    <template v-else>
      <div v-loading="loading" class="box">
        <div>
          <ul class="dropdown-menu dropdown-menu-fw">
            <template v-for="item in wdData">
              <li
                v-if="item.children && item.children.length > 0"
                :key="item.id"
                class="dropdown more-dropdown-sub1"
                :class="{ 'active': item.id == activeName }"
              >
                <a href="javascript:;" @click="toCategory(item)">{{ item.name }}<i
                  class="el-icon-arrow-down"
                  style="padding-left:10px"
                /></a>
                <ul class="dropdown-menu">
                  <li v-for="citem in item.children" :key="citem.id">
                    <a
                      href="javascript:;"
                      @click.prevent="toCategoryc(item, citem)"
                    >{{ citem.name }}</a>
                  </li>
                </ul>
              </li>
              <li v-else :key="item.id" :class="{ 'active': item.id == activeName }">
                <a href="javascript:;" @click="toCategory(item)">{{ item.name }}</a>
              </li>
            </template>
          </ul>
        </div>
        <div>
          <!--添加的标签页面-->
          <ul class="dropdown-menu dropdown-menu-fw">
            <div style="overflow: hidden;flex:1;padding-right:20px">
              <div style="float: left;margin-bottom:10px;margin-left: 10px" flex="c">
                <el-checkbox
                  v-model="xflbAll"
                  label="全部"
                  border
                  size="small"
                  style="margin-right: 8px"
                  @change="kckAll"
                />
                <el-checkbox-group
                  v-model="form.categoryIds"
                  size="small"
                  class="lable-box"
                  @change="kcheck"
                >
                  <el-checkbox v-for="item in xflbData" :key="item" :label="item.dictValue" border>
                    {{ item.dictLabel }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div
                style="float: right;margin-left: 460px"
                @click="$router.push('/thematicAnalysis/search')"
              >
                <el-input placeholder="搜索名称" size="small" readonly>
                  <i slot="suffix" class="el-input__icon el-icon-search" />
                </el-input>
              </div>
            </div>
          </ul>
        </div>

        <div v-for="item in tableData" :key="item.id" class="box_box_title" @click="toHref(item)">
          <div style="display: flex">
            <div class="text-center" style="width: 267px; height: 150px">
              <img
                style="width: 267px; height: 150px"
                :src="item.imgUrl ? item.imgUrl : defaultUrl"
                alt=""
              >
            </div>
            <div class="flex_col" flex="1">
              <div>
                <div flex="c" style="margin: 10px 0">
                  <!-- <span
                    style="
                      padding: 5px 10px;
                      background: #0D57BC;
                      color: #ffffff;
                      font-weight: 600;
                      margin-right: 10px;
                    "
                    >力荐</span
                  > -->
                  <p class="">{{ htmlRestore(item.title) }}</p>
                </div>
                <div style="font-size: 14px; color: #828282">
                  {{ htmlRestore(item.introduction) }}
                </div>
              </div>
              <div style="font-size: 14px; color: #828282">
                {{ item.publishDate }}
              </div>
            </div>
          </div>
        </div>
        <div class="pages">
          <el-pagination
            :current-page="pageData.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageData.pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 专题分析动态页面(修改过的页面)
 * @param {*}
 * @return {*}
 */
import VnotLogin from '@/components/notLogin.vue'
import { getUserInfo } from '@/utils/auth.js'
import { page } from '@/api/thematicAnalysis.js'
import { ttDemension } from '@/api/dict.js'
import { selectDictDataByType } from '@/api/dict.js'

export default {
  components: {
    VnotLogin
  },
  data() {
    return {
      defaultUrl: require('@/assets/img/default-img.png'),
      activeName: '',
      showTips: [
        {
          title: '专题分析动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '专题分析库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '专题分析梳理',
          text: '专家梳理整合，真正读懂专题分析脉络'
        },
        {
          title: '专题分析大数据',
          text: '用数据深度理解专题分析结构和趋势'
        }
      ],
      loading: false,
      tableData: [],
      show: false,
      form: {
        categoryIds: [], // 关键字
        articleCategory: '' // 文章类型
      },
      pageData: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      wdData: [],
      xflbData: [],
      xflbAll: true
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
      this.page()
      this.getweidu()
      this.selectDictDataByTypexflb()
    } else {
      this.show = false
    }
  },
  methods: {
    toHref(item) {
      const newUrl = this.$router.resolve('/thematicAnalysis/details/' + item.id)
      window.open(newUrl.href, '_blank')
    },
    // 细分类别
    selectDictDataByTypexflb() {
      selectDictDataByType({ dictType: 'ztfx_xflb' }).then((res) => {
        this.xflbData = res.data
      })
    },
    kckAll() {
      this.form.categoryIds = []
      this.page()
    },
    // 关键字
    kcheck(e) {
      if (e.length <= 0) {
        this.xflbAll = true
      } else {
        this.xflbAll = false
      }
      this.page()
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.page()
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val
      this.page()
    },
    handleClick(tab, event) {
      this.form.articleCategory = tab.name
      this.page()
    },
    // 获取列表
    page() {
      const form = Object.assign({}, this.form, this.pageData.pages)
      form.categoryIds = form.categoryIds.join(',')
      this.loading = true
      page(form).then((res) => {
        this.tableData = res.data.list
        this.pageData.total = res.data.count
        this.loading = false
      })
    },
    // 纬度获取文章类别
    getweidu() {
      ttDemension({ code: 'special_type' }).then((res) => {
        this.wdData = res.data[0].children
        this.wdData.unshift({ name: '全部', id: '' })
      })
    },
    toCategory(item) {
      this.activeName = item.id
      this.form.articleCategory = item.id
      this.page()
    },
    toCategoryc(pitem, item) {
      this.activeName = pitem.id
      this.form.articleCategory = item.id
      this.page()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === '专题分析详情' || to.name === '专题分析') {
      // 详情页路由
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }
    next()
  }

}
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__input {
    display: none;
}

::v-deep .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
}

::v-deep .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 0;
}

.box {
    position: relative;
    padding: 0 10%;

    .box_box {
        padding: 10px;
        background: #ffffff;

        /deep/ .el-input__inner {
            width: 235px;
            border-radius: 20px;
        }
    }

    .box_box_title {
        margin-top: 10px;
        background: #ffffff;
        cursor: pointer;

        p {
            font-weight: 600;
            color: #000;
            font-size: 16px;
        }

        .flex_col {
            padding: 5px 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

.lable-box {
    margin-bottom: -10px;

    .el-checkbox {
        margin-bottom: 10px;
    }
}
</style>
<style lang="scss" scoped>
.dropdown-menu-fw {
    position: relative;
    display: flex;
}

.dropdown-menu>li {
    display: block;
    position: relative;
    margin: 15px 0;
}

.dropdown-menu>li>a {
    color: #000;
    font-size: 14px;
    text-decoration: none;
    padding: 15px;
}

.dropdown-menu>li.active>a {
    color: #0D57BC;
}

.dropdown-menu {
    min-width: 175px;
    background: #fff;

    .dropdown-menu {
        position: absolute;
        z-index: 20;
        display: none;
    }

    & {

        .more-dropdown-sub1,
        .more-dropdown-sub2,
        .more-dropdown-sub3 {
            &:hover {
                .dropdown-menu {
                    display: block;
                }
            }
        }
    }
}

.el-row {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #1db317;
}

.bg-purple1 {
    margin-left: 350px;
    width: 235px;
    height: 32px;
}

// .bg-purple-light {
//     /*background: #ff0000;*/
// }
.grid-content {
    border-radius: 4px;
    height: 35px;
    min-height: 33px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}

.input-with-select {
    height: 33px;
}

/deep/.el-input__inner {
    height: 32px !important;
    border-radius: 30px;
    font-size: 13px;
    line-height: 32px;
}

/deep/.el-input__icon {
    line-height: 33px;
}
</style>
