<!--
 * @Author: your name
 * @Date: 2021-07-22 15:27:51
 * @LastEditTime: 2021-07-22 15:41:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/views/cuttingEdgeTechnology/indexBalance.vue
-->

<template>
  <PageContainer>
    <!--    <div class="not-login-box" v-if="!show">-->
    <!--      <VnotLogin title="前沿技术" text="前沿技术栏目围绕前沿技术本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新前沿技术"-->
    <!--                 :showTips="showTips"/>-->
    <!--    </div>-->
    <template slot="actionNav">
      <div class="tabs">
        <el-tabs v-model="form.contentCategory" :before-leave="leaveTab" @tab-click="tabClick">
          <el-tab-pane :key="'qb'" label="全部(原创+第三方)" name=" " />

          <el-tab-pane :key="'yc'" label="精选原创" name="yc" />
        </el-tabs>
      </div>
    </template>

    <div class="box_box">
      <div class="title_box">
        <!--        <div flex="c">-->

        <div class="search-btn-box ">
          <el-input
            v-model="values"
            class="search-controller"
            size="medium"
            clearable
            prefix-icon="el-icon-search"
            placeholder="请输入关键字"
            @clear="search"
            @keyup.enter.native="search"
          >
            <el-select slot="prepend" v-model="selectVal" placeholder="请选择">
              <el-option label="标题" value="title" />
              <el-option label="内容" value="htmlContent" />
            </el-select>
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
        </div>
      </div>
    </div>
    <ul class="dropdown-menu dropdown-menu-fw">
      <template v-for="item in keywords">
        <li
          v-if="item.children && item.children.length > 0"
          :key="item.id"
          class="dropdown more-dropdown-sub1"
          :class="{ 'active': item.id == activeName }"
        >
          <a href="javascript:;" @click="toCategory(item)">{{ item.name }}<i
            class="el-icon-arrow-down"
            style="padding-left:10px"
          /></a>
          <ul class="dropdown-menu">
            <li v-for="citem in item.children" :key="citem.id">
              <a href="javascript:;" @click.prevent="toCategoryc(item, citem)">{{ citem.name
              }}</a>
            </li>
          </ul>
        </li>
        <li v-else :key="item.id" :class="{ 'active': item.id == activeName }">
          <a href="javascript:;" @click="toCategory(item)">{{ item.name }}</a>
        </li>
      </template>
    </ul>
    <searchList
      v-loading="loading"
      show-bar
      :down-id="downId"
      update-timedown
      table-update-timet
      :table-data="list"
      :page-data="pageData"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      @toHref="toHref"
      @clickDown="clickDown"
    />

    <template slot="right">
      <div class="index-banner-title">
        <div class="index-banner-new">
          <div class="title-tag">
            <h3 class="title-tag-text" style=""><span><img src="@/assets/img/newIssue.png" alt=""></span>{{ tagText }}
            </h3>
            <div class="more" @click="moreClick">查看更多 <i class="el-icon-arrow-right" /></div>
          </div>
          <ul class="index-new-timeLine">
            <li v-for="item in tableDataRight" :key="item.id">
              <div class="index-new-timeLine-content">
                <div
                  style="cursor: pointer"
                  class="index-new-link"
                  @click="toHref('/cuttingEdgeTechnology/details/' + item.id, item)"
                >
                  <div flex="c">
                    <div v-if="item.contentCategory == 'yc'" class="ycDiv">原创</div>
                    <!--                  <div class="text-overflow" style=" padding-left: 10px;font-size: 14px;line-height: 20px;">-->
                    <TextOverTooltip ref-name="testName1" class-name="newUl-title" :content="item.title" />

                    <!--                  </div>-->

                    <div class="updateTime">{{ item.updateTime }}</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <!--        <div class="index-banner-hot">-->
        <!--          <div class="title-tag">-->
        <!--            <h3 class="title-tag-text" style=""><span><img src="@/assets/img/rankingList.png" alt=""></span>月榜排行</h3>-->
        <!--          </div>-->
        <!--          <ul class="index-new-timeLine">-->
        <!--            <li v-for="(item, index) in selectToList" :key="item.article_id">-->
        <!--              <div class="index-new-timeLine-content">-->
        <!--                <div style="cursor: pointer"-->
        <!--                     @click="rankingtoHref('/cuttingEdgeTechnology/details/' + item.article_id, item)"-->
        <!--                     class="index-new-link">-->
        <!--                  <div flex="c">-->
        <!--                    <span class="one" v-if="index === 0">{{ (index + 1).toString().padStart(2, '0') }}</span>-->
        <!--                    <span class="two" v-else-if="index === 1">{{ (index + 1).toString().padStart(2, '0') }}</span>-->
        <!--                    <span class="three" v-else-if="index === 2">{{ (index + 1).toString().padStart(2, '0') }}</span>-->
        <!--                    <span class="ranking" v-else>{{ (index + 1).toString().padStart(2, '0') }}</span>-->
        <!--                    <TextOverTooltip refName="testName1" className="newUl-title" :content="item.article_name">-->
        <!--                    </TextOverTooltip>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </div>
    </template>
    <template slot="actionBar">
      <div v-if="downReport > 0" class="downBox">
        <span> 已选中<span class="sum">{{ downReport }}</span> 篇报告（{{ downReport >= 10 ? '每次最多下载10篇' : '可继续添加' }}）</span>
        <el-button type="primary" icon="el-icon-download" @click="downAll('前沿技术','技术报告')">立即下载</el-button>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 前沿技术动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo, logRecords } from '@/utils/auth.js'
import { fmtDate } from '@/utils/date.js'
import { Message } from 'element-ui'
import searchList from '@/components/searchList.vue'
import fileMixin from '@/mixin/newFile.js'
import { selectTop } from '@/api/thematicAnalysis.js'
import { queryByPage } from '@/api/cuttingEdgeTechnology.js'
import { buryPoint } from '@/api/common'

import TextOverTooltip from '@/components/title/index'
import { ttDemension } from '@/api/dict'
export default {
  components: {
    TextOverTooltip,
    searchList
  },
  mixins: [fileMixin],
  data() {
    return {
      activeName: '',
      defaultUrl: require('@/assets/img/default-img.png'),
      showTips: [
        {
          title: '前沿技术动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '前沿技术库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '前沿技术梳理',
          text: '专家梳理整合，真正读懂前沿技术脉络'
        },
        {
          title: '前沿技术大数据',
          text: '用数据深度理解前沿技术结构和趋势'
        }
      ],
      selectVal: 'title',
      tagText: '原创推荐',
      selectToList: [],
      loading: false,
      downReport: 0,
      values: '',
      downId: [],
      checkData: [],
      list: [],
      tableDataRight: [],
      show: false,
      form: {
        keywords: [],
        contentCategory: ' '
      },
      keywords: [],
      pageData: {
        pages: {
          pageNo: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      keywordAll: true,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '',
        columnName: '前沿技术',
        userId: ''
      }
    }
  },
  watch: {
    downId: {
      deep: true,
      // immediate: true,
      handler(newVal, oldVal) {
        this.downReport = newVal.length
      }
    },
    'form.contentCategory': {
      deep: true,
      handler(newVal, oldVal) {
        this.reset()
      }
    },
    'form.keywords': {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.keywordAll = !(newVal.length > 0)
      }
    },
    selectVal: {
      deep: true,
      handler(newVal, oldVal) {
        this.queryByPage()
      }
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }
    this.selectTop()
    this.getKeyWords()
    this.queryByPage()
    this.pageRight()
  },
  methods: {
    toCategory(item) {
      this.activeName = item.id
      this.form.keywords = item.id
      this.queryByPage()
    },
    toCategoryc(pitem, item) {
      this.activeName = pitem.id
      this.form.keywords = item.id
      this.queryByPage()
    },
    selectTop() {
      selectTop({ column1: '前沿技术', column2: '技术报告' }).then(res => {
        this.selectToList = res.data.filter(item => item !== null && item !== undefined)
      })
    },
    leaveTab(activeName, oldActiveName) {
      if (!getUserInfo()) {
        return false
      }
    },
    reset() {
      this.form.keywords = []
      this.pageData = {
        pages: {
          pageNo: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      }
      this.values = ''
      this.downId = []
      this.queryByPage()
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 搜索
    search() {
      // const newUrl = this.$router.resolve('/cuttingEdgeTechnology/search?title=' + this.values)

      logRecords('前沿技术', '技术报告', '4', 'tt_technology', '', '', '', this.values)

      this.pageData.pages.pageNo = 1
      this.queryByPage()
    },
    clickDown(item, id) {
      if (this.downReport >= 10 && item.check !== true) {
        Message({
          showClose: true,
          message: '每次最多下载10篇',
          type: 'error'
        })
        return
      } else {
        if (!this.downId.includes(id)) {
          this.downId.push(id)
        } else {
          const index = this.downId.indexOf(id)
          if (index > -1) {
            this.downId.splice(index, 1)
          }
        }
        this.checkFun(item)
      }
    },
    toHref(url, item) {
      this.formLog.eventType = '1'
      const newUrl = this.$router.resolve('/cuttingEdgeTechnology/details/' + item.id)
      if (getUserInfo()) {
        logRecords('前沿技术', '技术报告', '1', 'tt_technology', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('前沿技术')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    rankingtoHref(url, item) {
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('前沿技术', '技术报告', '1', 'tt_technology', item.article_id, '', item.article_name, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('前沿技术')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.queryByPage()
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    handleCurrentChange(val) {
      this.pageData.pages.pageNo = val
      this.queryByPage()
    },
    // 查询所有关键字
    getKeyWords() {
      // getKeyWords().then((res) => {
      //   if (res.ok) {
      //     this.keywords = res.data;
      //   }
      // });
      ttDemension({ code: 'technology_type' }).then(res => {
        if (res.ok) {
          this.keywords = res.data[0].children
          this.keywords.unshift({ name: '全部', id: '' })
        }
      })
    },
    tabClick(tab, event) {
      if (getUserInfo()) {
        this.form.contentCategory = tab.name
        if (tab.name == 'yc') {
          this.tagText = '最新发布'
        } else if (tab.name == ' ') {
          this.tagText = '原创推荐'
        }
        this.pageRight()
      } else {
        this.form.contentCategory = 'yc'
        this.showLogin()
      }
    },
    moreClick(tab, event) {
      if (getUserInfo()) {
        if (this.form.contentCategory == 'yc') {
          this.tagText = '原创推荐'
          this.form.contentCategory = ' '
        } else if (this.form.contentCategory == ' ') {
          this.tagText = '最新发布'
          this.form.contentCategory = 'yc'
        }
        this.pageRight()
      } else {
        this.showLogin()
      }
    },
    // 关键字
    kcheck(e) {
      this.queryByPage()
    },
    // 关键字全选
    kckAll() {
      this.form.keywords = []
      this.queryByPage()
    },
    // 列表查询
    queryByPage() {
      const form = Object.assign({}, this.form, this.pageData.pages, { [this.selectVal]: this.values })
      // form.keywords = form.keywords ? form.keywords.join(',') : []
      this.loading = true
      queryByPage(form).then((res) => {
        this.list = res.data.list
        this.pageData.total = res.data.count
        this.loading = false
      })
    },
    // 获取右侧列表
    pageRight() {
      this.$nextTick(() => {
        const form = Object.assign({}, this.form, this.pageData.pages)
        if (form.contentCategory == 'yc') {
          form.contentCategory = ''
        } else if (form.contentCategory == ' ') {
          form.contentCategory = 'yc'
        }
        queryByPage(form).then((res) => {
          this.tableDataRight = res.data.list.map((item) => {
            item.updateTime = fmtDate(
              'YYYY-mm-dd',
              item.updateTime
            )
            return item
          })
        })
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === '前沿技术详情') {
      // 详情页路由
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }
    next()
  }
}
</script>
<style lang="scss" scoped>
.tabs {
  ::v-deep {
    .el-tabs__nav-wrap::after {
      width: 0;
    }

    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__item {
      padding: 0 24px;
      color: #666;
      height: 48px;
      line-height: 48px;
      font-weight: normal;

      &:hover {
        font-weight: normal;
      }

      &.is-active {
        color: #3f76ea;
        font-weight: bold;
      }
    }

    .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
    .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 0;
    }
  }
}

::v-deep {
  .el-checkbox__input {
    display: none;
  }

  .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
  }

  .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 0;
  }

}

.box_box {

  /*padding: 0 10%;*/
  // background-color: #ffffff;
  // padding-left: 10px;
  // padding-right: 320px;
  ::v-deep {
    .search-btn-box .el-input__inner {
      min-width: 200px;
    }

    .allBtn .el-checkbox-button__inner {
      padding: 8px 18px;
      border-radius: 4px;
    }

    .is-checked .el-checkbox-button__inner {
      background: #3f76ea;
      font-weight: normal;
      border: 0;
    }

    .title_box .el-select .el-input__inner {
      text-align: center;
      background: #fff;
      min-width: 80px;
      padding: 0 30px 0 12px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  .title_box {
    padding-bottom: 16px;
    overflow-x: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #f3f5f7;

    ::v-deep {
      .allBtn .el-checkbox-button__inner {
        padding: 8px 18px;
        border-radius: 4px;
      }
    }

    [flex="c"] {
      align-items: flex-start;
      padding-top: 3px;
    }
  }

}

.box_box_title {
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
}

.lable-box {
  margin-top: -8px;

  ::v-deep .labelCheck {
    margin-right: 10px;
    margin-top: 8px;

    .el-checkbox-button__inner {
      color: #333;
      border: 0;
      border-radius: 4px;
    }

    &.is-checked {
      .el-checkbox-button__inner {
        color: #fff;
      }
    }
  }
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  background-color: #0d57bc;
  color: #ffffff;
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  height: 57px;
  width: 41px;
  padding-top: 15px;
  writing-mode: vertical-lr;
  padding-left: 0;
  padding-right: 0;
}

.index-banner-title {

  .index-banner-new {
    background: #fff;
    padding-bottom: 18px;

    .title-tag {
      background: linear-gradient(#f2f5fd, #fff);
    }
  }

  .index-banner-hot {
    margin-top: 10px;
    padding-bottom: 18px;
    background: #fff;

    .title-tag {
      background: linear-gradient(#fff3ee, #fffbf9);
    }
  }
}

.title-tag {
  margin-bottom: 12px;
  padding: 22px 20px 0;
  background: linear-gradient(#f2f5fd, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-tag-text {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;

    span {
      padding-right: 10px;
      font-weight: bold;
      color: #0d57bc;
    }
  }

  .more {
    margin-right: -2px;
    color: #9ab7f4;
  }
}

.index-new-timeLine,
.index-new-timeLine li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.search-controller {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);

  ::v-deep .el-input__inner {
    border-color: #fff;
  }

  ::v-deep .el-input-group__prepend {
    padding-left: 12px;
    padding-right: 12px;
    color: #333;
    background-color: #fff;
    border-color: #fff;

    .el-select {
      position: relative;
      margin-left: -12px;
      margin-right: -12px;

      &:after {
        content: '';
        position: absolute;
        top: 4px;
        bottom: 4px;
        right: 0;
        width: 1px;
        background-color: #f3f5f7;
      }

      .el-input__suffix {
        right: 3px;
      }
    }
  }

  ::v-deep .el-input__prefix {
    font-size: 16px;
  }

  ::v-deep .el-input-group__append {
    padding-left: 16px;
    padding-right: 16px;
    color: #fff;
    background-color: #3f76ea;
    border-color: #3f76ea;

    .el-button {
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      border: none;
      font-weight: normal;
    }
  }
}

.index-new-timeLine {
  margin-left: 20px;
  margin-right: 20px;

  li {

    // position: relative;
    // &::before {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   top: 7px;
    //   width: 8px;
    //   height: 8px;
    //   border-radius: 50%;
    //   background: #fff;
    //   border: 2px solid #0d57bc;
    //   z-index: 1;
    // }
    .index-new-timeLine-content {
      position: relative;

      .index-new-link {
        padding: 8px 0;
        display: block;
        color: #000;

        // padding-left: 20px;
        .publishDate {
          color: #e6e6e6;
        }
        .updateTime {
          color: #e6e6e6;
        }

        span {
          font-size: 16px;
          padding-right: 10px;
          font-weight: 600;
        }

        .one {
          color: #ff0000;
        }

        .two {
          color: #ff6000;
        }

        .three {
          color: #fdd58a;
        }

        .ranking {
          color: #cccccc;
        }

        div {
          span {
            font-size: 14px;
            line-height: 16px;

            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              color: #bdbdbd;
              padding-left: 10px;
            }
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0px;

      &::after {
        top: 0;
      }

      .index-new-timeLine-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.search-btn-box {
  display: flex;
}

.ycDiv {
  display: inline-block;
  color: #0d57bc;
  font-size: 12px;
  line-height: 16px;
  padding: 2px;
  background: rgba(13, 87, 188, 0.08);
  margin-right: 4px;
}

.newUl-title {
  flex: 1;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  padding-right: 10px;
}
.dropdown-menu-fw {
  position: relative;
  display: flex;
}

.dropdown-menu>li {
  display: block;
  position: relative;
  margin: 15px 0;
}

.dropdown-menu>li>a {
  color: #000;
  font-size: 14px;
  text-decoration: none;
  padding: 15px;
}

.dropdown-menu>li.active>a {
  color: #0D57BC;
}

.dropdown-menu {
  min-width: 175px;
  background: #fff;

  .dropdown-menu {
    position: absolute;
    z-index: 20;
    display: none;
  }

  & {

    .more-dropdown-sub1,
    .more-dropdown-sub2,
    .more-dropdown-sub3 {
      &:hover {
        .dropdown-menu {
          display: block;
        }
      }
    }
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
