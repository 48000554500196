indexBalance.vue<template>
  <div class="capacity-contain">
    <div ref="bottomElement" class="bottom">
      <div class="list-title">
        利润表
      </div>
      <div class="list">
        <div class="list-sele">
          <div class="item">
            <span class="checkTitle">企业</span>
            <el-select
              v-model="form.enterprise"
              collapse-tags
              multiple
              placeholder="请选择类型"
              size="small"
              class="g-form-input"
              clearable
              filterable
            >
              <el-option
                v-for="item in enterpriseList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
            <!-- <SearchSelect placeholder="请选择类型" v-model="form.typeList" :options="typeList" size="small" /> -->
          </div>
          <div class="item item-model">
            <span class="checkTitle">年份</span>
            <el-select
              v-model="form.reportDate"
              collapse-tags
              multiple
              placeholder="请选择年份"
              size="small"
              class="g-form-input"
              clearable
              filterable
            >
              <el-option
                v-for="item in reportDateList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </div>
          <div class="item">
            <span class="checkTitle">报告期</span>
            <el-select
              v-model="form.reportPeriod"
              collapse-tags
              multiple
              placeholder="请选择类型"
              size="small"
              class="g-form-input"
              clearable
              filterable
            >
              <el-option
                v-for="item in reportPeriodList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>

          <div class="itembutton" flex="c">
            <el-button type="primary" size="mini" @click="search()">查询</el-button>
            <span><el-button type="primary" size="mini" @click="reset()">重置</el-button></span>
            <el-button
              v-has-role="'freeusers'"
              type="primary"
              plain
              class="search-button"
              :loading="excelLoading"
              @click="exportExcel"
            >导出
            </el-button>
          </div>
        </div>
        <!--                <div class="table-title">-->
        <!--                    {{ listtitle }}-->
        <!--                </div>-->
        <div v-loading="loading" class="tablelist">
          <tableIncome :table-data="tableData" :loading="listloading" />
        </div>
      </div>
    </div>
    <div v-if="operate" class="pages">
      <el-pagination
        :current-page="pageData.pages.page"
        :page-sizes="[10, 20, 30, 40, 100]"
        :page-size="pageData.pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import {
  exportExcel,
  getBusinessConditionsSections, getBusinessConditionsTable
} from '@/api/business.js'

import tableIncome from './tableIncome.vue'
import { buryPoint } from '@/api/common'
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import { downloadFile } from '@/utils/download'

// import actionTabs from '@/views/workInformation/components/actionTabs.vue'

export default {
  components: {
    tableIncome
  },
  data() {
    return {
      excelLoading: false,
      operate: true,
      listloading: false,
      listtitle: '',
      typefirstLabel: '',
      modelfirstLabel: '',
      enterfirstLabel: '',
      maxNum: '',
      orderType: '',
      mapList: [],
      reportDateList: [],
      tempcityList: [],
      tableHead: [],
      tableData: [],
      reportPeriodList: [{ label: '一季报', value: '一季报' }, { label: '中报', value: '中报' }, { label: '三季报', value: '三季报' }, { label: '年报', value: '年报' }],
      enterpriseList: [],
      pageData: {
        pages: {
          page: 1,
          pageSize: 20
        },
        total: 0
      },
      form: {
        reportDate: [],
        reportPeriod: [],
        enterprise: [],
        name: '利润表'
      },
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '企业情报',
        userId: ''
      },
      loading: false
    }
  },
  computed: {},
  created() {
    this.permissionSetting()
  },
  mounted() {
    // this.getMapData()
    this.getTableData()
    this.getSelections()
  },
  methods: {
    getSelections() {
      getBusinessConditionsSections().then(res => {
        this.reportDateList = res.data.reportDateList
        this.enterpriseList = res.data.enterpriseList
      })
    },
    getTableData() {
      this.listloading = true

      if (this.form.enterprise.length !== 0) {
        this.form.enterprise = this.form.enterprise.join(',')
      }
      if (this.form.reportDate.length !== 0) {
        this.form.reportDate = this.form.reportDate.join(',')
      }
      if (this.form.reportPeriod.length !== 0) {
        this.form.reportPeriod = this.form.reportPeriod.join(',')
      }

      const form = {
        ...this.form,
        ...this.pageData.pages
      }
      getBusinessConditionsTable(form).then(res => {
        if (this.form.enterprise.length !== 0) {
          this.form.enterprise = this.form.enterprise.split(',')
        }
        if (this.form.reportDate.length !== 0) {
          this.form.reportDate = this.form.reportDate.split(',')
        }
        if (this.form.reportPeriod.length !== 0) {
          this.form.reportPeriod = this.form.reportPeriod.split(',')
        }

        this.tableData = res.data.list
        this.pageData.total = res.data.count
        this.listloading = false
      })
    },
    // getMapData() {
    //   MapData({ isOverview: false }).then(res => {
    //     this.mapList = res.data.mapData
    //     this.maxNum = res.data.maxNum
    //   })
    // },
    // handleCustomSortChange(orderType) {
    //   this.orderType = orderType
    //   this.getTableData()
    // },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.pageData.pages.page = val
      this.getTableData()
    },

    permissionSetting() {
      if (getUserInfo()) {
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.operate = false
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.operate = false
            }
          }
        }
      } else {
        this.operate = false
      }
    },
    search() {
      this.loading = true
      if (getUserInfo()) {
        logRecords('企业情报', '国内汽车产能布局', '4', 'tt_enterprise_capacity', '', '', '', '查询')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('企业情报')) {
            this.sqsyLogin()
            this.insertBuryPoint()
            this.loading = false
            return
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              this.formLog.userId = getUserInfo().userId
              buryPoint(this.formLog)
              openSy()
              this.loading = false
              return
            } else {
              this.loading = false
            }
          }
        }
      } else {
        this.showLogin()
        this.loading = false
        return
      }

      this.pageData.pages.page = 1
      this.getTableData()
      this.loading = false
    },
    reset() {
      this.pageData.pages = {
        pageNum: 1,
        pageSize: 20
      }
      this.form = {
        reportDate: [],
        reportPeriod: [],
        enterprise: []
      }
      this.getTableData()
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
      this.loading = false
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
      this.loading = false
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    sqsy(url) {
      const newUrl = this.$router.resolve(url)

      window.open(newUrl.href, '_blank')
    },
    exportExcel() {
      this.excelLoading = true
      if (this.form.enterprise.length !== 0) {
        this.form.enterprise = this.form.enterprise.join(',')
      }
      if (this.form.reportDate.length !== 0) {
        this.form.reportDate = this.form.reportDate.join(',')
      }
      if (this.form.reportPeriod.length !== 0) {
        this.form.reportPeriod = this.form.reportPeriod.join(',')
      }
      const form = {
        ...this.form
      }

      exportExcel(form).then((res) => {
        if (this.form.enterprise.length !== 0) {
          this.form.enterprise = this.form.enterprise.split(',')
        }
        if (this.form.reportDate.length !== 0) {
          this.form.reportDate = this.form.reportDate.split(',')
        }
        if (this.form.reportPeriod.length !== 0) {
          this.form.reportPeriod = this.form.reportPeriod.split(',')
        }
        var reader = new FileReader()
        reader.readAsText(res)
        reader.addEventListener('loadend', function(e) {
          try {
            JSON.parse(e.target.result)
            const msg = JSON.parse(e.target.result)
            self.$message({
              showClose: true,
              message: msg.message,
              type: 'error'
            })
          } catch (err) {
            downloadFile(res, '经营情况-利润表.xlsx')
          }
        })
      })
      this.excelLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.capacity-top {
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    padding-top: 30px;
}

.capacity-contain {
    width: 100%;

    .bottom {
        width: 100%;
        background: #fff;

        .list {
            width: 100%;
            padding: 0 60px 10px 40px;

            .list-sele {
                display: flex;
                flex-wrap: wrap;
            }

            .item {
                padding-right: 30px;
                padding-top: 15px;
            }

            .itembutton {
                padding-top: 15px;

                button {
                    width: 120px;
                }

                span button {
                    background: #fff;
                    border: 0;
                    font-size: 12px;
                    color: rgb(153, 153, 153);
                }
            }

            .el-select {
                width: 210px;
                padding-left: 10px;
            }

            .table-title {
                font-size: 14px;
                color: rgb(34, 34, 34);
                font-weight: bold;
                padding: 30px 0 20px 0;
            }
        }

        .list-title {
            font-size: 18px;
            color: rgb(34, 34, 34);
            font-weight: bold;
            padding: 24px 60px 0 30px;
        }
    }

    .pages {
        padding: 40px 0;
        display: flex;
        justify-content: center;
        background: #fff;
    }
}
</style>
