<!--
 * @Author: your name
 * @Date: 2021-07-21 13:11:42
 * @LastEditTime: 2021-07-21 14:59:47
 * @LastEditors: Please set LastEditors
 * @Description: 国外销量数据
 * @FilePath: /information-vue/code/src/views/data/foreignSalesData/indexBalance.vue
-->

<template>
  <div class="outer-layer-box">
    <!--        <VnotLogin v-if="!show" title="数据" text="数据栏目围绕数据本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新数据" :showTips="showTips" />-->
    <template>
<!--      <Vtitle url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png" flex="sc">-->
<!--        <div class="data-top-title">-->
<!--          <div class="data-top-title-inner">-->
<!--            <span class="text">世界汽车工业</span>-->
<!--            <span class="time">已更新至{{ upDataDate }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </Vtitle>-->
      <el-form :model="form" ref="form" inline label-width="90px" class="n-data-content">
        <h1 class="n-title">显示字段</h1>
        <el-row style="margin:0px 0 10px;">
          <el-col class="n-check-m">
            <el-checkbox v-model="checkBoxShow[item.filed]" :class="index=='yearMonth'?'checkDisCss':''"
                         @change="(data)=>{checkBoxChange(data,item,index)}" :label="item.value"
                         v-for="(item,index) in queryFieldData" :key="item.value">{{ item.label }}
            </el-checkbox>
          </el-col>
        </el-row>
        <div class="n-data-line"></div>
        <h1 class="n-title">查询字段</h1>
        <el-row>
          <el-col :span="8">
            <el-form-item label="时间">
              <VdateEndStart v-model="times" :close="false" :isTimeSwitch="false" valueFormat='yyyyMM'
                             style="width:180px"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div class="smallBox">
              <span class="textInput">国家</span>
              <div class="mulSelectCss"
                   :class="{'disCss':queryFieldData.country.inputDisable,'visCss':!queryFieldData.country.inputDisable,hoverCss:hoverCss}">
                <!--                                            <span>-->
                <span class="chooseTextVal" v-if="checkObj.country.length">
                                    <el-tag size="small" type="info" @close="checkSelectClose('country')" closable
                                            class="chooseBox">{{ checkObj.country[0] }}</el-tag>
                                    <el-tag class="chooseLength" type="info" size="small"
                                            v-if="checkObj.country.length>1">+{{ checkObj.country.length - 1 }}</el-tag>
                                </span>
                <span class="chooseText" v-else>请选择国家</span>

                <!--                                            </span>-->
                <i class="el-icon-arrow-down el-icon--right iconArrow"></i>
              </div>
              <div @mouseenter="enter($event)" @mouseleave="leave($event)" class="checkSelect"
                   :class="queryFieldData.country.inputDisable?'checkSelectDis':'visCssVis'">
                <div flex="sb">
                  <div flex="c">
                    <div class="letters-box">
                      <el-button v-for="item in letters" :key="item" type="text" :class="{'act':actName == item}"
                                 @click="actBtn(item,'actName')">{{ item }}
                      </el-button>
                    </div>
                    <el-input v-model="searchValue" size="small" style="width:150px;margin-left:10px"
                              @input="filterInput" clearable></el-input>
                  </div>
                  <div>
                    <el-button type="primary" size="mini" @click="allSelect('country','actName')">全选</el-button>
                    <el-button type="primary" size="mini" @click="clear('country')">清空</el-button>
                  </div>
                </div>
                <el-checkbox-group v-if="countryList.length" v-model="checkObj.country"
                                   @change="data=>changeCountry(data,'country',false)">
                  <template v-for="item in filterValue">
                    <el-checkbox v-if="actName == '全部'" :label="item.name" :key="item.name">{{
                        item.name
                      }}
                    </el-checkbox>
                    <el-checkbox v-else-if="item.letter == actName" :label="item.name" :key="item.name">{{
                        item.name
                      }}
                    </el-checkbox>
                  </template>

                </el-checkbox-group>
                <div v-else class="nodata">暂无数据</div>

              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="smallBox">
              <span class="textInput">厂商/品牌</span>
              <div class="mulSelectCss"
                   :class="{'disCss':queryFieldData.ppai.inputDisable,'visCss':!queryFieldData.ppai.inputDisable,hoverCss:hoverCss}">
                                <span class="chooseTextVal" v-if="checkObj.ppai.length">
                                    <el-tag size="small" type="info" @close="checkSelectClose('ppai')" closable
                                            class="chooseBox">{{ checkObj.ppai[0] }}</el-tag>
                                    <el-tag class="chooseLength" type="info" size="small" v-if="checkObj.ppai.length>1">+{{
                                        checkObj.ppai.length - 1
                                      }}</el-tag>
                                </span>
                <span class="chooseText" v-else>请选择厂商/品牌</span>
                <i class="el-icon-arrow-down el-icon--right iconArrow"></i>
              </div>
              <div @mouseenter="enter($event)" @mouseleave="leave($event)" class="checkSelect"
                   :class="queryFieldData.ppai.inputDisable?'checkSelectDis':'visCssVis'">
                <div flex="sb">
                  <div flex="c">
                    <div class="letters-box">
                      <el-button v-for="item in letters" :key="item" type="text" :class="{'act':actName1 == item}"
                                 @click="actBtn(item,'actName1')">{{ item }}
                      </el-button>
                    </div>
                    <el-input v-model="searchValue1" size="small" style="width:150px;margin-left:10px"
                              @input="filterInput1" clearable></el-input>
                  </div>
                  <div>
                    <el-button type="primary" size="mini" @click="clear('ppai')">清空</el-button>
                  </div>
                </div>
                <el-checkbox-group v-if="ppaiList.length" v-model="checkObj.ppai"
                                   @change="data=>changeCountry(data,'ppai',true,'getCzhongList')">
                  <template v-for="item in filterValue1">
                    <el-checkbox v-if="actName1 == '全部'" :label="item.name" :key="item.name">{{
                        item.name
                      }}
                    </el-checkbox>
                    <el-checkbox v-else-if="item.letter == actName1" :label="item.name" :key="item.name">{{
                        item.name
                      }}
                    </el-checkbox>
                  </template>
                </el-checkbox-group>

                <!-- <el-checkbox-group v-if="ppaiList.length" v-model="checkObj.ppai" @change="data=>changeCountry(data,'ppai',true,'getCzhongList')">
                                <el-checkbox :label="item" v-for="(item) in ppaiList" :key="item">{{ item }}
                                </el-checkbox>
                            </el-checkbox-group> -->
                <div v-else class="nodata">暂无数据</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row style="position: relative">

          <el-col :span="8">
            <div class="smallBox">
              <span class="textInput">细分市场</span>
              <div class="mulSelectCss"
                   :class="{'disCss':queryFieldData.czhong.inputDisable,'visCss':!queryFieldData.czhong.inputDisable,hoverCss:hoverCss}">
                                <span class="chooseTextVal" v-if="checkObj.czhong.length">
                                    <el-tag size="small" type="info" @close="checkSelectClose('czhong')" closable
                                            class="chooseBox">{{ checkObj.czhong[0] }}</el-tag>
                                    <el-tag class="chooseLength" type="info" size="small"
                                            v-if="checkObj.czhong.length>1">+{{ checkObj.czhong.length - 1 }}</el-tag>
                                </span>
                <span class="chooseText" v-else>请选择细分市场</span>
                <i class="el-icon-arrow-down el-icon--right iconArrow"></i>
              </div>
              <div @mouseenter="enter($event)" @mouseleave="leave($event)" class="checkSelect"
                   :class="queryFieldData.czhong.inputDisable?'checkSelectDis':'visCssVis'">
                <div v-if="!checkObj.ppai.length" class="nodata">请先选择上一级</div>
                <template v-else-if="czhongList.length">
                  <div flex="sb">
                    <div flex="c">
                      <div class="letters-box">
                        <el-button v-for="item in letters" :key="item" type="text" :class="{'act':actName2 == item}"
                                   @click="actBtn(item,'actName2')">{{ item }}
                        </el-button>
                      </div>
                      <el-input v-model="searchValue2" size="small" style="width:150px;margin-left:10px"
                                @input="filterInput2" clearable></el-input>
                    </div>
                    <div>
                      <el-button type="primary" size="mini" @click="clear('czhong')">清空</el-button>
                    </div>
                  </div>
                  <el-checkbox-group v-model="checkObj.czhong"
                                     @change="data=>changeCountry(data,'czhong',true,'getJbieList')">

                    <template v-for="item in filterValue2">
                      <el-checkbox v-if="actName2 == '全部'" :label="item.name" :key="item.name">{{
                          item.name
                        }}
                      </el-checkbox>
                      <el-checkbox v-else-if="item.letter == actName2" :label="item.name" :key="item.name">{{
                          item.name
                        }}
                      </el-checkbox>
                    </template>

                    <!--
                                <el-checkbox :label="item" v-for="(item) in czhongList" :key="item">{{ item }}
                                </el-checkbox> -->
                  </el-checkbox-group>
                </template>

                <div v-else class="nodata">暂无数据</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="smallBox">
              <span class="textInput">级别</span>
              <div class="mulSelectCss"
                   :class="{'disCss':queryFieldData.jbie.inputDisable,'visCss':!queryFieldData.jbie.inputDisable,hoverCss:hoverCss}">
                                <span class="chooseTextVal" v-if="checkObj.jbie.length">
                                    <el-tag size="small" type="info" @close="checkSelectClose('jbie')" closable
                                            class="chooseBox">{{ checkObj.jbie[0] }}</el-tag>
                                    <el-tag class="chooseLength" type="info" size="small" v-if="checkObj.jbie.length>1">+{{
                                        checkObj.jbie.length - 1
                                      }}</el-tag>
                                </span>
                <span class="chooseText" v-else>请选择级别</span>
                <i class="el-icon-arrow-down el-icon--right iconArrow"></i>
              </div>
              <div @mouseenter="enter($event)" @mouseleave="leave($event)" class="checkSelect"
                   :class="queryFieldData.jbie.inputDisable?'checkSelectDis':'visCssVis'">
                <div v-if="!checkObj.czhong.length" class="nodata">请先选择上一级</div>
                <template v-else-if="jbieList.length">
                  <div flex="sb">
                    <div flex="c">
                      <div class="letters-box">
                        <el-button v-for="item in letters" :key="item" type="text" :class="{'act':actName3 == item}"
                                   @click="actBtn(item,'actName3')">{{ item }}
                        </el-button>
                      </div>
                      <el-input v-model="searchValue3" size="small" style="width:150px;margin-left:10px"
                                @input="filterInput3" clearable></el-input>
                    </div>
                    <div>
                      <el-button type="primary" size="mini" @click="clear('jbie')">清空</el-button>
                    </div>
                  </div>

                  <el-checkbox-group v-model="checkObj.jbie"
                                     @change="data=>changeCountry(data,'jbie',true,'getCxingList')">

                    <template v-for="item in filterValue3">
                      <el-checkbox v-if="actName3 == '全部'" :label="item.name" :key="item.name">{{
                          item.name
                        }}
                      </el-checkbox>
                      <el-checkbox v-else-if="item.letter == actName3" :label="item.name" :key="item.name">{{
                          item.name
                        }}
                      </el-checkbox>
                    </template>

                    <!-- <el-checkbox :label="item" v-for="(item) in jbieList" :key="item">{{ item }}
                                </el-checkbox> -->
                  </el-checkbox-group>

                </template>
                <div v-else class="nodata">暂无数据</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="smallBox">
              <span class="textInput">车型</span>
              <div class="mulSelectCss"
                   :class="{'disCss':queryFieldData.cxing.inputDisable,'visCss':!queryFieldData.cxing.inputDisable,hoverCss:hoverCss}">
                                <span class="chooseTextVal" v-if="checkObj.cxing.length">
                                    <el-tag size="small" type="info" @close="checkSelectClose('cxing')" closable
                                            class="chooseBox">{{ checkObj.cxing[0] }}</el-tag>
                                    <el-tag class="chooseLength" type="info" size="small"
                                            v-if="checkObj.cxing.length>1">+{{ checkObj.cxing.length - 1 }}</el-tag>
                                </span>
                <span class="chooseText" v-else>请选择车型</span>
                <i class="el-icon-arrow-down el-icon--right iconArrow"></i>
              </div>
              <div @mouseenter="enter($event)" @mouseleave="leave($event)" class="checkSelect"
                   :class="queryFieldData.cxing.inputDisable?'checkSelectDis':'visCssVis'">
                <div v-if="!checkObj.jbie.length" class="nodata">请先选择上一级</div>
                <template v-else-if="cxingList.length">
                  <div flex="sb">
                    <div flex="c">
                      <div class="letters-box">
                        <el-button v-for="item in letters" :key="item" type="text" :class="{'act':actName4 == item}"
                                   @click="actBtn(item,'actName4')">{{ item }}
                        </el-button>
                      </div>
                      <el-input v-model="searchValue4" size="small" style="width:150px;margin-left:10px"
                                @input="filterInput4" clearable></el-input>
                    </div>
                    <div>
                      <el-button type="primary" size="mini" @click="clear('cxing')">清空</el-button>
                    </div>
                  </div>

                  <el-checkbox-group v-model="checkObj.cxing" @change="data=>changeCountry(data,'cxing',false)">

                    <template v-for="item in filterValue4">
                      <el-checkbox v-if="actName4 == '全部'" :label="item.name" :key="item.name">{{
                          item.name
                        }}
                      </el-checkbox>
                      <el-checkbox v-else-if="item.letter == actName4" :label="item.name" :key="item.name">{{
                          item.name
                        }}
                      </el-checkbox>
                    </template>

                    <!-- <el-checkbox :label="item" v-for="(item) in cxingList" :key="item">{{ item }}
                                </el-checkbox> -->
                  </el-checkbox-group>

                </template>

                <div v-else class="nodata">暂无数据</div>
              </div>
            </div>
          </el-col>

        </el-row>
        <div class="text-center" style="margin-top: 28px;">
          <el-button @click="resetForm('form')">重置</el-button>
          <el-button type="primary" @click="search">查询</el-button>
        </div>

      </el-form>

      <div style="margin-top:10px">
        <div class="search-form">
          <div flex="sb" style="margin-bottom: 16px">
            <span class="table_title"></span>
            <div>
              <!-- <el-button type="primary" plain @click="ExportAll"  :loading="excelAllLoading">导出全部Excel</el-button> -->
              <el-button type="primary" plain @click="Export"  v-has-role="'freeusers'" :loading="excelLoading">导出
              </el-button>
            </div>
          </div>
          <div v-if="show && !showP">
            <el-table :data="tableData" style="width: 100%; margin-bottom: 20px" ref="tableRef" v-loading="loading"
                      stripe
                      border :header-cell-style="{ background: '#FAFAFA', color: '#000' }">
              <el-table-column v-for="item in headerDate" :label="getLabel(item)" :prop="item"
                               :key="item"></el-table-column>
            </el-table>
            <div>单位：辆，%</div>
            <div class="text-right">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                             :current-page="pageData.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pageSize"
                             layout="total, sizes, prev, pager, next, jumper" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
          <div v-else style="position: relative; ">
            <img style="width:100%" src="@/assets/img/sjqcgyb.jpg" alt=""/>
            <center>
              <div
                  style="position:relative;width:300px;height:94px;bottom:250px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;" v-if="!show">

                <div style="padding-top: 20px">
                  <span>【提示】数据内容需要会员<span style="color: #0D57BC;cursor: pointer" @click="showLogin2()">登录</span>后查看</span>
                </div>

                <div style="margin: 10px">
                  没有账号？可<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">申请试用</span>

                </div>
              </div>
              <div
                  style="position:relative;width:320px;height:94px;bottom:250px;font-size: 14px;font-weight: bold;background-color: #e0f9f9;box-shadow: 2px 2px 10px #d3cece;text-align: left"
                  v-else>
                <div style="padding-top: 20px;padding-left: 22px;">
                  <span style="font-size: 15px;">【提示】数据内容需要开通本栏目后查看</span>
                </div>
                <div style="padding: 10px 20px;text-align: center">
                  <span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+1)">在线申请试用</span>&nbsp;&nbsp;或&nbsp;&nbsp;<span style="color: #0D57BC;cursor: pointer" @click="sqsy('/sqSy?flag='+2)">直接联系我们</span>
                </div>
              </div>
            </center>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, logRecords, openSy} from "@/utils/auth.js";
import {
  getSaleDataGW,
  getCountryData,
  getPpaiData,
  getCzhongData,
  getJbieData,
  getCxingData,
  getTtAbroadSaleData,
  ttAbroadSaleDataDynamicExport,
  ttAbroadSaleDataExportAll,
} from "@/api/data.js";
import {downloadFile} from "@/utils/download.js";
import VdateEndStart from "@/components/dateEndStart";
import pinyin from "js-pinyin";
// import Vtitle from "../components/title.vue";
import {buryPoint} from "@/api/common";

export default {
  components: {
    // VnotLogin,
    VdateEndStart,
    // Vtitle,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    upDataDate: {
      type: String,
    }
  },
  data() {
    return {
      headerDate: [],
      hoverCss: false,
      showTips: [
        {
          title: "数据动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "数据库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "数据梳理",
          text: "专家梳理整合，真正读懂数据脉络",
        },
        {
          title: "数据大数据",
          text: "用数据深度理解数据结构和趋势",
        },
      ],
      tableData: [],
      //车辆类型
      vehicleModel: [
        {
          value: 1,
          name: "重型载货车",
        },
        {
          value: 2,
          name: "中型载货车",
        },
        {
          value: 3,
          name: "轻型载货车",
        },
        {
          value: 4,
          name: "微型载货车",
        },
        {
          value: 5,
          name: "大型客车",
        },
        {
          value: 6,
          name: "中型客车",
        },
        {
          value: 7,
          name: "轻型客车",
        },
        {
          value: 8,
          name: "基本型乘用车",
        },
        {
          value: 9,
          name: "多功能车(MPV)",
        },
        {
          value: 10,
          name: "两驱运动型多用途车(SUV)",
        },
        {
          value: 11,
          name: "四驱运动型多用途车(SUV)",
        },
        {
          value: 12,
          name: "交叉车型",
        },
        {
          value: 18,
          name: "≤25吨半挂牵引车",
        },
        {
          value: 19,
          name: "25～40吨半挂牵引车",
        },
        {
          value: 20,
          name: ">40吨半挂牵引车",
        },
      ],
      cycbj2: [
        {
          value: "8",
          name: "轿车",
        },
        {
          value: "11",
          name: "SUV",
        },
        {
          value: "9",
          name: "MPV",
        },
        {
          value: "7",
          name: "微客",
        },
      ],
      checkObj: {
        country: [],
        ppai: [],
        czhong: [],
        jbie: [],
        cxing: [],
      },
      form: {
        //国家
        country: "",
        //厂牌
        ppai: "",
        //细分市场
        czhong: "",
        //级别
        jbie: "",
        //车型
        cxing: "",
        startDate: "",
        monthEnd: "",
      },
      yearList: [],
      countryList: [],
      ppaiList: [],
      czhongList: [],
      jbieList: [],
      cxingList: [],
      swatchDateStr: "按月",
      swatchDateType: "month",
      valueFormat: "yyyy-MM",
      //分页
      pageData: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      headerNumber: 0,
      tableLablebefore: [],
      loading: false,
      tableDate: "month",
      checkBoxShow: {
        yearMonthShow: true,
        countryShow: true,
        ppaiShow: false,
        czhongShow: false,
        jbieShow: false,
        cxingShow: false,
      },
      queryFieldData: {
        yearMonth: {
          value: "date",
          label: "年月",
          isdisable: true,
          filed: "yearMonthShow",
          inputDisable: false,
        },
        country: {
          value: "country",
          label: "国家",
          isdisable: false,
          filed: "countryShow",
          inputDisable: false,
        },
        ppai: {
          value: "ppai",
          label: "厂商/品牌",
          isdisable: false,
          filed: "ppaiShow",
          inputDisable: true,
        },
        czhong: {
          value: "czhong",
          label: "细分市场",
          isdisable: true,
          filed: "czhongShow",
          inputDisable: true,
        },
        jbie: {
          value: "jbie",
          label: "级别",
          isdisable: true,
          filed: "jbieShow",
          inputDisable: true,
        },
        cxing: {
          value: "cxing",
          label: "车型",
          isdisable: true,
          filed: "cxingShow",
          inputDisable: true,
        },
      },
      detess: [],
      times: [],
      excelLoading: false,
      excelAllLoading: false,
      letters: [
        "全部",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      actName: "全部",
      actName1: "全部",
      actName2: "全部",
      actName3: "全部",
      actName4: "全部",
      searchValue: "",
      searchValue1: "",
      searchValue2: "",
      searchValue3: "",
      searchValue4: "",
      filterValue: [],
      filterValue1: [],
      filterValue2: [],
      filterValue3: [],
      filterValue4: [],
      showP: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "数据",
        userId: ""
      },
    };
  },
  created() {
    this.getCountryList();
    this.getPpaiList();
    this.getTtAbroadSaleData();

    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('国际化')) {
          this.showP = true;

        }
      }

      // this.init();
    } else {
      this.show = false;
    }

  },
  mounted() {
  },
  methods: {
    //申请试用
    sqsy(url) {
      let newUrl = this.$router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    //显示登录弹窗
    showLogin2() {
      this.$Vlogin({flag: true});
    },
    getTtAbroadSaleData() {
      getTtAbroadSaleData().then((res) => {
        if (res.ok) {
          this.times = [res.data, res.data];
          this.upDataDate = res.data;
          this.form.startDate = this.times[0];
          this.form.endDate = this.times[1];
          if (this.show) {
            this.init();

          }
        }
      });
    },
    init() {
      // let form = Object.assign({}, this.form, this.pageData);
      // for (let key in form) {
      //     if (typeof form[key] == "boolean") {
      //         if (!form[key]) {
      //             delete form[key];
      //         } else {
      //             form[key] = "1";
      //         }
      //     }
      // }

      this.loading = true;
      this.tableDate = this.swatchDateType;
      this.form.startDate = this.times[0];
      this.form.endDate = this.times[1];
      getSaleDataGW({
        ...this.form,
        ...this.pageData,
        ...this.checkBoxShow,
      }).then((res) => {
        if (res.ok) {
          this.tableData = res.data.tableData.list;
          this.headerDate = res.data.tableFiles;
          this.pageData.total = res.data.tableData.count;
          // this.lineTableData();
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    all(type) {
      this.$set(this.form, type, [""]);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //查询
    search() {
      if (getUserInfo()) {

        logRecords('国际化','世界汽车工业','3','tt_abroad_sale_data','','','','','查询')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('国际化')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.pageData.page = 1;
            this.init();
          }
        } else {
          this.pageData.page = 1;
          this.init();
        }
      } else {
        this.showLogin();
      }

    },
    //重置
    resetForm() {
      this.$refs.form.resetFields();
      this.form = {
        //国家
        country: "",
        //厂牌
        ppai: "",
        //细分市场
        czhong: "",
        //级别
        jbie: "",
        //车型
        cxing: "",
        yearStart: new Date().getFullYear(),
        monthStart: "",
        yearEnd: new Date().getFullYear(),
        monthEnd: "",
      };
      this.checkBoxShow = {
        yearMonthShow: true,
        countryShow: true,
        ppaiShow: false,
        czhongShow: false,
        jbieShow: false,
        cxingShow: false,
      };
      this.checkObj = {
        country: [],
        ppai: [],
        czhong: [],
        jbie: [],
        cxing: [],
      };
      for (let key in this.queryFieldData) {
        if (key != "yearMonth") {
          if (key == "country") {
            this.queryFieldData[key].isdisable = false;
            this.queryFieldData[key].inputDisable = false;
          } else if (key == "ppai") {
            this.queryFieldData[key].isdisable = false;
            this.queryFieldData[key].inputDisable = true;
          } else {
            this.queryFieldData[key].isdisable = true;
            this.queryFieldData[key].inputDisable = true;
          }
        }
      }
      //分页
      this.pageData = {
        page: 1,
        pageSize: 10,
        total: 0,
      };
      this.times = [];
      this.getTtAbroadSaleData();
    },
    //分页
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.init();
    },
    //分页
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.init();
    },
    // 查询字段复选框变化
    checkBoxChange(val, item, index) {
      if (!val) {
        this.form[index] = "";
        this.checkObj[index] = [];
      }
      if (index == "ppai") {
        let arr = ["czhong", "jbie", "cxing"];
        this.checkDis(val, arr, "czhong");
      } else if (index == "czhong") {
        let arr = ["jbie", "cxing"];
        this.checkDis(val, arr, "jbie");
      } else if (index == "jbie") {
        let arr = ["cxing"];
        this.checkDis(val, arr, "cxing");
      }
      for (let key in this.checkBoxShow) {
        let filed = key.substring(0, key.length - 4);
        this.queryFieldData[filed].inputDisable =
            !this.checkBoxShow[key];
        // console.log(this.queryFieldData[filed], this.checkBoxShow[key])
      }

      if (val && item.filed == "czhongShow") {
        this.$message("请选择厂商/品牌");
      }
      if (val && item.filed == "jbieShow") {
        this.$message("请选择细分市场");
      }
      if (val && item.filed == "cxingShow") {
        this.$message("请选择级别");
      }

      // console.log(this.checkBoxShow.jbieShow)

      let isArr = Array.isArray(this.form[index]);
      if (isArr) {
        this.$set(this.form, index, []);
      } else {
        this.$set(this.form, index, "");
      }
    },
    // 复选框禁用
    checkDis(val, arr, str) {
      if (!val) {
        arr.forEach((item) => {
          this.checkBoxShow[item + "Show"] = false;
          this.form[item] = "";
          this.checkObj[item] = [];
          // this.queryFieldData[item].isdisable = true;
        });
      } else {
        this.queryFieldData[str].isdisable = false;
      }
    },
    enter(e) {
      e.fromElement.classList.add("hoverCss");
    },
    leave(e) {
      e.fromElement.previousSibling.classList.remove("hoverCss");
    },
    //    获取国家
    getCountryList() {
      getCountryData().then((res) => {
        if (res.ok) {
          let newList = [];
          res.data.forEach((el) => {
            newList.push({
              name: el,
              letter: pinyin
                  .getFullChars(el)
                  .charAt(0)
                  .toUpperCase(),
            });
          });
          this.countryList = newList;
          this.filterValue = newList;
        }
      });
    },
    //    获取厂牌
    getPpaiList() {
      getPpaiData().then((res) => {
        if (res.ok) {
          let newList = [];
          res.data.forEach((el) => {
            newList.push({
              name: el,
              letter: pinyin.getFullChars(el).charAt(0),
            });
          });
          this.ppaiList = newList;
          this.filterValue1 = newList;
        }
      });
    },
    //    获取细分市场
    getCzhongList() {
      getCzhongData({ppai: this.form.ppai}).then((res) => {
        if (res.ok) {
          let newList = [];
          res.data.forEach((el) => {
            newList.push({
              name: el,
              letter: pinyin.getFullChars(el).charAt(0),
            });
          });
          this.czhongList = newList;
          this.filterValue2 = newList;
        }
      });
    },
    //    获取级别
    getJbieList() {
      getJbieData({
        ppai: this.form.ppai,
        czhong: this.form.czhong,
      }).then((res) => {
        if (res.ok) {
          let newList = [];
          res.data.forEach((el) => {
            newList.push({
              name: el,
              letter: pinyin.getFullChars(el).charAt(0),
            });
          });
          this.jbieList = newList;
          this.filterValue3 = newList;
        }
      });
    },
    //    获取车型
    getCxingList() {
      getCxingData({
        ppai: this.form.ppai,
        czhong: this.form.czhong,
        jbie: this.form.jbie,
      }).then((res) => {
        if (res.ok) {
          let newList = [];
          res.data.forEach((el) => {
            newList.push({
              name: el,
              letter: pinyin.getFullChars(el).charAt(0),
            });
          });
          this.cxingList = newList;
          this.filterValue4 = newList;
        }
      });
    },
    //监听选择
    changeCountry(val, filed, istrue, getOther) {
      let str = JSON.parse(JSON.stringify(val)).join(",");
      this.form[filed] = str;
      if (istrue) {
        this[getOther]();
      }
    },
    getLabel(val) {
      switch (val) {
        case "country":
          return "国家";
        case "ppai":
          return "厂商/品牌";
        case "czhong":
          return "细分市场";
        case "jbie":
          return "级别";
        case "cxing":
          return "车型";
        default:
          return val;
      }
    },
    checkSelectClose(filed) {
      this.checkObj[filed].splice(0, 1);
      this.form[filed] = this.checkObj[filed].join(",");
    },

    //导出
    Export() {
      if (getUserInfo()) {

        logRecords('国际化','世界汽车工业','3','tt_abroad_sale_data','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('国际化')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              openSy();
              this.insertBuryPoint();
            } else {
              this.form.startDate = this.times[0];
              this.form.endDate = this.times[1];
              let _self = this;
              this.excelLoading = true;
              let form = {...this.form, ...this.checkBoxShow};
              ttAbroadSaleDataDynamicExport(form).then((res) => {
                this.excelLoading = false;
                var reader = new FileReader();
                reader.readAsText(res);
                reader.addEventListener("loadend", function (e) {
                  try {
                    JSON.parse(e.target.result);
                    let msg = JSON.parse(e.target.result);
                    _self.$message({
                      showClose: true,
                      message: msg.message,
                      type: "error",
                    });
                  } catch (err) {
                    downloadFile(res, "世界汽车数据.xls");
                  }
                });
              });
            }
          }
        } else {
          this.form.startDate = this.times[0];
          this.form.endDate = this.times[1];
          let _self = this;
          this.excelLoading = true;
          let form = {...this.form, ...this.checkBoxShow};
          ttAbroadSaleDataDynamicExport(form).then((res) => {
            this.excelLoading = false;
            var reader = new FileReader();
            reader.readAsText(res);
            reader.addEventListener("loadend", function (e) {
              try {
                JSON.parse(e.target.result);
                let msg = JSON.parse(e.target.result);
                _self.$message({
                  showClose: true,
                  message: msg.message,
                  type: "error",
                });
              } catch (err) {
                downloadFile(res, "世界汽车数据.xls");
              }
            });
          });
        }
      } else {
        this.showLogin();
      }


    },
    ExportAll() {
      this.form.startDate = this.times[0];
      this.form.endDate = this.times[1];
      let _self = this;
      this.excelAllLoading = true;
      ttAbroadSaleDataExportAll(this.form).then((res) => {
        this.excelAllLoading = false;
        var reader = new FileReader();
        reader.readAsText(res);
        reader.addEventListener("loadend", function (e) {
          try {
            JSON.parse(e.target.result);
            let msg = JSON.parse(e.target.result);
            _self.$message({
              showClose: true,
              message: msg.message,
              type: "error",
            });
          } catch (err) {
            downloadFile(res, "世界汽车全部数据.xls");
          }
        });
      });
    },
    actBtn(item, str) {
      this[str] = item;
    },
    clear(name) {
      this.checkObj[name] = [];
      switch (name) {
        case "country":
          this.searchValue = "";
          this.filterValue = this.countryList;
          break;
        case "ppai":
          this.searchValue1 = "";
          this.filterValue1 = this.ppaiList;
          break;
        case "czhong":
          this.searchValue2 = "";
          this.filterValue2 = this.czhongList;
          break;
        case "jbie":
          this.searchValue3 = "";
          this.filterValue3 = this.jbieList;
          break;
        case "cxing":
          this.searchValue4 = "";
          this.filterValue4 = this.cxingList;
          break;
      }
    },
    allSelect(name, actName) {
      switch (name) {
        case "country":
          this.checkObj.country = this.countryList
              .filter((item) =>
                  this[actName] != "全部"
                      ? item.letter == this[actName]
                      : item
              )
              .map((item) => item.name);
          this.form[name] = this.checkObj.country.join(",");
          break;
        case "ppai":
          this.checkObj.ppai = this.ppaiList
              .filter((item) =>
                  this[actName] != "全部"
                      ? item.letter == this[actName]
                      : item
              )
              .map((item) => item.name);
          this.form[name] = this.checkObj.ppai.join(",");
          break;
        case "czhong":
          this.checkObj.czhong = this.czhongList
              .filter((item) =>
                  this[actName] != "全部"
                      ? item.letter == this[actName]
                      : item
              )
              .map((item) => item.name);
          this.form[name] = this.checkObj.czhong.join(",");
          break;
        case "jbie":
          this.checkObj.jbie = this.jbieList
              .filter((item) =>
                  this[actName] != "全部"
                      ? item.letter == this[actName]
                      : item
              )
              .map((item) => item.name);
          this.form[name] = this.checkObj.jbie.join(",");
          break;
        case "cxing":
          this.checkObj.cxing = this.cxingList
              .filter((item) =>
                  this[actName] != "全部"
                      ? item.letter == this[actName]
                      : item
              )
              .map((item) => item.name);
          this.form[name] = this.checkObj.cxing.join(",");
          break;
      }
    },
    filterInput(e) {
      // let letter = "全部";
      // if (e) {
      //     letter = pinyin.getFullChars(e).charAt(0);
      // }
      // this.actName = letter.toUpperCase();
      this.filterValue = this.fuzzyQuery(
          this.countryList,
          e.toUpperCase()
      );
    },
    filterInput1(e) {
      // let letter = "全部";
      // if (e) {
      //     letter = pinyin.getFullChars(e).charAt(0);
      // }
      // this.actName1 = letter.toUpperCase();
      this.filterValue1 = this.fuzzyQuery(this.ppaiList, e.toUpperCase());
    },
    filterInput2(e) {
      // let letter = "全部";
      // if (e) {
      //     letter = pinyin.getFullChars(e).charAt(0);
      // }
      // this.actName2 = letter.toUpperCase();
      this.filterValue2 = this.fuzzyQuery(
          this.czhongList,
          e.toUpperCase()
      );
    },
    filterInput3(e) {
      // let letter = "全部";
      // if (e) {
      //     letter = pinyin.getFullChars(e).charAt(0);
      // }
      // this.actName3 = letter.toUpperCase();
      this.filterValue3 = this.fuzzyQuery(this.jbieList, e.toUpperCase());
    },
    filterInput4(e) {
      // let letter = "全部";
      // if (e) {
      //     letter = pinyin.getFullChars(e).charAt(0);
      // }
      // this.actName4 = letter.toUpperCase();
      this.filterValue4 = this.fuzzyQuery(
          this.cxingList,
          e.toUpperCase()
      );
    },
    fuzzyQuery(list, keyWord) {
      var reg = new RegExp(keyWord);
      var arr = [];
      for (var i = 0; i < list.length; i++) {
        if (reg.test(list[i].name.toUpperCase())) {
          arr.push(list[i]);
        }
      }
      return arr;
    },
  },
};
</script>
<style lang="scss" scoped>
[flex="sb"] {
  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;
}

.is_active {
  background-image: linear-gradient(#eaeef6, #ffffff);
}

::v-deep .el-tabs__item.is-active {
  color: #0d57bc;
}

::v-deep .el-tabs__active-bar {
  background-color: #0d57bc;
}

.search-form {
  padding: 28px;
  background: #fff;
  margin-bottom: 10px;

  /deep/ {
    .el-date-editor .el-range-separator {
      width: 12%;
    }

    .el-checkbox-group {
      line-height: 34px;
    }

    .el-checkbox {
      margin-right: 16px;
    }
  }
}

.date-range-box {
  display: flex;
  align-items: center;

  .el-dropdown {
    margin-bottom: 20px;
  }
}

.newDateRange {
  display: inline-flex;
  align-items: center;
  width: 210px;

  .line-width {
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 20px;
  }

  .el-date-editor.el-input {
    width: 100%;
  }

  /deep/ {
    .el-input__icon.el-icon-date {
      display: none;
    }

    .el-input--prefix .el-input__inner {
      padding-left: 5px;
    }

    .el-input--suffix .el-input__inner {
      padding-right: 16px;
    }

    .el-input__suffix {
      right: -3px;
    }
  }
}

/deep/ .checkDisCss .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #0d57bc;
}

/deep/
.checkDisCss
.el-checkbox__input.is-disabled.is-checked
.el-checkbox__inner {
  background-color: #0d57bc;
  border-color: #0d57bc;
}

.textInput {
  text-align: right;
  vertical-align: middle;
  display: inline-block;
  padding-right: 12px;
  height: 32px;
  line-height: 32px;
  width: 90px;
  margin-bottom: 20px;
}

.mulSelectCss {
  cursor: default;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  width: 179px;
  border: 1px solid #dcdfe6;
  height: 33px;
  line-height: 32px;
  margin-bottom: 20px;
  border-radius: 4px;
  z-index: 10;
  /*border-radius:4px;*/
}

/*下拉多选*/
.mulSelectCss .iconArrow {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: #dcdfe6;
}

.visCss.mulSelectCss:hover {
  border-color: #0d57bc;
  background-color: #fff;
  border-bottom: 1px solid #fff;
  border-radius: 4px 4px 0 0;
  z-index: 1000;
}

.mulSelectCss .chooseText {
  padding-left: 12px;
  font-size: 13px;
  color: #ccc;
}

.hoverCss {
  border-color: #0d57bc;
  background-color: #fff;
  border-bottom: 1px solid #fff;
  border-radius: 4px 4px 0 0;
  z-index: 1000;
}

.disCss.mulSelectCss {
  background-color: #f5f7fa;
  cursor: not-allowed;
}

.smallBox .visCss.mulSelectCss:hover + .checkSelect {
  display: block;
}

.smallBox .checkSelect.visCssVis:hover {
  display: block;
}

.smallBox .checkSelect.visCssVis:hover .visCss {
  border-color: #0d57bc;
  background-color: #fff;
  border-bottom: 1px solid #fff !important;
  z-index: 1000;
}

.smallBox .checkSelect {
  border-radius: 4px;
  position: absolute;
  display: none;
  left: 0px;
  padding: 10px 20px;
  top: 32px;
  width: 100%;
  min-height: 40px;
  overflow: auto;
  max-height: 200px;
  border: 1px solid #0d57bc;
  z-index: 100;
  background-color: #fff;
}

.elCardOne,
.el-message {
  overflow: visible;
}

/deep/ .checkSelect .el-checkbox {
  /*width: 11%;*/
  font-weight: 400;
}

.timeSelect {
  width: 179px;
  margin-right: 5px;
}

.chooseTextVal {
  /*display: inline-block;*/
  /*height: 32px;*/
  /*line-height: 32px;*/
  margin-top: -2px;
}

.chooseBox {
  margin-left: 3px;
  margin-right: 3px;
  /*max-width: 130px;*/
  /*overflow: hidden;*/
  /*text-overflow:ellipsis;*/
  /*white-space: nowrap;*/
}

/deep/ .chooseTextVal .el-tag--small {
  height: 22px;
  line-height: 20px;
}

/deep/ .chooseTextVal .el-tag.el-tag--info .el-tag__close {
  margin-top: 2px;
}

.letters-box {
  .el-button {
    padding: 0;

    &.act {
      color: #000;
    }
  }
}
.more-icon {
  width: 15px
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
